const label = "Radio";
const name = 'Radio';

export default{
    icon: "radio_button_checked",
    label,
    name,
    haveAnswer:true,
    haveLikeAction:true,
    haveHaveModelAnswer:false,
    haveHits:false
    
}