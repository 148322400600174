import oneSipApiCall from "../apiCall.js"
import axios from "axios"
import url from "../config.js"

const userGroup={
  searchCoupon({commit,state},{router,keyword}){
    return oneSipApiCall(commit,state,router,'/admin-search-coupon', keyword)
  },
  saveCoupon({commit,state},{router,coupon}){
    return oneSipApiCall(commit,state,router,'/admin-add-coupon', coupon)
  }
}

export default userGroup;