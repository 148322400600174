const label = "Text";
const name = 'Text';

export default{
    icon: "text_fields",
    label,
    name,
    haveAnswer:false,
    haveLikeAction:false,
    haveHaveModelAnswer:false,
    haveHits:true
    
}