<template lang="html">
    <div class="md-layout md-alignment-center" ref="container">
        <div class="product-grid md-layout-item md-size-100">
            <div class="item-con" v-for="(item, i) in productList.slice(0, maxItemCount)" :key="i"
              :style="fixedCol"
            >
                <ProductCard :product="item" :darkTheme="darkTheme" :wishlist="wishlist"/>
            </div>
        </div>

        <div class="md-layout-item md-size-100 md-layout md-alignment-center " v-if="showMore">
          <md-button class="no-text-transform show-more-button"> Shop More </md-button>
        </div>
    </div>
</template>

<script>

import ProductCard from "./ProductCard";

export default {
  name: "ProductGrid",
  components: {
    ProductCard,
  },
  props: {
    showMore: {
      type: Boolean,
      default: false,
    },

    fixedColCount: {
      type: Number,
      default: null,
    },

    maxItemCount: {
      type: Number,
      default: 6,
    },

    productList: {
      type: Array,
      required: true,
    },

    darkTheme: {
      type: Boolean,
      default: false,
    },
    wishlist: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      // temp
      // maxItemCount: 6,
      // productList: [{},{},{},{},{},{},{},{},],
      // itemPrecent: "100%";

      colCount: 3,
    };
  },
  created() {
    // this.productList = [{},{},{},{},{},{},{},{},];
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
      fixedCol(){
        if( this.fixedColCount ){
          return `flex-basis: ${ 100 / this.fixedColCount - 1 }%`;
        }else{
          return `flex-basis: ${ 100 / this.colCount - 1 }%`;
        }
      },
  },
  methods: {
      onResize(){
          if( this.$refs.container.clientWidth > 600 ){
              this.colCount = 3;
          }else{
              this.colCount = 2;
          }

          // if( this.fixedColCount ){
          //   this.itemPrecent = `flex-basis: ${ 100 / this.fixedColCount - 1 }%`;
          // }else{
          //     // auto resize base on the container
          //     if( this.$refs.container.clientWidth > 600 ){
          //         this.itemPrecent = `flex-basis: ${ 100 / this.fixedColCount - 1 }%`;
          //     }
          // }
      },
  }
}
</script>

<style lang="scss" scoped>
.product-grid{
  margin: unset;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  // justify-content: space-between;
  justify-content: center;
}


.product-grid .item-con{
  flex-basis: 32%;
  margin-bottom: 10px;
}

//mobile
@media( max-width: 600px ){
  .product-grid .item-con{
    flex-basis: 49%;
  }
}

.show-more-button {
  font-size: 16px;
  color: #313131;
  text-decoration: underline;
}
</style>
