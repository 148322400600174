<template>
    <md-card style="margin:10px 0;">
        <md-card-content>
            
            <div class="preview-container">
              <div class="preview-header">
                <div class="preview-title">Preview</div>
                <div>
                  <button class="iconBtn" :class="device=='mobile'?'activate':''" @click="device = 'mobile'">
                    <img class="previewIcon" :src="require('@/assets/images/v2/Icons/icon_mobile.svg')"/>
                  </button>
                  <button class="iconBtn" :class="device=='tablet'?'activate':''" @click="device = 'tablet'">
                    <img class="previewIcon" :src="require('@/assets/images/v2/Icons/icon_tablet.svg')"/>
                  </button>
                  <button class="iconBtn" :class="device=='desktop'?'activate':''" @click="device = 'desktop'">
                    <img class="previewIcon" :src="require('@/assets/images/v2/Icons/icon_desktop.svg')"/>
                  </button>
                  <button class="iconBtn" @click="toggleFullscreen()">
                    <img class="previewIcon" :src="require('@/assets/images/v2/Icons/icon_fullscreen.svg')"/>
                  </button>
                  <button class="iconBtn" @click="previewInNewWindow()">
                    <img class="previewIcon" :src="require('@/assets/images/v2/Icons/icon_new_window.svg')"/>
                  </button>
                </div>
              </div>
              <!-- <div ref="editor" class="editor"></div> -->
              <div style="text-align:center" class="previewFrame">
                <iframe ref="preview" class="preview" :class="device"></iframe>
              </div>
            </div>
        </md-card-content>
    </md-card> 
</template>
<script>

  export default {
    components:{
    },
    methods:{
      updateHtmlPart(){
        this.$emit("update",this.content);
      },
      toggleFullscreen() {
        if (document.fullscreenEnabled || 
          document.webkitFullscreenEnabled || 
          document.mozFullScreenEnabled ||
          document.msFullscreenEnabled) {
          
          // which element will be fullscreen
          var iframe = this.$refs.preview;
          // Do fullscreen
          if (iframe.requestFullscreen) {
            iframe.requestFullscreen();
          } else if (iframe.webkitRequestFullscreen) {
            iframe.webkitRequestFullscreen();
          } else if (iframe.mozRequestFullScreen) {
            iframe.mozRequestFullScreen();
          } else if (iframe.msRequestFullscreen) {
            iframe.msRequestFullscreen();
          }
        }

      },
      fullscreenChange() {
        if (document.fullscreenEnabled ||
            document.webkitIsFullScreen || 
            document.mozFullScreen ||
            document.msFullscreenElement) {
          console.log('enter fullscreen');
        }
        else {
          console.log('exit fullscreen');
        }

        var iframe = this.$refs.preview;
        iframe.src = iframe.src;
      },
      previewInNewWindow() {
        if(this.window!=null && !this.window.closed){
          this.window.resizeTo(this.window_sizes[this.device][0],this.window_sizes[this.device][1])
        }
        else{
          const previewWindow = window.open('', 'Preview', 'width='+this.window_sizes[this.device][0]+',height='+this.window_sizes[this.device][1]);
          previewWindow.document.body.innerHTML=this.content;
          this.window=previewWindow;
        }
      }
    },

    name: "html-preview-component",
    props: {
      HtmlPart: {
        type: String,
        default(){
          return ""
        }
      },
    },
    data() {
      return {
        editor: null,
        content: "",
        device: "desktop",
        window:null,
        window_sizes:{
          "desktop":[1920,1080],
          "tablet":[1280,800],
          "mobile":[414,896]
        }
      };
    },
    computed: {

    },
    watch:{
      HtmlPart(newValue){
        this.content=newValue;
        this.$refs.preview.contentDocument.body.innerHTML=newValue;
        if(this.window!=null && !this.window.closed){
          this.window.document.body.innerHTML=this.content;
        }
      }
    },
    mounted(){
      this.$refs.preview.contentDocument.body.innerHTML=this.content;
      document.addEventListener('webkitfullscreenchange', this.fullscreenChange);
      document.addEventListener('mozfullscreenchange', this.fullscreenChange);
      document.addEventListener('fullscreenchange', this.fullscreenChange);
      document.addEventListener('MSFullscreenChange', this.fullscreenChange);
    }
  };
  </script>

  <style>
    .previewFrame{
      padding-top: 10px
    }
    .preview-title{
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      margin: 0;
    }
    .editor-container {
      display: flex;
      height: 500px;
    }

    .editor {
      width: 50%;
      height: 100%;
    }
    .preview:fullscreen, ::backdrop {
      background-color: rgba(255,255,255,1);
    }
    .preview-header{
      display:flex;
      justify-content: space-between;
    }
    .previewIcon{
      width: 24px!important;
      height:24px!important;
    }
    .iconBtn{
      background-color: white;
      border: 1px solid grey;
      padding: 2px 25px;
    }
    .iconBtn.activate{
      background-color:rgba(0, 255, 255, 0.300);
    }
    .desktop{
      width: 100%;
      min-height: 500px;
      
    }
    .tablet{
      width: 80%;
      min-height: 500px;
      
    }
    .mobile{
      width: 35%;
      min-width: 350px;
      min-height: 700px;
      
    }
  </style>