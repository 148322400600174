<template>
    <div>
        <label>{{Item.name}}</label><label style="color:red" v-if=(Item.required)>*</label>
        <md-field style="margin-top:0px;padding-top:5px">
            <md-input type=text class="formInput" v-model=Item.model @change="checkEdited" @input="checkAutocomplete($event, Item)"/>
        </md-field>
        <div class="autocompletediv" v-if="autocompleteList.length>0">
            <ul class="autocomplete-list">
                <li  v-for="autocompleteItem in autocompleteList" :key="autocompleteItem.Key">
                    <button  @click="setAutocompleteValue(autocompleteItem.Key, autocompleteItem.Name)">{{ autocompleteItem.Name }}</button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },
    name: "user-input-component",
    props: {
        Item:{
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            keyword: "",
            selectedValue: "",
            autocompleteList: [],
        };
    },
    methods: {
        checkEdited:function(){
            this.$emit("checkEdited", true);
        },
        checkAutocomplete: function(event){
  
            if(event.length > 1 && event !== this.selectedValue){
                setTimeout(() => {
                    this.searchFromServer(event);  
                }, 500);         
            }else{
                this.autocompleteList = [];
            }
            
        },
        setAutocompleteValue: function(Key, Name){
            console.log("setAutocompleteValue", Key, Name);
            this.selectedValue = Name;
            this.Item.model = Name;
            this.Item.value = Key
            this.autocompleteList = [];  
            this.keyword = "";  
        },
        searchFromServer: async function(keyword){
            var object = {
                "Keyword": keyword
            }
            try{
                const ret= await this.$store.dispatch("SearchUser",{router:this.router, keyword:object})
                console.log("searchFromServer", ret);
                var resultList = ret.data.data.hits.hits
                this.autocompleteList = [];
                for(var i=0; i<resultList.length; i++){
                    this.autocompleteList.push(
                        {
                            Key: resultList[i]._source.UserID,
                            Name: resultList[i]._source.GivenName + " " + resultList[i]._source.FamilyName + " (" + resultList[i]._source.Email + ")"
                        }
                    )
                }
            }
            catch(err){
                console.log(err.message);
            }
        },
    }
};
</script>
<style>
    .formInput{
        font-weight: 500;
        font-size: 18px !important;
    }
    .autocompletediv {
        background-color: white;
        z-index: 10;
        position: absolute;
    }
    .autocomplete-list {
        list-style: none;
        padding:unset;
        margin: unset;
        /* margin-top: -20px */
    }
    .autocomplete-list button{
        border: unset;
        background: unset;
        cursor: pointer;
    }
    .autocomplete-list li{
        border:1px solid #e9e9e9;
        border-top:unset;
    }
</style>