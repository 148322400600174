<template>
  <div>
    <label >{{Item.name}}</label>
    <label v-if=(Item.hits)>{{Item.hits}}</label>
    <md-field>
        <md-input type="text" v-model=Item.model @change="checkEdited" @focus="onFocus"/>
    </md-field>
    <Transition name="modal" v-if="showInputObjectDialog">
      <LanguageObjectInputPopup
        :Item="Item"
        v-on:popupcancel="popupcancel"
        v-on:addChip="addChip"
      />
    </Transition>

  </div>

</template>
<script>
import  LanguageObjectInputPopup from "./LanguageObjectInputPopup.vue";
export default {
  components: {
    LanguageObjectInputPopup,
    },
  methods:{
    popupcancel: function(){
      this.showInputObjectDialog = false;
    },
    addChip: function(){
      this.showInputObjectDialog = false;
        this.Item.model = "";
        for (const key in this.Item.value) {
          this.Item.model += key+":"+this.Item.value[key]+" / ";
        }
        this.Item.model = this.Item.model.slice(0, -2);
    },
    onFocus:function(){
      this.showInputObjectDialog = true;
    },
    checkEdited:function(){
      this.$emit("checkEdited", true);
    },
  },
  name: "object-input-component",
  props: {
      Item:{
        default() {
            return {};
        },
      }
  },
  data() {
    return {
      showInputObjectDialog: false,
    };
  },
  computed: {

  }
};
</script>