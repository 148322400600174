<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
          ></FormHeader>
          <center>
          <div style="width:50%">
            <md-card>
              <md-card-content style="text-align: left;">
                  <h3 class="formTitle">{{Form1Title}}</h3>
                  <LanguageObjectInput
                    v-on:checkEdited="checkEdited"
                    :Item="VarianceNameInput"
                  />
                 
                  <div class="row">
                    <div class="inputcolumn">
                      <NumberInput
                        v-on:checkEdited="checkEdited"
                        :Item="PriceInput"
                      />
                    </div>
                    <div class="inputcolumn">
                      <NumberInput
                        v-on:checkEdited="checkEdited"
                        :Item="WeightInput"
                      />
                    </div>
                  </div>
                  <div>
                    <label>Product Image</label>
                    
                      <draggable
                        :list="VarianceImageInput"
                        :group="{ name: 'g1' }"
                      >
                      <div v-for="(Item) in VarianceImageInput" :key="Item.ID">
                        <FileInput
                          v-on:RemoveItem="RemoveItem"
                          v-on:SetItemObject="SetItemObject"
                          :Item="Item"
                          :acceptType="acceptType"
                        />
                      </div>
  
                      </draggable>
                      <div style="text-align: center;" @click="addVarianceImageInput()">
                        <md-icon>add_circle_outline</md-icon> <u class="addLang">Add Image</u>
                      </div>
                  </div>
  
  
  
              </md-card-content>
            </md-card>
            <md-card style="margin:10px 0">
                <md-card-content>
                    <InventorySelecter
                        :InventoryList="InventoryList"
                        >
                    </InventorySelecter>
                </md-card-content>
            </md-card>
        </div>
        </center>
  
        </div>
      </div>
      <Spinner v-if="isLoading"/> 
      <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
    </div>
  </template>
  
  <script>
  
  import { 
    FormHeader,
    LanguageObjectInput,
    FileInput,
    NumberInput,
    InventorySelecter,
    Spinner
   } from "@/components";
   import draggable from "vuedraggable";
  
  export default {
    components: {
      FormHeader,
      LanguageObjectInput,
      FileInput,
      draggable,
      NumberInput,
      InventorySelecter,
      Spinner
    },
    created() {
      this.getLanguage();
    },
    computed: {
  
    },
    watch:{

    },
    methods:{
      SetItemObject: function(ID, object){
        var index = this.VarianceImageInput.findIndex(x => x.ID == ID)
        this.VarianceImageInput[index].url = object.url
        this.VarianceImageInput[index].base64 = object.base64
        this.$forceUpdate()
      },
      RemoveItem: function(ID){
        var index = this.VarianceImageInput.findIndex(x => x.ID == ID)
        this.VarianceImageInput.splice(index, 1)
      },
      addVarianceImageInput: function(){
        var ID = new Date().getTime();
        this.VarianceImageInput.push({ID: ID, base64: "", url: ""})
        console.log("VarianceImageInput", this.VarianceImageInput);
      },
      formAction: async function(){
        var object = {
            ProductID: this.$route.params.ProductID,
            Name: this.VarianceNameInput.value,
            Price: parseInt(this.PriceInput.value),
            Weight: parseInt(this.WeightInput.value),
        }
        var Image = [];
        for(var i = 0;i<this.VarianceImageInput.length;i++){
            var contentType= this.VarianceImageInput[i].base64.split(":",2)[1].split(";",1)[0]
            Image.push(contentType)
            }
        object["Image"] = Image;
        if(this.InventoryList.length > 0){
          object["Inventory"] = this.InventoryList
        }
        console.log("object", object);
        this.isLoading = true
        try{
            const ret = await this.$store.dispatch("createProductVariance",{router:this.router, variance:object})
            for(var i = 0; i<this.VarianceImageInput.length; i++){
                const retMedia = await this.$store.dispatch('updateMediaToS3',{file:this.VarianceImageInput[i].base64, urlObject:ret.data.UploadImageUrlList[i]})
                console.log(retMedia);
            }
            setTimeout(()=>{
                this.isLoading = false
                this.$router.replace({name: 'Product List', params:{message:"New Variance added"}});
            }, 2000);
        }catch(err){
          this.isLoading = false
          console.log("createProductVariance error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      checkEdited: async function(check){
        this.confirmBack = check
      },
      getLanguage: async function(){
        try{
          const ret= await this.$store.dispatch("getLanguage",{router:this.router, data:null})
          this.languageList = ret.data.Language;
          for(var i = 0; i< this.languageList.length; i++){
            this.VarianceNameInput.value[this.languageList[i].Language] = ""
          }
        }
        catch(err){
          console.log("getLanguage error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },

  
  
      },
  
    data() {
      return {
        isLoading: false,
        HeaderTitle: "Add Product Variance",
        Form1Title: "General Info",
        Form2Title: "Product Info",
        formActionButton: "Create Variance",
        formMessage:  null,
        showFormMessage: false,
        confirmBack:false,
        InventoryList:[],
        country:"",
        countryList:[],
        languageList:[],
        showInputObjectDialog: false,
        InputObjectActionButton: "Set",
        formItem: {},
        VarianceNameInput: {
          "type": "object",
          "key": "Name",
          "name": "Variance Name",
          "required": true,
          "style": "width: 100%;",
          "model": "",
          "value": {
          }
        },
        PriceInput:{
          "key": "Price",
          "name": "Price",
          "required": true,
          "value": "",
        },
        WeightInput: {
          "key": "Weight",
          "name": "Weight (g)",
          "required": true,
          "value": "",
        },
        ProductInfoInputList: [],
        acceptType: "image/*",
        VarianceImageInput: []
      }
    },
  
  };
  </script>
  <style>
    .dragArea {
      min-height: 50px;
      outline: 1px dashed;
    }
  </style>
  