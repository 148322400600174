import oneSipApiCall from "../apiCall.js"
import axios from "axios"
import url from "../config.js"

const analytics={
    searchUserActivity({commit,state},{router,keyword}){
        return oneSipApiCall(commit,state,router,'/AdminSearchUserActivity',keyword)
    }
}

export default analytics;