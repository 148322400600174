<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <FormHeader
                    v-on:formAction="formAction"       
                    :HeaderTitle="HeaderTitle"
                    :confirmBack="confirmBack"
                    :formActionButton="formActionButton"
                />

                <div class="row">
                    <div style="width:40%;min-width: 500px">
                        <md-card>
                            <md-card-content style="text-align: left;">
                                <h3 class="formTitle">Variance Info</h3>
                                <div class="row">
                                    <div class="col">
                                        <SelectInput
                                            :Item="VarianceInfo.StatusInput"
                                            v-on:checkEdited="checkEdited"
                                        />
                                    </div>
                                    <div class="col">
                                        <button @click="showSchedulePopup=true" class="newProductButton centerButton">Schedule</button>
                                    </div>

                                </div>

                                <TextInput
                                    :Item="VarianceInfo.EventDateInput"
                                    :readonly="readonly"
                                    v-on:checkEdited="checkEdited"
                                />
                                <TextInput
                                    :Item="VarianceInfo.EventTimeInput"
                                    :readonly="readonly"
                                    v-on:checkEdited="checkEdited"
                                />
                                <TextInput
                                    :Item="VarianceInfo.EventDurationInput"
                                    :readonly="readonly"
                                    v-on:checkEdited="checkEdited"
                                />
                                <SelectInput
                                    :Item="VarianceInfo.LocationInput"
                                    :readonly="readonly"
                                    v-on:checkEdited="checkEdited"
                                    style="padding-top:25px"
                                />
                                <TextInput
                                    :Item="VarianceInfo.MaxSeatInput"
                                    :readonly="readonly"
                                    v-on:checkEdited="checkEdited"
                                />
                                <TextInput
                                    :Item="VarianceInfo.PriceInput"
                                    :readonly="readonly"
                                    v-on:checkEdited="checkEdited"
                                />
                                <TextInput
                                    :Item="VarianceInfo.MinimumParticipator"
                                    :readonly="readonly"
                                    v-on:checkEdited="checkEdited"
                                />
                                <SelectInput
                                    :Item="VarianceInfo.HostInput"
                                    :readonly="readonly"
                                    v-on:checkEdited="checkEdited"
                                    style="padding-top:25px"
                                />
      
                            </md-card-content>
                        </md-card>
                    </div>
                    <div style="width:50%;min-width: 500px;padding-left: 50px;">
                        <md-card>
                            <md-card-content style="text-align: left;">
                                <h3>Participant List</h3>
                                <table style="text-align: center;">
                                    <thead style="text-align: center;">
                                        <th>
                                            Name
                                        </th>
                                        <th >
                                            Guest Invited
                                        </th>
                                        <th>
                                            Arrival Status
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(Participant, index) in ParticipantList" :key="index" :style="rowColor(index)" @click="onclickItem(index)" class="ParticipantRow"> 
                                        <!-- <tr v-for="(Participant, index) in ParticipantList" :key="index" >        -->
                                            <td>
                                                <label >
                                                    {{ Participant.Name }}
                                                </label>    
                                            </td>
                                            <td>
                                                <label>
                                                    {{ Participant.GuestNumber }}
                                                </label>
                                            </td>
                                            <td>
                                                <label>
                                                    {{ Participant.ParticipatedNumber }}/{{ Participant.NumberOfSeat }}
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </md-card-content>
                        </md-card>
                    </div>
                </div>
                <button class="enableButton"  @click="getSurveyLink()">Get Survey link</button>
                <button class="enableButton"  @click="getSurveyQRcode()" style="margin-left: 20px;">Survey QRcode</button>
                <button class="enableButton"  @click="goSurveyResult()" style="margin-left: 20px;">Survey Result</button>
            
            </div>
        </div>
        <UserEventRecordPopup
            :showPopup.sync="showPopup"
            :code="code"
            />
        <Spinner v-if="isLoading"/> 
        <Transition name="modal" v-if="showSchedulePopup">
            <div  class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="popup">
                            <div class="modal-header">
                                <slot name="header">Schedule</slot>
                            </div>
                            <div class="modal-body">
                                <table>
                                    <tr v-for="(schedule, index) in ScheduleList" :key="index">
                                        <td style="width: 20%;">
                                            <label>{{ schedule.Status }}</label>
                                        </td>
                                        <td style="width: 60%;">
                                            <label>{{ schedule.DateTime }}</label>
                                        </td>
                                        <td style="width: 20%;text-align: right;">
                                            <div v-if="(schedule.Status !== 'Started' && schedule.Status !== 'Ended')">
                                                <a class="remove" @click="removeSchedule(schedule.Status)">remove</a>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                <div class="row" style="text-align: center;padding-top: 20px;">
                                    <div class="column" style="width:47.5%">
                                        <label @click="closePopUp()">Cancel</label>
                                    </div>
                                    <div class="column" style="width:5%"></div>
                                    <div class="column" style="width:47.5%">
                                        <button class="cancelButton" @click="addSchedule">add</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </Transition>
        <Transition name="modal" v-if="showAddSchedulePopup">
            <div  class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="popup">
                            <div class="modal-header">
                                <slot name="header">Add Schedule</slot>
                            </div>
                            <div class="modal-body">
                                <div class="col">
                                    <SelectInput
                                        :Item="ScheduleInfo.StatusInput"
                                        v-on:checkEdited="checkEdited"
                                    />
                                </div>
                                <div class="col">
                                    <TextInput
                                        :Item="ScheduleInfo.DateTimeInput"
                                        v-on:checkEdited="checkEdited"
                                    />
                                </div>
                                <div class="row" style="text-align: center;padding-top: 20px;">
                                    <div class="column" style="width:47.5%">
                                        <label @click="closePopUp()">Cancel</label>
                                    </div>
                                    <div class="column" style="width:5%"></div>
                                    <div class="column" style="width:47.5%">
                                        <button class="cancelButton" @click="ScheduleVariance">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </Transition>
        <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
    </div>
</template>

<script>
import { 
  FormHeader,
  Spinner,
  TextInput,
  SelectInput,
  UserEventRecordPopup
 } from "@/components";
 import QRCode from 'qrcode';
export default {
  components: {  
    FormHeader,
    Spinner,
    TextInput,
    SelectInput,
    UserEventRecordPopup
  },
  data() {
    return { 
        showSchedulePopup: false,
        showAddSchedulePopup: false,
        formMessage: "",
        showFormMessage: false,
        code: '', 
        updateAttendance: false,
        showPopup: false,
        isLoading: false,
        HeaderTitle: "Edit Event Variance",
        confirmBack: false,
        formActionButton: "Save",
        LocationList: [],
        ParticipantList: [],
        eventRecord: {},
        canAttend: false,
        readonly: false,
        ScheduleList: [],
        ScheduleInfo: {
            StatusInput:{
                "type": "select",
                "key": "Status",
                "name": "Status",
                "required": true,
                "value": "",
                "option": [
                    {
                        "key": "Published",
                        "name": "公開",
                    },
                    {
                        "key": "Private",
                        "name": "私人",
                    },
                    {
                        "key": "UnListed",
                        "name": "不公開",
                    },
                ]
            },
            DateTimeInput:{
                "type": "datetime-local",
                "key": "DateTime",
                "name": "Date Time",
                "required": true,
                "value": null,
            }
        },
        VarianceInfo: {
            StatusInput:{
                "type": "select",
                "key": "Status",
                "name": "Status",
                "required": true,
                "value": "",
                "option": [
                    {
                        "key": "Draft",
                        "name": "草稿",
                    },
                    {
                        "key": "Published",
                        "name": "公開",
                    },
                    {
                        "key": "Private",
                        "name": "私人",
                    },
                    {
                        "key": "UnListed",
                        "name": "不公開",
                    },
                    {
                        "key": "Started",
                        "name": "開始",
                    },
                    {
                        "key": "Ended",
                        "name": "結束",
                    },
                    {
                        "key": "Cancelled",
                        "name": "取消",
                    }
                ]
            },
            EventDateInput:{
                "type": "Date",
                "key": "EventDate",
                "name": "Event Date",
                "required": true,
                "value": null, 
            },
            EventTimeInput: {
                "type": "time",
                "key": "EventTime",
                "name": "Event Time",
                "required": true,
                "value": null,
            },
            EventDurationInput: {
                "type": "number",
                "key": "Duration",
                "name": "Duration (minutes)",
                "required": true,
                "value": null,
            },
            LocationInput: {
                "type": "option",
                "key": "Location",
                "name": "Location",
                "required": true,
                "value": null,
                "option": []
            },
            MaxSeatInput: {
                "type": "number",
                "key": "MaxSeat",
                "name": "Max Seats",
                "required": true,
                "value": 0,
            },
            PriceInput: {
                "type": "number",
                "key": "Price",
                "name": "Price per Seat",
                "required": true,
                "value": null,
            },
            MinimumParticipator:{
                "type": "number",
                "key": "MinimumParticipator",
                "name": "Minimum Participator",
                "required": true,
                "value": 1,
            },
            HostInput: {
                "type": "option",
                "key": "HostID",
                "name": "Host",
                "required": true,
                "value": null,
                "option": []
            },
        }
    }
  },
  async created() {
    if(this.$route.params.varianceID){
        this.isLoading = true;
        await this.getEventVariance(this.$route.params.varianceID);
        await this.getParticipantList(this.$route.params.varianceID);
        this.isLoading = false;
    }else{
        this.$router.replace({name: 'Event List'})
    }
  
  },
  computed: {
    /*
        style function
    */

  },
  methods: {
    closePopUp: function(){
        this.showSchedulePopup = false;
        this.showAddSchedulePopup = false;
    },
    addSchedule: function(){
        this.showSchedulePopup = false;
        this.showAddSchedulePopup = true;
    },
    goSurveyResult: function(){
        this.$router.push({name: 'Survey Result', params: {eventID: this.$route.params.eventID, varianceID: this.$route.params.varianceID}})
    },
    getSurveyQRcode: function(){
        const url = "https://www.wesip.co/survey"
        const eventID = this.$route.params.eventID
        const varianceID = this.$route.params.varianceID
        const textToCopy = url+"/"+eventID+"/"+varianceID;

        QRCode.toDataURL(textToCopy, { errorCorrectionLevel: 'H' }, function (err, url) {
            if (err) {
                console.error(err);
            } else {
                const link = document.createElement('a');
                link.href = url;
                link.download = varianceID+'.png';
                link.click();
            }
        })
    },
    getSurveyLink: function(){


        const url = "https://www.wesip.co/survey"
        const eventID = this.$route.params.eventID
        const varianceID = this.$route.params.varianceID
        const textToCopy = url+"/"+eventID+"/"+varianceID;
        // Create a temporary textarea element
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        document.body.appendChild(textarea);

        // Copy the text from the textarea to the clipboard
        textarea.select();
        document.execCommand("copy");

        // Remove the temporary textarea element
        document.body.removeChild(textarea);

        this.formMessage = "Survey Link Copied"
        this.showFormMessage = true


    },
    cancel: function(){
        this.showPopup=false;
    },
    onclickItem: async function(index){
        console.log("onclickItem", index)
        this.code = this.ParticipantList[index].QRCode
        
        this.showPopup = true;
    },
    rowColor: function(index){
        var participant = this.ParticipantList[index]
        if(participant.NumberOfSeat == participant.ParticipatedNumber){
            return "background-color:#caf7dc !important"
        }else{
            return "background-color: #f1f1f1 !important"
        }
    },
    checkEdited: function(){
      this.confirmBack = true;
    },
    removeSchedule: async function(status){
        var object = {
            VarianceID: this.$route.params.varianceID,
            Status: status
        }
        console.log("removeSchedule", object)
        this.isLoading = true;
        try{
            const ret = await this.$store.dispatch('removeVarianceSchedule',{router:this.router, data:object})
            console.log("removeSchedule", ret)
            this.isLoading = false;
            this.getEventVariance(this.$route.params.varianceID);
            
        }catch(error){
            console.log(error)
        }      
    },
    ScheduleVariance: async function(){
        /*
            check Schedule Date Time 
            schedule date time must be after now and before event date time 24 hours
        */
        var now = new Date();
        var scheduleDateTime = new Date(this.ScheduleInfo.DateTimeInput.value);
        var eventDateTime = new Date(this.VarianceInfo.EventDateInput.value + " " + this.VarianceInfo.EventTimeInput.value);
        var eventDateTime24 = new Date(eventDateTime.getTime() - 24*60*60*1000);
        console.log("now", now)
        console.log("scheduleDateTime", scheduleDateTime)
        console.log("eventDateTime", eventDateTime)
        console.log("eventDateTime24", eventDateTime24)
        if(scheduleDateTime < now){
            this.formMessage = "Schedule Date Time must be after now"
            this.showFormMessage = true
            return;
        }
        if(scheduleDateTime > eventDateTime24){
            this.formMessage = "Schedule Date Time must be before event date time 24 hours"
            this.showFormMessage = true
            return;
        }
        var object = {
            VarianceID: this.$route.params.varianceID,
            Status: this.ScheduleInfo.StatusInput.value,
            DateTime: this.ScheduleInfo.DateTimeInput.value
        }
        console.log("ScheduleVariance", object)
        this.isLoading = true;
        try{
            const ret = await this.$store.dispatch('setVarianceSchedule',{router:this.router, data:object})
            this.isLoading = false;
            console.log("ScheduleVariance", ret)
            this.showAddSchedulePopup = false;
            this.getEventVariance(this.$route.params.varianceID);
        }catch(error){
            console.log(error)
        }
    },
    getEventVariance: async function(VarianceID){
        console.log("get variance", VarianceID); 
        this.isLoading = true;
        try{
            const ret= await this.$store.dispatch('getEventVariance',{router:this.router, data: {VarianceID: VarianceID}})
            this.isLoading = false;
            console.log("getEventVariance", ret.data.Variance)
            var variance = ret.data.Variance
            this.VarianceInfo.StatusInput.value = variance.Status
            this.VarianceInfo.EventDateInput.value = variance.EventDate
            this.VarianceInfo.EventTimeInput.value = variance.EventStartTime
            this.VarianceInfo.EventDurationInput.value = variance.Duration
            this.VarianceInfo.MaxSeatInput.value = variance.MaxSeat
            this.VarianceInfo.PriceInput.value = variance.Price
            this.VarianceInfo.MinimumParticipator.value = variance.MinimumParticipator
            this.VarianceInfo.LocationInput.value = variance.Location.EventHostLocationID
            this.VarianceInfo.HostInput.value = variance.Host.HostID
            if(variance.Status == "Draft"){
                this.readonly = false;
                this.getHost();
                this.listLocations();
            }else{
                this.readonly = true;
                this.VarianceInfo.LocationInput.option.push({
                    "key": variance.Location.EventHostLocationID,
                    "name": variance.Location.Name
                })
                
                this.VarianceInfo.HostInput.option.push({
                    "key": variance.Host.HostID,
                    "name": variance.Host.Name.zh + " ( " + variance.Host.Name.en + " )"
                })
            }
            if(variance.Schedule.length > 0){
                this.ScheduleList = [];
                for(var s in variance.Schedule){
                    /*
                        DateTime = ttl to string
                    */
                    this.ScheduleList.push({
                        Status: variance.Schedule[s].Status,
                        DateTime: new Date(variance.Schedule[s].ttl*1000).toLocaleString()
                    })
                }
            }
        

        }catch(err){
            this.isLoading = false;
            console.log("getEventVariance error", err);
        }
    },
    getHost: async function(){    
        try{
            const ret= await this.$store.dispatch('getHost',{router:this.router})

            console.log("getHost", ret)
            var hosts = ret.data.Host
            this.HostList = hosts
            for(var i= 0 ; i<hosts.length; i++){
                var object = {
                "key": hosts[i].HostID,
                "name": hosts[i].Name.zh + " ( " + hosts[i].Name.en + " )"
                }
                this.VarianceInfo.HostInput.option.push(object)
            }
        }catch(err){
            this.isLoading = false;
            console.log("getHost error", err);
        }
    },
    listLocations: async function(){
        try{
            const ret= await this.$store.dispatch('listLocations',{router:this.router})
            console.log("listLocations", ret)
            var locations = ret.data.data.Items
            this.LocationList = locations
            for(var i= 0 ; i<locations.length; i++){
                var object = {
                "key": locations[i].EventHostLocationID,
                }
                if(locations[i].SaleableArea){
                object.name = locations[i].Name + " ( " + locations[i].SaleableArea.toString() + " ft² )"
                }
                else{
                object.name = locations[i].Name
                }
                this.VarianceInfo.LocationInput.option.push(object)
            }
        }catch(err){
            this.isLoading = false;
            console.log("listLocations error", err);
        }
    },
    getParticipantList: async function(VarianceID){
        try{
            const ret= await this.$store.dispatch('getParticipantList',{router:this.router, data: {VarianceID: VarianceID}})
            console.log("getParticipantList", ret)
            var participants = ret.data.Participant
            this.ParticipantList = [];
            for(var p in participants){
                var name = ""
                if(participants[p].userInfo.Name){
                    name = participants[p].userInfo.Name
                }else{
                    name = participants[p].userInfo.GivenName + " " + participants[p].userInfo.FamilyName
                }
                var GuestNumber = 0
                if(participants[p].Guest && participants[p].Guest.length > 0){
                    GuestNumber = participants[p].Guest.length
                }
                var ParticipatedNumber = 0;
                if(participants[p].ParticipatedNumber){
                    ParticipatedNumber = participants[p].ParticipatedNumber
                }
                this.ParticipantList.push({
                    UserID: participants[p].UserID,
                    Name: name,
                    GuestNumber: GuestNumber,
                    Guest: participants[p].Guest,
                    NumberOfSeat: participants[p].NumberOfSeat,
                    ParticipatedNumber: ParticipatedNumber,
                    QRCode: participants[p].QRCode
                })
            }
            console.log("ParticipantList", this.ParticipantList);
            
        }catch(err){
            this.isLoading = false;
            console.log("getParticipantList error", err);
        }
    },
    /*
        form function
    */
    formAction() {
        var object = {
            VarianceID: this.$route.params.varianceID,
            Status: this.VarianceInfo.StatusInput.value,
        }
        if(this.VarianceInfo.StatusInput.value == "Draft"){
            object.EventDate = this.VarianceInfo.EventDateInput.value
            object.EventStartTime = this.VarianceInfo.EventTimeInput.value
            object.Duration = this.VarianceInfo.EventDurationInput.value
            object.MaxSeat = this.VarianceInfo.MaxSeatInput.value
            object.Price = this.VarianceInfo.PriceInput.value
            object.MinimumParticipator = this.VarianceInfo.MinimumParticipator.value
            object.Location = this.VarianceInfo.LocationInput.value
            object.HostID = this.VarianceInfo.HostInput.value
        }

        console.log("formAction", object)
        this.isLoading = true;
        try{
            this.$store.dispatch('updateEventVariance',{router:this.router, data: object})
            .then((ret)=> {
                this.isLoading = false;
                console.log(ret)
                this.formMessage = "Update Success"
                this.showFormMessage = true
                this.confirmBack = false;
                this.getEventVariance(this.$route.params.varianceID);
            })
            .catch(err=>{
                this.isLoading = false;
                console.log(err.message)
                if(err?.response?.status==403){
                    alert(err.response.data.message);
                }
            })
        }catch(err){
            console.log("updateEventVariance error", err);
        }
    },
    /*
        form function
    */
  },
  watch:{
    showPopup:function(newVal,oldVal){
      console.log(newVal)
      this.getParticipantList(this.$route.params.varianceID);
    },
    updateAttendance:function(newVal,oldVal){
      console.log("updateAttendance", newVal)
    }
  }
}
</script>
<style>
    .tab{
        text-align: center;
        padding-right: 1%;
    }
    .tabLabel{
        font-size: 25px;
    }
    .tabClick{
        cursor: pointer;
    }
    .tabSelected{
        background-color: black;
        color: white;
    }
    .tabNotSelected{
        background-color: white;
        color: black;
    }
    .TagePage{
        text-align: center;
        margin: unset;
        height: 75vh;
    }
    .ParticipantRow{
        cursor: pointer;
    }
</style>
