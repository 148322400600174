<template>
    <div>
        <h3 class="formTitle">{{ImageEditorTitle}}</h3>
        <table class="DragDropTable" style="width: 100%;margin-top:20px">
            <tr v-for="(value, key) in Image" :key="key">
                <td style="width: 70%">
                
                    <label>{{key}}</label><br>
                    <img 
                        :src=value
                        style="width: 400px; height: 200px; padding-top: 30px;object-fit: contain;"

                        />
                    <md-field >
                    <md-file
                        name="BackgroundImage"
                        id="BackgroundImage"
                        :disabled="sending"
                        accept="image/*"
                        @md-change="confirmUploadFile($event, key, value)"
                        />
                    </md-field>
                </td>
                <td style="width: 30%;text-align: center;">
                    <div class="imagePreview"  @click="previewImage(value)"><u>Preview</u></div>
                </td>
            </tr>
        </table>
    </div>
</template>
    <script>
      export default {
        methods:{
            previewImage: function(url){
                window.open(url, '_blank');
            },
            confirmUploadFile: async function (fileList, id, oldImage) {
                var MediaURLList = []
                if(fileList.constructor === FileList && fileList[0] !==undefined){
                    for(var i = 0; i<fileList.length; i++){
                        var file=fileList[i];
                        console.log("file", file)
                        var object = {
                        "file": file,
                        "base64": await this.getBase64(file),
                        "url": URL.createObjectURL(file),
                        }
                        MediaURLList.push(object);  
                        //this.MediaBase64List[i]=file;
                    }
                }
                var object = {
                "id": id,
                "MediaURLList": MediaURLList,
                "imageUrl": oldImage,
                }
                this.$emit("confirmUploadFile", object);
            },
            getBase64(file) {
                const reader = new FileReader()
                return new Promise(resolve => {
                reader.onload = ev => {
                    resolve(ev.target.result)
                }
                reader.readAsDataURL(file)
                })
            },
        },
        name: "image-editor-component",
        props: {
            Image:{
                required: true,
                type: Object,
                default(){
                    return {}
                }
            },
            ImageEditorTitle:{
                required: true,
                type: String,
                default(){
                    return ""
                }
            },
            // newImage:{   
            //     type: String,
            //     default(){
            //         return null
            //     }
            // },
            sending:{
                required: true,
                default(){
                    return false
                }
            },
        },
        data() {
          return {
          };
        },
        computed: {
    
        }
      };
    </script>
    <style>
        .imagePreview{
            font-weight: 400;
            font-size: 18px;
            padding-top:25px;
            cursor: pointer;
        }
    </style>