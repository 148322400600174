import axios from "axios"

import url from "./config.js"


const errorHandler={
    async handle(commit,err,router,action,data){
        var refreshToken=localStorage.getItem('refreshToken')
        if(!refreshToken){
            console.log("errorHandler no refreshToken");
            return false
        }
        const response = await axios({url: url+'/admin-refresh-token', data: {'refreshToken':refreshToken}, method: 'POST' })
        console.log("errorHandler resp", response.data);
        if(!response.data.AuthenticationResult){
            console.log("errorHandler no AuthenticationResult");
            return false
        }
        const idToken=response.data.AuthenticationResult.IdToken;
        const accessToken=response.data.AuthenticationResult.AccessToken;
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common['Authorization'] = idToken;

    
        return new Promise((resolve,reject)=>{

            axios({url: url+action, data:data, method: 'POST' })
            .then(resp => {
                commit('action_success');
                resolve(resp)
            })
            .catch(async err => {
                localStorage.removeItem('idToken')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                delete axios.defaults.headers.common['Authorization']
                router.push({path:"/login"})
                reject(err)    
            })
        })   
    }
}

export default errorHandler;