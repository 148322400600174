<template>
    <div class="content">
        <div class="md-layout">
            <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
            >
            <FormHeader
                v-on:formAction="formAction"
                :HeaderTitle="HeaderTitle"
                :confirmBack="confirmBack"
                :formActionButton="formActionButton"
            />
                
            </div>
        </div>
        <Spinner v-if="isLoading"/> 
        <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isLoading: false,
                showFormMessage: false,
                formMessage: '',
                formActionButton: 'Update',
                confirmBack: true,
                HeaderTitle: 'Update Taste Card',
            }
        },
        async created() {
            this.isLoading = true;
            await this.getTasteCard(this.$route.params.ProductID);
            this.isLoading = false;
        },
        methods:{
            getTasteCard: async function(ProductID){
               const ret = await this.$store.dispatch('getTasteCard',{router:this.router, data:{ProductID:ProductID}})
               
            },
        }
    }
</script>