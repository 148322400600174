<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <TableHeader
        :HeaderTitle="HeaderTitle"
        :AddNewButton="AddNewButton"
        :backButton=false
        v-on:createNewItem="createNewItem"
      ></TableHeader>
      <md-card>
          <md-card-content>
            <TableComponent 
            v-on:selectItem="selectItem"
            v-on:selectAllItem="selectAllItem"
            v-on:searchItem="searchItem"
            v-on:onclickItem="onclickItem"
            v-on:selectSortOption="selectSortOption"
            v-on:selectedItemActionFunction="selectedItemActionFunction"
            v-on:goPage="goPage"
            :TableList="TableItemList"
            :selectedItemCounter="selectedItemCounter"
            :selectedItem="selectedItem"
            :columns="columns"
            :selectAll="selectAll"
            :selectable="selectable"
            :haveSearchFunction="haveSearchFunction"
            :sortOption="sortOption"
            :selectedItemAction="selectedItemAction"
            :pageNumber="pageNumber"
            :showNextButton="showNextButton"
            ></TableComponent>
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
    </div>
    <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
    
  </div>
</template>

<script>
import { 
  TableComponent,
  TableHeader,
  Popup
} from "@/components";

export default {
  components: {
    TableComponent,
    TableHeader,
  },
  data() {
    return {
      message: "",
      showPopup: false,
      popupAction: "",
      PopupTitle: "",
      PopupContent: "",
      actionButton: "",
      index: 0,
      pageNumber: 1,
      showMessage: false,
      showNextButton: true,
      displaySize: 10,
      HeaderTitle: "Content Management",
      TableItemList: [],
      AllItemList: [],
      AddNewButton:  "",
      columns: ["Content Information"],
      sortOption:[],
      selectedItemAction: [
      ],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: false,
      haveSearchFunction: false,
      SearchResultList: [],
    }
  },
  created() {
    
      this.message = this.$route.params.message;
      // if(this.$route.params.page){
      //   if(Number(this.$route.params.page) > 0){
      //     this.pageNumber = Number(this.$route.params.page)
      //   }
      //   this.index = this.index + (Number(this.$route.params.page) - 1 )*this.displaySize
      // }
      this.checkShowMessage();
      this.listItem();
    },
  methods:{
    goPage:function(action){
      if(action == "next"){
        this.index = this.index + this.displaySize
        this.pageNumber = this.pageNumber + 1
      }else if(action == "prev" && this.index > 0){
        this.index = this.index - this.displaySize
        this.pageNumber = this.pageNumber - 1
      }else{
        this.index = 0
        this.pageNumber = 1
      }
      this.setDisplayList(this.AllItemList)
    },
    checkShowMessage: async function(){
      if(this.message){
        try{
          await this.delayFunction();
          this.showMessage = true;
        }catch(err){
          console.log(err)
        }
      }
    },
    delayFunction:async function (){
        setTimeout(function(){
          this.showMessage = false;
          this.message = "";
          return true;
        }, 1000);
    },
    onclickItem: function(ID){
      console.log("onclickItem", ID);
      this.$router.push({path: 'ContentManagement/'+ID})
    },
    createNewItem: function(){
      console.log("createNewItem")
    },
    searchItem: async function(keyword){
      console.log("searchItem", keyword);

    },
    selectSortOption: function(option){
      console.log("charge sorting with " + option);
    },
    selectedItemActionFunction: function(action){
      console.log("selectedItemActionFunction", action);
    },
    selectAllItem:function(action){
      this.selectAll = false;
      if(action){
        this.selectAll = true;
        for(var i = 0; i< this.TableItemList.length; i++){
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if(index < 0){
              this.selectedItem.push(this.TableItemList[i].ID)
          }
        }
      }else{
          for(var i = 0; i< this.TableItemList.length; i++){
              var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
              this.selectedItem.splice(index, 1);
          }
      }
      this.selectedItemCounter = this.selectedItem.length
    },
    selectItem:function(ID, action){
      if(action){
        this.selectedItem.push(ID)
      }else{
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID)
        this.selectedItem.splice(index, 1);
      }
      this.selectedItemCounter = this.selectedItem.length
    },
    listItem: async function(){
      try{
        const ret= await this.$store.dispatch('getInformationList',{router:this.router})
        this.AllItemList = ret.data.Information
        this.setDisplayList(this.AllItemList)
      }catch(err){
        console.log("getInformationList error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    setDisplayList(list){
      var newlist=[];
      var additionalIndex = (this.pageNumber-1) * this.displaySize
      var loopSize = this.displaySize;
      if(list.length < this.pageNumber*this.displaySize){
        loopSize = list.length - additionalIndex
      }
      for(var i = 0; i<loopSize; i++){
        var itemIndex = i+additionalIndex
        var object = {
            "ID": list[itemIndex].id,
            "Content Information": list[itemIndex].id,
          }
          newlist.push(object)
      }
      this.TableItemList = newlist;
      this.TableSelectUIControl(this.TableItemList)
    },
    TableSelectUIControl(ItemList){
      this.showNextButton = true;
      if(ItemList.length < this.displaySize){
        this.showNextButton = false;
      }

      var counter = 0;
      for(var i=0; i<ItemList.length;i++){
          var index = this.selectedItem.indexOf(ItemList[i].ID);
          if(index > -1){
              counter++
          }
      }
      if(counter ==  ItemList.length){
          this.selectAll = true;
      }else{
          this.selectAll = false;
      }
    }
  }
};
</script>
