
<template>
    <div>
    <div class="row" v-if="(haveSearchFunction || sortOption.length>0)">
        <div class="column" v-if="haveSearchFunction" :style="searchWidth">
            <input class="nosubmit" type="search" placeholder="Search..." v-on:keyup.enter="searchItem($event)">
        </div>
        <div class="column" v-if="(filterOption.length>0)">
          <button class="sortButton" @click="filterContorl(true)"><md-icon>import_export</md-icon>Filter</button>
        </div> 
        <div class="column"  v-if="(sortOption.length>0)">
          <button class="sortButton" @click="SortPopupControl()"><md-icon>import_export</md-icon>Sort</button>
        </div>     
    </div>
    <div v-if="showSortPopup" class="sortPopup">
      <div class="radiolist">
        <div v-for="Item in sortOption" :key="Item.Key" class="row" v-on:click="selectSortOption(Item.Key)">
          <div class="column" style="width:20%;text-align: center;">
            <input type="radio" class="sortradio" name="sortoption" :checked="Item.Value" />
          </div>
          <div class="column" style="width:80%" >
            <md-icon v-if="Item.Order=='desc'">arrow_downward</md-icon><md-icon v-else>arrow_upward</md-icon>{{Item.Name}}
            <!-- {{Item.Name}} -->
          </div>
          
        </div>
       </div>
    </div>
    <div class="row selectedrow">
      <div v-if="(selectedItemCounter > 0)" class="column actionItem" >
        {{selectedItemCounter}} selected
      </div>  
      <div v-if="(selectedItemCounter > 0)" class="column actionItem" >
        <u @click="buttonSelectAll">select all</u>
      </div> 
      <div class="column" v-if="((selectedItemCounter > 0) && selectedItemAction.length>0)">
          <div class="row">
            <div class="column actionItem"  v-for="Item in selectedItemAction" :key="Item.Key">
              <u @click="selectedItemActionFunction(Item.Key)" :style="Item.Style">{{Item.Name}}</u>
            </div>
        </div>
      </div> 
    </div>
      <div class="tableFixHead">
      <table style="width:100%;" >
          <thead>
              <th v-if="selectable" style="width:50px"><input type="checkbox" class="tablecheckbox" v-model="selectAll" v-on:click="selectAllItem($event)"/> </th>
              <th v-for="header in columns" :key="header">
                <div v-if="header == 'img'">
                  </div>
                  <div v-else v-on:click="sortBy(header)" class="tableHeader">
                    {{header}}
                  </div>
              </th>
              <th v-if="canClone" :key="Clone">
                <div class="tableHeader">
                  Clone
                </div>
              </th>
          </thead>
          <tbody>
            <tr v-for="Item in TableList" :key="Item.ID"  :id="Item.ID"  :class="{'isSelected': inSelected(Item.ID)}">
              <td v-if="selectable" style="width:50px"><input type="checkbox" class="tablecheckbox" v-bind:value=Item.ID v-model="selectedItem" v-on:click="selectItem(Item.ID, $event)"/> </td>
              <td v-for="header in columns" :key="header" @click="onclickItem(Item.ID)" style="cursor: pointer" :class="{'tdImg': isImg(header)} ">
                <div v-if="header == 'img'">
                  <img class="tableImg" :src=Item[header]>
                </div>
                <div v-else>
                  {{Item[header]}}
                </div>
              </td>
              <td v-if="canClone">
                <md-button @click="cloneAction(Item.ID)" class="cloneBtn" :id="`clone-${Item.ID}`">
                  Clone
                </md-button>
              </td>
            </tr>
          </tbody>

      </table>
      </div>
        <div class="row" v-if="showPageNumber">
          <div class="column" style="width:45%;text-align:right">
            <md-button v-if="(pageNumber>1)" class="backbutton" style="background-color:white !important;" @click="goPage('prev')">
              <md-icon class="backIcon" style="color:black !important;margin-left: -10px;">arrow_back_ios</md-icon>
            </md-button>
          </div>  
          <div class="column" style="width:10%;text-align:center">
            <div style="padding-top:25px;font-size:18px">{{pageNumber}}</div>
          </div>  
          <div class="column" style="width:45%;text-align:left">
            <md-button v-if="showNextButton" class="backbutton" style="background-color:white !important;" @click="goPage('next')">
              <md-icon class="backIcon" style="color:black !important;margin-left: -10px;">arrow_forward_ios</md-icon>
            </md-button>
          </div> 
        </div> 

        <Transition name="modal" >
          <div  class="filter-mask" >
            <div class="modal-wrapper">
              <div class="filter-container" >
                <div class="row filterRow">
                   <div class="column filterHeader" style="width:50%">
                    Filter
                   </div>
                   <div class="column filterClose" style="width:50%">
                    <button @click="filterContorl(false)" class="filterCloseButton"><md-icon >cancel</md-icon></button>
                   </div>
                </div>
                <hr>
                <div v-for="filter in filterOption" :key="filter.Key" style="padding-top:25px">
                  <div class="tableCollapsible">
                    <input type="checkbox" style="display:none" :id=filter.Name checked/>
                    <div class="row filterRow" @click="expandItem(filter.Name)">
                    <div class="filterOptionTitle" style="width: 90%;"><label>{{filter.Name}}</label></div>
                    <div class="filterOptionIcon" style="width: 10%; text-align: right;"> 
                      <md-icon :id="filter.Name+icon">expand_less</md-icon>
                    </div>
                  </div>
                    


                    <div v-if="filter.type=='checkbox'" class="filterCheckBox tableCollapsible-item">
                      <div v-for="option in filter.option" :key="option.Key">
                        <div style="padding-top:10px">
                          <input type="checkbox" class="tablecheckbox" v-model="option.Value" style="margin-right:10px">{{ option.Name }}
                        </div>
                      </div>
                    </div>

                    <div v-if="filter.type=='range'" class="filterRange tableCollapsible-item">
                    <div class="row filterRow">
                      <div class="column" style="width: 47.5%;"> 
                        <label>From</label>
                        <md-field>
                          <md-input :type=filter.inputType :value=filter.from class="formInput" v-model=filter.from />
                        </md-field>
                      </div>
                      <div class="column" style="width: 5%;"></div>
                      <div class="column" style="width: 47.5%;"> 
                        <label>To</label>
                        <md-field>
                          <md-input :type=filter.inputType :value=filter.to class="formInput" v-model=filter.to />
                        </md-field>
                      </div>
                    </div>
                  </div>

                  </div>

                  
                </div>
                <div class="row filterRow endOfFilter">
                  <div class="column clearAllFilter" style="width: 50%;">
                    <u @click="clearAllFilter">Clear all Filters</u>
                  </div>
                  <div class="column" style="width: 50%; text-align: right;">
                    <button  class="filterDone" @click="setFilter()">Done</button>
                  </div> 
                </div>
              </div>
            </div>
          </div>


        </Transition>
    

    </div>
  </template>
  
  <script>

  export default {
    methods:{
      sortBy: function(header){
        if(this.sort == "desc"){
          this.sort = "asc"
          this.TableList.sort((a, b) => a[header].toString().localeCompare(b[header].toString()));
        }else{
          this.sort = "desc"
          this.TableList.sort((a, b) => b[header].toString().localeCompare(a[header].toString()));
        }

      },
      setFilter:function(){
        this.filterContorl(false);
        this.$emit("setFilter", this.filterOption);
      },
      expandItem:function(element){
        let iconClass = document.getElementById(element+this.icon)
        console.log("iconClass", iconClass)
        if(document.getElementById(element).checked){
          iconClass.classList.remove('iconup')
          iconClass.classList.add('icondown')
        }else{
          iconClass.classList.remove('icondown')
          iconClass.classList.add('iconup')
        }
        document.getElementById(element).checked = !document.getElementById(element).checked;
        
      },
      filterContorl:function(action){
        let filterClass = document.querySelector('.filter-mask')
        if(action){
          filterClass.classList.remove('filter-out')
          filterClass.classList.add('filter-in')    
        }else{
          filterClass.classList.remove('filter-in')
          filterClass.classList.add('filter-out')
        }
      },
      clearAllFilter:function(){
        console.log("clearAllFilter");
        for(var i = 0; i<this.filterOption.length; i++){
          console.log(this.filterOption[i].Key);
          if(this.filterOption[i].type == "range"){
            this.filterOption[i].from = ""
            this.filterOption[i].to = ""
          }else{
            for(var x = 0; x<this.filterOption[i].option.length; x++){
              this.filterOption[i].option[x].Value = false
            }
          }
        }
      },
      SortPopupControl:function(){
        this.$emit("SortPopupControl");
      },
      goPage:function(action){
        this.$emit("goPage", action);
      },
      selectSortOption: function(option){
        this.$emit("selectSortOption", option);
      },
      cloneAction: function(id){
        this.$emit("cloneAction",id)
      },
      inSelected: function(ID){
        var index = this.selectedItem.indexOf(ID)
        if(index > -1){
          return true
        }else{
          return false
        }
      },
      isImg: function(header){
        if(header == 'img'){
          return true
        }
        return false
      },
      onclickItem: function(ID){
        this.$emit("onclickItem", ID);
      },
      selectItem : function(ID, event) {
        this.$emit("selectItem",ID, event.target.checked);
      },
      buttonSelectAll:function(){
        this.$emit("selectAllItem", true);
      },
      selectAllItem: function(event){
        console.log("selectAllItem");
        this.$emit("selectAllItem", event.target.checked);
      },
      searchItem: function(event){
        this.$emit("searchItem", {keyword: event.target.value});
      },
      selectedItemActionFunction:function(action){
        this.$emit("selectedItemActionFunction", action);
      },
      setSearchWidth:function(){
        if(this.sortOption.length> 0 && this.filterOption.length > 0){
          this.searchWidth = {
            'width': '89%'
          }
        }else if(this.sortOption.length> 0 || this.filterOption.length >0){
          this.searchWidth = {
            'width': '90%'
          }
        }
      }
    },
    name: "table-component",
    props: {
      canClone:{
        type: Boolean,
        required:false,
        default(){
          return false
        }
      },
      selectedItemCounter:{
        type: Number,
      },
      selectedItem:{
        type: Array,
        default() {
            return [];
        },
      },
      columns:{
        required: true,
        type: Array,
        default() {
            return [];
        },
      },
      TableList: {
        required: true,
        type: Array,
        default() {
            return [];
        },
      },
      selectable:{
        default(){
            return false
        }
      },
      haveSearchFunction: {
        default(){
            return false
        }
      },
      sortOption:{
        type: Array,
        default(){
          return []
        }
      },
      filterOption:{
        type:Array,
        default(){
          return []
        }
      },
      selectedItemAction:{
        type: Array,
        default(){
          return []
        }
      },
      selectAll: {
          default(){
              return false
          }
      },
      showNextButton:{
          default(){
              return false
          }
      },
      pageNumber:{
        type: Number,
        default(){
          return 0
        }
      },
      showSortPopup: {
          default(){
              return false
          }
      },
      showPageNumber:{
        default(){
          return true
        }
      }
    },
    data() {
      return {
        sort: "desc",
        showFilter: false,
        icon: "icon",
        searchWidth:{
          'width': '100%'
        },
        filterPostion:{
          'right': '-400px'
        }
      };
    },
    created() {
      this.setSearchWidth()
    },
    computed: {

    }
  };
  </script>

<style>
  .tableHeader{
    cursor: pointer;
  }
  button{
    cursor: pointer;
  }
  .tableCollapsible{
    overflow: hidden;
    cursor: pointer;
  }
  .tableCollapsible label{
    cursor: pointer;
  }
  .tableCollapsible-item{
    max-height: 1px;
    overflow: hidden;
    position: relative;
    top: -100%;
    opacity: 0.5;
    transition:all 0.5 ease;
  }
  .tableCollapsible input:checked ~ .tableCollapsible-item{
    min-height: 150px;
    opacity: 1;
    top: 0;
  }


  .filterRange{
    margin: 0 20px;
    padding-top: 15px
  }
  .filterCheckBox{
    padding-left: 25px;
    font-size: 18px;
  }
  .filterOptionTitle{
    padding-left:20px;
    font-size: 20px;
    font-weight: 300;
  }
  /* .filterOptionTitle:after{
    content: "";
    position: absolute;
    right: 15px;
    top: 15px;
    width: 15px;
    height: 15px;
    background: url(arrow.svg) no-repeat 0 0;
  } */
  .filterOptionIcon{
    font-size: 18px !important;
    font-weight: 300 !important;
    position: absolute !important;
    right: 20px !important;

  }
  .icondown {
    animation-name: rotationdown;
    animation-duration: 0.5s;
    animation-direction: normal; 
    transform: rotate(180deg);
  }
  @keyframes rotationdown {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  .iconup {
    animation-name: rotationup;
    animation-duration: 0.5s;
    animation-direction: normal; 
    transform: rotate(0deg);
  }
  @keyframes rotationup {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  .filterDone{
    margin-right: 30px;
    width: 120px;
    padding: 15px 20px;
    background-color: #575757;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 400; 
  }
  .clearAllFilter{
    font-size: 18px;
    font-weight: 500;
    padding-top:20px;
    padding-left: 30px;
  }
  .endOfFilter{
    cursor: pointer;
    bottom: 30px;
    position: absolute;
    width: 100%;
  }
  .filterClose{
    padding-top:37px;
    padding-right: 20px;
    text-align: right;
  }
  .filterHeader{
    font-size: 30px;
    font-weight: 800;
    padding-top:40px;
    padding-bottom: 20px;
    padding-left: 30px;
  }
  .filterRow{
    margin: unset;
  }
  .filterCloseButton{
    border: none;
    background:none;
  }
  .filter-mask{
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: table;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
    right: -400px
  }
  .filter-in{
    animation-name: filter-in;
    animation-duration: 0.5s;
    animation-direction: normal; 
    right: 0px
  }
  @keyframes filter-in {
    0%   { right:-400px;}
    100% { right:0px;}
  }
  
  .filter-out{
    animation-name: filter-out;
    animation-duration: 0.5s;
    animation-direction: normal; 
    right: -400px
  }
  @keyframes filter-out {
    0%   { right:0px;}
    100% { right:-400px;}
  }
  .filter-container{
    text-align: left;
    height: 100%;
    max-width: 500px;
    margin: 0px auto;
    position: relative;
    background-color: #fff;
    transition: all .3s ease;
    box-shadow: 0 27px 24px 0 rgba(0,0,0,.2), 0 40px 77px 0 rgba(0,0,0,.22);
    border-radius: 6px;
    border: none;
  }
  .backIcon{
      padding: unset;
      margin: unset;
      color:black !important;
    }
  .tablecheckbox{
    margin-left: 10px;
    accent-color: gray;
    width: 18px;
    height: 18px;
  }
  .radiolist{
    cursor: pointer;
    margin: auto;
    width: 80%;
    padding: 20px 0px 20px 0px;
  }
  .sortradio {
    accent-color: gray;
    width: 15px;
    height: 15px;
  }
  .sortPopup{
    font-size: 18px;
    position: fixed;
    right: 100px;
    min-width: 200px;
    border: 1px solid #fafafa;
    box-shadow: 2px 2px 2px 2px #999999 !important;
    background-color: white;
    z-index: 99;
  }
  .sortButton{
    margin-top:5px;
    border: none;
    background-color:rgba(250, 250, 250, 0); 
    font-size: 20px;
    cursor: pointer;
  }
  .actionItem{
    cursor: pointer;
    max-width: 150px;
    min-width: 100px
  }
  .selectedrow {
    min-height:30px;
    padding-top: 30px;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 400;
  }
  form.nosubmit {
    border: none;
    padding: 0;
  }

  input.nosubmit {
    border: 1px solid #555;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  }
table{
  border-collapse: collapse;
}
.tableFixHead          { 
  overflow: auto;  
  /* height: 60vh;  */
  max-height: 650xp;}
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; background: white;}
.tableImg {
  width:50px !important;
  height: 50px !important;
}
.tdImg {
  width: 50px;
}
 th {
    border-bottom: 1px solid black;
    height: 50px;
    max-width: 50px;
}
td {
    border-top: 1px solid gray;
    height: 50px;
    max-width: 50px;
}
.isSelected{
  background-color: #eaf4f3
}
.cloneBtn .md-ripple{
  padding: 12px 0px!important;
}

    table { page-break-inside:auto }
    tr    { page-break-inside:avoid; page-break-after:auto }
    thead { display:table-header-group; text-align: left; }
    tfoot { display:table-footer-group }
  </style>