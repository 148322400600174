import oneSipApiCall from "../apiCall.js"
import axios from "axios"
import url from "../config.js"
//var url = "https://ihhalvyjlh.execute-api.ap-southeast-1.amazonaws.com/test"
const Inventory={

  listInventory({commit,state},{router}){
    return oneSipApiCall(commit,state,router,'/list-inventory')
  },
  searchInventory({commit,state},{router, data}){
    return oneSipApiCall(commit,state,router,'/search-inventory', data)
  },
  getProductInventory({commit,state},{router, product}){
    return oneSipApiCall(commit,state,router,'/get-product-inventory', product)
  },
  createInventory({commit,state},{router, data}){
    return oneSipApiCall(commit,state,router,'/create-inventory', data)
  },
  getInventory({commit,state},{router, data}){
    return oneSipApiCall(commit,state,router,'/get-inventory', data)
  },
  updateInventory({commit,state},{router, data}){
    return oneSipApiCall(commit,state,router,'/update-inventory', data)
  },
  getVarianceInventory({commit,state},{router, data}){
    return oneSipApiCall(commit,state,router,'/get-variance-inventory', data)
  },
  updateInventoryDetail({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/update-inventory-detail', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },

  getInventoryDetail({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/get-inventory-detail', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
}

export default Inventory;