<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <md-card>
            <md-card-content>
              <TableComponent 
              v-on:selectItem="selectItem"
              v-on:selectAllItem="selectAllItem"
              v-on:searchItem="searchItem"
              v-on:onclickItem="onclickItem"
              v-on:selectSortOption="selectSortOption"
              v-on:selectedItemActionFunction="selectedItemActionFunction"
              v-on:goPage="goPage"
              v-on:SortPopupControl="SortPopupControl"
              v-on:setFilter="setFilter"
              :TableList="TableItemList"
              :selectedItemCounter="selectedItemCounter"
              :selectedItem="selectedItem"
              :columns="columns"
              :selectAll="selectAll"
              :selectable="selectable"
              :haveSearchFunction="haveSearchFunction"
              :sortOption="sortOption"
              :selectedItemAction="selectedItemAction"
              :pageNumber="pageNumber"
              :showNextButton="showNextButton"
              :showSortPopup="showSortPopup"
              :filterOption="filterOption"
              ></TableComponent>
            </md-card-content>
          </md-card>
          <!-- :AddNewItem="AddNewItem" -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { 
    TableComponent,

  } from "@/components";
  
  export default {
    name: "product-order-list",
    components: {
      TableComponent,
     
    },
    data() {
      return {
        showSortPopup: false,
        index: 0,
        pageNumber: 1,
        showNextButton: true,
        displaySize: 10,
        TableItemList: [],
        AddNewButton:  "",
        columns: ["OrderID", "PaymentMethod", "Total", "Status", "Created Date"],
        Source: ["OrderID", "PaymentMethod", "Total", "Status", "CreatedDatetime"],
        Sort: {"CreatedDatetime": "desc"},
        sortOption: [
          {Key: "CreatedDatetime", Name: "Created Date", Value: true, Order: "desc"},
          {Key: "Total", Name: "Total", Value: false, Order: "desc"},
  
        ],
        filterOption:[
          {
            Key: "Status", 
            Name: "Status", 
            type: "checkbox",
            inputType: "checkbox",
            option:[
              {Key: "Wait for payment", Name: "Wait for payment", Value: false},
              {Key: "IN PROCESS", Name: "IN PROCESS", Value: false},
              {Key: "COMPLETED", Name: "COMPLETED", Value: false},
              {Key: "Cancel", Name: "Cancel", Value: false}
            ]
          },
          {
            Key: "Total",
            Name: "Total", 
            type: "range",
            inputType: "number",
            from: "",
            to: ""
          }
        ],
        selectedItemAction: [
          //{Key: "delete", Name: "Delete selected", Style:"color:#CC2727"},
        ],
        selectedItem: [],
        selectedItemCounter: 0,
        selectAll: false,
        selectable: true,
        haveSearchFunction: true,
        filter: {},
        range: {},
      }
    },
    async created() {
        this.setLoading(true)
        await this.searchItem({keyword:""});
        this.setLoading(false)
      },
    methods:{
      setLoading(isLoading) {
        console.log("setLoading", isLoading);
        this.$emit("setLoading", isLoading);
      },
      setFilter: function (filterOption){
      console.log("setFilter", filterOption);
        this.filter = {};
        this.range = {};
      for(var f in filterOption){
        var option = filterOption[f]
        if(option.type == "range"){
          var filterObject = {}
          if(option.from){
            filterObject["Start"] = option.from
          }
          if(option.to){
            filterObject["End"] = option.to
          }
          if(filterObject.Start || filterObject.End){
            this.range[option.Key] = filterObject
          }
        }else{
          var filterList = [];
          for(var o in option.option){
            if(option.option[o].Value){
              filterList.push(option.option[o].Key)
            }
          } 
          if(filterList.length > 0){
            this.filter[option.Key] = filterList
          }
        }
      } 
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;

        this.index = 0;
        this.pageNumber = 1;
        console.log("filter", this.filter)
        console.log("range", this.range)
        this.searchItem({keyword:""});
      },
      SortPopupControl: function (){
          this.showSortPopup = !this.showSortPopup
        },
      goPage:async function(action){
        if(action == "next"){
          this.index = this.index + this.displaySize
          this.pageNumber = this.pageNumber + 1
        }else if(action == "prev" && this.index > 0){
          this.index = this.index - this.displaySize
          this.pageNumber = this.pageNumber - 1
        }else{
          this.index = 0
          this.pageNumber = 1
        }
        //this.$router.replace({ name: "Coupon List PageNumber", params: {page:this.pageNumber} })
        await this.searchItem({keyword:""});
      },
      selectedItemActionFunction:function(action){
        console.log("selectedItemActionFunction", action);
      },
      onclickItem: function(ID){
        this.$router.push({name: 'Order Detail', params:{orderID:ID}})
      },
      createNewItem: function(){
        
      },
      popupcancel: function(){
          this.showPopup = false;
        },
      popupaction: function (){
        this.showPopup = false;
        
      },  

      selectSortOption: function(option){
        console.log("charge sorting with " + option);
        var sortOrder = "";
        this.Sort = {}
        for(var o in this.sortOption){
          var sort = this.sortOption[o]
          if(sort.Key == option){
            sort.Value = true;
            sort.Order = sort.Order == "asc" ? "desc" : "asc"
            sortOrder = sort.Order
            this.Sort[option] = sortOrder
          }else{
      
            sort.Value = false;
          }
        }
        // for(var i = 0; i<this.sortOption.length; i++){
        //   if(this.sortOption[i].Key == option){ 
        //     this.sortOption[index].Value = true
        //     this.sortOption[index].Order = this.sortOption[index].Order == "asc" ? "desc" : "asc"
        //     sortOrder = this.sortOption[index].Order
        //     this.Sort[option] = sortOrder
        //   }else{
        //     this.sortOption[i].Value = false
        //   }
        // }
        console.log("sortOption", this.sortOption);
        this.showSortPopup = false;
        this.searchItem({keyword:""});
      },
      searchItem: async function({keyword}){
        this.isLoading = true;
        var object = {
          "Size": this.displaySize,
          "From": this.index,
          "Sort": this.Sort,
          "Source": this.Source,
          "Filter": this.filter,
          "Range": this.range
        }
        if(keyword){
          object["Keyword"] = keyword
          this.selectAll = false;
          this.selectedItem = [],
          this.selectedItemCounter = 0;
          this.index = 0;
          this.pageNumber = 1;
        }
        console.log("searchItem", object);
        
        try{
          const ret= await this.$store.dispatch('searchProductOrder',{router:this.router,keyword:object})
          this.setDisplayList(ret.data.data.hits.hits)
        }catch(err){
          console.log(err);
        }
      },
      selectAllItem:function(action){
          this.selectAll = false;
          if(action){
            this.selectAll = true;
            for(var i = 0; i< this.TableItemList.length; i++){
              var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
              if(index < 0){
                  this.selectedItem.push(this.TableItemList[i].ID)
              }
            }
          }else{
              for(var i = 0; i< this.TableItemList.length; i++){
                  var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
                  this.selectedItem.splice(index, 1);
              }
          }
          console.log("after", this.selectedItem);
          this.selectedItemCounter = this.selectedItem.length
        },
      selectItem:function(ID, action){
        console.log(ID, action);
        if(action){
          this.selectedItem.push(ID)
        }else{
          this.selectAll = false;
          var index = this.selectedItem.indexOf(ID)
          this.selectedItem.splice(index, 1);
        }
        console.log(this.selectedItem.length);
        this.selectedItemCounter = this.selectedItem.length
  
      },
        setDisplayList(list){
          var newlist=[];
          for(var i = 0; i<list.length; i++){
            var date = new Date(list[i]["_source"].CreatedDatetime).toDateString();
            var object = {
              "ID": list[i]["_source"].OrderID,
              "OrderID": list[i]["_source"].OrderID,
              "PaymentMethod": list[i]["_source"].PaymentMethod,
              "Total": list[i]["_source"].Total,
              "Status": list[i]["_source"].Status,
              "Created Date": date,
            }
            newlist.push(object)
          }
          this.TableItemList = newlist;
          this.TableSelectUIControl(this.TableItemList)
        },
        TableSelectUIControl(ItemList){
          this.showNextButton = true;
          if(ItemList.length < this.displaySize){
            this.showNextButton = false;
          }
          var counter = 0;
          for(var i=0; i<ItemList.length;i++){
              var index = this.selectedItem.indexOf(ItemList[i].ID);
              if(index > -1){
                  counter++
              }
          }
          if(counter ==  ItemList.length){
              this.selectAll = true;
          }else{
              this.selectAll = false;
          }
        }
    }
  };
  </script>
  