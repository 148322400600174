<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
          ></FormHeader>
          <center>
            <md-card style="width:50%;min-width: 500px">
                <md-card-content >
                    <FormComponent
                    v-on:checkEdited="checkEdited"
                    :FormTitle="FormTitle"
                    :FormItem="FormItem"
                    :haveDoneButton="false"
                    >
                    </FormComponent>
                </md-card-content>
            </md-card>
        </center> 
 
        </div>
      </div>
      <Spinner v-if="isLoading"/> 
      <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
    </div>
  </template>
  
  <script>
  
  import { 
    FormHeader,
    FormComponent,
    Spinner
   } from "@/components";

  
  export default {
    components: {
      FormHeader,
      FormComponent,
      Spinner
    },
    created() {
      this.getInformation("InspirationTitle");
    },
    mounted(){
    },
    methods:{
      /***  Page Function  ***/
      getInformation: async function(InformationID){
        this.isLoading = true;
          try{
            const ret = await this.$store.dispatch('getInformation',{router:this.router,data:{id: InformationID}})
            console.log("getInformation", ret)
            this.setDatatoForm(ret.data.Information.content)
            this.isLoading = false;
          }catch(err){
            this.isLoading = false;
            console.log("getInformation error", err.response.data);
            this.formMessage = err.response.data.message
            this.showFormMessage = true
          }
      },
      formAction: async function(){
        this.sending = true;
        var Content = await this.getFormData(this.FormItem)
        var object = {
          id: "InspirationTitle",
          content: Content
        }
        console.log("formAction", object);
        this.isLoading = true;
        try{
          const ret = await this.$store.dispatch('updateInformation',{router:this.router,data:object})
          console.log("updateInformation", ret)
          this.sending = false;
          setTimeout(()=>{
            this.isLoading = false;
              this.$router.replace({name: 'Content Management', params:{message:"Inspiration Title has been updated"}});
          }, 2000);
        }catch(err){
          this.isLoading = false;
          this.sending = false;
          console.log("updateInformation error", err.response.data);
            this.formMessage = err.response.data.message
            this.showFormMessage = true
        }
      },
      setDatatoForm: function(data){
        console.log("setDatatoForm", data);
        for (const [key, value] of Object.entries(this.FormItem)) {
            for(var i = 0 ;i<value.length; i++){
                value[i]["value"] = data[value[i].key];  
            }
        }
      }, 
      getFormData: async function(form){
          var FormData = {}
          for(const [key, value] of Object.entries(form)){
            for(var i = 0; i<value.length; i++){
                if(value[i].required){
                  if(value[i].value){
                    if(value[i].type == 'number'){              
                      FormData[value[i].key] = parseInt(value[i].value)
                    }else{
                      FormData[value[i].key] = value[i].value
                    }
                  }else{
                    this.formMessage = value[i].name + " is required"
                    this.showFormMessage = true;
                    return
                  }
                }else{
                  if(value[i].type == 'number'){           
                    FormData[value[i].key] = parseInt(value[i].value)
                  }else{
                    FormData[value[i].key] = value[i].value
                  }    
                }
            }
          }
          return FormData
      },

      /*** End of Page Function  ***/
     

      /***  open Popup  ***/

      /***  end of open Popup  ***/

      /***  Popup Action  ***/

      /***  end of Popup Action  ***/
    },
    data() {
      return {
        isLoading: false,
        sending: false,
        HeaderTitle: "Inspiration Title",
        formActionButton: "Save",
        formMessage:  null,
        FormTitle: "Text Information",
        showFormMessage: false,
        confirmBack:false,
        checkEdited: false,
        Image: {},
        FormItem: {
            "row1":[
            {
                "type": "object",
                "key": "Title",
                "name": "Title",
                "required": true,
                "style": "width:100%",
                "value": {
                    "en": null,
                    "zh": null,
                },
            },
            ],
        },
      }
    },
  
  };
  </script>