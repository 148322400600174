const label = "Option";
const name = 'Option';

export default{
    icon: "list",
    label,
    name,
    haveAnswer:true,
    haveLikeAction:false,
    haveHaveModelAnswer:true,
    haveHits:true
    
}