<template>
	<div class="full-width">
			<div id="questionSection">
				<md-empty-state
					:class="{hide:!isEmpty}"
					md-icon="quiz"
					md-label="Create your first question"
					md-description="Creating question, you'll be able to create your survey template.">
					<md-button class="md-primary md-raised" @click="$emit('createQuestion')">Create first question</md-button>
				</md-empty-state>
				<QuestionRow 
					v-for="(question,index) in this.questionList" 
					:key="question.index" 
					:index="index"
					:questionObj="question"
					v-on:removeQuestion="removeQuestion"
					v-on:rowSelected="rowSelected"
					v-on:rowMoveUp="rowMoveUp"
					v-on:rowMoveDown="rowMoveDown"
					v-on:updateQuestionProp="updateQuestionProp"
				/>
			</div>
			<div id="addButtonDiv">
				<md-button class="md-fab md-primary" @click="$emit('createQuestion')">
					<md-icon>add</md-icon>
				</md-button>
			</div>
	</div>
</template>
<script>
import $ from "jquery";
import QuestionRow from './QuestionRow';
export default {
	name:"question-row-editor",
	props:{
		questionList:{
			type:Array,
		},
	},
	components:{
		QuestionRow
	},
	created(){
		console.log(this.questionList);
	},
	methods:{
		removeQuestion:function(index){
			//console.log("remove-emit: "+index);
			this.$emit('removeQuestion',index);
		},
		rowSelected:function(index,value){
			this.$emit('rowSelected',index,value);
		},
		rowMoveUp:function(index,offsetTop){
			this.$emit('rowMoveUp',index,offsetTop);
		},
		rowMoveDown:function(index,offsetTop){
			this.$emit('rowMoveDown',index,offsetTop);
		},
		updateQuestionProp:function(index,key,value){
			this.$emit('updateQuestionProp',index,key,value);
		}
	},
	computed: {
    isEmpty: function () {
      //console.log($.isEmptyObject(this.questionList))
      return $.isEmptyObject(this.questionList)
    }
  }
}
</script>
<style lang="scss" scoped>
	.md-empty-state{
		margin-top:100px;
	}
	.full-width{
		width:100%
	}
	#questionSection{
		width: 100%;
	}
	#addButtonDiv{
		position: fixed;
    right: 15px;
    bottom: 50px;
		z-index: 99;
	}
	.hide{
		display: none;
	}
</style>
<style lang="css">
	.md-fab .md-ripple{
    padding: 0!important;
  }
</style>