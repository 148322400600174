import oneSipApiCall from "../apiCall.js"
import axios from "axios"
import FormData from "form-data"
const Buffer = require('buffer').Buffer;
import url from "../config.js"

//var url = "https://ihhalvyjlh.execute-api.ap-southeast-1.amazonaws.com/test"
const inspiration={
  searchInspiration({commit,state},{router,keyword}){
    return oneSipApiCall(commit,state,router,'/search-inspiration',keyword)
  },
  createInspiration({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/create-inspiration',data)
  },
  deleteInspiration({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/delete-inspiration',data)
  },
  deleteInspirationMedia({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/delete-inspiration-media',data)
  },
  updateInspirationThumbnail({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/update-inspiration-thumbnail',data)
  },
  deleteInspirationThumbnail({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/delete-inspiration-thumbnail',data)
  },
  searchTasteCard({commit,state},{router,keyword}){
    return oneSipApiCall(commit,state,router,'/search-taste-card',keyword)
  },
  createAuthor({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/create-author', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  listAuthor({commit,state},{router}){
    return oneSipApiCall(commit,state,router,'/list-author')
  },
  getInspiration({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/get-inspiration', data)
  },
  listInspiration({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/list-inspiration', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  updateInspiration({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/update-inspiration', data)
  },

}

export default inspiration;