<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <FormHeader
        v-on:formAction="formAction"
        :HeaderTitle="HeaderTitle"
        :confirmBack="confirmBack"
        :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <div style="width:45%;">
            <md-card style="margin:10px 0;">
            <md-card-content>
                <h3 class="formTitle">{{Form1Title}}</h3>
                <LanguageObjectInput
                  v-on:checkEdited="checkEdited"
                  :Item="ProductNameInput"
                />
               
                <div class="row" style="padding: 10px 0">
                  <div class="inputcolumn">
                    <SelectInput
                      v-on:checkEdited="checkEdited"
                      :Item="SupplierInput"
                    />
                  </div>
                  <div class="inputcolumn">
                    <SelectInput
                      v-on:checkEdited="checkEdited"
                      :Item="StatusInput"
                    />
                  </div>
                </div>
                <!-- <ChipsInput
                  v-on:checkEdited="checkEdited"
                  :Ite"
                /> -->
                <CountryInput
                      v-on:checkEdited="checkEdited"
                      :languageList="languageList"
                      :Item="CountryInput"
                    />
                <ParingTagInput
                  v-on:checkEdited="checkEdited"
                  :Item="PairingTagsInput"
                />
                <TasteNotesInput
                  v-on:checkEdited="checkEdited"
                  :Item="TasteNotesInput"
                />
                <AromaInput
                  v-on:checkEdited="checkEdited"
                  :Item="AromaInput"
                />


                <FiveSensesInput
                  v-on:checkEdited="checkEdited"
                  :Item="FiveSensesInput"
                />
                <div>
                  <label>Product Image</label>
                  
                    <draggable
                      :list="ProductImageInput"
                      :group="{ name: 'g1' }"
                    >
                    <div v-for="(Item) in ProductImageInput" :key="Item.ID">
                      <FileInput
                        v-on:RemoveItem="RemoveItem"
                        
                        :Item="Item"
                        :acceptType="acceptType"
                      />
                    </div>

                    </draggable>
                    <div style="text-align: center;" @click="addProductImageInput()">
                      <md-icon>add_circle_outline</md-icon> <u class="addLang">Add Image</u>
                    </div>
                </div>

                <div style="padding-top: 50px">
                  <label>Taste Card Product Image</label>    
                  <FileInput
                    v-on:RemoveItem="RemoveItem"
                    :Item="TasteCardImage"
                    :acceptType="acceptType"
                  />
                </div>



            </md-card-content>
          </md-card>

          </div>
          <div style="width:55%;padding-left: 30px;">
            <md-card style="margin:10px 0;">
            <md-card-content>
              <div style="text-align:left">
                <div class="row">
                  <div style="width:100%"> <h3 class="formTitle">Product Info</h3></div>
                      <div style="padding:20px 20px 0px 20px;width:100%">
                          <label for="product-type">Product Type</label>
                          <table style="width:100%">
                            <tr>
                              <td v-for="(value, key) in ProductTypeList" :key="key" style="border:none">
                                <md-radio v-model="ProductType" :value=value.ProductTypeID>{{ value.Name.en }}</md-radio>
                              </td>
                            </tr>
                          </table>

                      </div>
                </div>
                <div class="row" >
                  <div class="column inputcolumn" v-for="(field, key) in ProductInfoInputList" :key="key">
                    <LanguageObjectInput
                      v-if="field.type == 'object'"
                      v-on:checkEdited="checkEdited"
                      :Item="field"
                    />
                    <NumberInput
                    v-if="field.type == 'number'"
                      v-on:checkEdited="checkEdited"
                      :Item="field">

                    </NumberInput>
                  </div>
   
                </div>
                <div class="row">
                      <div style="padding:20px 20px 0px 20px;width:100%">
                          <label for="product-type">Parameter</label>
                      </div>
                </div>
                <div class="row" >
                  <div class="column inputcolumn" v-for="(field, key) in ProductParameterInputList" :key="key">
                 
                  <TextInput
                      v-if="field.type=='Text'"
                      v-on:checkEdited="checkEdited"
                      :Item="field"
                   /> 
                    <SelectInput
                      style="padding-top:30px"
                      v-if="field.type=='Option'"
                      v-on:checkEdited="checkEdited"
                      :Item="field"
                    /> 
                   
                    <TimeInput
                      v-if="field.type=='Time'"
                      v-on:checkEdited="checkEdited"
                      :item="field"
                      :name="field.name"
                      :value="field.value"
                    />
                  </div>
   
                </div>
                <!-- <FormComponent
                  :FormItem="ProductInfoItem"
                  :haveDoneButton=false
                  >
                </FormComponent> -->
              </div>
            </md-card-content>
          </md-card>
          <md-card>
              <md-card-content>
                <div style="width:100%"> <h3 class="formTitle">Product Variance</h3></div>
                <TableComponent
                  v-on:selectItem="selectItem"
                  v-on:selectAllItem="selectAllItem"
                  v-on:onclickItem="onclickItem"
                  v-on:selectedItemActionFunction="selectedItemActionFunction"
                  :TableList="TableItemList"
                  :selectedItemCounter="selectedItemCounter"
                  :selectedItem="selectedItem"
                  :columns="columns"
                  :selectAll="selectAll"
                  :selectable="selectable"
                  :selectedItemAction="selectedItemAction"
                  :showPageNumber="false"
                />

                <div style="text-align: center;padding-top: 10px;" @click="showAddVariancePopup()">
                      <md-icon>add_circle_outline</md-icon> <u class="addLang">Add Variance</u>
                  </div>

                </md-card-content>
            </md-card>
          </div>
        </div>   
      </div>
    </div>
    <Transition name="modal" v-if="showAddVariance">
      <AddVariancePopup
      v-on:popupcancel="popupcancel"
      v-on:popupaction="popupaction"
      PopupTitle="Add Variance"
      actionButton="Add"
      ></AddVariancePopup>
    </Transition>
    <Spinner v-if="isLoading"/> 
    <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
  </div>
</template>

<script>

import { 
  FormHeader,
  LanguageObjectInput,
  SelectInput,
  CountryInput,
  FileInput,
  TextInput,
  // ChipsInput,
  ParingTagInput,
  TasteNotesInput,
  AromaInput,
  NumberInput,
  AddVariancePopup,
  TableComponent,
  Spinner,
  FiveSensesInput,
  TimeInput
 } from "@/components";


 import draggable from "vuedraggable";

export default {
  components: {
    FormHeader,
    LanguageObjectInput,
    SelectInput,
    CountryInput,
    FileInput,
    TextInput,
    draggable,
    // ChipsInput,
    ParingTagInput,
    TasteNotesInput,
    AromaInput,
    NumberInput,
    AddVariancePopup,
    TableComponent,
    Spinner,
    FiveSensesInput,
    TimeInput
  },
  async created() {
    this.isLoading = true;
    await this.getLanguage();
    await this.getProductType();
    this.getSuppliers();      
    this.setProductInfoItem(this.ProductType);
    this.isLoading = false;

  },

  watch:{
      ProductType: function(newValue){
          this.setProductInfoItem(newValue);
      }
  },
  methods:{
    //TableComponent function
    selectItem:function(ID, action){
      console.log(ID, action);
      if(action){
        this.selectedItem.push(ID)
      }else{
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID)
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length

    },
    selectAllItem:function(action){
        this.selectAll = false;
        if(action){
          this.selectAll = true;
          for(var i = 0; i< this.TableItemList.length; i++){
            var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
            if(index < 0){
                this.selectedItem.push(this.TableItemList[i].ID)
            }
          }
        }else{
            for(var i = 0; i< this.TableItemList.length; i++){
                var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
                this.selectedItem.splice(index, 1);
            }
        }
        console.log("after", this.selectedItem);
        this.selectedItemCounter = this.selectedItem.length
      },
    onclickItem: function(ID){
      console.log("onclickItem", ID);
    },
    selectedItemActionFunction:function(action){
      console.log("selectedItemActionFunction", action);
      if(action == "delete"){
        console.log("delete", this.selectedItem);
        for(var i = 0; i< this.selectedItem.length; i++){
          var varianceIndex = this.ProductVarianceList.findIndex(x => x.ID == this.selectedItem[i]);
          this.ProductVarianceList.splice(varianceIndex, 1);
          var tableIndex = this.TableItemList.findIndex(x => x.ID == this.selectedItem[i]);
          this.TableItemList.splice(tableIndex, 1);
        }
        //this.deleteSelectedProduct();
      }
    },
    //end of TableComponent function
    popupaction: function(data){
      console.log(data);
      var id = new Date().getTime();
      data.ID = id;
// columns: ["img", "Variance Name", "Price", "Weight"],
      var object = {
        "ID": id,
        "img": data.Image[0].url,
        "Variance Name": data.Name.en +" / "+ data.Name.zh,
        "Price": data.Price,
        "Weight": data.Weight,   
      }
      this.TableItemList.push(object);
      this.showAddVariance = false;
      this.ProductVarianceList.push(data);
      console.log("addVariance", this.ProductVarianceList);

    },
    popupcancel: function(){
      this.showAddVariance = false;
    },
    showAddVariancePopup: function(){
      this.showAddVariance = true;
    },
    SetItemObject: function(ID, object){
      var index = this.ProductImageInput.findIndex(x => x.ID == ID)
      this.ProductImageInput[index].url = object.url
      this.ProductImageInput[index].base64 = object.base64
      this.$forceUpdate()
    },
    SetTasteCardItemObject: function(object){
      console.log(object);
      this.TasteCardImage.url = object.url
      this.TasteCardImage.base64 = object.base64
      this.$forceUpdate()
    },
    RemoveItem: function(ID){
      var index = this.ProductImageInput.findIndex(x => x.ID == ID)
      this.ProductImageInput.splice(index, 1)
    },
    addProductImageInput: function(){
      var ID = new Date().getTime();
      this.ProductImageInput.push({ID: ID, base64: "", url: "", type: "ProductImage"})
      console.log("ProductImageInput", this.ProductImageInput);
    },
    formAction: async function(){
      console.log("new function");
      if(this.ProductVarianceList.length == 0){
        this.formMessage = "Please add at least one variance";
        this.showFormMessage = true;
        return;
      }
      
   
      var ProductID = await this.createProduct();
      console.log("ProductID", ProductID);
      if(!ProductID){
        this.isLoading = false;
        this.formMessage = "Create Product Error";
        this.showFormMessage = true;
        return;
      }
      var TasteCardID = await this.createTasteCard(ProductID);
      console.log("TasteCardID", TasteCardID);
      if(!TasteCardID){
        this.isLoading = false;
        this.formMessage = "Create Taste Card Error";
        this.showFormMessage = true;
        return;
      }

      var createResult = await this.createInspiration(TasteCardID, ProductID);
      console.log("createResult", createResult);

      this.isLoading = false;
      setTimeout(()=>{
          this.$router.replace({name: 'Product List', params:{message:"New Product created successfully"}});
        }, 2000);


    },
    createProduct: async function(){
      var object = {
        "Name": this.ProductNameInput.value,
        "SupplierID": this.SupplierInput.value,
        "Country": this.CountryInput.value,
        "ParingTags": this.PairingTagsInput.value,
        "TasteNote": this.TasteNotesInput.value,
        "Aroma": this.AromaInput.value,
        "ProductType": this.ProductType,
        "TasteCardImage": this.TasteCardImage.base64.split(":",2)[1].split(";",1)[0],
        "Status": this.StatusInput.value,
      }
      for(var i = 0; i<this.ProductInfoInputList.length; i++){
        if(this.ProductInfoInputList[i].type == "number"){
          object[this.ProductInfoInputList[i].key] = parseFloat(this.ProductInfoInputList[i].value)
        }else{
          object[this.ProductInfoInputList[i].key] = this.ProductInfoInputList[i].value
        }  
      }
      var Image = [];
      for(var i = 0;i<this.ProductImageInput.length;i++){
          var contentType= this.ProductImageInput[i].base64.split(":",2)[1].split(";",1)[0]
          Image.push(contentType)
        }
      object["Image"] = Image;
      this.isLoading = true;
      try{
        const ret = await this.$store.dispatch("createProduct",{router:this.router, product:object})
        console.log("createProduct", ret.data);
        var ProductID = ret.data.ProductID;
        await this.$store.dispatch('updateMediaToS3',{file:this.TasteCardImage.base64, urlObject:ret.data.tasteCardUrl})
        for(var i = 0; i<this.ProductImageInput.length; i++){
          const retMedia = await this.$store.dispatch('updateMediaToS3',{file:this.ProductImageInput[i].base64, urlObject:ret.data.UploadImageUrlList[i]})
        }
        for(var i = 0; i<this.ProductVarianceList.length; i++){
          this.ProductVarianceList[i].ProductID = ProductID;
          await this.createProductVariance(this.ProductVarianceList[i]);
        }
        return ProductID;
      }catch(err){
        this.isLoading = false;
        console.log("createProduct error", err.response.data);
        return false;
      }
    },

    createInspiration: async function(TasteCardID, ProductID){

      var Object = {
        "Author": ["39ca754c-80e1-7040-07ce-6c6a3c486e37"],
        "MediaList": [{
          "Type": "TasteCard",
          "ID": TasteCardID,
        }],
        "ProductList": [ProductID],
        "Status": "Published",
      }
      try{
        const ret = await this.$store.dispatch("createInspiration",{router:this.router, data:Object})
        console.log("createInspiration", ret.data);
        return true;
      }catch(err){
        console.log("createInspiration error", err.response.data);
        return false;
      }
    },
    createTasteCard: async function(ProductID){
      var TasteCardObject = {
        "ProductID": ProductID,
        "ProductType": this.ProductType,
        "TasteNote": this.TasteNotesInput.value,
        "Aroma": this.AromaInput.value,
        "FiveSenses": {},
        "Parameter": {},
      }
      for(var i = 0; i< this.FiveSensesInput.value.length; i++){
        TasteCardObject.FiveSenses[this.FiveSensesInput.value[i].key] = parseInt(this.FiveSensesInput.value[i].value)
      }
      for(var i = 0; i<this.ProductParameterInputList.length; i++){
        if(this.ProductParameterInputList[i].Unit){
          
          //check value contain unit or not
          if(this.ProductParameterInputList[i].value.includes(this.ProductParameterInputList[i].Unit)){
            TasteCardObject.Parameter[this.ProductParameterInputList[i].key] = this.ProductParameterInputList[i].value
          }else{
            TasteCardObject.Parameter[this.ProductParameterInputList[i].key] = this.ProductParameterInputList[i].value + this.ProductParameterInputList[i].Unit
          }
        }else{
          console.log(this.ProductParameterInputList[i], "no unit");
          TasteCardObject.Parameter[this.ProductParameterInputList[i].key] = this.ProductParameterInputList[i].value
        }   
      }
      console.log("TasteCardObject", TasteCardObject);
      for(const [key, value] of Object.entries(TasteCardObject.FiveSenses)){
        if(value > 4 || value < 0){
          this.formMessage = "Five Senses value must between 0-4"
          this.showFormMessage = true
          return
        }
      }
      for(const [key, value] of Object.entries(TasteCardObject.Parameter)){
        if(!value){
          console.log(key+ " no value");
          return
        }
      }
      try{
        const ret = await this.$store.dispatch("createTasteCard",{router:this.router, tasteCard:TasteCardObject})
        console.log("createTasteCard", ret.data);
        var TasteCardID = ret.data.TasteCard;
        return TasteCardID;
      }catch(error){
        console.log("createTasteCard error", error.response.data);
        return false;
      }
    },

    createProductVariance: async function(data){
      var object = {
        "ProductID": data.ProductID,
        "Name": data.Name,
        "Price": parseInt(data.Price),
        "Weight": parseInt(data.Weight),
      }
      if(data.Inventory){
        object["Inventory"] = data.Inventory
      }
      var Image = [];
        for(var i = 0;i<data.Image.length;i++){
            var contentType= data.Image[i].base64.split(":",2)[1].split(";",1)[0]
            Image.push(contentType)
            }
        object["Image"] = Image;
      try{
            const ret = await this.$store.dispatch("createProductVariance",{router:this.router, variance:object})
            for(var i = 0; i<data.Image.length; i++){
                const retMedia = await this.$store.dispatch('updateMediaToS3',{file: data.Image[i].base64, urlObject:ret.data.UploadImageUrlList[i]})
            }
        }catch(err){
          console.log("createProductVariance error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
    },
    checkEdited: async function(check){
      this.confirmBack = check
    },
    getLanguage: async function(){
      try{
        const ret= await this.$store.dispatch("getLanguage",{router:this.router, data:null})
        console.log("getLanguage", ret.data);
        this.languageList = ret.data.Language;
        for(var i = 0; i< this.languageList.length; i++){
          this.ProductNameInput.value[this.languageList[i].Language] = ""
        }
      }
      catch(err){
        console.log("getLanguage error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    getProductType: async function(){
      try{
        const ret= await this.$store.dispatch("getProductType",{router:this.router, data:null})
        console.log("getProductType", ret.data);
        this.ProductTypeList = ret.data.ProductType;
        this.ProductType = ret.data.ProductType[0].ProductTypeID
        //this.countryList = ret.data.Country;
      }
      catch(err){
        console.log("getProductType error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    getSuppliers: async function(){
      try{
        const ret= await this.$store.dispatch("listSuppliers",{router:this.router})
        for(var i in ret.data.supplierList){
          this.SupplierInput.option.push({
            key: ret.data.supplierList[i].SupplierID,
            name: ret.data.supplierList[i].Name,
          })
        }

        console.log("SupplierInput", this.SupplierInput);
      }
      catch(err){
        console.log("listSuppliers error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    setProductInfoItem: function(ID){
      console.log(this.ProductTypeList);
      console.log("ID", ID);
      var ProductType = this.ProductTypeList.find(item => item.ProductTypeID == ID)
      console.log("ProductType", ProductType);
      var Column = ProductType["Column"];
      
      this.ProductInfoInputList = [];
      for(var i = 0; i<Column.length; i++){
        if(Column[i]["DataType"] == "object"){
          var object = {
            "type": "object",
            "key": ProductType.Column[i].Key,
            "name": ProductType.Column[i].Name,
            "required": ProductType.Column[i].required,
            "model": "",
            "value": {
            }
          }
  
          for(var l = 0; l< this.languageList.length; l++){
            object.value[this.languageList[l].Language] = ""
          }
          console.log("object", object);
          this.ProductInfoInputList.push(object)
        }else{
          var object = {
            "type": ProductType.Column[i].DataType,
            "key": ProductType.Column[i].Key,
            "name": ProductType.Column[i].Name,
            "required": ProductType.Column[i].required,
            "model": "",
            "value": ""
          }
          this.ProductInfoInputList.push(object)
        }
      }

      this.ProductParameterInputList = [];
      console.log("ProductType.Parameter", ProductType.Parameter);
      if(ProductType.Parameter){
        for(var p in ProductType.Parameter){
          var parameter = ProductType.Parameter[p];
          var object = {
            type: parameter.Type,
            required: true,
            key: parameter.Key,
            name: parameter.Name.zh,
            value: ""
          }
          if(parameter.Type == 'Option'){
            var option = [];
            for(var o in parameter.Option){
              var optionSelect = parameter.Option[o]
              option.push({
                key: optionSelect.Key,
                name: optionSelect.Name.zh,
              })
              object.option = option;
            }
          }
          if(parameter.Unit){
            object.Unit = parameter.Unit
          }
          this.ProductParameterInputList.push(object)
        }
      }

      console.log("ProductInfoInputList", this.ProductInfoInputList);
      },


    },

  data() {
    return {
      isLoading: false,
      HeaderTitle: "Add Product",
      Form1Title: "General Info",
      Form2Title: "Product Info",
      formActionButton: "Create Product",
      formMessage:  null,
      showFormMessage: false,
      confirmBack:false,
      InventoryList:[],
      country:"",
      countryList:[],
      ProductTypeList:[],
      languageList:[],
      showInputObjectDialog: false,
      InputObjectActionButton: "Set",
      formItem: {},
      StatusInput:{
        "key": "Status",
        "name": "Status",
        "required": true,
        "value": "",
        "option": [
          {
            "key": "Hide",
            "name": "隱藏",
          },
          {
            "key": "Published",
            "name": "公開",
          },
        ]
      },
      ProductNameInput: {
        "type": "object",
        "key": "Name",
        "name": "Product Name",
        "required": true,
        "style": "width: 100%;",
        "model": "",
        "value": {
        }
      },
      SupplierInput:{
        "key": "Supplier",
        "name": "Supplier",
        "required": true,
        "value": "",
        "option": []
      },
      CountryInput: {
        "key": "Country",
        "name": "Country",
        "required": true,
        "model": "",
        "value": "",
      },
      PairingTagsInput:{
        "key": "ParingTags",
        "name": "Paring Tags",
        "required": false,
        "model": [],
        "value": [],
      },
      TasteNotesInput: {
        "key": "TasteNote",
        "name": "Taste Notes",
        "required": false,
        "value": [],
        "model": [],
      },
      AromaInput: {
        "key": "Aroma",
        "name": "Aroma",
        "required": true,
        "value": [],
        "model": [],
      },
      FiveSensesInput: {
        "key": "FiveSenses",
        "name": "五感 (between 0-4)",
        "required": false,
        "value": [
          {
            
            "key": "Sweet",
            "name": "甜",
            "value": 0,
          },
          {
            "key": "Sour",
            "name": "酸",
            "value": 0,
          },
          {
            "key": "Bitter",
            "name": "苦",
            "value": 0,
          },
          {
            "key": "Salty",
            "name": "鹹",
            "value": 0,
          },
          {
            "key": "Umami",
            "name": "鮮",
            "value": 0,
          },
        ],
      },
      ProductInfoInputList: [],
      ProductParameterInputList: [],
      ProductType: "",
      ProductInfoItem: {},
      acceptType: "image/*",
      ProductImageInput: [],
      TasteCardImage: {
        ID: 1,
        type: "TasteCard",
        base64: "", url: ""
      },
      showAddVariance: false,
      ProductVarianceList: [],
      //Table Component Data
      columns: ["img", "Variance Name", "Price", "Weight"],
      TableItemList: [],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      selectedItemAction: [
        {Key: "delete", Name: "Delete selected", Style:"color:#CC2727"},
      ],



    }
  },

};
</script>
<style>
  .dragArea {
    min-height: 50px;
    outline: 1px dashed;
  }
  .variance-table th{
    text-align: left;
  }
</style>
