<template>
  <div class="wrapper">
    <div class="section page-header header-filter" :style="headerStyle" >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-33 md-small-size-66 md-xsmall-size-100 md-medium-size-40 mx-auto"
          >
            <form @submit.prevent="login">
              <login-card header-color="dark">
                
                <h4 slot="title" class="card-title" style='font-family: "Roboto", "Helvetica", "Arial", sans-serif;'>WeSip Admin Panel</h4>

                <p slot="description" class="description">Login</p>
                <md-field class="md-form-group" slot="inputs">
                  <md-icon>email</md-icon>
                  <label style="top:-10px">Email...</label>
                  <md-input v-model="email" type="email"></md-input>
                </md-field>
                <md-field class="md-form-group" slot="inputs">
                  <md-icon>lock_outline</md-icon>
                  <label style="top:-10px">Password...</label>
                  <md-input v-model="password" type="password"></md-input>
                </md-field>
                <md-button slot="footer" class="md-simple md-success md-lg" type="submit">
                  Get Started
                </md-button>
                
              </login-card>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import "../assets/scss/material-kit.scss";
</style>

<script>
import { LoginCard } from "@/components";

export default {
  methods:{
    login:function(){
      let email=this.email
      let password=this.password
      this.$store.dispatch('login',{email,password})
      .then(async ()=> {
          try{
            await this.$router.push("/dashboard");
          }
          catch(err){
            throw new Error(`Problem handling something: ${err}.`);
          }
          
          
        })
      .catch(err=>console.log(err))
    }
  },
  components: {
    LoginCard
  },
  bodyClass: "login-page",
  data() {
    return {
      firstname: null,
      email: null,
      password: null
    };
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/home-03.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
        height:'100vh'
      };
    }
  }
};
</script>

<style lang="css"></style>
