<template>
    <div>
        <md-card class="fiveSensesBox">
            <md-card-content>
                <div style="padding: 0px 20px;">
                    <label>香氣*</label><br>
                    <div v-for="(item, index) in Aroma" :key="index">
                        <label>{{item.Name.zh}}</label> <input type="checkbox" class="sortradio" readonly/><br>
                    </div>
                    <label>其他</label> <input type="checkbox" class="sortradio" readonly/><br>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
export default {
    components: {
    },
    props: {
        Aroma: {
            default() {
                return [];
            },
        },
        displayLanguage: {
            default() {
                return "zh";
            },
        },
    },
    data() {
        return {
            
        };
    },
    async create(){
        console.log("Aroma question")
    },
    computed: {

    },


};
</script>
<style>
    .fiveSensesBox{
        
    }
    .fiveSensesBox label{
        font-size: 18px;
        font-weight: bold;
    }
</style>