<template>
    <div>
        <md-card class="TagePage" style="margin: unset;">
          <md-card-content style="text-align: left;">
            <div class="row">
                <div style="width: 30%;padding:0 20px">
                    <SelectInput
                        :Item="EventTypeInput"
                        v-on:checkEdited="checkEdited"
                        style="padding-top:25px"
                    />
                </div>
                <div style="width: 30%;padding:0 20px">
                    <SelectInput
                        :Item="EventLevelInput"
                        v-on:checkEdited="checkEdited"
                        style="padding-top:25px"
                    />
                </div>
                <div style="width: 30%;padding:0 20px">
                    <CountryInput
                        v-on:checkEdited="checkEdited"
                        :languageList="languageList"
                        :Item="EventLocationInput"
                    />
                </div>
            </div>
            <div class="row" style="padding-top:25px">
                <div class="inputcolumn">
                    <SelectInput
                        :Item="LanguageInput"
                        v-on:checkEdited="checkEdited"
                    />
                </div>
                <div class="inputcolumn">
                    <SelectInput
                        :Item="DisplayLanguageInput"
                        v-on:checkEdited="checkEdited"
                    />
                </div>  
            </div>
            <TextInput
                :Item="TitleInput"
                v-on:checkEdited="checkEdited"
            />
            <TextInput
                :Item="DescriptionInput"
                v-on:checkEdited="checkEdited"
            />
            <ChipsInput
                :Item="TagsInput"
                v-on:checkEdited="checkEdited"
            />
            <TextAreaInput
                :Item="PolicyInput"
                v-on:checkEdited="checkEdited"
            />
            <div v-if="(mode=='Edit')" class="row" style="padding-top:25px">
                <div style="width: 20%">
                    <SelectInput
                        :Item="StatusInput"
                        v-on:checkEdited="checkEdited"
                    />
                </div>
                <!-- <div class="inputcolumn">
                    <SelectInput
                        :Item="EndSurveyInput"
                        v-on:checkEdited="checkEdited"
                    />
                </div>   -->
            </div>
             
          </md-card-content>
        </md-card>
    </div>
</template>


<script>
import { 
   SelectInput,
   CountryInput,
   ChipsInput,
  TextInput,
  TextAreaInput,
 } from "@/components";

export default {
    components: {
        SelectInput,
        CountryInput,
        ChipsInput,
        TextInput,
        TextAreaInput
    },
    props: {
        mode: {
            default (){
                return "Create"
            }
        },
        Status: {
            default (){
                return "Draft"
            }
        },
        EventTypeInput:{
            default: {
                "type": "select",
                "key": "EventType",
                "name": "Event Type",
                "required": true,
                "value": null,
                "option": [],
            },
        },
        EventLevelInput:{
            default: {
                "type": "select",
                "key": "EventLevel",
                "name": "Event Level",
                "required": true,
                "value": null,
                "option": [],
            },
        },
        EventLocationInput:{
            default: {
                "type": "country",
                "key": "EventLocation",
                "name": "Event Location",
                "required": true,
                "value": null,
                "option": [],
            },
        },
        DisplayLanguageInput:{
            default: {
                "type": "select",
                "key": "DisplayLanguage",
                "name": "Display Language",
                "required": true,
                "value": null,
                "option": [],
            },
        },
        LanguageInput:{
            default: {
                "type": "select",
                "key": "Language",
                "name": "Language",
                "required": true,
                "value": null,
                "option": [],
            },
        },
        TitleInput:{
            default: {
                "type": "text",
                "key": "Title",
                "name": "Title",
                "required": true,
                "value": null,
            },
        },
        DescriptionInput:{
            default: {
                "type": "text",
                "key": "Description",
                "name": "Description",
                "required": false,
                "value": null,
            },
        },
        TagsInput:{
            default: {
                "type": "array",
                "key": "Tags",
                "name": "Tags",
                "required": false,
                "value": null,
            },
        },
        PolicyInput:{
            default: {
                "type": "text",
                "key": "Policy",
                "name": "Policy",
                "required": true,
                "value": null,
            },
        },
        StatusInput:{
            default: {
                "type": "select",
                "key": "Status",
                "name": "Status",
                "required": true,
                "value": null,
                "option": [],
            },
        },
        // StartSurveyInput:{
        //     default: {
        //         "type": "select",
        //         "key": "StartSurvey",
        //         "name": "Start Survey",
        //         "required": true,
        //         "value": null,
        //         "option": []
        //     },
        // },
        // EndSurveyInput:{
        //     default: {
        //         "type": "select",
        //         "key": "EndSurvey",
        //         "name": "End Survey",
        //         "required": true,
        //         "value": null,
        //         "option": []
        //     },
        // },
    },
    async created() {
        this.isLoading = true
        await this.getLanguage();
        this.getEventType();
        //this.getEventSurvey();
        await this.getEventLevel();
        // //await this.getLocation();
        this.isLoading = false
    },
    watch:{
        isLoading: function(newValue){
          this.$emit("isLoading", newValue)
        },
  },
    methods:{
        checkEdited:function(){
          this.$emit("checkEdited", true);
        },
        getLanguage: async function(){
            try{
                const ret= await this.$store.dispatch("getLanguage",{router:this.router, data:null})
                console.log("getLanguage", ret);
                var Language = ret.data.Language
                this.languageList = Language;
                this.DisplayLanguageInput.option = [];
                for(var i=0;i<Language.length;i++){
                    this.DisplayLanguageInput.option.push({
                        key: Language[i].Language,
                        name: Language[i].Language
                    })
                }
            }
            catch(err){
                console.log("getLanguage error", err);
                this.formMessage = err
                this.showFormMessage = true
            }
        },
        getEventSurvey: async function(){
            var object={
                TemplateType: "Event"
            }
            try{
                const ret= await this.$store.dispatch("listSurveyTemplates",{router:this.router, data:object})
                console.log("listSurveyTemplates", ret.data);
                for(var i = 0; i< ret.data.data.length; i++){
                    this.StartSurveyInput.option.push({
                        "key": ret.data.data[i].TemplateID,
                        "name": ret.data.data[i].Name,
                    })
                    this.EndSurveyInput.option.push({
                        "key": ret.data.data[i].TemplateID,
                        "name": ret.data.data[i].Name,
                    })
                }
            }
            catch(err){
                console.log(err.message);
            }
        },
        getEventType: async function (){
            try{
                const ret = await this.$store.dispatch('getEventType',{router:this.router})
                console.log("getEventType", ret);
                var EventType = ret.data.EventType
                this.EventTypeInput.option = [];
                for(var i= 0 ; i<EventType.length; i++){
                    this.EventTypeInput.option.push({
                        "key": EventType[i].EventTypeID,
                        "name": EventType[i].Name.en+" / "+EventType[i].Name.zh
                    })
                }
            }catch(err){
                console.log("getEventType error",  err);
                this.formMessage = err.response.data.message
                this.showFormMessage = true
            }
        },
        getEventLevel: async function (){
            try{
                const ret = await this.$store.dispatch('getEventLevel',{router:this.router})
                console.log("getEventLevel", ret);
                var EventLevel = ret.data.EventLevel
                this.EventLevelInput.option = [];
                for(var i= 0 ; i<EventLevel.length; i++){
                    this.EventLevelInput.option.push({
                        "key": EventLevel[i].EventLevelID,
                        "name": EventLevel[i].Name.en+" / "+EventLevel[i].Name.zh
                    })
                }
            }catch(err){
                console.log("getEventLevel error",  err);
                this.formMessage = err.response.data.message
                this.showFormMessage = true
            }
        },
    },
    data() {
        return {
            isLoading: false,
            languageList: [],
        };
    },
}
</script>