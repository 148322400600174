import oneSipApiCall from "../apiCall.js"
import axios from "axios"
import url from "../config.js"
//var url = "https://ihhalvyjlh.execute-api.ap-southeast-1.amazonaws.com/test"
const edm={
  listEmailTemplate({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/list-email-template',data)
  },

  createEmailTemplate({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/create-email-template',data)
  },
  updateEmailTemplate({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/update-email-template',data)
  },
  deleteEmailTemplate({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/delete-email-template',data)
  },
  
  getEmailTemplate({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/get-email-template',data)
  },
  createEmailEvent({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/create-email-event',data)
  },
  listEmail({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/list-email', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  sendTestEmail({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/send-test-email', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },

  createEmailEventDraft({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/create-email-event-draft', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  searchEmailEvent({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/search-email-event',data)
  },
  listEmailEvent({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/list-email-event', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  cancelEmailEvent({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/cancel-email-event', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  getEmailEvent({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/get-email-event', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  editEmailEvent({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/edit-email-event', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  editEmailEventDraft({commit,state},data){
    return new Promise((resolve, reject) => {

      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/edit-email-event-draft', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  

}

export default edm;