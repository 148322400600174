import oneSipApiCall from "../apiCall.js"
import axios from "axios"
import url from "../config.js"

const footerContent={
    getFooterContent({commit,state},{router}){
        return new Promise((resolve, reject) => {
            commit('action_request');
            axios({url: url+'/getFooterContent', method: 'GET' })
            .then(resp => {
              commit('action_success');
              console.log(resp);
              resolve(resp)
            })
            .catch(err => {
              if(err.response.stats==401){ 
                this.$router.push({path: '/login'})
              }
              commit('action_error',{err})
              reject(err)
            })
            
          })
  },
  createFooterContent({commit,state},form){
    return new Promise((resolve, reject) => {
      console.log("createFooterContent", form);
      commit('action_request');
      resolve(state.idToken);
      axios({url: url+'/createFooterContent', data:form, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(async err => {
        try{
            var ret=await errorHandler.handle(commit,err,router,path,params)
            console.log(ret)
            if(ret.status==200){
              resolve(ret)
            }
            else{
              console.log(err);
              commit('action_error',{err})
              reject(err)
            }
          }
          catch(err){
            console.log(err);
            commit('action_error',{err})
            reject(err)
          }
      })
      
    })
  },
  updateFooterContent({commit,state},form){
    return new Promise((resolve, reject) => {
      console.log("updateFooterContent", form);
      commit('action_request');
      resolve(state.idToken);
      axios({url: url+'/updateFooterContent', data:form, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        if(err.response.stats==401){ 
          this.$router.push({path: '/login'})
        }
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  deleteFooterContent({commit,state},form){
    return new Promise((resolve, reject) => {
      console.log("deleteFooterContent", form);
      commit('action_request');
      resolve(state.idToken);
      axios({url: url+'/deleteFooterContent', data:form, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(async err => {
        try{
            var ret=await errorHandler.handle(commit,err,router,path,params)
            console.log(ret)
            if(ret.status==200){
              resolve(ret)
            }
            else{
              console.log(err);
              commit('action_error',{err})
              reject(err)
            }
          }
          catch(err){
            console.log(err);
            commit('action_error',{err})
            reject(err)
          }
      })
      
    })
  },
  setFooterContent({commit,state},form){
    return new Promise((resolve, reject) => {
      console.log("setFooterContent", form);
      commit('action_request');
      resolve(state.idToken);
      axios({url: url+'/setFooterContent', data:form, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        if(err.response.stats==401){ 
          this.$router.push({path: '/login'})
        }
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
}

export default footerContent;