<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <FormHeader
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          >
        </FormHeader>
        <center>
          <md-card style="width:50%;min-width: 500px">
            <md-card-content >
                <FormComponent
                v-on:formSubmit="formSubmit"
                v-on:checkEdited="checkEdited"
                v-on:additionalFunction="additionalFunction"
                :FormTitle="FormTitle"
                :FormItem="FormItem"
                :formMessage="formMessage"
                :showFormMessage="showFormMessage"
                :additionalFormButton="additionalFormButton"
                >
                </FormComponent>
            </md-card-content>
          </md-card>
        </center>
        </div>
      </div>
      <Spinner v-if="isLoading"/> 
    </div>
  </template>
  
  <script>
  
  import { 
    FormComponent,
    FormHeader,
    Spinner
   } from "@/components";
  export default {
    methods:{
      checkEdited: async function(check){
        this.confirmBack = check
      },
      additionalFunction: async function (object){
        console.log("add customer to group")
        this.isLoading = true
        try{
            const ret= await this.$store.dispatch('createUserGroup',{router:this.router,data:object})
            this.isLoading = false
            this.$router.replace({name: 'Add Customer To Customer Group', params:{customerGroupID: object.UserGroupID}})
          }
          catch(err){
            this.isLoading = false
            console.log("createUserGroup error", err.response.data);
            this.formMessage = err.response.data.message
            this.showFormMessage = true
          }
      },
      formSubmit: async function(object){
        console.log("formSubmit")
        console.log(object)
        this.isLoading = true
        try{
            const ret= await this.$store.dispatch('createUserGroup',{router:this.router,data:object})
            this.isLoading = false
             setTimeout(()=>{
              console.log("go to CustomerGroup List");
              this.$router.replace({name: 'CustomerGroup List', params:{message:"New Customer Group added"}});
             }, 2000);
          }
          catch(err){
            this.isLoading = false
            console.log("createUserGroup error", err.response.data);
            this.formMessage = err.response.data.message
            this.showFormMessage = true
          }
      },

    },
    components: {
      FormComponent,
      FormHeader,
      Spinner
    },
    data() {
      return {
        isLoading: false,
        HeaderTitle: "Add Customer Group",
        FormTitle: "Customer Group",
        additionalFormButton: "Add Customer to Group",
        formMessage:  null,
        showFormMessage: false,
        confirmBack:false,
        FormItem: {
          "row1":[
            {
              "type": "text",
              "key": "UserGroupID",
              "name": "User Group ID",
              "required": true,
              "style": "width: 100%",
              "value": "",
            },
          ],
        }
      }
    },
  
  };
  </script>
  