<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <TableHeader
        :HeaderTitle="HeaderTitle"
        :AddNewButton="AddNewButton"
        v-on:createNewItem="createNewItem"
      ></TableHeader>
      <md-card>
          <md-card-content>
            <TableComponent 
            v-on:selectItem="selectItem"
            v-on:selectAllItem="selectAllItem"
            v-on:searchItem="searchItem"
            v-on:onclickItem="onclickItem"
            v-on:selectSortOption="selectSortOption"
            v-on:selectedItemActionFunction="selectedItemActionFunction"
            v-on:goPage="goPage"
            v-on:SortPopupControl="SortPopupControl"
            v-on:setFilter="setFilter"
            :TableList="TableItemList"
            :selectedItemCounter="selectedItemCounter"
            :selectedItem="selectedItem"
            :columns="columns"
            :selectAll="selectAll"
            :selectable="selectable"
            :haveSearchFunction="haveSearchFunction"
            :sortOption="sortOption"
            :selectedItemAction="selectedItemAction"
            :pageNumber="pageNumber"
            :showNextButton="showNextButton"
            :showSortPopup="showSortPopup"
            :filterOption="filterOption"
            ></TableComponent>
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
      <Transition name="modal" v-if="showPopup">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="popupaction"
            :PopupTitle="PopupTitle"
            :PopupContent="PopupContent"
            :actionButton="actionButton"
          ></Popup>
      </Transition>
    </div>
    <Spinner v-if="isLoading"/> 
    <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
    
  </div>
</template>

<script>
import { 
  TableComponent,
  TableHeader,
  Popup,
  Spinner
} from "@/components";

export default {
  components: {
    TableComponent,
    TableHeader,
    Popup,
    Spinner
  },
  data() {
    return {
      message: "",
      showPopup: false,
      showSortPopup: false,
      isLoading: false,
      PopupTitle: "Delete Product",
      PopupContent: "The Product data will no longer saved and will not be able to recover the data",
      actionButton: "Delete anyway",
      index: 0,
      pageNumber: 1,
      showMessage: false,
      showNextButton: true,
      displaySize: 10,
      HeaderTitle: "Product List",
      ProductTypeList: [],
      SupplierList: [],
      TableItemList: [],
      AddNewButton:  "Add Product",
      columns: ["img", "Product Name", "Type", "Minimum Price", "Producer"],
      Source: ["Name", "ProductType.Name", "MinimumPrice", "SupplierID", "Image","ProductID"],
      Sort: {"CreatedDatetime": "desc"},
      //sortOption:[],
      sortOption: [
        {Key: "CreatedDatetime", Name: "Recent Added", Value: true, Order: "desc"},
        {Key: "MinimumPrice", Name: "Price", Value: false, Order: "desc"},
      ],
      selectedItemAction: [
        {Key: "delete", Name: "Delete selected", Style:"color:#CC2727"},
      ],
      //filterOption: [],
      filterOption:[
          {
            Key: "ProductType", 
            Name: "Type", 
            type: "checkbox",
            inputType: "checkbox",
            option:[]
          },
          {
            Key: "MinimumPrice",
            Name: "Minimum Price", 
            type: "range",
            inputType: "number",
            from: "",
            to: ""
          }
        ],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      haveSearchFunction: true,
      filter: {},
      range: {},
    }
  },
  async created() {
    this.isLoading = true;
    await this.getProductType();
    await this.getSuppliers();
      console.log("params", this.$route.params)
      this.message = this.$route.params.message;
      if(this.$route.params.page){
        if(Number(this.$route.params.page) > 0){
          this.pageNumber = Number(this.$route.params.page)
        }
        this.index = this.index + (Number(this.$route.params.page) - 1 )*this.displaySize
      }
      this.checkShowMessage();
      await this.searchItem({keyword:""});
      this.isLoading = false;
    },
  methods:{
    getSuppliers: async function(){
      try{
        const ret= await this.$store.dispatch("listSuppliers",{router:this.router})

        for(var i in ret.data.supplierList){
          this.SupplierList.push({
            SupplierID: ret.data.supplierList[i].SupplierID,
            Name: ret.data.supplierList[i].Name,
          })
        }
        // for(var i= 0 ; i<ret.data.supplierList.Users.length; i++){
        //   this.SupplierList.push({
        //     SupplierID: ret.data.supplierList.Users[i].Attributes[0].Value,
        //     Name: ret.data.supplierList.Users[i].Username,
        //   })
        // }
      }
      catch(err){
        console.log("listSuppliers error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
      }
    },
    getProductType: async function(){
      try{
        const ret= await this.$store.dispatch("getProductType",{router:this.router, data:null})
        this.ProductTypeList = ret.data.ProductType;
        console.log("getProductType", this.ProductTypeList)
        var filter = this.filterOption.find(x => x.Key == "ProductType")
        filter.option = [];
        for(var p in this.ProductTypeList){
          filter.option.push({
            "Key": this.ProductTypeList[p].ProductTypeID,
            "Name": this.ProductTypeList[p].Name.zh,
            "Value": false
          })

        }
      }
      catch(err){
        console.log("getProductType error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
      }
    },
    setFilter: function (filterOption){
      console.log("setFilter", filterOption);
        this.filter = {};
      for(var f in filterOption){
        var option = filterOption[f]
        if(option.type == "range"){
          var filterObject = {}
          if(option.from){
            filterObject["Start"] = option.from
          }
          if(option.to){
            filterObject["End"] = option.to
          }
          if(filterObject.Start || filterObject.End){
            this.range[option.Key] = filterObject
          }
        }else{
          var filterList = [];
          for(var o in option.option){
            if(option.option[o].Value){
              filterList.push(option.option[o].Key)
            }
          } 
          if(filterList.length > 0){
            this.filter[option.Key] = filterList
          }
        }
      } 
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;

        this.index = 0;
        this.pageNumber = 1;
        console.log("filter", this.filter)
        this.searchItem({keyword:""});
      },
    SortPopupControl: function (){
        this.showSortPopup = !this.showSortPopup
      },
    goPage:function(action){
      if(action == "next"){
        this.index = this.index + this.displaySize
        this.pageNumber = this.pageNumber + 1
      }else if(action == "prev" && this.index > 0){
        this.index = this.index - this.displaySize
        this.pageNumber = this.pageNumber - 1
      }else{
        this.index = 0
        this.pageNumber = 1
      }
      this.$router.replace({ name: "Product List PageNumber", params: {page:this.pageNumber} })
      this.searchItem({keyword:""})
    },
    checkShowMessage: async function(){
      if(this.message){
        try{
          await this.delayFunction();
          this.showMessage = true;
        }catch(err){
          console.log(err)
        }
      }
    },
    delayFunction:async function (){
        setTimeout(function(){
          this.showMessage = false;
          this.message = "";
          return true;
        }, 1000);
    },
    selectedItemActionFunction:function(action){
      console.log("selectedItemActionFunction", action);
      if(action == "delete"){
        this.showPopup = true
        //this.deleteSelectedProduct();
      }
    },
    onclickItem: function(ID){
      this.$router.push({name: 'Update Product', params:{ProductID:ID}})
    },
    createNewItem: function(){
      this.$router.push({name: 'Create Product'})
    },
    popupcancel: function(){
        this.showPopup = false;
      },
    popupaction: function (){
      this.showPopup = false;
      this.deleteSelectedProduct();
    },  
    deleteSelectedProduct: async function(){
      console.log("delete Product")
      this.isLoading = true;
      for(var i = 0; i<this.selectedItem.length; i++){
        await this.deleteProduct(this.selectedItem[i])
      }
      this.message = this.selectedItem.length + " Products has been Delete"
      try{
        
        await this.delayFunction();
        this.selectedItem = [],
        this.selectedItemCounter = 0;
        this.showMessage = true;
        this.searchItem({keyword:""});
      }catch(err){
        this.isLoading = false;
        console.log(err)
      }
    },
    deleteProduct: async function (ProductID){
        try{
          const ret= await this.$store.dispatch('deleteProduct',{router:this.router,product:{ProductID: ProductID}})
        }catch(err){
          console.log("deleteProduct error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
    },
    selectSortOption: function(option){
      console.log("charge sorting with " + option);
      var sortOrder = "";
      var index = this.sortOption.findIndex(sort => sort.Key == option)
      console.log("option", index);
      if(index > -1){
        this.sortOption[index].Value = true
        this.sortOption[index].Order = this.sortOption[index].Order == "asc" ? "desc" : "asc"

        sortOrder = this.sortOption[index].Order
        this.Sort[option] = sortOrder

        this.showSortPopup = false;
        this.searchItem({keyword:""});
      }
    },

    selectAllItem: async function(action){
        this.selectAll = false;
        if(action){
          this.selectAll = true;
            try{
              const ret= await this.$store.dispatch('listAllProductID',{router:this.router})
              this.selectedItem = ret.data.ProductList.map(item => String(item.ProductID))
            }catch(err){
              console.log("listAllProductID error", err.response.data);
              this.formMessage = err.response.data.message
              this.showFormMessage = true
            } 
        }else{
          this.selectedItem = []
        }
        console.log("after", this.selectedItem);
        this.selectedItemCounter = this.selectedItem.length
      },
    selectItem:function(ID, action){
      console.log(ID, action);
      if(action){
        this.selectedItem.push(ID)
      }else{
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID)
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length

    },
    searchItem: async function({keyword}){
      this.isLoading = true;
      var object = {
        "Size": this.displaySize,
        "From": this.index,
        "Sort": this.Sort,
        "Source": this.Source,
        "Filter": this.filter,
        "Range": this.range
      }
      if(keyword){
        object["Keyword"] = keyword
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;
        this.index = 0;
        this.pageNumber = 1;
      }

      try{
        const ret= await this.$store.dispatch('searchProduct',{router:this.router,keyword:object})
        this.setDisplayList(ret.data.data.hits.hits)
        this.isLoading = false;
      }catch(err){
        this.isLoading = false;
        console.log("searchProduct error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
      }
    },

      setDisplayList(list){
        var newlist=[];
        for(var i = 0; i<list.length; i++){
          var Supplier = this.SupplierList.find(x => x.SupplierID == list[i]["_source"].SupplierID)
          var object = {
            "ID": list[i]["_source"].ProductID,
            "Product Name": list[i]["_source"].Name.en + " / " + list[i]["_source"].Name.zh,
            "Type": list[i]["_source"].ProductType.Name.en,
            "Minimum Price": list[i]["_source"].MinimumPrice,
            "Producer": Supplier.Name,
          }
          if(list[i]["_source"].Image.length > 0){
            object["img"] = process.env.VUE_APP_S3_URL + list[i]["_source"].Image[0].path
          }
          newlist.push(object)
        }
        this.TableItemList = newlist;
        this.TableSelectUIControl(this.TableItemList)
      },
      TableSelectUIControl(ItemList){
        this.showNextButton = true;
        if(ItemList.length < this.displaySize){
          this.showNextButton = false;
        }
        var counter = 0;
        for(var i=0; i<ItemList.length;i++){
            var index = this.selectedItem.indexOf(ItemList[i].ID);
            if(index > -1){
                counter++
            }
        }
        if(counter ==  ItemList.length){
            this.selectAll = true;
        }else{
            this.selectAll = false;
        }
      }
  }
};
</script>
