<template>
  <div class="white-bg full-height">
    <ComponentSet v-for="(item,index) in menuList" :key="index" :item="item"/>
  </div>
</template>
<script>
import initMenu from '../../config/menu.js';
import ComponentSet from './ComponentSet.vue';
export default {
  name:"survey-tools-bar",
  components:{
    ComponentSet
  },
  data(){
    return {
      menuList: initMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
  .white-bg{
    background-color: white;
  }
  .full-height{
    height:100%;
  }
</style>
