import oneSipApiCall from "../apiCall.js"

const payment={
  searchProductOrder({commit,state},{router,keyword}){
    return oneSipApiCall(commit,state,router,'/admin-search-product-order',keyword)
  },
  searchGatheringOrder({commit,state},{router,keyword}){
    return oneSipApiCall(commit,state,router,'/admin-search-gathering-order',keyword)
  },
  listGatheringParticipationPayment({commit,state},{router,gatheringID}){
    return oneSipApiCall(commit,state,router,'/admin-list-gathering-participation-payment',gatheringID)
  },
  listGatheringProductPayment({commit,state},{router,gatheringID}){
    return oneSipApiCall(commit,state,router,'/admin-list-gathering-product-payment',gatheringID)
  },
  listParticipationPayment({commit,state},{router}){
    return oneSipApiCall(commit,state,router,'/admin-list-participation-payment')
  },
  listProductPayment({commit,state},{router}){
    return oneSipApiCall(commit,state,router,'/admin-list-product-payment')
  },
  calculateOrder({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-calculate-order',data)
  },
  createOrder({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-create-order',data)
  },
  getOrderDetail({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-get-order-detail',data)
  },
  createDeliveryOrder({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-create-delivery-order',data)
  },
  searchRefundRequest({commit,state},{router,keyword}){
    return oneSipApiCall(commit,state,router,'/admin-search-refund-request',keyword)
  },
  getRefundRequestDetail({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-get-refund-request',data)
  },
  updateRefundRequest({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-update-refund-request',data)
    return new Promise((resolve, reject) => {
      console.log(data);
      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/updateRefundRequest', data:data, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        if(err.response.stats==401){
          
        }
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  refundPayment({commit,state},{router,method,payment}){
    if(method=="Paypal")
      return oneSipApiCall(commit,state,router,'/admin-paypal-refund',payment)
    return "Invalid Method";
  }
}
export default payment;