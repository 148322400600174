
<template>
    <div class="row" style="padding: 0 10px">
      <div class="column" style="width: 50%">
        <div class="row">
          <md-button v-if="backButton" class="backbutton" style="background-color:white !important;" @click="goBack">
            <md-icon class="backIcon" style="color:black !important;margin-left: -10px;">arrow_back_ios</md-icon>
          </md-button>
          <h3 class="title" style="flex: 1" v-bind:style="[(backButton)? {'padding-left':'20px'}: '']"> {{HeaderTitle}}</h3>
        </div>

      </div>
      <div class="column" style="width: 50%; text-align: right;">
        <button v-if="AddNewButton" @click="createNewItem()" class="tablenewItemButton">{{AddNewButton}}</button>
      </div>
        
        
    </div>
</template>
  
  <script>
  export default {
   
    methods:{
      createNewItem: function(){
        this.$emit("createNewItem");
      },
      goBack: function(){
        this.$router.go(-1)
      },
    },

    name: "table-header-component",
    props: {
        HeaderTitle:{
        required: true,
        type: String,
        default() {
            return "";
        },
      },
      AddNewButton:{
        type: String,
        default() {
            return "";
        },
      },
      backButton:{
        default(){
          return false;
        }
      }
    },
    data() {
      return {
        
      };
    },
    computed: {

    }
  };
  </script>

  <style>
    .backButtonTitlePadding{
      padding-left: 20px;
    }
    .backIcon{
      padding: unset;
      margin: unset;
      color:black !important;
    }
    .backbutton{
      padding: unset;
      margin: 15px 0 0 0 !important;
      min-width: 40px !important;
      width: 40px !important;
      height: 45px !important;
    }
  .tablenewItemButton{
    min-width: 100px;
    margin-top: 15px;
    padding: 15px 20px;
    background-color: #575757;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 400; 
  }
  </style>
  