// =========================================================
// * Vue Material Dashboard - v1.4.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";

import VueRouter from "vue-router";
import App from "./App";

// router setup
import routes from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import Chartist from "chartist";

import store from './store'
import Axios from 'axios'

import VueCompositionAPI from '@vue/composition-api'

import Vuetify from "vuetify";
import MuseUI from 'muse-ui';


Vue.prototype.$http = Axios;

const idToken = localStorage.getItem('idToken')
if (idToken) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = idToken
  //console.log(isLoggedIn);
}

//vue-material quick fix
Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV !== 'production') {
    // Show any error but this one
    if (err.message !== "Cannot read property 'badInput' of undefined" && err.message !== "Cannot read properties of undefined (reading 'badInput')") {
      console.error(err);
    }
  }
};

// configure router
let router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active",
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    console.log("test: "+store.getters.isLoggedIn);
    console.log("test: "+store.getters.refreshToken);
    next('/login') 
  } else {
    if(store.getters.isLoggedIn && to.name=="login"){
      console.log("test: "+store.getters.isLoggedIn);
      console.log("test: "+store.getters.refreshToken);
      next('/dashboard')
    }
    else{
    next()
    } 
  }
})


Vue.prototype.$Chartist = Chartist;
import { library } from '@fortawesome/fontawesome-svg-core'; /* import the fontawesome core */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'; /* import font awesome icon component */
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faFacebookF, faLine, faFacebookMessenger, faPinterest, faTelegramPlane, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
library.add( faEnvelope, faFacebookF, faLine, faFacebookMessenger, faPinterest, faTelegramPlane, faTwitter, faWhatsapp );
// import { fab } from '@fortawesome/free-brand-svg-icons';
// import { fas } from '@fortawesome/free-solid-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';
Vue.component('font-awesome-icon', FontAwesomeIcon);  /* add font awesome icon component */

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.use(VueCompositionAPI)
Vue.use(MuseUI);
Vue.use(Vuetify);

const opts = {};

var vuetify = new Vuetify(opts);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  router,
  store,
  vuetify,
  data: {
    Chartist: Chartist
  }
});
