<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <FormHeader
        v-on:formAction="formAction"
        :HeaderTitle="HeaderTitle"
        :confirmBack="confirmBack"
        :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <div style="width:35%" v-if="!editOn">
            <md-card style="margin:10px 0">
            <md-card-content>
              <FormComponent
                v-on:formSubmit="formSubmit"
                v-on:checkEdited="checkEdited"
                :FormTitle="FormTitle"
                :FormItem="FormItem"
                >
              </FormComponent>
            </md-card-content>
          </md-card>
          </div>
          <div style="width:30%;padding-top:10px">
            <CodeEditor
              :code="HtmlPart"
              v-on:updateCode="updateHtmlPart"
            ></CodeEditor>
          </div>
          <div style="width:65%;padding-left: 30px;z-index: 0;">
            <HtmlPreview
            :HtmlPart="HtmlPart"
            @update="updateHtmlPart"
            ></HtmlPreview>
          </div>
        </div>   
      </div>
    </div>
    <Spinner v-if="isLoading"/> 
    <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
  </div>
</template>

<script>

import { 
  FormComponent,
  FormHeader,
  CodeEditor,
  HtmlPreview,
  Spinner
 } from "@/components";



export default {
  components: {
    FormComponent,
    FormHeader,
    HtmlPreview,
    CodeEditor,
    Spinner
  },
  created() {
    this.getEmailTemplate(this.$route.params.templateName);
  },
  methods:{
    getEmailTemplate: async function(templateName){

      console.log("getEmailTemplate", templateName)
      this.isLoading = true
      try{
        const ret = await this.$store.dispatch('getEmailTemplate',{router:this.router,data:{TemplateName: templateName}})
        console.log("getEmailTemplate", ret)
        this.setDatatoForm(ret.data.TemplateData);
        this.setDatatoForm(ret.data.TemplateResult.Template);
        this.HtmlPart = ret.data.TemplateResult.Template.HtmlPart;    
        this.isLoading = false
      }catch(err){
        this.isLoading = false
        console.log("getEmailTemplate error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    setDatatoForm: function(data){
      console.log("setDatatoForm", data)
      for (const [key, value] of Object.entries(this.FormItem)) {
        console.log(value)
        for(var i = 0 ;i<value.length; i++){
          if(data[value[i].key]){
            value[i]["value"] = data[value[i].key];
          }
        }
      }
      console.log("FormItem", this.FormItem)
    }, 
    formAction: function(){
      this.editOn=!this.editOn;
    },
    formSubmit: async function(data){
     
      data.HtmlPart = this.HtmlPart;
      console.log("formSubmit", data)
      this.isLoading = true;
      try{
          const ret= await this.$store.dispatch('updateEmailTemplate',{router:this.router,data:data})
          setTimeout(()=>{
              this.isLoading = false;
              this.$router.replace({name: 'Email Template', params:{message:"New Email Template created"}})
              
             }, 2000);
        }
        catch(err){
          this.isLoading = false;
          console.log("updateEmailTemplate error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
    },

    checkEdited: async function(check){
      this.confirmBack = check
    },
    updateHtmlPart(value){
      this.confirmBack = true;
      this.HtmlPart=value;
    }
  },
  watch:{
    HtmlPart(newValue){
      console.log("HtmlPart changed: "+newValue);
    }
  },
  data() {
    return {
      isLoading: false,
      HeaderTitle: "Edit Email Template",
      FormTitle: "Template Info",
      formActionButton: "Edit Mode",
      formMessage:  null,
      showFormMessage: false,
      confirmBack:false,
      QuestionList:[],
      HtmlPart: "",
      editOn:false,
      FormItem: {
        "row1":[
          {
            "type": "text",
            "key": "TemplateName",
            "name": "Template Name",
            "required": true,
            "style": "width:100%",
            "value": null,
          },
        ],
        "row2":[
          {
            "type": "text",
            "key": "SubjectPart",
            "name": "Subject",
            "required": true,
            "style": "width:100%",
            "value": null,
          },
        ],
        "row3":[
          {
            "type": "text",
            "key": "TextPart",
            "name": "Text",
            "required": false,
            "style": "width:100%",
            "value": null,
          },
        ],
        "row4":[
          {
            "type": "object",
            "key": "templateData",
            "name": "Data",
            "hits": "TemplateData (using {{ data }} in html for data)",
            "style": "width:100%",
            "value": {},
          },
        ],


      },
    }
  },

};
</script>
