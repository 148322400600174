
<template>
    <div> 

        <div v-if="QuestionList.length==0" style="text-align:center"> 
            <md-card style="margin:10px 0;width: 550px;">
            <md-card-content>
                <h3 class="formTitle">Create your first Page of Survey</h3>
                <md-button  style=" background-color: #575757 !important;width: 30% !important;" @click="addSurveyPage()">Start</md-button>
            </md-card-content>
            </md-card>
        </div>
        <!-- <div v-else class="row" >      
            <div v-for="(value, index) in QuestionList" :key="index" class="column" style="min-width: 550px;">
                <div >
                    Page: {{ index + 1 }}
                    <SurveyTemplateQuestion
                        :QuestionList="value['questionList']"
                    />
                </div>
            </div>
        </div> -->
        <div v-else>
            <div class="row"  style="flex-wrap: inherit;">
                <div class="column" v-for="(value, index) in QuestionList" :key="index" style="padding-right: 20px;">
                    <md-card style="margin:10px 0;width: 550px;">
                        <md-card-content>   
                            <div class="row">
                                <div class="col"> <h3 class="formTitle"> Page: {{ index + 1 }}</h3></div>
                                <div style="text-align: right;" class="row">
                                    <div class="col" @click="diplicateSurveyPage(index)"><md-icon class="iconButton">file_copy</md-icon></div>
                                    <div class="col" @click="delSurveyPage(index)"><md-icon class="iconButton" style="color:#cc2727">delete</md-icon> </div>
                                </div>
                            </div>
                            <div>
                                <label>Title</label>
                                <md-field style="margin-top:0px;padding-top:5px">
                                    <md-input type="text" class="formInput" v-model=value.Title />
                                </md-field>
                                <label>Description</label>
                                <md-field style="margin-top:0px;padding-top:5px">
                                    <md-input type="text" class="formInput" v-model=value.Description />
                                </md-field>
                            </div>
                            <SurveyTemplateQuestion
                                :QuestionList="value['QuestionList']"
                            />
                        </md-card-content>
                    </md-card>
                </div>
                <div class="column">
                    <div>
                        <md-button class="md-fab md-mini md-primary md-arrow" @click="addSurveyPage()">
                            <md-icon>add</md-icon>
                        </md-button>
                    </div>
                    <!-- <md-button  style="background-color: #575757 !important;width: 200px !important;"  >Add Page</md-button> -->
                </div>
            </div> 

        </div>
    </div>

</template>
  
  <script>

import SurveyTemplateQuestion from "./SurveyTemplateQuestion.vue";

  export default {
    components: {
        SurveyTemplateQuestion
    },
    methods:{
        addSurveyPage: function(){
            console.log(this.QuestionList.length);
            this.paddingValue = 170 + (this.QuestionList.length * 570);
            this.$emit("addSurveyPage")
        },
        delSurveyPage: function(index){
            this.paddingValue = 170 + (this.QuestionList.length * 570);
            this.$emit("delSurveyPage", index)
        },
        diplicateSurveyPage: function(index){
            this.paddingValue = 170 + (this.QuestionList.length * 570);
            this.$emit("diplicateSurveyPage", index)
        },
        addQuestion: function(){
            console.log("addQuestion")
            //this.$emit("addQuestion")
        },
    },

    name: "survey-template-question-component",
    props: {
        QuestionList:{
            type: Array,
            default(){
                return []
            }
        }
    },
    data() {
      return {
        paddingValue: 170
      };
    },
    computed: {

    }
  };
  </script>

  <style>
    .addQuestion{
        cursor: pointer;
        font-size: 18px;
    }
    .formTitle{
      padding-left: 15px;
      margin-top: 10px;
      font-weight: 500;
      font-size: 20px;
    }
    .iconButton{
        cursor: pointer;
    }

  </style>
  