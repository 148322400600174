<template>
  <div class="container">
    <div class="button-bar">
        <md-button type="button" class="md-primary" @click="save">Save</md-button>
      </div>
    <md-card class="md-layout-item template-card">
      <md-card-header class="template-header">
        <div class="md-title">Survey Template Info</div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label>Gathering Type</label>
              <md-input v-model="gatheringType" :readonly="$route.params.surveyTemplate"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label>Name</label>
              <md-input v-model="name"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label>Description</label>
              <md-input v-model="description"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-checkbox v-model="hideProductName">Hide Product Name</md-checkbox>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-checkbox v-model="canReSubmit">Can Re-Submit</md-checkbox>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <QuestionRowEditor 
      :questionList="questionList" 
      v-on:createQuestion="createQuestion"
      v-on:removeQuestion="removeQuestion"
      v-on:rowSelected="rowSelected"
      v-on:rowMoveUp="rowMoveUp"
      v-on:rowMoveDown="rowMoveDown"
      v-on:updateQuestionProp="updateQuestionProp"
    />
  </div>
</template>

<script>
import QuestionRowEditor from './components/simple/QuestionRowEditor.vue';

export default {
  name: "simple-survey-template-builder",
  components:{
    QuestionRowEditor
  },
  props:['surveyTemplate'],
  data(){
    if(this.surveyTemplate!==undefined)
      return {
        questionList:this.surveyTemplate.ProductQuestion || [],
        questionCount:0,
        name:this.surveyTemplate.Name ||"",
        gatheringType:this.surveyTemplate.GatheringType ||"",
        description:this.surveyTemplate.Description ||"",
        hideProductName:this.surveyTemplate.HideProductName ||false,
        canReSubmit:this.surveyTemplate.CanReSubmit ||false,
        loading:false
      }
    else{
        return {
        questionList:[],
        questionCount:0,
        name:"",
        gatheringType:"",
        description:"",
        hideProductName:false,
        canReSubmit:false,
        loading:false
      }
    }
  },
  methods:{
    createQuestion:function(){
      var newQuestion={
        "index":this.questionCount,
        "question":"",
        "questionType":"",
        "optional":"false",
        "description":"",
      };
      if(this.questionList===undefined)
        this.questionList=[];
      this.questionList.push(newQuestion);
      this.questionCount++;
      //console.log(this.questionList);
    },
    removeQuestion:function(index){
      //console.log(index);
      //console.log(this.questionList[index]);
      this.questionList.splice(index,1)
      //.map((q, index) => q.index = index) //doesn't work
      //this.$delete(this.questionList,index);
      //console.log(this.questionList);
      
    },
    rowSelected:function(index,value){
      this.questionList[index].questionType=value;
      //console.log(this.questionList);
    },
    rowMoveUp:function(index,offsetTop){
      // console.log(index);
      // console.log(this.questionList[index]);
      // console.log(this.questionList);
      if(index!=0){
        var temp=this.questionList[index-1];
        this.$set(this.questionList,index-1,this.questionList[index]);
        this.$set(this.questionList,index,temp)
      }
      // console.log(this.questionList);
      // console.log(offsetTop);
      window.scrollTo(0, offsetTop);
    },
    rowMoveDown:function(index,offsetTop){
      // console.log(index);
      // console.log(this.questionList[index]);
      // console.log(this.questionList);
      if(index!=this.questionList.length-1){
        var temp=this.questionList[index+1];
        this.$set(this.questionList,index+1,this.questionList[index]);
        this.$set(this.questionList,index,temp)
      }
      // console.log(this.questionList);
      // console.log(offsetTop);
      window.scrollTo(0, offsetTop);
    },
    updateQuestionProp:function(index,key,value){
      this.questionList[index][key]=value;
      //console.log(index,key,value);
    },
    removeQuestionProp:function(index,key){
      this.$delete(this.questionList[index],key);
      //console.log(index,key);
    },
    save:function(){
      this.loading = true;
      var data={
        "gatheringType" : this.gatheringType,
        "description": this.description,
        "name": this.name,
        "productQuestion": this.questionList,
        "hideProductName" : this.hideProductName,
        "canReSubmit": this.canReSubmit,
      }
      console.log(data);
      this.$emit("save",data);
      
    }
    //create
  }
};
</script>

<style lang="scss" scoped>
  
  .container{
    display: block;
    height: 100%;
    width:100%;
    // flex-direction: row;
    margin: 0;
    padding: 0;
  }
  .item{
    margin:auto;
  }
  .left-bar{
    flex:1
  }
  .right-bar{
    flex:1
  }
  .main-area{
    flex:3
  }
  .template-card{
    margin-left: auto!important;
    margin-right: auto!important;
    display: table;
    width: 90%;
  }
  .template-header{
    background-color: #4caf50!important;
    color:white;
    
  }
  .button-bar{
    text-align: right;
    width: 100%;
    margin-bottom: 20px;
    right: 25px;
    position: relative;
  }
</style>
<style lang="css">
  .md-theme-default{
    height: auto;
  }

</style>
