<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <TableHeader
          :HeaderTitle="HeaderTitle"
          :AddNewButton="AddNewButton"
          :backButton=true
          v-on:createNewItem="createNewItem"
        ></TableHeader>
        <center>
          <md-card style="width:90%;">
            <md-card-content>
              <TableComponent 
              v-on:selectItem="selectItem"
              v-on:selectAllItem="selectAllItem"
              v-on:searchItem="searchItem"
              v-on:onclickItem="onclickItem"
              v-on:selectSortOption="selectSortOption"
              v-on:selectedItemActionFunction="selectedItemActionFunction"
              v-on:goPage="goPage"
              v-on:SortPopupControl="SortPopupControl"
              v-on:setFilter="setFilter"
              :TableList="TableItemList"
              :selectedItemCounter="selectedItemCounter"
              :selectedItem="selectedItem"
              :columns="columns"
              :selectAll="selectAll"
              :selectable="selectable"
              :haveSearchFunction="haveSearchFunction"
              :sortOption="sortOption"
              :filterOption="filterOption"
              :selectedItemAction="selectedItemAction"
              :pageNumber="pageNumber"
              :showNextButton="showNextButton"
              :showSortPopup="showSortPopup"
              ></TableComponent>
            </md-card-content>
          </md-card>
        </center>

          <!-- :AddNewItem="AddNewItem" -->
        </div>
        <Transition name="modal" v-if="showPopup">
            <Popup
              v-on:popupcancel="popupcancel"
              v-on:popupaction="popupaction"
              :PopupTitle="PopupTitle"
              :PopupContent="PopupContent"
              :actionButton="actionButton"
            ></Popup>
        </Transition>
      </div>
      
      <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
      
    </div>
  </template>
  
  <script>
  import { 
    TableComponent,
    TableHeader,
    Popup
  } from "@/components";
  
  export default {
    components: {
      TableComponent,
      TableHeader,
      Popup
    },
    data() {
      return {
        message: "",
        showPopup: false,
        showSortPopup: false,
        PopupTitle: "Disable account",
        PopupContent: "This action will forbid TableItemList to access their account",
        actionButton: "Disable anyway",
        index: 0,
        pageNumber: 1,
        showMessage: false,
        showNextButton: true,
        displaySize: 10,
        HeaderTitle: "Add Customer to Group",
        TableItemList: [],
        AddNewButton:  "Done",
        columns: ["Email", "Customer Name", "Gender", "Birthday"],
        Source: ["Email", "FamilyName", "UserID", "GivenName", "Gender", "Birthday", "Phone", "Name"],
        Sort: {"CreatedDateTime": "desc"},
        sortOption: [
          {Key: "CreatedDateTime", Name: "Recent Added", Value: true, Order: "desc"},
        ],
        filterOption:[
          {
            Key: "Gender", 
            Name: "Gender", 
            type: "checkbox",
            inputType: "checkbox",
            option:[
            {
                "Key": "M",
                "Name": "Male",
                "Value": false
              },
              {
                "Key": "F",
                "Name": "Female",
                "Value": false
              },
              {
                "Key": "Others",
                "Name": "Others",
                "Value": false
              }
            ]
          },
          {
            Key: "Offical", 
            Name: "Offical", 
            type: "checkbox",
            inputType: "checkbox",
            option:[
              {
                "Key": true,
                "Name": "Offical",
                "Value": false
              },
            ]
          },
          
          {
            Key: "Birthday",
            Name: "Birthday", 
            type: "range",
            inputType: "date",
            from: "",
            to: ""
          }
        ],
        selectedItemAction: [],
        selectedItem: [],
        selectedItemCounter: 0,
        selectAll: false,
        selectable: true,
        haveSearchFunction: true,
        customerGroupID: "",
        filter: {},
        range: {},
        filterOut: [],
      }
    },
    async created()  {
        console.log("params", this.$route.params)
        this.customerGroupID = this.$route.params.customerGroupID
        this.message = this.$route.params.message;
        if(this.$route.params.page){
          if(Number(this.$route.params.page) > 0){
            this.pageNumber = Number(this.$route.params.page)
          }
          this.index = this.index + (Number(this.$route.params.page) - 1 )*this.displaySize
        }
        this.checkShowMessage();
        await this.setFilterOut();
        await this.searchItem({keyword:""});
      },
    methods:{
      setFilter: function (filterOption){
      console.log("setFilter", filterOption);
        this.filter = {};
      for(var f in filterOption){
        var option = filterOption[f]
        if(option.type == "range"){
          var filterObject = {}
          if(option.from){
            filterObject["Start"] = option.from
          }
          if(option.to){
            filterObject["End"] = option.to
          }
          if(filterObject.Start || filterObject.End){
            this.range[option.Key] = filterObject
          }
        }else{
          var filterList = [];
          for(var o in option.option){
            if(option.option[o].Value){
              filterList.push(option.option[o].Key)
            }
          } 
          if(filterList.length > 0){
            this.filter[option.Key] = filterList
          }
        }
      } 
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;

        this.index = 0;
        this.pageNumber = 1;
        console.log("filter", this.filter)
        this.searchItem({keyword:""});
      },
      SortPopupControl: function (){
          this.showSortPopup = !this.showSortPopup
        },
      goPage: async function(action){
        //this.selectAll = false;
        if(action == "next"){
          this.index = this.index + this.displaySize
          this.pageNumber = this.pageNumber + 1
        }else if(action == "prev" && this.index > 0){
          this.index = this.index - this.displaySize
          this.pageNumber = this.pageNumber - 1
        }else{
          this.index = 0
          this.pageNumber = 1
        }
        this.$router.replace({ name: "Add Customer To Customer Group PageNumber", params: {page:this.pageNumber} })
        await this.searchItem({keyword:""})
      },
      checkShowMessage: async function(){
        if(this.message){
          try{
            await this.delayFunction();
            this.showMessage = true;
          }catch(err){
            console.log(err)
          }
        }
      },
      delayFunction:async function (){
          setTimeout(function(){
            this.showMessage = false;
            this.message = "";
            return true;
          }, 1000);
      },
      selectedItemActionFunction:function(action){
        console.log("selectedItemActionFunction", action);
      },
      onclickItem: function(ID){
        this.$router.push({name: 'Customer Profile', params:{customer:ID}})
      },
      createNewItem: function(){
        console.log("done action", this.selectedItem);
        //this.$router.push({name: 'Create Customer'})
        this.addUserToUserGroup();
      },
      addUserToUserGroup: async function (){
        var object = {
          UserGroupID: this.customerGroupID,
          UserID: this.selectedItem
        }
        console.log("addUserToUserGroup", object)
        try{
          const ret= await this.$store.dispatch('addUserToUserGroup',{router:this.router,data:object})
          console.log(ret);
          this.message = this.selectedItem.length + " user has beend add to " + this.customerGroupID
          this.showMessage = true;
          this.selectedItem = [];
          this.selectedItemCounter = this.selectedItem.length;
          await this.setFilterOut();
          this.searchItem({keyword:""})
        }catch(err){
          console.log("addUserToUserGroup error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      popupcancel: function(){
          this.showPopup = false;
        },
      popupaction: function (){
        this.showPopup = false;
        //this.disableSelectedUser();
      }, 
      searchItem: async function({keyword}){
        this.isLoading = true;
        var object = {
          "Size": this.displaySize,
          "From": this.index,
          "Sort": this.Sort,
          "Source": this.Source,
          "Filter": this.filter,
        }
        if(keyword){
          object["Keyword"] = keyword
          this.selectAll = false;
          this.selectedItem = [],
          this.selectedItemCounter = 0;
          this.index = 0;
          this.pageNumber = 1;
        }
        if(this.filterOut.length > 0){
          object["FilterOut"] = this.filterOut
        }
        try{
          const ret= await this.$store.dispatch('SearchUser',{router:this.router,keyword:object})
          console.log("SearchUser", ret);
          this.setDisplayList(ret.data.data.hits.hits)
          this.isLoading = false;
        }catch(err){
          this.isLoading = false;
          console.log("SearchUser error", err.response.data);
            this.formMessage = err.response.data.message
            this.showFormMessage = true
        }
      },
      selectSortOption: function(option){
        console.log("charge sorting with " + option);
        var sortOrder = "";
        var index = this.sortOption.findIndex(sort => sort.Key == option)
        console.log("option", index);
        if(index > -1){
          this.sortOption[index].Value = true
          this.sortOption[index].Order = this.sortOption[index].Order == "asc" ? "desc" : "asc"

          sortOrder = this.sortOption[index].Order
          this.Sort[option] = sortOrder

          this.showSortPopup = false;
          this.searchItem({keyword:""});
        }
      },
      selectAllItem: async function(action){
        this.selectAll = false;
        if(action){
          this.selectAll = true;
            try{
              const ret= await this.$store.dispatch('listAllUserID',{router:this.router})
              this.selectedItem = ret.data.UserList.map(item => String(item.UserID))
            }catch(err){
              console.log("listAllUserID error", err.response.data);
              this.formMessage = err.response.data.message
              this.showFormMessage = true
            } 
        }else{
          this.selectedItem = []
        }
        console.log("after", this.selectedItem);
        this.selectedItemCounter = this.selectedItem.length
      },
      selectItem:function(ID, action){
        console.log(ID, action);
        if(action){
          this.selectedItem.push(ID)
        }else{
          this.selectAll = false;
          var index = this.selectedItem.indexOf(ID)
          this.selectedItem.splice(index, 1);
        }
        console.log(this.selectedItem.length);
        this.selectedItemCounter = this.selectedItem.length
  
      },
      setFilterOut: async function(){
        var object = {
          "UserGroupID": this.customerGroupID,
        }
        console.log(object);
        try{
          const ret= await this.$store.dispatch('listUserInUserGroup',{router:this.router,data:object})
          console.log(ret.data.userList);
          for(var i = 0;i<ret.data.userList.length;i++){
            this.filterOut.push({"match": {"UserID": ret.data.userList[i].UserID}})
          }
        }catch(err){
          console.log("listUserInUserGroup error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      // listCustomer: async function(){
      //   var object = {
      //     "Size": this.displaySize,
      //     "From": this.index,
      //     "Source": this.Source
      //   }
      //   if(this.filter.length > 0){
      //     object["Filter"] = this.filter
      //   }
      //   if(this.filterOut.length > 0){
      //     object["FilterOut"] = this.filterOut
      //   }
      //   console.log("object", object);
      //   try{
      //     const ret= await this.$store.dispatch('SearchUser',{router:this.router,keyword:object})
      //     console.log(ret.data.data.hits.hits);
      //     this.setDisplayList(ret.data.data.hits.hits)
      //   }catch(err){
      //     console.log(err.message)
      //   }
      //   },
      setDisplayList(list){
        var newlist=[];
        for(var i = 0; i<list.length; i++){
          var object = {
            "ID": list[i]["_source"].UserID,
            "Email": list[i]["_source"].Email,
            "Customer Name": list[i]["_source"].GivenName+' '+list[i]["_source"].FamilyName,
            "Gender": list[i]["_source"].Gender,
            "Birthday": list[i]["_source"].Birthday,
          }
          newlist.push(object)
        }
        this.TableItemList = newlist;
        this.TableSelectUIControl(this.TableItemList)
      },
      TableSelectUIControl(ItemList){
        this.showNextButton = true;
        if(ItemList.length < this.displaySize){
          this.showNextButton = false;
        }
        var counter = 0;
        for(var i=0; i<ItemList.length;i++){
            var index = this.selectedItem.indexOf(ItemList[i].ID);
            if(index > -1){
                counter++
            }
        }
        if(counter ==  ItemList.length){
            this.selectAll = true;
        }else{
            this.selectAll = false;
        }
      }
    }
  };
  </script>
  