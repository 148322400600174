<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <FormHeader
          HeaderTitle="Add Product Order"
          :confirmBack="confirmBack"
          formActionButton="計算"
          v-on:formAction="formAction"
          >
        </FormHeader>
        <center>
          <md-card style="width:70%;min-width: 600px;text-align: left;">
            <md-card-content >
              <h3 >User</h3>
                <AutoComplete
                    :item="UserInfo"
                    v-on:checkEdited="checkEdited"
                    v-on:checkAutocomplete="searchUser"
                    v-on:setAutocompleteValue="setUser"
                    style="width: 50%;"
                />
              <h3 >收件資料</h3>
              <div class="deliverAddress">
                <div class="row">
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="familyNameInput"
                      />
                    </div>
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="givenNameInput"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="phoneInput"
                      />
                    </div>
                  </div>
                  <TextInput
                    v-on:checkEdited="checkEdited"
                    :Item="addressOneInput"
                  />

                  <TextInput
                    v-on:checkEdited="checkEdited"
                    :Item="addressTwoInput"
                  />
                  <div class="row" >
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="districtInput"
                      />
                    </div>
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="postCodeInput"
                      />
                    </div>
                  </div>
                  <div class="row" >
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="cityInput"
                      />
                    </div>
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="countryInput"
                      />
                    </div>
                  </div>

              </div>
            <ProductVarianceSelecter
                v-on:setProductList="setProductList"
                v-on:checkEdited="checkEdited"
                :ProductList="ProductList"
            />
            <h3 >訂單狀態</h3>
            <SelectInput
                v-on:checkEdited="checkEdited"
                :Item="OrderStatusInput"
                style="width: 50%;"
              />
            </md-card-content>
          </md-card>
        </center>
        </div>
      </div>
      <Transition name="modal" v-if="showCalculatePopup">
          <div  class="modal-mask">
              <div class="modal-wrapper">
                  <div class="order-container">
                    <div class="popupclose" @click="showCalculatePopup=false">
                      <md-icon>close</md-icon>
                    </div>
                      <div class="popup">
                          <div class="modal-header">
                              <slot name="header">訂單預覽</slot>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="column orderinfo" style="width: 40%;">
                                <label>用戶: {{ User.Name }}</label> <br>
                                <label>收件人資料:</label> <br>
                                <label>姓名: {{ familyNameInput.value }} {{ givenNameInput.value }}</label> <br>
                                <label>電話: {{ phoneInput.value }}</label> <br>
                                <label>地址1: {{ addressOneInput.value }}</label> <br>
                                <label>地址2: {{ addressTwoInput.value }}</label> <br>
                                <label>地區: {{ districtInput.value }}</label> <br>
                                <label>郵遞區號: {{ postCodeInput.value }}</label> <br>
                                <label>城市: {{ cityInput.value }}</label> <br>
                                <label>國家/地區: {{ countryInput.value }}</label> <br>
                                <label>訂單狀態: {{ getOrderStatusName(OrderStatusInput.value) }}</label> <br>
                              </div>
                            <div  class="column" style="width: 60%;">
                              <div style="height: 350px; overflow: auto;"> 
                                <div class="row" v-for="product in ProductList" :key="product.ID" >
                                <div class="row" style="width: 100%;">
                                  <div class="column" style="width: 100px;max-width: 100px;">
                                    <img class="productImg" :src=product.img>
                                  </div>
                                  <div class="column productText" style="width: 75%;,max-width: 850px;">
                                    <div> {{ product["Product Name"] }}</div> 
                                  </div>
                                </div>

                                <div v-if="product.Variance.length>0" class="row" style="width: 100%; padding: 20px 5% 20px 5%">
                                  <div class="row" style="width: 100%;">
                                      <div class="column" style="width: 40%;max-width: 250px; min-width: 150px">
                                        Variance
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 200px; min-width: 100px">
                                        數量
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 200px; min-width: 100px">
                                        單價
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 250px; min-width: 100px">
                                        總價
                                      </div>
                                    </div>
                                  <div class="row" v-for="variance in product.Variance" :key="variance.ID" style="width: 100%;">
                                    <div class="column" style="width: 40%;max-width: 250px; min-width: 150px">
                                      {{ variance["Name"] }}
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 250px; min-width: 100px">
                                        {{ variance["Quantity"] }}
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 250px; min-width: 100px">
                                        ${{ variance["Price"] }}
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 250px; min-width: 100px">
                                        ${{ parseInt(variance["Quantity"]) * parseInt(variance["Price"]) }}
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                              </div>

                              <div class="orderinfo">
                                <label>總計: ${{ CalculateResult.SubTotal }}</label> <br>
                                <label>運費: ${{ CalculateResult.ShippingFee }}</label> <br>
                                <label>稅項: ${{ CalculateResult.Tax }}</label> <br>
                                <div v-if="CalculateResult.Discount">    
                                  <label v-if="CalculateResult.Discount.Campaign">
                                    折扣:  {{ CalculateResult.Discount.Campaign.Description }} : ${{ CalculateResult.Discount.Campaign.Discount }}
                                  </label> <br>
                                </div>
                                <div class="row" style="padding-left: 8px;">
                                  <label>總價: ${{ CalculateResult.Total}}</label> <div @click="showEditOrderPopup=true"><md-icon class="editOrder" >edit</md-icon></div>
                                </div>
                                <div v-if="NewTotal >0">
                                  <label>新總價: ${{ NewTotal }}</label> <br>
                                  <label>新總價原因: {{ NewTotalReason }}</label> <br>
                                </div>
                              </div>
                            </div>
                          </div>

                      </div> 
                  </div>
                  <div style="text-align: center;">
                    <button class="actionButton orderButton" @click="createOrder()">生成訂單</button>
                  </div>
              </div>
              </div>
          </div>
      </Transition>
      <Transition name="modal" v-if="showEditOrderPopup">
          <div  class="modal-mask">
              <div class="modal-wrapper">
                  <div class="modal-container">
                    <div class="popupclose" @click="showEditOrderPopup=false">
                      <md-icon>close</md-icon>
                    </div>
                      <div class="popup">
                          <div class="modal-header">
                              <slot name="header">Add Variance</slot>
                          </div>
                          <div class="modal-body">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="NewTotalInput"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="NewTotalReasonInput"
                              />

                              <div class="row" style="text-align: center;padding-top: 20px;">
                                  <div class="column" style="width:47.5%">
                                      <button class="cancelButton" @click="showEditOrderPopup=false">取消</button>
                                  </div>
                                  <div class="column" style="width:5%"></div>
                                  <div class="column" style="width:47.5%">
                                      <button class="actionButton"  @click="updateTotal()">確認</button>
                                  </div>
                              </div>
                          </div>
                      </div> 
                  </div>
              </div>
          </div>
      </Transition>

      <Spinner v-if="isLoading"/> 
      <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
    </div>
  </template>
  
  <script>
  
  import { 
    FormHeader,
    AutoComplete,
    ProductVarianceSelecter,
    TextInput,
    SelectInput,
    Spinner
   } from "@/components";
  export default {
    components: {
      FormHeader,
      AutoComplete,
      ProductVarianceSelecter,
      TextInput,
      SelectInput,
      Spinner
    },
    methods:{
      updateTotal: function(){
        console.log("updateTotal");
        if(this.NewTotalInput.value == 0){
          this.formMessage = "請輸入新總價";
          this.showFormMessage = true;
          return;
        }
        if(this.NewTotalReasonInput.value == ""){
          this.formMessage = "請輸入新總價原因";
          this.showFormMessage = true;
          return;
        }
        //new toal price should not higher than the original total price
        if(this.NewTotalInput.value > this.CalculateResult.Total){
          this.formMessage = "新總價不可高於原總價";
          this.showFormMessage = true;
          return;
        }
        //new total price should not lower than the original 85% total price
        if(this.NewTotalInput.value < this.CalculateResult.Total * 0.85){
          this.formMessage = "新總價不可低於原總價的85%";
          this.showFormMessage = true;
          return;
        }
        this.NewTotal = this.NewTotalInput.value;
        this.NewTotalReason = this.NewTotalReasonInput.value;
        this.showEditOrderPopup = false;
      },
      formAction: async function(){
        this.formCheck();
        if(this.showFormMessage){
          return;
        }
        this.VarianceList = [];
        for(var p = 0; p < this.ProductList.length; p++){
          for(var v = 0; v < this.ProductList[p].Variance.length; v++){
            this.VarianceList.push({
              VarianceID: this.ProductList[p].Variance[v].ID,
              Quantity: this.ProductList[p].Variance[v].Quantity
            })
          }
        }
        if(this.VarianceList.length == 0){
          this.formMessage = "請選擇商品";
          this.showFormMessage = true;
          return;
        }
        var object = {
          UserID: this.User.Key,
          VarianceList: this.VarianceList
        }
        this.isLoading = true;
        try{
          const ret = await this.$store.dispatch('calculateOrder',{router:this.router,data:object})
          console.log(ret.data.data);
          this.CalculateResult = ret.data.data;
          this.isLoading = false;
          this.showCalculatePopup = true;
        }catch(err){
          this.isLoading = false;
          console.log("formAction error", err.response.data);
        }
      },
      formCheck: async function(){
        console.log("formCheck");
        if(this.ProductList.length == 0){
          this.formMessage = "請選擇商品";
          this.showFormMessage = true;
          return;
        }
        if(this.User.Key == null){
          this.formMessage = "請選擇用戶";
          this.showFormMessage = true;
          return;
        }
        if(this.familyNameInput.value == ""){
          this.formMessage = "請輸入姓氏";
          this.showFormMessage = true;
          return;
        }
        if(this.givenNameInput.value == ""){
          this.formMessage = "請輸入名字";
          this.showFormMessage = true;
          return;
        }
        if(this.phoneInput.value == ""){
          this.formMessage = "請輸入電話";
          this.showFormMessage = true;
          return;
        }
        if(this.addressOneInput.value == ""){
          this.formMessage = "請輸入地址 1";
          this.showFormMessage = true;
          return;
        }
        if(this.districtInput.value == ""){
          this.formMessage = "請輸入地區";
          this.showFormMessage = true;
          return;
        }
        if(this.cityInput.value == ""){
          this.formMessage = "請輸入城市";
          this.showFormMessage = true;
          return;
        }
        if(this.countryInput.value == ""){
          this.formMessage = "請輸入國家/地區";
          this.showFormMessage = true;
          return;
        }
      },
      createOrder: async function(){

        
        var object = {
        "UserID": this.User.Key,
        "DeliveryAddress": {
            "family_name": this.familyNameInput.value,
            "given_name": this.givenNameInput.value,
            "phone": this.phoneInput.value,
            "addressOne": this.addressOneInput.value,
            "addressTwo": this.addressTwoInput.value,
            "district": this.districtInput.value,
            "postCode": this.postCodeInput.value,
            "city": this.cityInput.value,
            "country": this.countryInput.value
          },
          "VarianceList": this.VarianceList,
          "Status": this.OrderStatusInput.value
        }

        if(this.NewTotal > 0){
          object.NewTotal = parseInt(this.NewTotal);
          object.NewTotalReason = this.NewTotalReason;
        }
        console.log("formAction", object);
        this.isLoading = true;
        try{
          const ret = await this.$store.dispatch('createOrder',{router:this.router,data:object})
          console.log(ret.data);
          this.isLoading = false;
          this.$router.push({ name: 'Payments' })
        }catch(err){
          this.isLoading = false;
          console.log("formAction error", err.response.data);
        }
      },
      checkEdited: async function(check){
        this.confirmBack = check
      },
      searchUser: async function(keyword){
        console.log("searchUser", keyword);
        var object = {
        "Keyword": keyword,
        "Source": this.UserSource
      }
        try{
            const ret= await this.$store.dispatch('SearchUser',{router:this.router,keyword:object})
            console.log(ret.data);
            var resultList = [];
            for(var i = 0; i < ret.data.data.hits.hits.length; i++){
                resultList.push({
                    Key: ret.data.data.hits.hits[i]._source.UserID,
                    Name: ret.data.data.hits.hits[i]._source.Email
                })
            }
            this.UserInfo.autocompleteList = resultList;

        }catch(err){
            console.log("SearchUser error", err.response.data);
        }
      },
      setUser: function(object){
        this.User = object
      }, 
      setProductList: function(list){
      for(var i=0;i<list.length;i++){
        var index = this.ProductList.findIndex(x => x.ID == list[i].ID)
        if(index < 0){
          this.ProductList.push(list[i])
        }
      }
    },
    getOrderStatusName: function(key){
      var index = this.OrderStatusInput.option.findIndex(x => x.key == key)
      if(index >= 0){
        return this.OrderStatusInput.option[index].name
      }
      return ""
    },
    },

    data() {
      return {
        isLoading: false,
        formActionButton: "",
        formMessage:  null,
        showFormMessage: false,
        showCalculatePopup: false,
        showEditOrderPopup: false,
        confirmBack:false,
        UserSource: ["UserID", "Email"],
        ProductSource: ["ProductID", "Name"],
        UserInfo: {
            name: "Email",
            autocompleteList: [],
        },
        ProductInfo: {
            name: "Product",
            autocompleteList: [],
        },
        User: {},
        ProductList: [],
        VarianceList: [],
        CalculateResult: {},
        addressOneInput:{
          "key": "addressOne",
          "name": "地址 1",
          "required": true,
          "value": "",
          "type": "text"
        },
        addressTwoInput:{
          "key": "addressTwo",
          "name": "地址 2",
          "required": false,
          "value": "",
          "type": "text"
        },
        districtInput:{
          "key": "district",
          "name": "地區",
          "required": true,
          "value": "",
          "type": "text"
        },
        phoneInput:{
          "key": "phone",
          "name": "電話",
          "required": true,
          "value": "",
          "type": "text"
        },
        cityInput:{
          "key": "city",
          "name": "城市",
          "required": true,
          "value": "",
          "type": "text"
        },
        countryInput:{
          "key": "country",
          "name": "國家/地區",
          "required": true,
          "value": "",
          "type": "text"
        },
        familyNameInput:{
          "key": "family_name",
          "name": "姓氏",
          "required": true,
          "value": "",
          "type": "text"
        },
        givenNameInput:{
          "key": "given_name",
          "name": "名字",
          "required": true,
          "value": "",
          "type": "text"
        },
        postCodeInput:{
          "key": "postCode",
          "name": "郵遞區號",
          "required": false,
          "value": "",
          "type": "text"
        },
        OrderStatusInput:{
          "key": "Status",
          "name": "訂單狀態",
          "required": true,
          "value": "IN PROCESS",
          "option": [
            {
              key: "IN PROCESS",
              name: "處理中"
            },
            {
              key: "COMPLETED",
              name: "已完成"
            }
          ]
        },
        NewTotalInput:{
          "key": "newTotal",
          "name": "新總價",
          "required": true,
          "value": "",
          "type": "number"
        },
        NewTotalReasonInput:{
          "key": "newTotalReason",
          "name": "新總價原因",
          "required": true,
          "value": "",
          "type": "text"
        },  
        NewTotal: 0,
        NewTotalReason: "",
      }
    },
  
  };
  </script>

  <style>
  .deliverAddress{
    width: 100%;

    .row{
      width: 100%;
    }

    .column{
      padding: 0px 10px;
    }
  }
  .popupclose{
    position: absolute;
    top: 3px;
    left: 3px;
    cursor: pointer;
  }
  .order-container{
    max-width: 80%;
    height: 80%;
    margin: 0px auto;
    position: relative;
    background-color: #fff;
    transition: all .3s ease;
    box-shadow: 0 27px 24px 0 rgba(0,0,0,.2), 0 40px 77px 0 rgba(0,0,0,.22);
    border-radius: 6px;
    border: none;
  }
  .orderinfo label{
    padding: 0 10px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
  }
  .editOrder{
    cursor: pointer;
    font-size: 18px !important;
  }
  .orderButton{
    width: 20%;
    min-width: 200px;
    max-width: 250px;
  }
  
  </style>
  