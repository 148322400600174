<template>
  <form>
    <h5 for="BackgroundColor">{{ item.ID }}</h5>
    <div class="md-layout md-gutter">
      <div class="md-layout-item">
        <label for="BackgroundColor">Title</label>
        <table style="width: 100%">
          <div v-for="(value, key) in item.Title" :key="key">
            <tr>
              <td>
                <label>{{ key }}</label>
              </td>
              <td style="width: 100%">
                <md-field class="md-layout-item">
                  <md-input
                    name="Content"
                    id="Content"
                    v-model="item.Title[key]"
                  />
                </md-field>
              </td>
              <td>
                <md-button
                  class="md-icon-button md-accent"
                  @click="delContentTitleLang(key, index)"
                  ><md-icon>close</md-icon></md-button
                >
              </td>
            </tr>
          </div>
        </table>
        <md-card-actions>
          <md-button class="md-primary" @click="setNewLangIndex(index)"
            >Add new Lang</md-button
          >
        </md-card-actions>
      </div>
    </div>
    <div class="md-layout md-gutter" style="padding-top: 10px">
      <table style="width: 100%">
        <tr>
          <td>
            <div class="md-layout-item">
              <md-field>
                <label for="TitleColour">TitleColour</label>
                <md-input
                  name="TitleColour"
                  id="TitleColour"
                  v-model="item.TitleColour"
                  :disabled="sending"
                />
              </md-field>
            </div>
          </td>
          <td>
            <div class="md-layout-item">
              <label for="Public">Public</label><br />

              <label for="true">true</label>
              <input
                type="radio"
                id="Public"
                name="Public"
                value="true"
                v-model="item.Public"
              />

              <label for="false">false</label>
              <input
                type="radio"
                id="Public"
                name="Public"
                value="false"
                v-model="item.Public"
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="md-layout md-gutter" v-if="item.Url">
      <div class="md-layout-item">
        <table style="width: 100%">
          <tr>
            <td>
              <md-field>
                <label for="Url">Url</label>
                <md-input
                  name="Url"
                  id="Url"
                  v-model="item.Url"
                  :disabled="sending"
                />
              </md-field>
            </td>
            <td>
              <md-button class="md-icon-button md-accent">
                <md-icon>close</md-icon>
              </md-button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </form>
</template>
<script>
import { validationMixin } from 'vuelidate'
export default {
  name: "nested-nav-menu-input-field",
  data:()=>({
    sending: false,
  }),
  props: {
    item: {
      required: true,
      type: Object,
      default(){
        return {}
      }
    },
  },
};
</script>
<style scoped>
</style>