import text from './fields/text';
import radio from './fields/radio';
import option from './fields/option';
import checkbox from './fields/checkbox';

export default function initMenu(){
    return[
        {
            name:"components",
            title:"Components",
            list:[
                text,
                radio,
                option,
                checkbox
            ]
        }
    ]
}