<template>
<div class="content">
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <FormHeader
                :HeaderTitle="orderID"
            />

            <div class="row" style="width: 100%;">
                <div class="column" style="width: 60%;">
                    <md-card style="margin:10px 0">
                        <md-card-content>
                          <div class="row">
                            <div class="column" style="width: 50%;">
                              <h4 style="font-weight: 600;">訂單內容</h4>
                              <!-- {{ orderDetail }} -->
                              <label>總計: {{ orderDetail.SubTotal }}</label> <br>
                              <label>運費: {{ orderDetail.ShippingFee }}</label> <br>
                              <label>稅項: {{ orderDetail.Taxes }} </label> <br>
                              <div v-if="orderDetail.Discount">    
                                    <label v-for="(value, key) in orderDetail.Discount" :key="key">
                                      折扣:  {{ value.Description }} : ${{ value.Discount }}
                                    </label> <br>
                              </div>
                              <label>總價: {{ orderDetail.Total }}</label> <br>

                              <div v-if="!orderDetail.Delivery">
                                <button  @click="showAddDeliveryIDPopup=true" class="tablenewItemButton">新增運單</button>
                              
                              </div>
                              <div v-else>
                                <label>運送方式: {{ orderDetail.Delivery.DeliveryMethod }}</label> <br>
                                <label>運送單號: {{ orderDetail.Delivery.DeliveryID }}</label> <br>
                                <label>運送單號: {{ orderDetail.Delivery.Url }}</label> <br>
                              </div>
                            </div>
                            <div class="column" style="width: 50%;">
                              <h4 style="font-weight: 600;">收件資料</h4>
                              <label>姓名: {{ orderDetail.DeliveryAddress.family_name }} {{ orderDetail.DeliveryAddress.given_name }}</label> <br>
                                <label>電話: {{ orderDetail.DeliveryAddress.phone }}</label> <br>
                                <label>地址1: {{ orderDetail.DeliveryAddress.addressOne }}</label> <br>
                                <label>地址2: {{ orderDetail.DeliveryAddress.addressTwo }}</label> <br>
                                <label>地區: {{ orderDetail.DeliveryAddress.district }}</label> <br>
                                <label>郵遞區號: {{ orderDetail.DeliveryAddress.postCode }}</label> <br>
                                <label>城市: {{ orderDetail.DeliveryAddress.city }}</label> <br>
                                <label>國家/地區: {{ orderDetail.DeliveryAddress.country }}</label> <br>
                            </div>
                          </div>

                            <div class="row" v-for="product in orderDetail.Product" :key="product.ProductID" style="padding-top:50px">
                                <div class="row" style="width: 100%;">
                                  <div class="column" style="width: 100px;max-width: 100px;">
                                    <img class="productImg" :src= "s3path+product.Image[0].path">
                                  </div>
                                  <div class="column productText" style="width: 75%;,max-width: 850px;">
                                    <div>{{ product.Name.en }} / {{ product.Name.zh }}</div> 
                                  </div>
                                </div>

                                <div class="row" style="width: 100%; padding: 20px 5% 20px 5%">
                                  <div class="row" style="width: 100%;" >
                                      <div class="column" style="width: 40%;max-width: 250px; min-width: 150px">
                                        Variance
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 200px; min-width: 100px">
                                        數量
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 200px; min-width: 100px">
                                        單價
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 250px; min-width: 100px">
                                        總價
                                      </div>
                                    </div>
                                  <div class="row" v-for="variance in product.VarianceList" :key="variance.VarianceID" style="width: 100%;">
                                    <div class="column" style="width: 40%;max-width: 250px; min-width: 150px">
                                        {{ variance.Name.en }} / {{ variance.Name.zh }}
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 250px; min-width: 100px">
                                        {{ variance["SelectedQuantity"] }}
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 250px; min-width: 100px">
                                        ${{ variance["Price"] }}
                                      </div>
                                      <div class="column" style="width: 20%;max-width: 250px; min-width: 100px">
                                        ${{ parseInt(variance["SelectedQuantity"]) * parseInt(variance["Price"]) }}
                                      </div>
                                  </div>
                                  </div>
                                
                              </div>
                    
                        </md-card-content>
                    </md-card>
                </div>
                <div class="column" style="width: 35%;padding-left: 50px;">
                    <md-card style="margin:10px 0">
                        <md-card-content>
                            <h4 style="font-weight: 600;">用戶資料</h4>
                           
                                <label>姓氐: {{ user.FamilyName }}</label> <br>
                                <label>名子: {{ user.GivenName }}</label> <br>
                                <label>電話: +{{ user.PhoneCountryCode }}  {{ user.Phone }}</label> <br>
                                <label>電郵: {{ user.Email }}</label> <br>
                                <label>性別: {{ user.Gender }}</label> <br>
                                <label>出生日期: {{ user.Birthday }}</label> <br>
                          
                        </md-card-content>
                    </md-card>

                </div>

            </div>
        </div>
    </div>
    <Transition name="modal" v-if="showAddDeliveryIDPopup">
      <div  class="modal-mask">
          <div class="modal-wrapper">
              <div class="modal-container">
                <div class="popupclose" @click="showAddDeliveryIDPopup=false">
                  <md-icon>close</md-icon>
                </div>
                  <div class="popup">
                      <div class="modal-header">
                          <slot name="header">新增運單</slot>
                      </div>
                      <div class="modal-body">
                        <TextInput
                          v-on:checkEdited="checkEdited"
                          :Item="deliveryMethodInput"
                        />
                        <TextInput
                          v-on:checkEdited="checkEdited"
                          :Item="deliveryIDInput"
                        />
                        <TextInput
                          v-on:checkEdited="checkEdited"
                          :Item="urlInput"
                        />
                          <div class="row" style="text-align: center;padding-top: 20px;">
                              <div class="column" style="width:47.5%">
                                  <button class="cancelButton" @click="showAddDeliveryIDPopup=false">取消</button>
                              </div>
                              <div class="column" style="width:5%"></div>
                              <div class="column" style="width:47.5%">
                                  <button class="actionButton"  @click="createDeliveryOrder()">確認</button>
                              </div>
                          </div>
                      </div>
                  </div> 
              </div>
          </div>
        </div>
    </Transition>
    <Spinner v-if="isLoading"/>
</div>
</template>

<script>
import { 
    FormHeader,
    TextInput,
    Spinner
} from "@/components";
export default {
    components: {
        FormHeader,
        TextInput,
        Spinner
    },
    created() {  
        console.log(this.$route.params.orderID);
        this.getOrderDetail();
    },
    methods:{
      createDeliveryOrder: async function(){
        this.showAddDeliveryIDPopup = false;
        this.isLoading = true;
        var object = {
          "OrderID": this.orderID,
          "DeliveryMethod": this.deliveryMethodInput.value,
          "DeliveryID": this.deliveryIDInput.value,
          "Url": this.urlInput.value
        }
        try {
            const response = await this.$store.dispatch('createDeliveryOrder',{router:this.router,data: object})
            console.log(response.data);
            this.getOrderDetail();
        } catch (error) {
            console.log(error);
        } finally {
            this.isLoading = false;
        }
      },
      getOrderDetail: async function(){
          this.isLoading = true;
          try {
              const response = await this.$store.dispatch('getOrderDetail',{router:this.router,data:{OrderID: this.orderID}})
              console.log(response.data);
              this.orderDetail = response.data.order;
              this.user = response.data.user;
          } catch (error) {
              console.log(error);
          } finally {
              this.isLoading = false;
          }
      },
    },
    data() {
        return {
            s3path: process.env.VUE_APP_S3_URL,
            isLoading: false,
            orderDetail: {},
            user: {},
            orderID: this.$route.params.orderID,
            showAddDeliveryIDPopup: false,
            deliveryIDInput:{
              "key": "DeliveryID",
              "name": "運單號碼",
              "required": true,
              "value": "",
              "type": "text"
            },
            deliveryMethodInput:{
              "key": "DeliveryMethod",
              "name": "運送方式",
              "required": true,
              "value": "",
              "type": "text"
            },
            urlInput:{
              "key": "Url",
              "name": "Url",
              "required": true,
              "value": "",
              "type": "text"
            }
        }
    },
}
</script>