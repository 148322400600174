<template>
    <div>
        <label class="infoTitle">{{ InformationTitle }}</label>
        <table class="informationTable">
            <tr v-for="(value, key) in InformationData" :key="key">
                <td style="text-align: left;">{{ key }}</td>
                <td style="text-align: right;">{{ value }}</td>
            </tr>
        </table>
    </div>
</template>
<script>
     export default {
        props: {
            InformationTitle: {
                type: String,
                default: "Information Title"
            },
            InformationData: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
    }
</script>

<style>
    .informationTable {
        width: 100%;
    }
    .informationTable td {
        border: none;
    }
    .infoTitle{
      margin-top: 10px;
      font-weight: 500;
      font-size: 20px;
    }
</style>