import oneSipApiCall from "../apiCall.js"
import axios from "axios"
import url from "../config.js"

const navigationMenu={
    getNavigationMenu({commit,state},{router}){
        return new Promise((resolve, reject) => {
            commit('action_request');
            axios({url: url+'/getNavigationMenu', method: 'GET' })
            .then(resp => {
              commit('action_success');
              console.log(resp);
              resolve(resp)
            })
            .catch(err => {
              if(err.response.stats==401){ 
                this.$router.push({path: '/login'})
              }
              commit('action_error',{err})
              reject(err)
            })
            
          })
  },
  // createNavigationMenu({commit,state},form){
  //   return new Promise((resolve, reject) => {
  //     console.log("createNavigationMenu", form);
  //     commit('action_request');
  //     resolve(state.idToken);
  //     axios({url: url+'/createNavigationMenu', data:form, method: 'POST' })
  //     .then(resp => {
  //       commit('action_success');
  //       console.log(resp);
  //       resolve(resp)
  //     })
  //     .catch(async err => {
  //       try{
  //           var ret=await errorHandler.handle(commit,err,router,path,params)
  //           console.log(ret)
  //           if(ret.status==200){
  //             resolve(ret)
  //           }
  //           else{
  //             console.log(err);
  //             commit('action_error',{err})
  //             reject(err)
  //           }
  //         }
  //         catch(err){
  //           console.log(err);
  //           commit('action_error',{err})
  //           reject(err)
  //         }
  //     })
      
  //   })
  // },
  // updateNavigationMenu({commit,state},form){
  //   return new Promise((resolve, reject) => {
  //     console.log("updateNavigationMenu", form);
  //     commit('action_request');
  //     resolve(state.idToken);
  //     axios({url: url+'/updateNavigationMenu', data:form, method: 'POST' })
  //     .then(resp => {
  //       commit('action_success');
  //       console.log(resp);
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       if(err.response.stats==401){ 
  //         this.$router.push({path: '/login'})
  //       }
  //       commit('action_error',{err})
  //       reject(err)
  //     })
      
  //   })
  // },
  setNavigationMenu({commit,state},form){
    return new Promise((resolve, reject) => {
      console.log("setNavigationMenu", form);
      commit('action_request');
      resolve(state.idToken);
      axios({url: url+'/setNavigationMenu', data:form, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        console.log(err)
        if(err.response.stats==401){ 
          this.$router.push({path: '/login'})
        }
        commit('action_error',{err})
        reject(err)
      })
      
    })
  },
  // deleteNavigationMenu({commit,state},form){
  //   return new Promise((resolve, reject) => {
  //     console.log("deleteNavigationMenu", form);
  //     commit('action_request');
  //     resolve(state.idToken);
  //     axios({url: url+'/deleteNavigationMenu', data:form, method: 'POST' })
  //     .then(resp => {
  //       commit('action_success');
  //       console.log(resp);
  //       resolve(resp)
  //     })
  //     .catch(async err => {
  //       try{
  //           var ret=await errorHandler.handle(commit,err,router,path,params)
  //           console.log(ret)
  //           if(ret.status==200){
  //             resolve(ret)
  //           }
  //           else{
  //             console.log(err);
  //             commit('action_error',{err})
  //             reject(err)
  //           }
  //         }
  //         catch(err){
  //           console.log(err);
  //           commit('action_error',{err})
  //           reject(err)
  //         }
  //     })
      
  //   })
  // },
}

export default navigationMenu;