<template>
    <div>
        <md-card class="TagePage" style="margin: unset;">
          <md-card-content >
                <div v-if="(ImageList.length>0)">
                    <div style="width: 100%;text-align: right;">
                        <button @click="addFileInput()" class="newProductButton">Add Event Image</button>
                    </div>
                    <draggable
                      :list="ImageList"
                      :group="{ name: 'g1' }"
                      style="height: 65vh; overflow: auto;margin-top: 15px;"
                    >
                    <div v-for="(Item) in ImageList" :key="Item.ID">
                        <div v-if="(Item.Action != 'delete')">
                            <FileInput
                                v-on:RemoveItem="RemoveItem"
                                v-on:SetItemObject="SetItemObject"
                                :Item="Item"
                                :acceptType="acceptType"
                            />
                        </div>

                    </div>

                    </draggable>
                </div>
                <div v-else>
                    <button @click="addFileInput()" class="newProductButton centerButton">Add Event Image</button>
                </div>
            </md-card-content>
        </md-card>
        <Spinner v-if="isLoading"/> 
    </div>
    
</template>

<script>
import { 
  FileInput,
  Spinner

 } from "@/components";

 import draggable from "vuedraggable";
export default {
    components: {
        FileInput,
        Spinner,
        draggable
    },
    methods:{
        addFileInput: function(){
            var ID = new Date().getTime();
            this.ImageList.push({ID: ID, base64: "", url: "", current: false, Action: "add"})
        },
        RemoveItem: function(ID){
            var index = this.ImageList.findIndex(x => x.ID == ID)
            if(this.mode == "Create"){
                this.ImageList.splice(index, 1)
            }else{ 
                if(this.ImageList[index].current == true){
                    this.ImageList[index].Action = "delete"
                    //this.ImageList.find(x => x.ID == ID).Action = "delete"
                }else{
                    this.ImageList.splice(index, 1) 
                }  
            }
            this.$forceUpdate()
            console.log(this.ImageList)

        },
        SetItemObject: function(ID, object){
            var index = this.ImageList.findIndex(x => x.ID == ID)
            this.ImageList[index].url = object.url
            this.ImageList[index].base64 = object.base64
            this.$forceUpdate()
        },
    },
    props: {
        ImageList:{
            default() {
                return [];
            },
        },
        mode: {
            default (){
                return "Create"
            }
        },
        Status: {
            default (){
                return "Draft"
            }
        },
    },
    data() {
        return {
            isLoading: false,
            acceptType: "image/*",
        }
    }
}
</script>