<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <FormHeader
        v-on:formAction="formAction"
        :HeaderTitle="HeaderTitle"
        :confirmBack="confirmBack"
        :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <div style="width:45%;">
            <md-card style="margin:10px 0;">
            <md-card-content>
                <h3 class="formTitle">{{Form1Title}}</h3>
                <LanguageObjectInput
                  v-on:checkEdited="checkEdited"
                  :Item="ProductNameInput"
                />
               
                <div class="row" style="padding: 10px 0">
                  <div class="inputcolumn">
                    <SelectInput
                      v-on:checkEdited="checkEdited"
                      :Item="SupplierInput"
                    />
                  </div>
                  <div class="inputcolumn">
                    <SelectInput
                      v-on:checkEdited="checkEdited"
                      :Item="StatusInput"
                    />
                  </div>
                </div>
                <CountryInput
                      v-on:checkEdited="checkEdited"
                      :languageList="languageList"
                      :Item="CountryInput"
                    />
                <ParingTagInput
                  v-on:checkEdited="checkEdited"
                  :Item="ParingTagsInput"
                />
                <TasteNotesInput
                  v-on:checkEdited="checkEdited"
                  :Item="TasteNotesInput"
                />
                <AromaInput
                  v-on:checkEdited="checkEdited"
                  :Item="AromaInput"
                />
                <FiveSensesInput
                  v-on:checkEdited="checkEdited"
                  :Item="FiveSensesInput"
                />
                <div>
                  <label>Product Image</label>
                  
                    <draggable
                      :list="ProductImageInput"
                      :group="{ name: 'g1' }"
                    >
                    <div v-for="(Item) in ProductImageInput" :key="Item.ID">
                      <div v-if="(Item.Action != 'delete')">
                        <FileInput
                          v-on:RemoveItem="RemoveProductImage"
                          v-on:SetItemObject="SetItemObject"
                          :Item="Item"
                          :acceptType="acceptType"
                        />
                      </div>

                    </div>

                    </draggable>
                    <div style="text-align: center;" @click="addProductImageInput()">
                      <md-icon>add_circle_outline</md-icon> <u class="addLang">Add Image</u>
                    </div>
                </div>

                <div style="padding-top: 50px">
                  <label>Taste Card Product Image</label>    
                  <FileInput
                    v-on:RemoveItem="RemoveTasteCardImage"
                    :Item="TasteCardImage"
                    :acceptType="acceptType"
                  />
                </div>



            </md-card-content>
          </md-card>

          </div>
          <div style="width:55%;padding-left: 30px;">
            <md-card style="margin:10px 0;">
            <md-card-content>
              <div style="text-align:left">
                <div class="row">
                  <div style="width:100%"> <h3 class="formTitle">Product Info</h3></div>
                      <div style="padding:20px 20px 0px 20px;width:100%">
                          <label for="product-type">Product Type</label><br>
                          {{ ProductType }}
                      </div>
                </div>
                <div class="row" style="padding-top:20px">
                  <div class="column inputcolumn" v-for="(field, key) in ProductInfoInputList" :key="key">
                    <LanguageObjectInput
                      v-if="field.type == 'object'"
                      v-on:checkEdited="checkEdited"
                      :Item="field"
                    />
                    <NumberInput
                    v-if="field.type == 'number'"
                      v-on:checkEdited="checkEdited"
                      :Item="field">

                    </NumberInput>
                  </div>
   
                </div>
                <div class="row">
                      <div style="padding:20px 20px 0px 20px;width:100%">
                          <label for="product-type">Parameter</label>
                      </div>
                </div>
                <div class="row" >
                  <div class="column inputcolumn" v-for="(field, key) in ProductParameterInputList" :key="key">
                    <TextInput
                        v-if="field.type=='Text'"
                        v-on:checkEdited="checkEdited"
                        :Item="field"
                    /> 
                      <SelectInput
                        style="padding-top:30px"
                        v-if="field.type=='Option'"
                        v-on:checkEdited="checkEdited"
                        :Item="field"
                      /> 
                      <TimeInput
                        v-if="field.type=='Time'"
                        v-on:checkEdited="checkEdited"
                        :item="field"
                        :name="field.name"
                        :value="field.value"
                      />
                    </div>
                  </div>
              </div>
            </md-card-content>
          </md-card>
          <md-card>
              <md-card-content>
                <div style="width:100%"> <h3 class="formTitle">Product Variance</h3></div>
                <TableComponent
                  v-on:selectItem="selectItem"
                  v-on:selectAllItem="selectAllItem"
                  v-on:onclickItem="onclickItem"
                  v-on:selectedItemActionFunction="selectedItemActionFunction"
                  :TableList="TableItemList"
                  :selectedItemCounter="selectedItemCounter"
                  :selectedItem="selectedItem"
                  :columns="columns"
                  :selectAll="selectAll"
                  :selectable="selectable"
                  :selectedItemAction="selectedItemAction"
                  :showPageNumber="false"
                />

                <div style="text-align: center;padding-top: 10px;" @click="showAddVariancePopup()">
                      <md-icon>add_circle_outline</md-icon> <u class="addLang">Add Variance</u>
                  </div>

                </md-card-content>
            </md-card>
          </div>
        </div>  
      </div>
    </div>
    <Transition name="modal" v-if="showdeleteVariancePopup">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="deleteVariance"
            :PopupTitle="deleteVarianceTitle"
            :PopupContent="deleteVarianceContent"
            :actionButton="deleteVarianceActionButton"
          ></Popup>
      </Transition>
    <Transition name="modal" v-if="showAddVariance">
      <AddVariancePopup
      v-on:popupcancel="popupcancel"
      v-on:popupaction="AddVariance"
      PopupTitle="Add Variance"
      actionButton="Add"
      ></AddVariancePopup>
    </Transition>
    <Transition name="modal" v-if="showUpdateVariance">
      <AddVariancePopup
      v-on:popupcancel="popupcancel"
      v-on:popupaction="UpdateVariance"
      :VarianceNameInput="VarianceNameInput"
      :PriceInput="PriceInput"
      :WeightInput="WeightInput"
      :VarianceImageInput="VarianceImageInput"
      :InventoryList="CurrentInventoryList"
      PopupTitle="Update Variance"
      actionButton="Update"
      ></AddVariancePopup>
    </Transition>
    <Spinner v-if="isLoading"/> 
    <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
  </div>
</template>

<script>

import { 
  FormHeader,
  LanguageObjectInput,
  SelectInput,
  CountryInput,
  FileInput,
  TasteNotesInput,
  AromaInput,
  NumberInput,
  Popup,
  ParingTagInput,
  AddVariancePopup,
  TableComponent,
  Spinner,
  FiveSensesInput,
  TimeInput,
  TextInput
 } from "@/components";
 import draggable from "vuedraggable";

export default {
  components: {
    FormHeader,
    LanguageObjectInput,
    SelectInput,
    CountryInput,
    FileInput,
    draggable,
    TasteNotesInput,
    AromaInput,
    NumberInput,
    Popup,
    ParingTagInput,
    AddVariancePopup,
    TableComponent,
    Spinner,
    FiveSensesInput,
    TimeInput,
    TextInput
  },
  async created() {
    this.isLoading = true;
    await this.getLanguage();
    await this.getSuppliers();   
    await this.getProductInfo();
    this.isLoading = false;
  },

  methods:{
    /*
      TableComponent function
    */
    selectItem:function(ID, action){
      console.log(ID, action);
      if(action){
        this.selectedItem.push(ID)
      }else{
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID)
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length

    },
    selectAllItem:function(action){
        this.selectAll = false;
        if(action){
          this.selectAll = true;
          for(var i = 0; i< this.TableItemList.length; i++){
            var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
            if(index < 0){
                this.selectedItem.push(this.TableItemList[i].ID)
            }
          }
        }else{
            for(var i = 0; i< this.TableItemList.length; i++){
                var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
                this.selectedItem.splice(index, 1);
            }
        }
        console.log("after", this.selectedItem);
        this.selectedItemCounter = this.selectedItem.length
      },
    onclickItem: async function(ID){
      this.$router.push({name: 'Update Variance', params:{VarianceID:ID}})
    },
    selectedItemActionFunction:function(action){
      console.log("selectedItemActionFunction", action);
      if(action == "delete"){
        console.log("delete", this.selectedItem);
        this.deleteVarianceContent = "Are you sure you want to delete "+ this.selectedItem.length +" selected Variance?"
        this.showdeleteVariancePopup = true
       
      }
    },
    /*
      end of TableComponent function
    */


    /*
      Popup action 
    */
    showAddVariancePopup: function(){
      this.showAddVariance = true;
    },
    popupcancel: function(){
      this.showAddVariance = false;
      this.showdeleteVariancePopup = false;
      this.showUpdateVariance = false;
    },
    deleteVariance:async function (){
      this.showdeleteVariancePopup = false;
      this.isLoading = true;
      try{      
        var error = false;
        for(var i = 0; i< this.selectedItem.length; i++){
          error = await this.DeleteVariance(this.selectedItem[i]);
        }
        this.isLoading = false;
        if(!error){    
          this.formMessage = "Variance has been deleted successfully"
          this.showFormMessage = true;
        }
        this.getProductInfo();
      }catch(err){
        this.isLoading = false;
        this.formMessage = err
        this.showFormMessage = true;
        console.log(err);
      }
    },  
    /*
      end of Popup action
    */
    /*
      Image function
    */
    SetItemObject: function(ID, object){
      var index = this.ProductImageInput.findIndex(x => x.ID == ID)
      this.ProductImageInput[index].url = object.url
      this.ProductImageInput[index].base64 = object.base64
      this.$forceUpdate()
    },
    RemoveProductImage: function(ID){
      console.log("RemoveItem", ID);
      var index = this.ProductImageInput.findIndex(x => x.ID == ID)

      if(this.ProductImageInput[index].current == true){
        this.ProductImageInput[index].Action = "delete"   
      }else{
        this.ProductImageInput.splice(index, 1) 
      }
      this.$forceUpdate()
      console.log(this.ProductImageInput[index]);   
    },
    RemoveTasteCardImage: function(){
      this.TasteCardImage={
        ID: 1,
        type: "TasteCard",
        base64: "", url: ""
      },
      this.$forceUpdate() 
    },
    addProductImageInput: function(){
      var ID = new Date().getTime();
      this.ProductImageInput.push({ID: ID, base64: "", url: "", current: false, Action: "add"})
      console.log("ProductImageInput", this.ProductImageInput);
    },
    /*
      api call function
    */
    AddVariance: async function(data){
      var object = {
        "ProductID": this.$route.params.ProductID,
        "Name": data.Name,
        "Price": parseInt(data.Price),
        "Weight": parseInt(data.Weight),
      }
      if(data.Inventory){
        object["Inventory"] = data.Inventory
      }
      var Image = [];
        for(var i = 0;i<data.Image.length;i++){
            var contentType= data.Image[i].base64.split(":",2)[1].split(";",1)[0]
            Image.push(contentType)
            }
        object["Image"] = Image;
        console.log("addVariance", object);
      try{
            const ret = await this.$store.dispatch("createProductVariance",{router:this.router, variance:object})
            console.log("createProductVariance", ret.data);
            for(var i = 0; i<data.Image.length; i++){
                const retMedia = await this.$store.dispatch('updateMediaToS3',{file: data.Image[i].base64, urlObject:ret.data.UploadImageUrlList[i]})
                console.log(retMedia);
            }
            this.formMessage = "Variance has been added successfully"
            this.showFormMessage = true;
            this.showAddVariance = false;
            this.getProductInfo();

        }catch(err){
            console.log(err.message);
        }
    },
    DeleteVariance: async function(data){
      const Object = {
        ProductID: this.$route.params.ProductID,
        VarianceID: data
      }
      try{
            const ret = await this.$store.dispatch("deleteProductVariance",{router:this.router, data:Object})
            console.log("deleteProductVariance", ret.data);
            this.isLoading = false;
            return false
        }catch(err){
          console.log("deleteProductVariance error", err.response.data);
       
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
    },
    formAction: async function(){
      console.log("formAction");
      this.isLoading = true;
      await this.updateProduct();
      await this.TasteCardDataCheck();
      await this.getProductInfo();
      this.isLoading = false;
      this.confirmBack = false;
    },
    TasteCardDataCheck: async function(){
      var TasteCardObject = {
        "ProductID": this.$route.params.ProductID,
        "ProductType": this.ProudctTypeValue,
        "TasteNote": this.TasteNotesInput.value,
        "Aroma": this.AromaInput.value,
        "FiveSenses": {},
        "Parameter": {},
      }
      for(var i = 0; i< this.FiveSensesInput.value.length; i++){
        TasteCardObject.FiveSenses[this.FiveSensesInput.value[i].key] = parseInt(this.FiveSensesInput.value[i].value)
      }
      for(var i = 0; i<this.ProductParameterInputList.length; i++){
        if(this.ProductParameterInputList[i].Unit){
          
          //check value contain unit or not
          if(this.ProductParameterInputList[i].value.includes(this.ProductParameterInputList[i].Unit)){
            TasteCardObject.Parameter[this.ProductParameterInputList[i].key] = this.ProductParameterInputList[i].value
          }else{
            TasteCardObject.Parameter[this.ProductParameterInputList[i].key] = this.ProductParameterInputList[i].value + this.ProductParameterInputList[i].Unit
          }
        }else{
          console.log(this.ProductParameterInputList[i], "no unit");
          TasteCardObject.Parameter[this.ProductParameterInputList[i].key] = this.ProductParameterInputList[i].value
        }   
      }
      console.log("TasteCardObject", TasteCardObject);
      for(const [key, value] of Object.entries(TasteCardObject.FiveSenses)){
        if(value > 4 || value < 0){
          this.formMessage = "Five Senses value must between 0-4"
          this.showFormMessage = true
          return
        }
      }
      for(const [key, value] of Object.entries(TasteCardObject.Parameter)){
        if(!value){
          console.log(key+ " no value");
          return
        }
      }

      if(this.haveTasteCard){
        await this.updateTasteCard(TasteCardObject);
      }else{
        await this.createTasteCard(TasteCardObject);
      }
    },
    updateProduct: async function(){
      console.log("updateProduct");
      var object = {
        "ProductID": this.$route.params.ProductID,
        "Name": this.ProductNameInput.value,
        "SupplierID": this.SupplierInput.value,
        "Country": this.CountryInput.value,
        "ParingTags": this.ParingTagsInput.value,
        "Aroma": this.AromaInput.value,
        "TasteNote": this.TasteNotesInput.value,
        "ProductType": this.ProudctTypeValue,
        "Status": this.StatusInput.value,
      }
      if(this.TasteCardImage.base64){
        object["TasteCardImage"] = this.TasteCardImage.base64.split(":",2)[1].split(";",1)[0];
      }
      for(var i = 0; i<this.ProductInfoInputList.length; i++){
        if(this.ProductInfoInputList[i].type == "number"){
          object[this.ProductInfoInputList[i].key] = parseFloat(this.ProductInfoInputList[i].value)
        }else{
          object[this.ProductInfoInputList[i].key] = this.ProductInfoInputList[i].value
        }   
      }
      var Image = [];
      var newImage = []
      for(var i = 0;i<this.ProductImageInput.length;i++){
        console.log(this.ProductImageInput[i]);
          if(this.ProductImageInput[i].current){
            var currentImage = this.currentImageList.find(x => x.ImageID == this.ProductImageInput[i].ID)
            currentImage.newImage = false;
            currentImage.Action = this.ProductImageInput[i].Action
            Image.push(currentImage)
          }else{
            newImage.push(this.ProductImageInput[i]);
            var contentType= this.ProductImageInput[i].base64.split(":",2)[1].split(";",1)[0]
            Image.push({
              Action: this.ProductImageInput[i].Action,
              newImage: true,
              contentType: contentType
            })
          }
        }
      object["Image"] = Image;
      console.log("object", object);
      console.log("TasteCardImage", this.TasteCardImage);
      //return
      try{
        const ret = await this.$store.dispatch("updateProduct",{router:this.router, product:object})
        console.log("updateProduct", ret.data);
        for(var i = 0; i<newImage.length; i++){
          const retMedia = await this.$store.dispatch('updateMediaToS3',{file:newImage[i].base64, urlObject:ret.data.UploadImageUrlList[i]})
          console.log(retMedia);
        }
        if(this.TasteCardImage.base64){
          const retMedia = await this.$store.dispatch('updateMediaToS3',{file:this.TasteCardImage.base64, urlObject:ret.data.tasteCardUrl})
          console.log("TasteCardImage", retMedia);
        }
        this.formMessage = this.$route.params.ProductID+" has been updated successfully"
        this.showFormMessage = true;
      }catch(err){
        this.isLoading = false;
        console.log("updateProduct error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    createTasteCard: async function(TasteCardObject){
      try{
        const ret = await this.$store.dispatch("createTasteCard",{router:this.router, tasteCard:TasteCardObject})
        console.log("createTasteCard", ret.data);
      }catch(error){
        console.log("createTasteCard error", error.response.data);
      }
    },
    updateTasteCard: async function(TasteCardObject){
      TasteCardObject.CardID = this.CardID
      try{
        const ret = await this.$store.dispatch("updateTasteCard",{router:this.router, tasteCard:TasteCardObject})
        console.log("updateTasteCard", ret.data);
      }catch(error){
        console.log("updateTasteCard error", error.response.data);
      }
    },
    getLanguage: async function(){
      try{
        const ret= await this.$store.dispatch("getLanguage",{router:this.router, data:null})
        this.languageList = ret.data.Language;
        for(var i = 0; i< this.languageList.length; i++){
          this.ProductNameInput.value[this.languageList[i].Language] = ""
        }
      }
      catch(err){
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    getInventoryList: async function(){
      try{
        const ret = await this.$store.dispatch("listInventory",{router:this.router})
        this.InventoryList = ret.data.InventoryList;
      }catch(err){
        console.log("listInventory error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    getVarianceInventory: async function(){
      try{
        const ret = await this.$store.dispatch("getProductInventory",{router:this.router, product:{ProductID: this.$route.params.ProductID}})
        console.log(ret);
        return ret.data.InventoryList

      }catch(err){
        console.log("getProductInventory error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    getSuppliers: async function(){
      try{
        const ret= await this.$store.dispatch("listSuppliers",{router:this.router})
        for(var i in ret.data.supplierList){
          this.SupplierInput.option.push({
            key: ret.data.supplierList[i].SupplierID,
            name: ret.data.supplierList[i].Name,
          })
        }
      }
      catch(err){
        console.log("listSuppliers error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },

    getProductInfo: async function(){
      this.isLoading = true;
      var Object = {
        "ProductID": this.$route.params.ProductID
      }
      try{
        const ret= await this.$store.dispatch("getProduct",{router:this.router, product:Object})
        console.log("getProduct", ret.data);
        this.setFormValue(ret.data.ProductDetail);
        console.log("TasteCard", ret.data.ProductDetail.TasteCard);
        this.TasteCardImage.url = this.s3path + "taste-card-resource/product/"+ this.$route.params.ProductID
        if(ret.data.ProductDetail.TasteCard){
          console.log('have taste card');
          this.haveTasteCard = true;
          this.CardID = ret.data.ProductDetail.TasteCard;
          await this.getTasteCard(ret.data.ProductDetail.TasteCard);
        }else{
          this.haveTasteCard = false;
          console.log('no taste card');
        }
        this.isLoading = false;
      }
      catch(err){
        this.isLoading = false;
        console.log("getProduct error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    getTasteCard: async function(CardID){
      console.log("getTasteCard", CardID);
      var Object = {
        "CardID": CardID
      }
      try{
        const ret= await this.$store.dispatch("getProductTasteCard",{router:this.router, data:Object})
        console.log("getTasteCardResult", ret.data);
        for(var i = 0; i< this.FiveSensesInput.value.length; i++){
          this.FiveSensesInput.value[i].value = ret.data.TasteCardDetail.FiveSenses[this.FiveSensesInput.value[i].key]
          } 
        for(var i = 0; i <this.ProductParameterInputList.length; i++){
          if(this.ProductParameterInputList[i].type == "Option"){
            this.ProductParameterInputList[i].value = ret.data.TasteCardDetail.Parameter[this.ProductParameterInputList[i].key].en
          }else{
            this.ProductParameterInputList[i].value = ret.data.TasteCardDetail.Parameter[this.ProductParameterInputList[i].key]
          }
        }
        
      }
      catch(err){
        console.log("getTasteCard error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    setFormValue: async function (data){
        //set product variacne
        this.ProductVarianceList = data.VarianceList;
        this.TableItemList = [];
        for(var i = 0; i<this.ProductVarianceList.length; i++){
          var imagePath = ""
          if(this.ProductVarianceList[i].Image[0]){
            imagePath = this.ProductVarianceList[i].Image[0].path
          }
          this.TableItemList.push({
            "ID": this.ProductVarianceList[i].VarianceID,
            "img": this.s3path + imagePath,
            "Variance Name": this.ProductVarianceList[i].Name.en + " / " + this.ProductVarianceList[i].Name.zh,
            "Price": this.ProductVarianceList[i].Price,
            "Weight": this.ProductVarianceList[i].Weight,
          })
        }
        //ProductNameInput
        for (const [key, value] of Object.entries(data.Name)) {
            this.ProductNameInput.model += key +" : " + value + " / ";
            this.ProductNameInput.value[key] = value;
          }
          this.ProductNameInput.model = this.ProductNameInput.model.slice(0, -2)
        //SupplierInput
        this.SupplierInput.value = data.SupplierID;
        //CountryInput
        this.CountryInput.value = data.Country.CountryID;
        this.CountryInput.model = data.Country.Name.en+", "+data.Country.Name.zh+", "+data.Country.Alpha2+", "+data.Country.Alpha3

        //StatusInput
        this.StatusInput.value = data.Status;

        //ParingTagssInput
        if(data.ParingTags && data.ParingTags.length > 0){
          this.ParingTagsInput.value = []
          this.ParingTagsInput.model = []
          for(var i =0; i< data.ParingTags.length; i++){
            this.ParingTagsInput.value.push(data.ParingTags[i].ParingTagID)
            var ParingTagsString = "";
            for(const [key, value] of Object.entries(data.ParingTags[i].Name)){
              ParingTagsString += key +" : " + value + " / ";
            }
            ParingTagsString = ParingTagsString.slice(0, -2)
            this.ParingTagsInput.model.push(ParingTagsString)
          }
        }

        //TasteNotesInput
        if(data.TasteNote.length > 0){
          this.TasteNotesInput.value = []
          this.TasteNotesInput.model = []
          for(var i =0; i< data.TasteNote.length; i++){
            this.TasteNotesInput.value.push(data.TasteNote[i].TasteNoteID)
            var TasteNotesString = "";
            for(const [key, value] of Object.entries(data.TasteNote[i].Name)){
              TasteNotesString += key +" : " + value + " / ";
            }
            TasteNotesString = TasteNotesString.slice(0, -2)
            this.TasteNotesInput.model.push(TasteNotesString)
          }
        }

        //AromaInput
        if(data.Aroma && data.Aroma.length > 0){
          this.AromaInput.value = []
          this.AromaInput.model = []
          for(var i =0; i< data.Aroma.length; i++){
            this.AromaInput.value.push(data.Aroma[i].AromaID)
            var AromaString = "";
            for(const [key, value] of Object.entries(data.Aroma[i].Name)){
              AromaString += key +" : " + value + " / ";
            }
            AromaString = AromaString.slice(0, -2)
            this.AromaInput.model.push(AromaString)
          }
        }

        //ProductImageInput
        this.ProductImageInput = []
        if(data.Image.length > 0){
          this.currentImageList = data.Image;
          for(var i =0; i< data.Image.length; i++){
            var object = {
              ID: data.Image[i].ImageID,
              current: true,
              url: this.s3path+data.Image[i].path,
              base64: "",
            }
            this.ProductImageInput.push(object)
          }
        }

        //ProductType
        this.ProductType = data.ProductType.Name.en;
        this.ProudctTypeValue = data.ProductType.ProductTypeID;
        var Column = data.ProductType["Column"];
        
        this.ProductInfoInputList = [];
        for(var i = 0; i<Column.length; i++){
          if(Column[i]["DataType"] == "object"){
            var object = {
              "type": "object",
              "key": Column[i].Key,
              "name": Column[i].Name,
              "required": Column[i].required,
              "model": "",
              "value": {},
            }
            var model = "";
            for(var l = 0; l< this.languageList.length; l++){
              if(data[Column[i].Key]){
                model += this.languageList[l].Language +" : " + data[Column[i].Key][this.languageList[l].Language] + " / ";
                object.value[this.languageList[l].Language] = data[Column[i].Key][this.languageList[l].Language]
              }

            }
            model = model.slice(0, -2)
            object.model = model;
            this.ProductInfoInputList.push(object)
          }else{
            var object = {
              "type": Column[i].DataType,
              "key": Column[i].Key,
              "name": Column[i].Name,
              "required": Column[i].required,
              "model": data[Column[i].Key],
              "value": data[Column[i].Key]
            }
            this.ProductInfoInputList.push(object)
          }
        }

        this.ProductParameterInputList = [];
        if(data.ProductType["Parameter"]){
          var Parameter = data.ProductType["Parameter"];
          for(var p in Parameter){
            
            var parameter = Parameter[p];
            console.log(parameter);
            var object = {
              type: parameter.Type,
              required: true,
              key: parameter.Key,
              name: parameter.Name.zh,
              value: ""
            }
            if(parameter.Type == 'Option'){
              var option = [];
              for(var o in parameter.Option){
                var optionSelect = parameter.Option[o]
                option.push({
                  key: optionSelect.Key,
                  name: optionSelect.Name.zh,
                })
              object.option = option;
            }
          }
          if(parameter.Unit){
            object.Unit = parameter.Unit
          }
            this.ProductParameterInputList.push(object)
          }
          console.log("ProductParameterInputList", this.ProductParameterInputList);
        }
      },
    checkEdited: async function(check){
      this.confirmBack = check
    },
  },

  data() {
    return {
      haveTasteCard: false,
      CardID: "",
      isLoading: false,
      HeaderTitle: "Update Product",
      Form1Title: "General Info",
      Form2Title: "Product Info",
      formActionButton: "Update Product",
      formMessage:  null,
      showFormMessage: false,
      confirmBack:false,
      ProductTypeList:[],
      languageList:[],
      s3path: process.env.VUE_APP_S3_URL,
      TasteCardImage: {
        ID: 1,
        type: "TasteCard",
        base64: "", url: ""
      },
      StatusInput:{
        "key": "Status",
        "name": "Status",
        "required": true,
        "value": "",
        "option": [
          {
            "key": "Hide",
            "name": "隱藏",
          },
          {
            "key": "Published",
            "name": "公開",
          },
        ]
      },
      ProductNameInput: {
        "type": "object",
        "key": "Name",
        "name": "Product Name",
        "required": true,
        "style": "width: 100%;",
        "model": "",
        "value": {
        }
      },
      SupplierInput:{
        "key": "Supplier",
        "name": "Supplier",
        "required": true,
        "value": "",
        "option": []
      },
      CountryInput: {
        "key": "Country",
        "name": "Country",
        "required": true,
        "model": "",
        "value": "",
      },
      ParingTagsInput:{
        "key": "ParingTags",
        "name": "Paring Tags",
        "required": false,
        "value": [],
        "model": [],
      },
      TasteNotesInput: {
        "key": "TasteNote",
        "name": "Taste Notes",
        "required": true,
        "value": [],
        "model": [],
      },
      AromaInput: {
        "key": "Aroma",
        "name": "Aroma",
        "required": true,
        "value": [],
        "model": [],
      },
      ProductInfoInputList: [],
      ProductType: "",
      ProudctTypeValue: "",
      ProductInfoItem: {},
      acceptType: "image/*",
      currentImageList: [],
      ProductImageInput: [],
      ProductVarianceList: [],
      InventoryList: [],
      showAddVariance: false,
      showdeleteVariancePopup: false,
      deleteVarianceTitle: "Confirm Delete Variance",
      deleteVarianceContent: "",
      deleteVarianceActionButton: "Delete",
      //Table Component Data
      columns: ["img", "Variance Name", "Price", "Weight"],
      TableItemList: [],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      selectedItemAction: [
        {Key: "delete", Name: "Delete selected", Style:"color:#CC2727"},
      ],
      showUpdateVariance: false,
      VarianceNameInput: {},
      PriceInput: {},
      WeightInput: {},
      VarianceImageInput: [],
      CurrentInventoryList: [],
      ProductParameterInputList: [],
      FiveSensesInput: {
        "key": "FiveSenses",
        "name": "五感 (between 0-4)",
        "required": false,
        "value": [
          {
            
            "key": "Sweet",
            "name": "甜",
            "value": 0,
          },
          {
            "key": "Sour",
            "name": "酸",
            "value": 0,
          },
          {
            "key": "Bitter",
            "name": "苦",
            "value": 0,
          },
          {
            "key": "Salty",
            "name": "鹹",
            "value": 0,
          },
          {
            "key": "Umami",
            "name": "鮮",
            "value": 0,
          },
        ],
      },
    }
  },

};
</script>
<style>
  .dragArea {
    min-height: 50px;
    outline: 1px dashed;
  }
</style>
