
<template>
  
      <div style="text-align:left">
        <div v-if="FormTitle" class="row">
          <div style="width:50%"> <h3 class="formTitle">{{FormTitle}}</h3></div>
          <div v-if="(!editAble)" class="action" @click="editForm()"><u>Edit</u> </div>
        </div>
       
        <div style="padding-top:20px;">
          <div class="row" v-for="(value, key) in FormItem" :key="key" >
            <div v-for="Item in value.filter(obj=>obj.hide!==true)" :key="Item.key" class="column inputcolumn" :style="Item.style">
             
              <div v-if="editAble">
                <div v-if="Item.type !='checkbox' && Item.type !='object'">
                  <label>{{Item.name}}</label><label style="color:red" v-if=(Item.required)>*</label>
                </div>
                <div v-if="(Item.type=='text' || Item.type=='password' || Item.type == 'date' || Item.type == 'email' || Item.type == 'number' || Item.type == 'datetime-local' || Item.type == 'color' || Item.type == 'email')">
                  <md-field style="margin-top:0px;padding-top:5px">
                      <md-input v-if="Item.autocompleteList" :type=Item.type :value=Item.value class="formInput" step="any" v-model=Item.value @change="checkEdited" @input="checkAutocomplete($event, Item)"/>
                      <md-input v-else :type=Item.type :value=Item.value class="formInput" v-model=Item.value @change="checkEdited"/>
                  </md-field>
                  <div class="autocompletediv" v-if="(Item.autocompleteList && Item.autocompleteList.length>0)">
                    <ul class="autocomplete-list">
                      <li  v-for="autocompleteItem in autocompleteList" :key="autocompleteItem.Key">
                        <button  @click="setAutocompleteValue(Item, autocompleteItem.Name)">{{ autocompleteItem.Name }}</button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-if="Item.type == 'phone'" class="row" >
                  <table>
                    <tr>
                      <td style="width: 5%;">
                        <label style="font-size: 20px;">+</label>
                      </td>
                      <td style="width: 25%;">
                        <md-field style="margin-top:0px;padding-top:5px">
                          <md-input type=number :value=Item.countyCode class="formInput" v-model=Item.countyCode @change="checkEdited" style="width: 29%;" />
                        </md-field>
                      </td>
                      <td style="width: 5%;">
                        <label style="font-size: 25px;">-</label>
                      </td>
                      <td style="width: 65%;">
                        <md-field style="margin-top:0px;padding-top:5px">
                          <md-input :type=Item.type :value=Item.value class="formInput" v-model=Item.value @change="checkEdited"/>
                        </md-field>
                      </td>
                    </tr>
                  </table>
                  <!-- <div style="width:70%">
                    <div style="width:1%">
                      -
                    </div>
                    <div style="width:99%">
                      <md-field style="margin-top:0px;padding-top:5px">
                        <md-input :type=Item.type :value=Item.value class="formInput" v-model=Item.value @change="checkEdited"/>
                      </md-field>
                    </div>
                  </div> -->

                </div>
                <div v-if="Item.type=='checkbox'" class="row" style="padding:10px 0 10px 0">
                  <div><input type="checkbox" class="tablecheckbox"  v-model="Item.value"/></div>
                  
                  <div style="padding-left:10px"><label>{{Item.name}}</label><label style="color:red" v-if=(Item.required)>*</label></div>
                  
                </div>
                <div v-if="Item.type=='select'">
                  <md-field style="margin-top:0px;padding-top:0px">
                    <md-select v-model=Item.value class="formInput" @md-selected="checkEdited">
                      <md-option v-for="option in Item.option.filter(obj=>obj.hide!==true)" :key="option.key" :value=option.key>{{option.name}}</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div v-if="Item.type=='array'">
                  <md-chips v-model="Item.value"  md-check-duplicated style="margin-top:0px;padding-top:0px"></md-chips>
                </div>
                <div v-if="(Item.type=='file')">
                  <md-field style="margin-top:0px;padding-top:5px">
                      <md-file v-if="Item.multiple" :accept="Item.accept" :id="Item.key" @md-change="uploadFile($event, key, Item.key, Item.multiple)" multiple/>
                      <md-file v-else :accept="Item.accept" :id="Item.key" @md-change="uploadFile($event, key, Item.key, Item.multiple)" />
                  </md-field>
                </div>
                <div v-if="(Item.type=='object')" style="padding-bottom:20px">
                  <label >{{Item.name}}</label>
                  <label v-if=(Item.hits)>{{Item.hits}}</label>
                  <table class="DragDropTable" style="width: 100%">
                      <div v-for="(ItemValue, ItemKey) in Item.value" :key="ItemKey">
                        <tr>
                          <td>
                            <label >{{ItemKey}}</label>
                          </td>
                          <td style="width: 100%">
                            <center>
                              <md-field style="width:90%;">
                                  <md-input type="text" v-model=Item.value[ItemKey] @change="checkEdited"/>
                              </md-field>
                            </center>
                          </td>
                          <td>
                              <div class="itemRemove"  @click="confirmDeleteData(key, Item.key, ItemKey)"><u>remove</u></div>
                          </td>
                        </tr>
                      </div>
                  </table>
                  <div style="text-align: center;" @click="addData(key, Item.key)">
                      <md-icon>add_circle_outline</md-icon> <u class="addLang">{{addDataText}}</u>
                  </div>
                </div>
                <div v-if="(Item.type=='autocomplete')" style="padding-bottom:20px">

                </div>
              </div>

              <div v-else style="padding-bottom:20px">
                <label>{{Item.name}}</label><br>
                <div v-if="(Item.type =='array')">
                  <md-chip class="md-primary" v-for="tag in Item.value" :key="tag">{{tag}}</md-chip>
                </div>
                <div v-else-if="(Item.type =='phone')" style="font-size:16px;font-weight:400">
                  +{{ Item.countyCode }}{{ Item.value }}
                </div>
                <div v-else style="font-size:16px;font-weight:400">{{Item.value}}</div>
              </div>

            </div>
          </div>
        </div>
        <div v-if="editAble" style="text-align:center;padding-top:10px">
          <md-button v-if="cancelButton" style=" background-color: #575757 !important;width: 35% !important; margin-right: 50px;" @click="cancelAction()">{{cancelButton}}</md-button>
          <md-button v-if="haveDoneButton" style=" background-color: #575757 !important;width: 35% !important;" @click="formSubmit()">{{DoneButton}}</md-button>
          
          <md-button v-if="additionalFormButton" style=" background-color: #575757 !important;width: 35% !important; margin-left: 50px;" @click="additionalFunction()">{{additionalFormButton}}</md-button>
        </div>
        <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>

        <Transition name="modal" v-if="showModal">
          <div  class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="popup">
                        <div class="modal-header">
                            <slot name="header">{{addDataText}}</slot>
                        </div>
                        <div class="modal-body" style="text-align: center">
                          <md-field>  
                            <md-input   v-model="newData" />
                          </md-field>
                          <div style="text-align:center;padding-top:10px">
                            <md-button style=" background-color: #575757 !important;width: 35% !important; margin-right: 50px;" @click="showModal=false">Cancel</md-button>
                            <md-button style=" background-color: #575757 !important;width: 35% !important;" @click="createNewData()">Create</md-button>
                          </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        </Transition>
        <Transition name="modal" v-if="showDeleteDataModal">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="delDataPopupAction"
            :PopupTitle="delDataPopupTitle"
            :PopupContent="delDataPopupContent"
            :actionButton="delDataActionButton"
          ></Popup>
        </Transition>
        <!-- <Transition name="modal">
          <div v-if="showDeleteDataModal" class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div class="modal-header">
                  <slot name="header">Confirm Delete</slot>
                </div>
                <div class="modal-body" style="text-align: center">
                    <h7>
                      Are you sure to delete {{deleteData}}
                    </h7>
                </div> 
                <div class="row">
                  <div style="width:47.5%;text-align: right;">
                    <md-button class="md-primary" @click="delData()">Delete</md-button>
                  </div>
                  <div style="width:5%">
                  </div>
                  <div style="width:47.5%">
                    <md-button class="md-primary" @click="showDeleteDataModal=false">Cancel</md-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition> -->
      </div>
      
    
</template>
  
  <script>
 import  Popup from "./Popup.vue";
  export default {
    components:{
      Popup
    },
    methods:{
      delDataPopupAction: function(){
        this.delData();
        this.showDeleteDataModal = false;
      },
      popupcancel: function(){
        this.showDeleteDataModal = false;
        this.showModal = false;
      },
      checkAutocomplete: function(event, Item){
        this.autocompleteList = [];
        if(!this.autocompleteClick && event.length > 0){
          this.autocompleteList = Item.autocompleteList.filter(function(data) {
            return data.Name.toLowerCase().includes(event.toLowerCase());
          }) 
        }else{
          this.autocompleteClick = false;
        }

      },
      setAutocompleteValue: function(Item, value){
        console.log("setAutocompleteValue")
        Item.value = value;
        this.autocompleteList = [];
        console.log("set autocompletediv to none")
        this.autocompleteClick = true;
        this.autocompletediv = {
            display: "none"
        }

        
      },
      confirmDeleteData: function(row, key, valueKey){
        this.deleteDataRow = row
        this.deleteDatakey = key
        this.deleteData = valueKey
        this.delDataPopupContent = "Are you sure to delete "+ valueKey,
        this.showDeleteDataModal = true;

      },
      delData: function(){
        for(var i = 0; i< this.FormItem[this.deleteDataRow].length; i++){
          if(this.FormItem[this.deleteDataRow][i].key == this.deleteDatakey){
            delete this.FormItem[this.deleteDataRow][i].value[this.deleteData];
          }
        }
        this.showDeleteDataModal = false;
        //delete this.form.templateData[data];
        //this.$forceUpdate()
      },
      addData: function(row, key){
        this.newDataRow = row;
        this.newDataKey = key;
        this.showModal=true
      },
      createNewData:function(){
        console.log("here createNewData", this.newData);
        this.showModal=false
        //this.form.templateData[this.newData] = "";
        for(var i = 0; i< this.FormItem[this.newDataRow].length; i++){
          if(this.FormItem[this.newDataRow][i].key == this.newDataKey){
            this.FormItem[this.newDataRow][i].value[this.newData] = ""
          }
        }
        this.newData = "";
        this.newDataRow = "";
        this.newDataKey = "";
      },
      uploadFile: async function(fileList, key, itemkey, multiple){
        var MediaURLList = []
        if(fileList.constructor === FileList && fileList[0] !==undefined){
            for(var i = 0; i<fileList.length; i++){
                var file=fileList[i];
                console.log("file", file)
                var object = {
                  "file": file,
                  "base64": await this.getBase64(file),
                  "url": URL.createObjectURL(file),
                }
                MediaURLList.push(object);  
                //this.MediaBase64List[i]=file;
            }
            console.log("MediaURLList", MediaURLList);
            for(var i=0; i<this.FormItem[key].length; i++){
              if(this.FormItem[key][i].key == itemkey){
                this.FormItem[key][i].value =  MediaURLList
              }
            }
        }
      },
      getBase64(file) {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result)
          }
          reader.readAsDataURL(file)
        })
      },
      checkEdited:function(){
        this.$emit("checkEdited", true);
      },
      cancelAction: function(){
        this.$emit("cancelAction");
      },
      additionalFunction: async function(){
        var FormData = await this.getFormData();
        this.$emit("additionalFunction", FormData);
      },
      editForm:function(){
        console.log("go to editForm")
        this.$emit("editForm");
      },
      emailValidation: function(mail){
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (mail.match(mailformat)){
          return true
        }
          return false
      },
      formSubmit: async function(){
        var FormData = await this.getFormData();
        if(FormData!==null && FormData!==undefined)
          this.$emit("formSubmit", FormData);
      },
      getFormData: async function(){
        var FormData = {}
        console.log(this.FormItem)
        for(const [key, value] of Object.entries(this.FormItem)){
          for(var i = 0; i<value.length; i++){
            if(value[i].type == 'email'){
              if(value[i].required){
                const checkResult = this.emailValidation(value[i].value);
                if(!checkResult){
                  this.formMessage = "Invalied Email Address"
                  this.showFormMessage = true;
                  return
                }
              }
              FormData[value[i].key] = value[i].value
            }else if(value[i].type == 'phone'){
              if(value[i].required){
                if(value[i].value!==null && value[i].value!==undefined){
                  FormData[value[i].key] = "+"+value[i].countyCode + value[i].value
                }else{
                  this.formMessage = value[i].name + " is required"
                  this.showFormMessage = true;
                  return
                }
              }else{
                FormData[value[i].key] = "+"+value[i].countyCode + value[i].value
              }
            }else{

              if(value[i].required){

                if(value[i].value!==null && value[i].value!==undefined){
                  if(value[i].type == 'number'){              
                    FormData[value[i].key] = parseInt(value[i].value||0)
                  }else{
                    FormData[value[i].key] = value[i].value
                  }
                }else{
                  this.formMessage = value[i].name + " is required"
                  this.showFormMessage = true;
                  return
                }
              }else{
                if(value[i].value!==null && value[i].value!==undefined){
                  if(value[i].type == 'number'){           
                    FormData[value[i].key] = parseInt(value[i].value)
                  }else{
                    FormData[value[i].key] = value[i].value
                  }
                }
                
              }
            }
          }
        }
        return FormData
      }
    },
    name: "form-component",
    props: {
      FormTitle:{
        type: String,
        default(){
          return ""
        }
      },
      FormItem:{
        required: true,
        type: Object,
        default() {
            return {};
        },
      },
      formMessage:{
        type: String,
        default(){
          return ""
        }
      },
      cancelButton:{
        type: String,
        default(){
          return ""
        }
      },
      addDataText:{
        type: String,
        default(){
          return "Add Data"
        }
      },
      additionalFormButton: {
        type: String,
        default(){
          return ""
        }
      },
      showFormMessage:{
        default(){
          return false
        }
      },
      haveDoneButton:{
        default(){
          return true
        }
      },
      DoneButton:{
        default(){
          return "Done"
        }
      },
      editAble:{
        default(){
          return true
        }
      }
    },
    data() {
      return {
        showModal: false,
        showDeleteDataModal: false,
        autocompleteList: [],
        newDataRow: "",
        newDataKey: "",
        newData: "",
        deleteDataRow: "",
        deleteDatakey: "",
        deleteData: "",
        MediaURLList:[],
        MediaBase64List:[],
        delDataPopupTitle: "Confirm Delete",
        delDataPopupContent: "",
        delDataActionButton: "Delete",
        autocompleteClick: true,
        autocompletediv:{
          display: "block"
        }
      };
    },

    computed: {

    }
  };
  </script>

  <style>
    .itemRemove{
      font-weight: 400;
      font-size: 18px;
      padding-top:25px;
      color:#CC2727;
      cursor: pointer;
  }
  .modal-mask{
    z-index: 50;
  }

  .autocompleteShow {
    display: block;
  }
  .autocompleteHide {
    display: none;
  }
  .autocomplete-list {
    list-style: none;
    padding:unset;
    margin: unset;
    margin-top: -20px
  }
  .autocomplete-list button{
    border: unset;
    background: unset;
    cursor: pointer;
  }
  .autocomplete-list li{
    border:1px solid #e9e9e9;
    border-top:unset;
  }
    .inputcolumn{
      width:50%;
      padding:0 20px
    }
    .action{
        text-align:right;
        width:50%;
        margin: 10px 0;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
    }
    .formButton{
      background-color: #575757 !important;
      width: 100px !important;
    }
    .formTitle{
      padding-left: 15px;
      margin-top: 10px;
      font-weight: 500;
      font-size: 20px;
    }
    .formInput{
      font-weight: 500;
      font-size: 18px !important;
    }
    .md-select-menu{
      z-index: 99 !important;
    }
    .DragDropTable tr td{
      border: none;
    }
    .addLang{
        cursor: pointer;
        font-size: 18px;
    }
  </style>
  