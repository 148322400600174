<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <TableHeader
        :HeaderTitle="HeaderTitle"
        :AddNewButton="AddNewButton"
        v-on:createNewItem="createNewItem"
      ></TableHeader>
      <md-card>
          <md-card-content>
            <TableComponent 
            v-on:selectItem="selectItem"
            v-on:selectAllItem="selectAllItem"
            v-on:searchItem="searchItem"
            v-on:onclickItem="onclickItem"
            v-on:selectSortOption="selectSortOption"
            v-on:selectedItemActionFunction="selectedItemActionFunction"
            v-on:goPage="goPage"
            v-on:SortPopupControl="SortPopupControl"
            v-on:cloneAction="cloneAction"
            :TableList="TableItemList"
            :selectedItemCounter="selectedItemCounter"
            :selectedItem="selectedItem"
            :columns="columns"
            :selectAll="selectAll"
            :selectable="selectable"
            :haveSearchFunction="haveSearchFunction"
            :sortOption="sortOption"
            :selectedItemAction="selectedItemAction"
            :pageNumber="pageNumber"
            :showNextButton="showNextButton"
            :showSortPopup="showSortPopup"
            :canClone="true"
            ></TableComponent>
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
      <Transition name="modal" v-if="showPopup">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="popupaction"
            :PopupTitle="PopupTitle"
            :PopupContent="PopupContent"
            :actionButton="actionButton"
          ></Popup>
      </Transition>
    </div>
    <Spinner v-if="isLoading"/> 
    <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
    
  </div>
</template>

<script>
import { 
  TableComponent,
  TableHeader,
  Popup,
  Spinner
} from "@/components";

export default {
  components: {
    TableComponent,
    TableHeader,
    Popup,
    Spinner
  },
  data() {
    return {
      isLoading: false,
      message: "",
      showPopup: false,
      showSortPopup: false,
      PopupTitle: "Disable account",
      PopupContent: "This action will forbid customers to access their account",
      actionButton: "Disable anyway",
      index: 0,
      pageNumber: 1,
      showMessage: false,
      showNextButton: true,
      displaySize: 10,
      HeaderTitle: "Coupon List",
      TableItemList: [],
      AddNewButton:  "Add Coupon",
      columns: ["Coupon ID", "Group", "Status", "Type", "Start Date", "End Date", "Use Limit"],
      sortOption: [
        {Key: "StartDate", Name: "Start Date", Value: true, Order: "desc"},
        {Key: "EndDate", Name: "End Date", Value: false, Order: "desc"},
      ],
      selectedItemAction: [
        //{Key: "delete", Name: "Delete selected", Style:"color:#CC2727"},
      ],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      haveSearchFunction: true,
      filter: [],
      itemDetails:[]
    }
  },
  async created() {
    this.isLoading = true;
      this.message = this.$route.params.message;
      if(this.$route.params.page){
        if(Number(this.$route.params.page) > 0){
          this.pageNumber = Number(this.$route.params.page)
        }
        this.index = this.index + (Number(this.$route.params.page) - 1 )*this.displaySize
      }
      this.checkShowMessage();
      this.setDefaultSort()
      await this.listItem();
      this.isLoading = false;
    },
  methods:{
    setDefaultSort: function(){
        var index = this.sortOption.findIndex(option => option.Value === true)
        var object = {}
        object[this.sortOption[index].Key] = {"order" : this.sortOption[index].Order}
        this.Sort = [object]
      },
    SortPopupControl: function (){
        this.showSortPopup = !this.showSortPopup
      },
    cloneAction: function(id){
      //console.log(id)
      console.log(this.itemDetails)
      var target=this.itemDetails.find(obj=>obj._source.CouponID===id)._source
      //console.log(target)
      this.createNewItem(target)
      
    },
    goPage:function(action){
      if(action == "next"){
        this.index = this.index + this.displaySize
        this.pageNumber = this.pageNumber + 1
      }else if(action == "prev" && this.index > 0){
        this.index = this.index - this.displaySize
        this.pageNumber = this.pageNumber - 1
      }else{
        this.index = 0
        this.pageNumber = 1
      }
      this.$router.replace({ name: "Coupon List PageNumber", params: {page:this.pageNumber} })
      this.listItem()
    },
    checkShowMessage: async function(){
      if(this.message){
        try{
          await this.delayFunction();
          this.showMessage = true;
        }catch(err){
          console.log(err)
        }
      }
    },
    delayFunction:async function (){
        setTimeout(function(){
          this.showMessage = false;
          this.message = "";
          return true;
        }, 1000);
    },
    selectedItemActionFunction:function(action){
      console.log("selectedItemActionFunction", action);
      if(action == "delete"){
        this.showPopup = true
      }
    },
    onclickItem: function(ID){
      //this.$router.push({name: 'Update Product', params:{productID:ID}})
    },
    createNewItem: function(params={}){
      this.$router.push({name: 'Create Coupon',params:params})
    },
    popupcancel: function(){
        this.showPopup = false;
      },
    popupaction: function (){
      this.showPopup = false;
    },  
    selectSortOption: function(option){
        console.log("charge sorting with " + option);
        var sortKey = "";
        var sortOrder = "";
          for(var i = 0; i<this.sortOption.length; i++){  
            if(this.sortOption[i].Key == option){
              if(this.sortOption[i].Value == true){
                if(this.sortOption[i].Order == "asc"){
                  this.sortOption[i].Order = "desc"
                }else{
                  this.sortOption[i].Order = "asc"
                }
              }else{   
                this.sortOption[i].Value = true
              }
              sortKey = this.sortOption[i].Key
              sortOrder = this.sortOption[i].Order

            }else{
              this.sortOption[i].Value = false
            }
          }
          var object = {}
          object[sortKey] = {"order" : sortOrder}
          this.Sort = [object]
          this.showSortPopup = false;
          this.listItem();
      },
    searchItem: async function(keyword){
      this.selectAll = false;
      this.selectedItem = [],
      this.selectedItemCounter = 0;

      this.index = 0;
      this.pageNumber = 1;
      console.log("search " + keyword)
      var object = {
        "Size": this.displaySize,
        "From": this.index,
        "Keyword": keyword,
        "Sort": this.Sort
      }
      if(this.filter.length > 0){
          object["Filter"] = this.filter
        }
      try{
        const ret= await this.$store.dispatch('searchCoupon',{router:this.router,keyword:object})
        this.setDisplayList(ret.data.data.hits.hits)
      }catch(err){
        console.log("searchCoupon error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    selectAllItem:function(action){
        this.selectAll = false;
        if(action){
          this.selectAll = true;
          for(var i = 0; i< this.TableItemList.length; i++){
            var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
            if(index < 0){
                this.selectedItem.push(this.TableItemList[i].ID)
            }
          }
        }else{
            for(var i = 0; i< this.TableItemList.length; i++){
                var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
                this.selectedItem.splice(index, 1);
            }
        }
        console.log("after", this.selectedItem);
        this.selectedItemCounter = this.selectedItem.length
      },
    selectItem:function(ID, action){
      console.log(ID, action);
      if(action){
        this.selectedItem.push(ID)
      }else{
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID)
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length

    },
    listItem: async function(){
      this.isLoading = true;
      var object = {
        "Size": this.displaySize,
        "From": this.index,
        "Sort": this.Sort
      }
      if(this.filter.length > 0){
          object["Filter"] = this.filter
        }
      console.log(object);
      try{
        const ret= await this.$store.dispatch('searchCoupon',{router:this.router,keyword:object})
        this.setDisplayList(ret.data.data.hits.hits)
        this.itemDetails=ret.data.data.hits.hits
        this.isLoading = false;
      }catch(err){
        this.isLoading = false;
        console.log("searchCoupon error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
      },
      setDisplayList(list){
        var newlist=[];
        for(var i = 0; i<list.length; i++){
          var object = {
            "ID": list[i]["_source"].CouponID,
            "Coupon ID": list[i]["_source"].CouponID,
            "Group": list[i]["_source"].CouponType,
            "Status": list[i]["_source"].Status,
            "Type": list[i]["_source"].DiscountType,
            "Start Date": list[i]["_source"].StartDate,
            "End Date": list[i]["_source"].EndDate,
            "Use Limit": list[i]["_source"].UseLimit,
          }
          newlist.push(object)
        }
        this.TableItemList = newlist;
        this.TableSelectUIControl(this.TableItemList)
      },
      TableSelectUIControl(ItemList){
        this.showNextButton = true;
        if(ItemList.length < this.displaySize){
          this.showNextButton = false;
        }
        var counter = 0;
        for(var i=0; i<ItemList.length;i++){
            var index = this.selectedItem.indexOf(ItemList[i].ID);
            if(index > -1){
                counter++
            }
        }
        if(counter ==  ItemList.length){
            this.selectAll = true;
        }else{
            this.selectAll = false;
        }
      }
  }
};
</script>
