<template>
  <div class="content" style="overflow-x: scroll;">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <FormHeader
        v-on:formAction="formAction"
        :HeaderTitle="HeaderTitle"
        :confirmBack="confirmBack"
        :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row" style="flex-wrap: inherit;">
          <div style="min-width:40%">
            <md-card style="margin:10px 0">
            <md-card-content>
              <FormComponent
                v-on:checkEdited="checkEdited"
                :FormTitle="FormTitle"
                :FormItem="FormItem"
                :haveDoneButton=false
                >
              </FormComponent>
            </md-card-content>
          </md-card>
          </div>
          <div style="min-width:45%;padding-left: 20px;">
            <SurveyTemplatePage
              v-on:addSurveyPage="addSurveyPage"
              v-on:delSurveyPage="delSurveyPage"
              v-on:diplicateSurveyPage="diplicateSurveyPage"
              :QuestionList="QuestionList"

            />
          </div>
        </div>   
      </div>
    </div>
    <Spinner v-if="isLoading"/> 
    <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
  </div>
</template>

<script>

import { 
  FormComponent,
  FormHeader,
  //SurveyTemplateQuestion,
  SurveyTemplatePage,
  Spinner
 } from "@/components";

export default {
  components: {
    FormComponent,
    FormHeader,
    //SurveyTemplateQuestion,
    SurveyTemplatePage,
    Spinner

  },
  created() {

  },
  methods:{
    diplicateSurveyPage: function(index){
      console.log("diplicateSurveyPage", index)
      this.QuestionList.splice(index+1, 0, JSON.parse(JSON.stringify(this.QuestionList[index])))
    },
    addSurveyPage: function(){
      console.log("addSurveyPage")
      this.QuestionList.push({
        "Title": "",
        "Description": "",
        "PageName": "Page "+(this.QuestionList.length+1),
        "QuestionList": []
      })
    },
    delSurveyPage: function(index){ 
      this.QuestionList.splice(index, 1)
    },
    formAction: async function(){
      
      var object = await this.getFormData(this.FormItem)
      console.log("mainFormObject", object);
      console.log("QuestionList", this.QuestionList);
      object["ProductQuestion"] = this.QuestionList
      console.log("object", object);
      this.isLoading = true
      try{
          const ret= await this.$store.dispatch('createSurvetTemplate',{router:this.router,surveyTemplate:object})
          console.log(ret);
          setTimeout(()=>{
            this.isLoading = false
                this.$router.replace({name: 'Survey Template List', params:{message:"New Survey Template added"}});
              }, 2000);
          }
        catch(err){
          this.isLoading = false
          console.log("createSurvetTemplate error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
    },
    getFormData: async function(form){
        var FormData = {}
        for(const [key, value] of Object.entries(form)){
          for(var i = 0; i<value.length; i++){
              if(value[i].required){
                if(value[i].value){
                  if(value[i].type == 'number'){              
                    FormData[value[i].key] = parseInt(value[i].value)
                  }else{
                    FormData[value[i].key] = value[i].value
                  }
                }else{
                  this.formMessage = value[i].name + " is required"
                  this.showFormMessage = true;
                  return
                }
              }else{
                if(value[i].type == 'number'){           
                  FormData[value[i].key] = parseInt(value[i].value)
                }else{
                  FormData[value[i].key] = value[i].value
                }    
              }
          }
        }
        return FormData
    },

    checkEdited: async function(check){
      this.confirmBack = check
    },
  },

  data() {
    return {
      isLoading: false,
      HeaderTitle: "Add Survey Template",
      FormTitle: "Survey Template Info",
      formActionButton: "Save",
      formMessage:  null,
      showFormMessage: false,
      confirmBack:false,
      QuestionList:[],
      FormItem: {
        "row1":[
          {
            "type": "text",
            "key": "Name",
            "name": "Template Name (範本名稱)",
            "required": true,
            "style": "width:100%",
            "value": null,
          },
        ],
        "row2":[
          {
            "type": "text",
            "key": "Description",
            "name": "Description",
            "required": false,
            "style": "width:100%",
            "value": null,
          },
        ],
        "row3":[
          {
            "type": "select",
            "key": "TemplateType",
            "name": "Template Type (範本類型)",
            "required": false,
            "style": "width:100%",
            "value": null,
            "option": [
              {
                "key": "Event",
                "name": "Event",
              },
              {
                "key": "Product",
                "name": "Product",
              },
              {
                "key": "EventProduct",
                "name": "Event Product",
              },
            ]
          },
        ],

        "row4":[
          {
            "type": "checkbox",
            "key": "HideProductName",
            "name": "Hide Product Name",
            "required": false,
            "style": "width:100%",
            "value": false,
          },
        ],
        "row5":[
        {
            "type": "checkbox",
            "key": "CanReSubmit",
            "name": "Can Re-submit",
            "required": false,
            "style": "width:100%",
            "value": false,
          },
        ],

      },
    }
  },

};
</script>
