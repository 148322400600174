<template>
    <div>
        <table>
            <tr>
                <td style="width:20px">
                    
                    <div><input type="checkbox" class="tablecheckbox"  v-model="Item.value"/></div> 
                </td>
                <td>
                    <div style="padding-left:10px"><label>{{Item.name}}</label><label style="color:red" v-if=(Item.required)>*</label></div>
                </td>
            </tr>
        </table>   
    </div>
</template>
<script>
export default {
    components: {

    },
    methods:{
        checkEdited:function(){
          this.$emit("checkEdited", true);
        },
    },
    name: "checkbox-input-component",
    props: {
        Item:{
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            
        };
    },
    computed: {

    }
};
</script>
<style>

</style>