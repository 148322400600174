<template>
    <div>
        <h4>{{item.title}}</h4>
        <draggable 
        :group="{name:'default', pull:'clone',put:false}" 
        :sort="false"
        :list="item.list">

            <div v-for="(data, index) in item.list" :key="index">
                <div>
                    <i>{{data.icon}}</i>
                </div>
                <span>{{data.label}}</span>
            </div>
        </draggable>
    </div>
</template>
<script>
import draggable from 'vuedraggable';
export default{
    name:"component-set",
    props:[
        "item"
    ],
    components:{
        draggable
    },
}
</script>

<style lang="scss" scoped>

</style>