<template>
    <div>
        <label>{{item.name}}</label><label style="color:red" v-if=(required)>*</label>
        <div class="row" style="margin-top:0px;padding-top:5px">
            <md-field style="width: 20%;">
                <md-input type="number"  class="formInput" min="0" v-model="hours" @change="getTimeString"/>
            </md-field>
            時
            <md-field style="width: 20%;">
                <md-input type="number"  class="formInput" min="0" max="59" v-model="minutes" @change="getTimeString"/>
            </md-field>
            分
            <md-field style="width: 20%;">
                <md-input type="number"  class="formInput" min="0" max="59" v-model="seconds" @change="getTimeString"/>
            </md-field>
            秒
        </div>

    </div>
</template>
<script>
export default {
    components: {

    },
    methods:{
        checkEdited:function(event){
            console.log(event);
          this.$emit("checkEdited", true);
        },

        getTimeString() {
            var timeString = "";
            if(this.hours > 0){
                timeString += this.hours + "h";
            }
            if(this.minutes > 0){
                timeString += this.minutes + "m";
            }
            if(this.seconds > 0){
                timeString += this.seconds + "s";
            }
            console.log("timeString", timeString);
            this.item.value = timeString;
            //this.$emit('update:value', timeString)
        }
    },
    name: "time-input-component",
    props: {
        name: {
            default: "time-input",
        },
        required: {
            default: false,
        },
        value: {
            default: "",
        },
        item: {
            default: null,
        },
    },
    data() {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
            totalSeconds: 0
        };
    },
    watch: {
        value(newValue){
            console.log("value", newValue);
            if(newValue == ""){
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
            }else{
                var timeString = newValue;
                var timeStringArray = timeString.split("h");
                if(timeStringArray.length > 1){
                    this.hours = parseInt(timeStringArray[0]);
                    timeString = timeStringArray[1];
                }
                timeStringArray = timeString.split("m");
                if(timeStringArray.length > 1){
                    this.minutes = parseInt(timeStringArray[0]);
                    timeString = timeStringArray[1];
                }
                timeStringArray = timeString.split("s");
                if(timeStringArray.length > 1){
                    this.seconds = parseInt(timeStringArray[0]);
                }
            }
            console.log("timeString", timeString);
        },
        seconds(newSeconds) {
            if (newSeconds >= 60) {
                const remainingSeconds = newSeconds % 60;
                const additionalMinutes = Math.floor(newSeconds / 60);
                this.minutes += additionalMinutes;
                this.seconds = remainingSeconds;
            }
        },
        minutes(newMinutes) {
            if (newMinutes >= 60) {
                const remainingMinutes = newMinutes % 60;
                const additionalHours = Math.floor(newMinutes / 60);
                this.hours += additionalHours;
                this.minutes = remainingMinutes;
            }
        }
    },
    create() {
       console.log("create", this.Item);
    },
    computed: {
       
    }
};
</script>
<style>

</style>