<template>
  <div class="question-div" ref="top">
    <md-card class="md-layout-item md-size-100 md-small-size-100">
      <div style="position:absolute;right:-60px">
        <md-button class="md-fab md-mini md-primary md-arrow" @click="$emit('rowMoveUp',index,$refs['top'].offsetTop)">
          <md-icon>arrow_upward</md-icon>
        </md-button>
        <br>
        <md-button class="md-fab md-mini md-primary md-arrow" @click="$emit('rowMoveDown',index,$refs['top'].offsetTop)">
          <md-icon>arrow_downward</md-icon>
        </md-button>
      </div>
      <md-card-header>
        <div class="md-title">Question {{index+1}}</div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label>Question</label>
              <md-input v-model="question"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label>Description</label>
              <md-input v-model="description"></md-input>
            </md-field>
          </div>
        </div>
        <div class="type-layout md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label for="type">Type</label>
              <md-select v-model="questionTypeStr" name="type" id="type" @md-selected="selected">
                <md-optgroup v-for="(elem,i) in menuList" :key="i" :label="elem.title">
                  <md-option v-for="(type,j) in elem.list" :key="j" :value="type.name+'::'+i+'::'+j">{{type.label}}</md-option>
                </md-optgroup>
              </md-select>
            </md-field>
          </div>
        </div>
        <div v-if="haveAnswer" class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label>Answer</label>
              <md-input v-model="answer"></md-input>
              <span class="answer md-helper-text">Use ; to separate multiple answer</span>
            </md-field>
          </div>
        </div>
        <div v-if="haveHaveModelAnswer" class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-checkbox v-model="haveModelAnswer">Have Model Answer</md-checkbox>
          </div>
        </div>
        <div v-if="haveModelAnswer" class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label>Model Answer</label>
              <md-input v-model="modelAnswer"></md-input>
              <span class="answer md-helper-text">Use ; to separate multiple answer</span>
            </md-field>
          </div>
        </div>
        <div v-if="haveHits" class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label>Hits</label>
              <md-input v-model="Hits"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-checkbox v-model="optional">Optional</md-checkbox>
          </div>
        </div>
        <div v-if="haveLikeAction" class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-checkbox v-model="LikeAction">Like Action</md-checkbox>
          </div>
        </div>
        
      </md-card-content>
      <md-card-actions>
          
        <md-button type="button" class="md-accent" @click="$emit('removeQuestion',index)">Remove</md-button>
      </md-card-actions>
    </md-card>
  </div>
</template>
<script>
import initMenu from '../../config/menu.js'
export default {
  name:"question-row",
  props:{
    questionObj:{
      type:Object
    },
    index:{
      type:Number
    }
  },
  methods:{
    selected:function(value){
      console.log(value);
      var arr=value.split("::");
      var i=arr[1];
      var j=arr[2];
      this.haveLikeAction=this.menuList[i].list[j].haveLikeAction;
      
      this.haveAnswer=this.menuList[i].list[j].haveAnswer;
      
      this.haveHits=this.menuList[i].list[j].haveHits;
      
      this.haveHaveModelAnswer=this.menuList[i].list[j].haveHaveModelAnswer;
      
      this.$emit('rowSelected',this.index,arr[0]);
      if(!this.haveLikeAction)
        this.$emit('removeQuestionProp',this.index,'LikeAction');
      if(!this.haveAnswer)
        this.$emit('removeQuestionProp',this.index,'answer');
      if(!this.haveHits)
        this.$emit('removeQuestionProp',this.index,'Hits');
      if(!this.haveHits)
        this.$emit('removeQuestionProp',this.index,'Hits');
      if(!this.haveHaveModelAnswer)
        this.$emit('removeQuestionProp',this.index,'modelAnswer');
    },
    searchIndexInMenuList(value){
      var i=0;
      var j=0;
      for(i=0;i<this.menuList.length;i++){
        for(j=0;j<this.menuList[i].list.length;j++){
          if(this.menuList[i].list[j].name==value)
            return [i,j];
        }
      }
      return false;
    }
  },
  watch:{
    question:function(newValue,oldValue){
      this.$emit('updateQuestionProp',this.index,'question',newValue)
    },
    answer:function(newValue,oldValue){
      this.$emit('updateQuestionProp',this.index,'answer',newValue.split(";"))
    },
    LikeAction:function(newValue,oldValue){
      this.$emit('updateQuestionProp',this.index,'LikeAction',newValue)
    },
    modelAnswer:function(newValue,oldValue){
      this.$emit('updateQuestionProp',this.index,'modelAnswer',newValue.split(";"))
    },
    Hits:function(newValue,oldValue){
      this.$emit('updateQuestionProp',this.index,'Hits',newValue)
    },
    optional:function(newValue,oldValue){
      this.$emit('updateQuestionProp',this.index,'optional',newValue)
    },
    description:function(newValue,oldValue){
      this.$emit('updateQuestionProp',this.index,'description',newValue)
    },
    haveModelAnswer:function(newValue,oldValue){
      if(newValue===false)
        this.$emit('removeQuestionProp',this.index,'modelAnswer')
    },
  },
  data(){
    //console.log(initMenu());
    //console.log(this.questionObj);
    
    return {
      menuList:initMenu(),
      question:this.questionObj.question || null,
      questionType:this.questionObj.questionType || "Select Type",
      optional:this.questionObj.optional || null,
      LikeAction:this.questionObj.LikeAction || null,
      haveLikeAction:false,
      haveAnswer:false,
      haveHits:false,
      haveHaveModelAnswer:false,
      haveModelAnswer:this.questionObj.modelAnswer?true:false,
      modelAnswer:this.questionObj.modelAnswer ? this.questionObj.modelAnswer.join(";") : null,
      answer:this.questionObj.answer? this.questionObj.answer.join(";") : null,
      Hits:this.questionObj.Hits || null,
      description:this.questionObj.description || null,
      questionTypeStr:null
    }
  },
  mounted(){
    console.log(this.menuList);
    var ml_i=this.searchIndexInMenuList(this.questionType);
    if(ml_i!==false)
      var str=this.questionType+"::"+ml_i[0]+"::"+ml_i[1];
      this.selected(str);
      this.questionTypeStr=str;
  }
}
</script>
<style lang="scss" scoped>
  .question-div{
    margin: 0 100px;
  }
  .type-layout{
    width: 50%;
  }
  .md-card .md-card-header{
    background-color: #4caf50;
    color:white; 
  }
  .md-button.md-theme-default.md-accent{
    background-color: #ff5252!important;
  }
  .md-primary.md-arrow, .md-primary.md-arrow:hover, .md-primary.md-arrow:active, .md-primary.md-arrow:focus{
    background-color:#448aff!important;
  }
  .md-field .answer.md-helper-text{
    opacity: 1;
    visibility: visible;
    bottom: -22px!important;
  }
</style>