<template>
  <div class="content">
    
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
 
      <FormHeader
        :HeaderTitle="HeaderTitle"
        >
      </FormHeader>
      
      <div class="row">
        <div style="width:45%">
          <md-card style="margin:10px 0">
          <md-card-content>
            <InformationDisplay
              :InformationTitle="UserInfoTitle"
              :InformationData="UserInfo"
              >
              </InformationDisplay>
          </md-card-content>
        </md-card>

        <md-card style="margin:10px 0">
          <md-card-content>
            <InformationDisplay
              :InformationTitle="RequestInfoTitle"
              :InformationData="RequestInfo"
              >
              </InformationDisplay>
          </md-card-content>
        </md-card>


        </div>
        <div style="width:55%;padding-left: 30px;">
          <md-card style="margin:10px 0">
            <md-card-content>
              <InformationDisplay
              :InformationTitle="OrderInfoTitle"
              :InformationData="OrderInfo"
              >
              </InformationDisplay>
            </md-card-content>
          </md-card>
          <md-card style="margin:10px 0">
            <md-card-content>
              <InformationDisplay
              :InformationTitle="PaymentInfoTitle"
              :InformationData="PaymentInfo"
              >
              </InformationDisplay>
              <ProductListTable
              style="padding-top: 20px;"
              :Title="ProductInfoTitle"
              :ProductList="ProductList"
              >
               
              </ProductListTable>
            </md-card-content>
          </md-card>
        </div>
      </div>
      <hr>
      <div v-if="showRefundAction">
        <button class="RejectButton" @click="showConfirmReject=true">Reject</button>
        <button class="AcceptButton" @click="showConfiremAccept=true" style="margin-left: 20px; ">Accept</button>
      </div>

      <Transition name="modal" v-if="showConfirmReject">
          <AddDataPopup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="RejectRefund"
            :PopupTitle="RejectRefundTitle"
            :PopupContent="RejectRefundContent"
            :actionButton="RejectRefundActionButton"
          ></AddDataPopup>
      </Transition>
      <Transition name="modal" v-if="showConfiremAccept">
          <AddDataPopup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="AcceptRefund"
            :PopupTitle="AcceptRefundTitle"
            :PopupContent="AcceptRefundContent"
            :actionButton="AcceptRefundActionButton"
          ></AddDataPopup>
      </Transition>

      </div>

    </div>


    <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
  </div>
</template>
<script>


import {
FormHeader,
InformationDisplay,
ProductListTable,
AddDataPopup
} from "@/components";


export default {
  components: {
    FormHeader,
    InformationDisplay,
    ProductListTable,
    AddDataPopup
  },
  created() {  
    this.RefundRequestID = this.$route.params.RefundRequestID;
    this.getRefundReuest();
  },
  methods:{
    //popup action
    RejectRefund: async function(){
      var rejectObject = await this.getFormData(this.RejectRefundContent);
      rejectObject.RefundRequestID = this.RefundRequestID;
      rejectObject.ReFundAction = "reject";
      console.log("RejectRefund", rejectObject)
      try{
        const ret= await this.$store.dispatch('updateRefundRequest',{router:this.router,data:rejectObject})
        console.log(ret);
        this.popupcancel();
        this.message = this.RefundRequestID + "refund request has been rejected"
        this.showMessage = true
      }catch(err){
        console.log("updateRefundRequest error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
    },
    AcceptRefund: async function(){
        var acceptObject = await this.getFormData(this.AcceptRefundContent);
        acceptObject.RefundRequestID = this.RefundRequestID;
        acceptObject.ReFundAction = "accept";
        console.log("AcceptRefund", acceptObject)
        try{
          const ret= await this.$store.dispatch('updateRefundRequest',{router:this.router,data:acceptObject})
          console.log(ret);
          this.popupcancel();
          this.message = this.RefundRequestID + "refund request has been accepted"
          this.showMessage = true
        }catch(err){
          console.log("updateRefundRequest error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
    },
    popupcancel: function(){
      this.showConfirmReject = false;
      this.showConfiremAccept = false;
    },
    //end of popup action
    getFormData: async function(form){
        var FormData = {}
        for(const [key, value] of Object.entries(form)){
          for(var i = 0; i<value.length; i++){
              if(value[i].required){
                if(value[i].value){
                  if(value[i].type == 'number'){              
                    FormData[value[i].key] = parseInt(value[i].value)
                  }else{
                    FormData[value[i].key] = value[i].value
                  }
                }else{
                  this.message = value[i].name + " is required"
                  this.showMessage = true;
                  return
                }
              }else{
                if(value[i].type == 'number'){           
                  FormData[value[i].key] = parseInt(value[i].value)
                }else{
                  FormData[value[i].key] = value[i].value
                }    
              }
          }
        }
        return FormData
    },
    getRefundReuest: async function(){
      var Object = {
        RefundRequestID: this.RefundRequestID,
      };
      console.log("getRefundReuest", Object)
      try{
        const ret= await this.$store.dispatch('getRefundRequestDetail',{router:this.router,data:Object})
        console.log("getRefundReuest", ret);
        //this.UserInfo = ret.data.UserInfo;
        this.setUserInfo(ret.data.UserInfo);
        this.setRequestInfo(ret.data.refundRequestDetail);
        if(ret.data.refundRequestDetail.Status == "wait for approve"){
          this.showRefundAction = true;
        }
        this.setOrderInfo(ret.data.OrderDetail);
        this.setPaymentInfo(ret.data.OrderDetail);
        this.setProductInfo(ret.data.OrderDetail.Product);
      }catch(err){
        console.log("getRefundRequestDetail error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
      }
    },
    setProductInfo: function(data){
      for(var i=0; i<data.length; i++){
        this.ProductList.push({
          "Image": process.env.VUE_APP_S3_URL+"product/"+data[i].ProductID,
          "ProductName": data[i].Name,
          "Quantity": data[i].Quantity,
          "UnitPrice": data[i].UnitPrice,
        });
      }
    },
    setPaymentInfo: function(data){
      this.PaymentInfo["Shipping Fee"] =  "$"+ data.ShippingFee;
      this.PaymentInfo["Sub Total"] = "$"+ data.SubTotal;
      this.PaymentInfo["Taxes"] = "$"+ data.Taxes;
      if(data.Discount.Campaign){
        this.PaymentInfo["Discount"] = data.Discount.Campaign.Description+"("+data.Discount.Campaign.Discount+")";
      }
      
      this.PaymentInfo["Total"] = "$"+ data.Total;
    },
    setOrderInfo: function(data){
      this.OrderInfo["Order ID"] = data.OrderID;
      this.OrderInfo["Payment Method"] = data.PaymentMethod;
      this.OrderInfo["Payment ID"] = data.PaymentID;
      this.OrderInfo["Created Date"] = data.CreatedDatetime.split("T")[0];
    },
    setRequestInfo: function(data){
      this.RequestInfo["Refund Status"] = data.Status;
      this.RequestInfo["Refund Reason"] = data.Reason;
      this.RequestInfo["Request Date"] = data.CreatedDatetime.split("T")[0];
      if(data.Status != "wait for approve"){
        this.RequestInfo["Admin Reason"] = data.AdminReason
        }
    },
    setUserInfo: function(data){
      this.UserInfo["User ID"] = data.UserID;
      this.UserInfo["Name"] = data.Name;
      this.UserInfo["Gender"] = data.Gender;
      this.UserInfo["Phone"] = data.Phone;
      this.UserInfo["Email"] = data.Email;
    } 
  },
  
  data() {
    return {
      showMessage: false,
      message: "",
      HeaderTitle: "Refund Request",
      RefundRequestID: "",
      editAble: false,
      showRefundAction: false,
      UserInfoTitle: "User Info",
      UserInfo: {
        "User ID": "",
        "Name": "",
        "Gender": "",
        "Phone": "",
        "Email": "",
      },
      RequestInfoTitle: "Request Info",
      RequestInfo: {
        "Refund Status": "",
        "Refund Reason": "",
        "Request Date": "",
        "Admin Reason": "",
      },
      OrderInfoTitle: "Order Info",
      OrderInfo: {
        "Order ID": "",
        "Payment Method": "",
        "Payment ID": "",
        "Created Date": ""
      },
      PaymentInfoTitle: "Payment Summary",
      PaymentInfo: {
        "Shipping Fee": "",
        "Sub Total": "",
        "Taxes": "",
        "Discount": "",
        "Total": "",
      },
      ProductInfoTitle: "Products",
      ProductList: [],
      showConfirmReject: false,
      RejectRefundTitle: "Reject Refund",
      RejectRefundContent: {
        "row1":[
          {
            "type": "text",
            "key": "Reason",
            "name": "Reason",
            "required": true,
            "style": "width:100%",
            "value": null,
          },
        ],
      },
      RejectRefundActionButton: "Reject",
      showConfiremAccept: false,
      AcceptRefundTitle: "Accept Refund",
      AcceptRefundContent: {
        "row1":[
          {
            "type": "text",
            "key": "Reason",
            "name": "Reason",
            "required": true,
            "style": "width:100%",
            "value": null,
          },
        ],
        "row2":[
          {
            "type": "number",
            "key": "RefundAmount",
            "name": "Refund Amount",
            "required": true,
            "style": "width:100%",
            "value": null,
          },
        ],
      },
      AcceptRefundActionButton: "Accept",



    };
  },

};
</script>
<style>
.enableButton{
  color: #cc2727;
  border-color: #cc2727;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
.RejectButton{
  min-width: 100px;
  color: #575757;
  border-color: #575757;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
.AcceptButton{
  min-width: 100px;
  color: white;
  background-color: #575757;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400; 
}
</style>