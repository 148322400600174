<template>
    <div>
        <label>{{Item.name}}</label><label style="color:red" v-if=(Item.required)>*</label>
        <md-field style="margin-top:0px;padding-top:5px">
            <md-input :type=Item.type :value=Item.value class="formInput" v-model=Item.value :autocomplete="autocomplete"  @change="checkEdited" :readonly="readonly"/>
        </md-field>
    </div>
</template>
<script>
export default {
    components: {

    },
    methods:{
        checkEdited:function(event){
            console.log(event);
          this.$emit("checkEdited", true);
        },
    },
    name: "text-input-component",
    props: {
        Item:{
            default() {
                return {};
            },
        },
        autocomplete:{
            default: "no",
        },
        readonly:{
            default: false,
        },
    },
    data() {
        return {
            
        };
    },
    computed: {

    }
};
</script>
<style>

</style>