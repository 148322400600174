<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <FormHeader
                    v-on:formAction="formAction"
                    :HeaderTitle="HeaderTitle"
                    :confirmBack="confirmBack"
                    :formActionButton="formActionButton"
                />
                <center>
                    <draggable
                      :list="SurveyList"
                      :group="{ name: 'SurveyList' }"
                    >
                        <div v-for="(survey, key) in SurveyList" :key="key">
                            <md-card  class="surveyArea" >
                                <div style="position:absolute;right:-60px">
                                    <md-button class="md-fab md-mini md-primary md-arrow arrowIcon" @click="moveUp(key)" :style="checkUpArrowColor(key)">
                                        <md-icon>arrow_upward</md-icon>
                                    </md-button>
                                    <br>
                                    <md-button class="md-fab md-mini md-primary md-arrow arrowIcon" @click="moveDown(key)" :style="checkDownArrowColor(key)">
                                        <md-icon>arrow_downward</md-icon>
                                    </md-button>
                                </div>
                                <md-card-content >
                                    <div @click="updateSurvey(survey.SurveyID)">
                                        <label v-if="(survey.SurveyType == 'EventProduct')"> Product Survey </label>
                                        <label v-else> Event Survey </label>
                                        <br>
                                        <h4 class="surveyTitle">{{ survey.Name }}</h4>
                                        <div v-if="(survey.SurveyType == 'EventProduct')">
                                            <label v-for="(ParameterValue, ParameterKey) in survey.Parameter" :key="ParameterKey">
                                                
                                                {{ ParameterValue.value }}
                                            </label>
                                        </div>
                                    </div>

                                </md-card-content>
                            </md-card>
                        </div>
                    </draggable>
                    <md-card  class="AddSurveyArea">
                        <md-card-content>
                            <div @click="addExtraSurvey()">
                                <label style="cursor: pointer;">+ Add Extra Survey</label>
                            </div>
                           
                        </md-card-content>
                    </md-card>
                </center>
            </div>
        </div>
        <Transition name="modal" v-if="showAddSurvey">
            <div  class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="popup">
                            <div class="modal-header">
                                <slot name="header">Pick a survey template to start</slot>
                            </div>
                            <div class="modal-body">
                                <SelectInput
                                    v-on:checkEdited="checkEdited"
                                    :Item="SurveyInput"
                                />
                                <div class="row" style="text-align: center;padding-top: 20px;">
                                    <div class="column" style="width:47.5%">
                                        <label @click="showAddSurvey=false">Cancel</label>
                                    </div>
                                    <div class="column" style="width:5%"></div>
                                    <div class="column" style="width:47.5%">
                                        <button class="cancelButton" >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </Transition>
        <Spinner v-if="isLoading"/> 
        <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
    </div>
</template>

<script>
import { 
    SelectInput,
    FormHeader,
    Spinner
} from "@/components"
 import draggable from "vuedraggable";
 export default {
    components: {  
        SelectInput,
        FormHeader,
        Spinner,
        draggable
    },
    data() {
        return {
            HeaderTitle: "Event Survey Summary",
            confirmBack: false,
            formActionButton: "Done",
            isLoading: false,
            showFormMessage: false,
            formMessage: "",
            showAddSurvey: false,
            SurveyList: [],
            SurveyInput:{
                "type": "select",
                "key": "surveyTemplate",
                "name": "Survey Template",
                "required": true,
                "value": null,
                "option": []
            },
        }
    },
    async created() {
        this.isLoading = true;
        this.getEventSurvey();
        await this.getEventSurveyList(this.$route.params.eventID);
        this.isLoading = false;
    },
    methods: {
        checkEdited: function(check){
            this.confirmBack = check
        },
        updateSurvey(SurveyID){
            console.log("updateSurvey", SurveyID)
            this.$router.push({ name: "Update Event Survey", params:{eventID:this.$route.params.eventID, surveyID:SurveyID}});
        },
        addExtraSurvey(){
            console.log("addExtraSurvey")
            this.showAddSurvey = true;
        },
        moveUp(index){
            if(index!=0){
                var temp=this.SurveyList[index-1];
                this.$set(this.SurveyList,index-1,this.SurveyList[index]);
                this.$set(this.SurveyList,index,temp)
            }
        },
        moveDown(index){
            if(index!=this.SurveyList.length-1){
                var temp=this.SurveyList[index+1];
                this.$set(this.SurveyList,index+1,this.SurveyList[index]);
                this.$set(this.SurveyList,index,temp)
            }
        },
        checkUpArrowColor(index){
            if(index==0){
                return "background-color:#acacac !important"
            }else{
                return "background-color: #575757"
            }
        },
        checkDownArrowColor(index){
            if(index==this.SurveyList.length-1){
                return "background-color:#acacac !important"
            }else{
                return "background-color: #575757"
            }
        },
        async formAction(){
            console.log(this.SurveyList);
            var surveySortList = [];
            for(var s in this.SurveyList){
                surveySortList.push(this.SurveyList[s].SurveyID);
            }
            var object = {
                EventID: this.$route.params.eventID,
                Survey: surveySortList
            }
            console.log(object);
            //this.isLoading = true;
            try{
                const ret = await this.$store.dispatch("updateSurveySort", { router: this.router, data: object });
                this.isLoading = false;
                this.$router.push({ name: "Event List" });
            }catch(error){
                this.isLoading = false;
                console.log(error);
            }
            //
        },
        getEventSurvey: async function(){
            var object={
                TemplateType: "Event"
            }
            try{
                const ret= await this.$store.dispatch("listSurveyTemplates",{router:this.router, data:object})
                console.log("listSurveyTemplates", ret.data);
                for(var i = 0; i< ret.data.data.length; i++){
                    this.SurveyInput.option.push({
                        "key": ret.data.data[i].TemplateID,
                        "name": ret.data.data[i].Name,
                    })
                }
            }
            catch(err){
                console.log(err.message);
            }
        },
        async getEventSurveyList(eventID) {
           try{
                const ret= await this.$store.dispatch('listEventSurvey',{router:this.router, data:{EventID:eventID}})
                this.SurveyList = ret.data.SurveyList
           }catch(error){
                console.log(error);
           }
        },
    },
}
</script>
<style>
    .AddSurveyArea{
        margin: 10px 0 !important ;
        width: 60% !important;
        cursor: pointer;
    }
    .AddSurveyArea label{
        font-size: 18px !important;
        font-weight: 400;
    }
    .surveyArea{
        margin: 10px 0 !important ;
        width: 60% !important;
        text-align: left !important;
        min-height: 120px !important;   
        cursor: pointer; 
    }
    .surveyTitle{
        font-size: 25px !important;
        margin-top: 5px;
        font-weight: 500;
    }
    .arrowIcon{
        margin-top: 0px !important;
        margin-bottom: 10px !important;
    }
</style>