<template>
    <div>
        <label>{{Item.name}}</label><label style="color:red" v-if=(Item.required)>*</label>
        <table class="DragDropTable" style="width: 100%">

            <tr v-for="(ItemValue, ItemKey) in Item.value" :key="ItemKey">
                <td style="width:20%">
                    <label>{{ItemKey}}</label>
                </td>
                <td style="width:80%; text-align: right;">
                    <md-field>
                        <md-input type="text" v-model=Item.value[ItemKey] @change="checkEdited"/>
                    </md-field>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    components: {

    },
    methods:{
        checkEdited:function(){
          this.$emit("checkEdited", true);
        },
    },
    name: "object-input-component",
    props: {
        Item:{
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            
        };
    },
    computed: {

    }
};
</script>
<style>

</style>