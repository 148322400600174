<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <FormHeader
                    v-on:formAction="formAction"
                    :HeaderTitle="HeaderTitle"
                    :confirmBack="confirmBack"
                    :formActionButton="formActionButton"
                />
                <div class="row" style="flex-wrap: inherit;">
                    <div style="min-width:45%">
                        <md-card style="margin:10px 0">
                            <md-card-content>
                                <TextInput
                                    :Item="SurveyNameInput"
                                    v-on:checkEdited="checkEdited"
                                    
                                />
                                <TextInput
                                    :Item="SurveyDescriptionInput"
                                    v-on:checkEdited="checkEdited"
                                />
                               
                            </md-card-content>
                        </md-card>
                    </div>
                    <div style="min-width:50%;padding-left: 20px;">
                        <div v-for="(page, key) in Question" :key="key">

                            <div class="collapsible">
                                <input type="checkbox" style="display:none" :id=key checked/>
                                <div class="row unsetMargin" @click="expandItem(key)">
                                    <div class="explorIcon" > 
                                        <md-icon :id="key+icon" style="color: white;">expand_less</md-icon>
                                    </div>
                                    <div class="col explorTitle"><label>Page: {{key+1}}</label></div>
                                </div>
                                <div class="collapsible-item">
                                    <md-card class="questionContent">
                                        <md-card-content >
                                            <label>Title</label>
                                            <md-field style="margin-top:0px;padding-top:5px">
                                                <md-input type="text" class="formInput" v-model=page.Title />
                                            </md-field>
                                            <label>Description</label>
                                            <md-field style="margin-top:0px;padding-top:5px">
                                                <md-input type="text" class="formInput" v-model=page.Description />
                                            </md-field>
                                            <div v-for="(Question, index) in page.QuestionList" :key="index">
                                                <Aroma
                                                    v-if="(Question.Type=='Aroma')"
                                                    :Aroma="Aroma"
                                                />
                                                <FiveSenses
                                                    v-if="(Question.Type=='FiveSenses')"
                                                />
                                                <div v-if="(Question.Type=='TasteNote')">
                                                    <label>主觀風味</label>
                                                    <md-field style="margin-top:0px;padding-top:5px">
                                                        <md-input type="text"  class="formInput" readonly/>
                                                    </md-field>
                                                </div>
                                                <div v-if="(Question.Type=='Radio')">
                                                    <md-card class="fiveSensesBox">
                                                        <md-card-content>
                                                            <label>{{ Question.Question }}</label><br>
                                                            <label>{{ Question.Description }}</label><br>
                                                            <div v-for="(answer, aindex) in Question.Answers" :key="aindex">
                                                                <label>{{ answer.Name }}</label> <input type="radio" class="sortradio" readonly/><br>
                                                            </div>
                                                        </md-card-content>
                                                    </md-card>

                                                </div>
                                            </div>
                                        </md-card-content>
                                    </md-card>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <Spinner v-if="isLoading"/> 
        <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
    </div>
</template>

<script>
import { 
    TextInput,
    FormHeader,
    Spinner,
    FiveSenses,
    Aroma
} from "@/components";  

 export default {
    components: {  
        TextInput,
        FormHeader,
        Spinner,
        FiveSenses,
        Aroma
    },
    data() {
        return {
            HeaderTitle: "Edit Survey",
            FormTitle: "Survey Info",
            confirmBack: false,
            formActionButton: "Save",
            isLoading: false,
            showFormMessage: false,
            formMessage: "",
            Question: [],
            Aroma: [],
            eventID: "",
            SurveyNameInput:{
                    "type": "text",
                    "key": "Name",
                    "name": "Survey Name",
                    "required": true,
                    "style": "width:100%",
                    "value": null,
                },
                SurveyDescriptionInput: {
                    "type": "text",
                    "key": "Description",
                    "name": "Description",
                    "required": false,
                    "style": "width:100%",
                    "value": null,
                }

        }
    },
    async created() {
        this.isLoading = true;
        await this.getAroma(this.$route.params.eventID);
        await this.getSurvey(this.$route.params.surveyID);
        this.isLoading = false;
    },
    methods: {
        expandItem:function(element){
            let iconClass = document.getElementById(element+this.icon)
            if(document.getElementById(element).checked){
                iconClass.classList.remove('iconup')
                iconClass.classList.add('icondown')
            }else{
                iconClass.classList.remove('icondown')
                iconClass.classList.add('iconup')
            }
            document.getElementById(element).checked = !document.getElementById(element).checked;
            
        },
        checkEdited: function(check){
            this.confirmBack = check
        },
        formAction: async function(){
          var object = {
            SurveyID: this.$route.params.surveyID,
            Name: this.SurveyNameInput.value,
            Description: this.SurveyDescriptionInput.value,
          }  

            try{
                const ret = await this.$store.dispatch('updateSurvey',{router:this.router, data:object})
                this.$router.go(-1);
            }catch(error){
                console.log(error)
            }
        },
        getSurvey: async function(surveyID){
            console.log(surveyID)
            try{
                const ret= await this.$store.dispatch('getSurvey',{router:this.router, data:{SurveyID:surveyID}})
                console.log(ret.data)
                this.SurveyNameInput.value = ret.data.Survey.Name
                this.SurveyDescriptionInput.value = ret.data.Survey.Description
                this.Question = ret.data.Survey.Question
            }catch(error){
                console.log(error)
            }
        },
        getAroma: async function(eventID){
          
            var object = {
                EventID: eventID,
            }
            const ret= await this.$store.dispatch("getSurveyTasteNoteAroma",{router:this.router, data:object})
            console.log("getSurveyTasteNoteAroma", ret);
            this.Aroma = ret.data.aromaList
        },
    }

}
</script>
<style>
    .questionContent{
        margin: unset !important;
    }

</style>