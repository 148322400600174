<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <FormHeader
        :HeaderTitle="HeaderTitle"
        :confirmBack="confirmBack"
        >
      </FormHeader>
      <center>
        <md-card style="width:50%;min-width: 500px">
          <md-card-content >
              <FormComponent
              v-on:formSubmit="formSubmit"
              v-on:checkEdited="checkEdited"
              :FormTitle="FormTitle"
              :FormItem="FormItem"
              :formMessage="formMessage"
              :showFormMessage="showFormMessage"
              >
              </FormComponent>
          </md-card-content>
        </md-card>
      </center>
      </div>
    </div>
    <Spinner v-if="isLoading"/> 
  </div>
</template>

<script>

import { 
  FormComponent,
  FormHeader,
  Spinner
 } from "@/components";
export default {
  created(){
    console.log(this.$route.params);
    this.getUser(this.$route.params.customer);
  },
  methods:{
    checkEdited: async function(check){
      this.confirmBack = check
    },
    getUser: async function(UserID){
      this.isLoading = true
      try{
        const ret= await this.$store.dispatch('getCustomerInfo',{router:this.router,customer:{userID: UserID}})
        console.log(ret);
        this.userInfo = ret.data.userInfo
        this.setDatatoForm(this.userInfo)
        this.isLoading = false
      }catch(err){
        this.isLoading = false
        console.log("getCustomerInfo error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
    setDatatoForm: function(data){
      console.log("setDatatoForm", data)
      for (const [key, value] of Object.entries(this.FormItem)) {
        console.log(value)
        for(var i = 0 ;i<value.length; i++){
          if(value[i].type == "phone"){
            value[i]["value"] = data[value[i].key];
            value[i]["countyCode"] = data["PhoneCountryCode"];
          }else{
            value[i]["value"] = data[value[i].key];
          }
          
        }
      }
      console.log("FormItem", this.FormItem)
    },  
    formSubmit: async function(object){
      object.UserID = this.userInfo.UserID
      console.log("formSubmit", object)
      this.isLoading = true
      try{
          const ret= await this.$store.dispatch('updateCustomer',{router:this.router,customer:object})
          this.isLoading = false
          //this.$router.replace({name: 'Customer Profile', params:{customer:this.userInfo.UserID, message: this.userInfo.Name + " Update success"}})
          this.$router.go(-1);
        }
        catch(err){
          this.isLoading = false
          this.sending = false;
          console.log("updateCustomer error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }

    },
  },
  components: {
    FormComponent,
    FormHeader,
    Spinner
  },
  data() {
    return {
      //form default data
      isLoading: false,
      HeaderTitle: "Edit Profile",
      FormTitle: "Customer Profile",
      formMessage:  null,
      showFormMessage: false,
      FormItem: {
        "row1":[
          {
            "type": "text",
            "key": "GivenName",
            "name": "First Name",
            "required": true,
            "value": ""
          },
          {
            "type": "text",
            "key": "FamilyName",
            "name": "Last Name",
            "required": true,
            "value": ""
          },
        ],
        "row2":[
          {
            "type": "text",
            "key": "Name",
            "name": "Username",
            "required": true,
            "value": ""
          },
        ],
        "row3":[
          {
            "type": "phone",
            "key": "Phone",
            "name": "Phone",
            "required": true,
            "value": "",
            "countyCode": ""
          },
          {
            "type": "text",
            "key": "Email",
            "name": "Email",
            "required": true,
            "value": ""
          },
        ],
        "row4":[
          {
            "type": "date",
            "key": "Birthday",
            "name": "Birthday",
            "required": true,
            "value": ""
          },
          {
            "type": "select",
            "key": "Gender",
            "name": "Gender",
            "required": true,
            "value": "",
            "option": [
              {
                "key": "M",
                "name": "M",
              },
              {
                "key": "F",
                "name": "F",
              },
              {
                "key": "Others",
                "name": "Others",
              }
            ]
          },
        ],
      },
      //page custome data
      userInfo: {},
      confirmBack: false
    }
  },

};
</script>
