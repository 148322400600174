<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <TableHeader
        :HeaderTitle="HeaderTitle"
        :AddNewButton="AddNewButton"
        v-on:createNewItem="createNewItem"
      ></TableHeader>
      <md-card>
          <md-card-content>
            <TableComponent 
            v-on:selectItem="selectItem"
            v-on:selectAllItem="selectAllItem"
            v-on:searchItem="searchItem"
            v-on:onclickItem="onclickItem"
            v-on:selectSortOption="selectSortOption"
            v-on:selectedItemActionFunction="selectedItemActionFunction"
            v-on:goPage="goPage"
            v-on:SortPopupControl="SortPopupControl"
            :TableList="TableItemList"
            :selectedItemCounter="selectedItemCounter"
            :selectedItem="selectedItem"
            :columns="columns"
            :selectAll="selectAll"
            :selectable="selectable"
            :haveSearchFunction="haveSearchFunction"
            :sortOption="sortOption"
            :selectedItemAction="selectedItemAction"
            :pageNumber="pageNumber"
            :showNextButton="showNextButton"
            :showSortPopup="showSortPopup"
            ></TableComponent>
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
      <Transition name="modal" v-if="showPopup">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="popupaction"
            :PopupTitle="PopupTitle"
            :PopupContent="PopupContent"
            :actionButton="actionButton"
          ></Popup>
      </Transition>
    </div>
    <Spinner v-if="isLoading"/> 
    <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
    
  </div>
</template>

<script>
import { 
  TableComponent,
  TableHeader,
  Popup,
  Spinner
} from "@/components";

export default {
  components: {
    TableComponent,
    TableHeader,
    Popup,
    Spinner
  },
  data() {
    return {
      isLoading: false,
      message: "",
      showPopup: false,
      showSortPopup: false,
      PopupTitle: "Disable account",
      PopupContent: "This action will forbid customers to access their account",
      actionButton: "Disable anyway",
      index: 0,
      pageNumber: 1,
      showMessage: false,
      showNextButton: true,
      displaySize: 10,
      HeaderTitle: "Customer List",
      TableItemList: [],
      AddNewButton:  "Add Customer",
      columns: ["Customer Name", "Username", "Phone", "Email"],
      Source: ["Email", "FamilyName", "UserID", "GivenName", "Gender", "Birthday", "Phone", "Name"],
      Sort: {"CreatedDateTime": "desc"},
      sortOption: [
        {Key: "CreatedDateTime", Name: "Recent Added", Value: true, Order: "desc"},
      ],
      selectedItemAction: [
        {Key: "disable", Name: "Disable User", Style:"color:#CC2727"},
      ],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: false,
      haveSearchFunction: true,
    }
  },
  async created() {
      this.isLoading = true;
      this.message = this.$route.params.message;
      if(this.$route.params.page){
        if(Number(this.$route.params.page) > 0){
          this.pageNumber = Number(this.$route.params.page)
        }
        this.index = this.index + (Number(this.$route.params.page) - 1 )*this.displaySize
      }
      this.checkShowMessage();
      await this.searchItem({keyword:""});
      this.isLoading = false;
    },
  methods:{

    SortPopupControl: function (){
        this.showSortPopup = !this.showSortPopup
      },
    goPage:function(action){
      if(action == "next"){
        this.index = this.index + this.displaySize
        this.pageNumber = this.pageNumber + 1
      }else if(action == "prev" && this.index > 0){
        this.index = this.index - this.displaySize
        this.pageNumber = this.pageNumber - 1
      }else{
        this.index = 0
        this.pageNumber = 1
      }
      this.$router.replace({ name: "Customer List PageNumber", params: {page:this.pageNumber} })
      //this.listItem()
      this.searchItem({keyword:""});
    },
    checkShowMessage: async function(){
      if(this.message){
        try{
          await this.delayFunction();
          this.showMessage = true;
        }catch(err){
          console.log(err)
        }
      }
    },
    delayFunction:async function (){
        setTimeout(function(){
          this.showMessage = false;
          this.message = "";
          return true;
        }, 1000);
    },
    selectedItemActionFunction:function(action){
      console.log("selectedItemActionFunction", action);
      if(action == "disable"){
        this.showPopup = true
        //this.disableSelectedUser();
      }
    },
    onclickItem: function(ID){
      this.$router.push({name: 'Customer Profile', params:{customer:ID}})
    },
    createNewItem: function(){
      this.$router.push({name: 'Create Customer'})
    },
    popupcancel: function(){
        this.showPopup = false;
      },
    popupaction: function (){
      this.showPopup = false;
      this.disableSelectedUser();
    },  
    disableSelectedUser: async function(){
      for(var i = 0; i<this.selectedItem.length; i++){
        await this.actionForAccount(this.selectedItem[i], "disable")
      }
      this.message = this.selectedItem.length + " uses has been Disable"
      try{
        await this.delayFunction();
        this.showMessage = true;
      }catch(err){
        console.log(err)
      }
    },
    actionForAccount: async function (UserID, action){
        try{
          const ret= await this.$store.dispatch('enabledisableCustomer',{router:this.router,data:{userID: UserID, action: action}})
        }catch(err){
          console.log("enabledisableCustomer error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
    },
    selectSortOption: function(option){
      console.log("charge sorting with " + option);
      var sortOrder = "";
      var index = this.sortOption.findIndex(sort => sort.Key == option)
      console.log("option", index);
      if(index > -1){
        this.sortOption[index].Value = true
        this.sortOption[index].Order = this.sortOption[index].Order == "asc" ? "desc" : "asc"

        sortOrder = this.sortOption[index].Order
        this.Sort[option] = sortOrder

        this.showSortPopup = false;
        this.searchItem({keyword:""});
      }
    },
    selectAllItem:function(action){
        this.selectAll = false;
        if(action){
          this.selectAll = true;
          for(var i = 0; i< this.TableItemList.length; i++){
            var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
            if(index < 0){
                this.selectedItem.push(this.TableItemList[i].ID)
            }
          }
        }else{
            for(var i = 0; i< this.TableItemList.length; i++){
                var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
                this.selectedItem.splice(index, 1);
            }
        }
        console.log("after", this.selectedItem);
        this.selectedItemCounter = this.selectedItem.length
      },
    selectItem:function(ID, action){
      console.log(ID, action);
      if(action){
        this.selectedItem.push(ID)
      }else{
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID)
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length

    },
    searchItem: async function({keyword}){
      this.isLoading = true;
      var object = {
        "Size": this.displaySize,
        "From": this.index,
        "Sort": this.Sort,
        "Source": this.Source
      }
      if(keyword){
        object["Keyword"] = keyword
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;
        this.index = 0;
        this.pageNumber = 1;
      }
      try{
        const ret= await this.$store.dispatch('SearchUser',{router:this.router,keyword:object})
        this.setDisplayList(ret.data.data.hits.hits)
        this.isLoading = false;
      }catch(err){
        this.isLoading = false;
        console.log("SearchUser error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
      }
    },
    // listItem: async function(){
    //   this.isLoading = true;
    //   var object = {
    //     "Size": this.displaySize,
    //     "From": this.index,
    //     "Sort": this.Sort,
    //     "Source": this.Source
    //   }

    //   try{
    //     const ret= await this.$store.dispatch('SearchUser',{router:this.router,keyword:object})
        
    //     this.setDisplayList(ret.data.data.hits.hits)
    //     this.isLoading = false;
    //   }catch(err){
    //     this.isLoading = false;
    //     console.log("SearchUser error", err.response.data);
    //       this.formMessage = err.response.data.message
    //       this.showFormMessage = true
    //   }
    //   },
      setDisplayList(list){
        var newlist=[];
        for(var i = 0; i<list.length; i++){
          var object = {
            "ID": list[i]["_source"].UserID,
            "Customer Name": list[i]["_source"].GivenName+' '+list[i]["_source"].FamilyName,
            "Username": list[i]["_source"].Name,
            "Phone": list[i]["_source"].Phone,
            "Email": list[i]["_source"].Email,
            "Gender": list[i]["_source"].Gender,
            "Birthday": list[i]["_source"].Birthday,
          }
          newlist.push(object)
        }
        this.TableItemList = newlist;
        this.TableSelectUIControl(this.TableItemList)
      },
      TableSelectUIControl(ItemList){
        this.showNextButton = true;
        if(ItemList.length < this.displaySize){
          this.showNextButton = false;
        }
        var counter = 0;
        for(var i=0; i<ItemList.length;i++){
            var index = this.selectedItem.indexOf(ItemList[i].ID);
            if(index > -1){
                counter++
            }
        }
        if(counter ==  ItemList.length){
            this.selectAll = true;
        }else{
            this.selectAll = false;
        }
      }
  }
};
</script>
