<template>
    <div>
        <label >{{Item.name}}</label>
        <label v-if=(Item.hits)>{{Item.hits}}</label>
            <div>
                <md-field style="margin-top:0px;padding-top:5px">
                    <md-input type=text class="formInput" v-model=value @change="checkEdited" @input="checkAutocomplete()" autocomplete=no />
                </md-field>
            </div>
            <ul class="autocomplete-list" v-if="Item.autocompleteList.length">
                <li  v-for="autocompleteItem in Item.autocompleteList" :key="autocompleteItem.Key">
                    <button  @click="setAutocompleteValue(autocompleteItem.Key, autocompleteItem.Name)">{{ autocompleteItem.Name }}</button>
                </li>
            </ul>
        <!-- <div class="autocompletediv" v-if="Item.showAutoComplete">
            <ul class="autocomplete-list">
                <li  v-for="autocompleteItem in Item.autocompleteList" :key="autocompleteItem.Key">
                    <button  @click="setAutocompleteValue(autocompleteItem.Key, autocompleteItem.Name)">{{ autocompleteItem.Name }}</button>
                </li>
            </ul>
        </div> -->
    </div>

</template>
<script>

export default {
    components: {

    },
    created() {
    },
    methods:{
        checkEdited: function(){
            this.$emit("checkEdited", true);
        },
        checkAutocomplete: function(){
            console.log("checkAutocomplete", this.value);
            if(this.value.length >= 2 && this.value != this.clickedValue){
               this.$emit("checkAutocomplete", this.value);     
            }else{
                this.Item.autocompleteList = [];
            }
        },
        setAutocompleteValue: function(key, name){
            this.value = name;
            this.Item.autocompleteList = [];
            this.clickedValue = name;
            var object = {
                "Key": key,
                "Name": name
            }
            this.$emit("setAutocompleteValue", object);
        },
    },
    name: "autocomplete-component",
    props: {
        Item:{
            default() {
                return {};
            },
        },
        showAutoComplete: {
            default(){
                return false;
            }
        }
    },
    data() {
        return {
            keyword: "",
            autocompleteList: [],
            showAddNewCountry: false,
            Message: "",
            value: "",
            clickedValue: "",     
        };
    },
    computed: {

    }
};
</script>
<style>
    .formInput{
        font-weight: 500;
        font-size: 18px !important;
    }
    .autocompletediv {
      z-index: 10;
      position: absolute;
    }
    .autocomplete-list {
        list-style: none;
        padding:unset;
        margin: unset;
        /* margin-top: -20px */
    }
    .autocomplete-list button{
        border: unset;
        background: unset;
        cursor: pointer;
    }
    .autocomplete-list li{
        border:1px solid #e9e9e9;
        border-top:unset;
    }
    .add-button{
        border: unset;
        background: unset;
        cursor: pointer;
        margin-top: 10px;
    }
</style>