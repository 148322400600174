<template>
    <md-card class="md-card-nav-tabs" md-with-hover>
        <md-card-media>
          <img :src="image?image:defaultImage" alt="product.Name">
        </md-card-media>
        <md-card-header>
          <div>{{product.Name}}</div>
          <div class="md-subhead"></div>
        </md-card-header>
        <md-card-content>
          <slot name="content">Producer: {{product.NameOfProducer}}</slot>
          <br>
          <slot name="content">Year: {{product.YearOfProduction}}</slot>
          <br>
          <slot name="content">Price: $ {{product.UnitPrice}} HKD</slot>
        </md-card-content>
    </md-card>
</template>

<script>
export default {
  name: "query-product-card",
  props:[
    'product'
  ],
  data:()=>({

    loading:false,
    image:null,
    defaultImage:require("@/assets/img/we_sip_logo.png")
  }),
  methods:{
  },
  created(){

  },
  
};
</script>
<style lang="scss" scoped>
  .md-card .md-title{
    font-size: 18px;
    line-height: 26px;
  }
  .md-card .md-card-header{
    padding: 0;
    margin: 0;
    background-color: white;
    border: none;
    box-shadow: none;
    text-align: center;
  }
</style>
