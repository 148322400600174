<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <FormHeader
        :HeaderTitle="HeaderTitle"
        :confirmBack="confirmBack"
        >
      </FormHeader>
      <center>
        <md-card style="width:50%;min-width: 500px">
          <md-card-content >
              <FormComponent
              v-on:formSubmit="formSubmit"
              v-on:checkEdited="checkEdited"
              :FormTitle="FormTitle"
              :FormItem="FormItem"
              :formMessage="formMessage"
              :showFormMessage="showFormMessage"
              >
              </FormComponent>
          </md-card-content>
        </md-card>
      </center>
      </div>
    </div>
    <Spinner v-if="isLoading"/> 
  </div>
</template>

<script>

import { 
  FormComponent,
  FormHeader,
  Spinner
 } from "@/components";
export default {
  methods:{
    checkEdited: async function(check){
      //console.log("checkEdited");
      this.confirmBack = check
      if(this.FormItem.row2[0].value=="Event"){ //hide Free Shipping while type is Gathering
        this.$set(this.FormItem.row2[1].option[2],'hide',true)
        if(this.FormItem.row2[1].value==this.FormItem.row2[1].option[2].key){
          this.$set(this.FormItem.row2[1],'value',null)
        }

        //minimum headcount
        this.$set(this.FormItem.row4[2],'hide',false)
        if(this.FormItem.row4[2].value<=0)
          this.$set(this.FormItem.row4[2],'value',null)
      }
      else{
        this.$set(this.FormItem.row2[1].option[2],'hide',false)

        //minimum headcount
        this.$set(this.FormItem.row4[2],'hide',true)
        this.$set(this.FormItem.row4[2],'value',0)
      }
      if(this.FormItem.row2[0].value=="Product"){ //hide Free Headcount while type is Product
        this.$set(this.FormItem.row2[1].option[3],'hide',true)
        if(this.FormItem.row2[1].value==this.FormItem.row2[1].option[3].key){
          this.$set(this.FormItem.row2[1],'value',null)
        }
      }
      else{
        this.$set(this.FormItem.row2[1].option[3],'hide',false)
      }
      if(this.FormItem.row2[1].value=="FreeHeadcount"){ //Free Headcount, hide amount
        this.$set(this.FormItem.row3[0],'hide',true)
        this.$set(this.FormItem.row3[0],'value',0)
        this.$set(this.FormItem.row3[1],'hide',false)
        if(this.FormItem.row3[1].value<=0)
          this.$set(this.FormItem.row3[1],'value',null)
        
        
      }
      else{ //hide free headcount
        this.$set(this.FormItem.row3[0],'hide',false)
        if(this.FormItem.row3[0].value<=0)
          this.$set(this.FormItem.row3[0],'value',null)
        this.$set(this.FormItem.row3[1],'hide',true)
        this.$set(this.FormItem.row3[1],'value',0)
        
      }
      //console.log(this.FormItem)
    },
    formSubmit: async function(object){
      this.isLoading = true
      try{
          console.log(object);
          const ret= await this.$store.dispatch('saveCoupon',{router:this.router,coupon:object})
          
          this.isLoading = false
          this.$router.push({name: 'Coupon List', params:{message:"New Coupon added"}})
        }
        catch(err){
          this.isLoading = false
          console.log("createCoupon error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
    },

  },
  mounted(){
    //console.log("test")
    console.log(this.$route.params)
    var params=this.$route.params
    var tempObj=Object.assign(this.FormItem)
    tempObj.row1[0].value=params["CouponID"]
    tempObj.row2[0].value=params["CouponType"]
    tempObj.row2[1].value=params["DiscountType"]
    tempObj.row3[0].value=params["Amount"]
    tempObj.row3[1].value=params["UseLimit"]
    tempObj.row4[0].value=params["AmountUpperLimit"]
    tempObj.row4[1].value=params["MinimumAmountLimit"]
    tempObj.row5[0].value=params["StartDate"]
    tempObj.row5[1].value=params["EndDate"]
    tempObj.row6[0].value=params["Description"]
    //if(params["UserGroup"].length>0)
    tempObj.row7[0].value=params["UserGroup"]
    //console.log(tempObj.row7[0])
    tempObj.row7[1].value=params["Status"]
    
    
    this.FormItem=Object.assign(tempObj)
  },
  components: {
    FormComponent,
    FormHeader,
    Spinner
  },
  data() {
    return {
      isLoading: false,
      HeaderTitle: "Add Coupon",
      FormTitle: "New Coupon",
      formMessage:  null,
      showFormMessage: false,
      confirmBack:false,
      FormItem: {
        "row1":[
          {
            "type": "text",
            "key": "CouponID",
            "name": "Coupon Code",
            "required": true,
            "value": null,
          },
        ],
        "row2":[
          {
            "type": "select",
            "key": "CouponType",
            "name": "Coupon Type",
            "required": true,
            "value": null,
            "option": [
              {
                "key": "Product",
                "name": "Product",
              },
              {
                "key": "Event",
                "name": "Event",
              }
            ]
          },
          {
            "type": "select",
            "key": "DiscountType",
            "name": "Discount Type",
            "required": true,
            "value": null,
            "option": [
              {
                "key": "Amount",
                "name": "Amount",
              },
              {
                "key": "Percentage",
                "name": "Percentage",
              },
              {
                "key": "FreeShipping",
                "name": "Free Shipping",
              },
              {
                "key": "FreeHeadcount",
                "name": "Free Headcount",
              }
              /*{
                "key": "FreeItem",
                "name": "Free Item",
              },*/
            ]
          },
        ],
        "row3":[
          {
            "type": "number",
            "key": "Amount",
            "name": "Amount",
            "required": true,
            "value": null,
          },
          {
            "type": "number",
            "key": "FreeHeadcount",
            "name": "Free Headcount",
            "required": true,
            "value": null,
            "hide":true
          },
          {
            "type": "number",
            "key": "UseLimit",
            "name": "Use Limit",
            "required": false,
            "value": null,
          },
        ],
        "row4":[
          {
            "type": "number",
            "key": "AmountUpperLimit",
            "name": "Amount Discount Upper Limit",
            "required": true,
            "value": null,
          },
          {
            "type": "number",
            "key": "MinimumAmountLimit",
            "name": "Minimum Amount Required",
            "required": true,
            "value": null,
          },
          {
            "type": "number",
            "key": "MinimumHeadcount",
            "name": "Minimum Headcount",
            "required": true,
            "value": null,
            "hide":true
          },
        ],
        "row5":[
          {
            "type": "date",
            "key": "StartDate",
            "name": "Start Date",
            "required": true,
            "value": null,
          },
          {
            "type": "date",
            "key": "EndDate",
            "name": "End Date",
            "required": true,
            "value": null,
          },
          {
            "type": "number",
            "key": "ValidationDays",
            "name": "Coupon Validation Days",
            "required": true,
            "value": null,
          },
        ],
        "row6":[
          {
            "type": "text",
            "key": "Description",
            "name": "Description",
            "required": true,
            "style": "width:100%",
            "value": null,
          },
        ],
        "row7":[
          {
            "type": "array",
            "key": "UserGroup",
            "name": "Customer Group",
            "required": false,
            "value": [],
          },
          {
            "type": "select",
            "key": "Status",
            "name": "Status",
            "required": true,
            "value": null,
            "option": [
              {
                "key": "Published",
                "name": "Published",
              },
              {
                "key": "Draft",
                "name": "Draft",
              }
            ]
          },
        ],
      }
    }
  },

};
</script>
