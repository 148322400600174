<template>
    <div>
        <md-card class="fiveSensesBox">
            <md-card-content>
                <div style="padding: 0px 20px;">
                    <label>甜度*</label><br>
                    <label>低</label><br>
                    <label>1</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>2</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>3</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>4</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>5</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>高</label>
                </div>
            </md-card-content>
        </md-card>
        <md-card class="fiveSensesBox">
            <md-card-content>
                <div style="padding: 0px 20px;">
                    <label>酸度*</label><br>
                    <label>低</label><br>
                    <label>1</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>2</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>3</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>4</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>5</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>高</label>
                </div>
            </md-card-content>
        </md-card>
        <md-card class="fiveSensesBox">
            <md-card-content>
                <div style="padding: 0px 20px;">
                    <label>苦度*</label><br>
                    <label>低</label><br>
                    <label>1</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>2</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>3</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>4</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>5</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>高</label>
                </div>
            </md-card-content>
        </md-card>
        <md-card class="fiveSensesBox">
            <md-card-content>
                <div style="padding: 0px 20px;">
                    <label>咸度*</label><br>
                    <label>低</label><br>
                    <label>1</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>2</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>3</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>4</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>5</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>高</label>
                </div>
            </md-card-content>
        </md-card>
        <md-card class="fiveSensesBox">
            <md-card-content>
                <div style="padding: 0px 20px;">
                    <label>鮮度*</label><br>
                    <label>低</label><br>
                    <label>1</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>2</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>3</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>4</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>5</label> <input type="radio" class="sortradio" readonly/><br>
                    <label>高</label>
                </div>
            </md-card-content>
        </md-card>

    </div>
</template>
<script>
export default {
    components: {
    },
    methods:{

    },
    props: {
    },
    data() {
        return {
            
        };
    },
    computed: {

    }
};
</script>
<style>
    .fiveSensesBox{
        
    }
    .fiveSensesBox label{
        font-size: 18px;
        font-weight: bold;
    }
</style>