import oneSipApiCall from "../apiCall.js"

const table={
  getCountry({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-get-country', data)
  },
  getProductType({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-get-product-type', data)
  },
  getLanguage({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-get-language', data)
  },
  searchAroma({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-search-aroma', data)
  },
  createAroma({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-create-aroma', data)
  },
  searchTasteNotes({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-search-tastenote', data)
  },
  searchCountry({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-search-country', data)
  },
  searchParingTag({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-search-paring-tag', data)
  },
  createCountry({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-create-country', data)
  },
  createParingTag({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-create-paring-tag', data)
  },
  createTasteNote({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-create-tastenote', data)
  },
  

  
}

export default table;