<template>
    <div>

    </div>
</template>

<script>

export default {
    name: "Logout",
    methods:{
        async logout(){
            console.log("logout");
            await this.$store.dispatch('logout',{router:this.router})
            this.$router.replace({name: 'login'})
        }
    },
    mounted(){
        this.logout()
    }
}
</script>