<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <TableHeader
          :HeaderTitle="HeaderTitle"
          :AddNewButton="AddNewButton"
          :backButton=true
          v-on:createNewItem="createNewItem"
        ></TableHeader>
        <md-card>
            <md-card-content>
              <TableComponent 
              v-on:selectItem="selectItem"
              v-on:selectAllItem="selectAllItem"
              v-on:searchItem="searchItem"
              v-on:onclickItem="onclickItem"
              v-on:selectSortOption="selectSortOption"
              v-on:selectedItemActionFunction="selectedItemActionFunction"
              v-on:goPage="goPage"
              :TableList="TableItemList"
              :selectedItemCounter="selectedItemCounter"
              :selectedItem="selectedItem"
              :columns="columns"
              :selectAll="selectAll"
              :selectable="selectable"
              :haveSearchFunction="haveSearchFunction"
              :sortOption="sortOption"
              :selectedItemAction="selectedItemAction"
              :pageNumber="pageNumber"
              :showNextButton="showNextButton"
              ></TableComponent>
            </md-card-content>
          </md-card>
          <!-- :AddNewItem="AddNewItem" -->
        </div>
        <Transition name="modal" v-if="showPopup">
            <Popup
              v-on:popupcancel="popupcancel"
              v-on:popupaction="popupaction"
              :PopupTitle="PopupTitle"
              :PopupContent="PopupContent"
              :actionButton="actionButton"
            ></Popup>
        </Transition>
      </div>
      <hr> 
      <button class="enableButton" @click="confirmDeleteUserGroup()">Delete user Group</button>
      <Spinner v-if="isLoading"/> 
      <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
      
    </div>
  </template>
  
  <script>
  import { 
    TableComponent,
    TableHeader,
    Popup,
    Spinner
  } from "@/components";
  
  export default {
    components: {
      TableComponent,
      TableHeader,
      Popup,
      Spinner
    },
    data() {
      return {
        isLoading: false,
        message: "",
        showPopup: false,
        popupAction: "",
        PopupTitle: "",
        PopupContent: "",
        actionButton: "",
        index: 0,
        pageNumber: 1,
        showMessage: false,
        showNextButton: true,
        displaySize: 10,
        HeaderTitle: "",
        TableItemList: [],
        AllItemList: [],
        AddNewButton:  "Add Customer to Group",
        columns: ["Customer Name", "Username", "Phone", "Email"],
        sortOption:[],
        selectedItemAction: [
          {Key: "removeUser", Name: "Remove User", Style:"color:#CC2727"}
        ],
        selectedItem: [],
        selectedItemCounter: 0,
        selectAll: false,
        selectable: true,
        haveSearchFunction: true,
        SearchResultList: [],
      }
    },
    async created() {
        this.isLoading = true;
        this.HeaderTitle = this.$route.params.customerGroupID
        this.message = this.$route.params.message;
        if(this.$route.params.page){
          if(Number(this.$route.params.page) > 0){
            this.pageNumber = Number(this.$route.params.page)
          }
          this.index = this.index + (Number(this.$route.params.page) - 1 )*this.displaySize
        }
        this.checkShowMessage();
        await this.listItem();
        this.isLoading = false;
      },
    methods:{
      confirmDeleteUserGroup: function(){ 
        this.PopupTitle = "Confirm delete"
        this.PopupContent = "The Customer group data will no longer be saved and will not be able to recover the data."
        this.actionButton = "Delete anyway"
        this.popupAction = "deleteUserGroup"
        this.showPopup=true;
      },
      goPage:function(action){
        if(action == "next"){
          this.index = this.index + this.displaySize
          this.pageNumber = this.pageNumber + 1
        }else if(action == "prev" && this.index > 0){
          this.index = this.index - this.displaySize
          this.pageNumber = this.pageNumber - 1
        }else{
          this.index = 0
          this.pageNumber = 1
        }
        this.setDisplayList(this.AllItemList)
      },
      checkShowMessage: async function(){
        if(this.message){
          try{
            await this.delayFunction();
            this.showMessage = true;
          }catch(err){
            console.log(err)
          }
        }
      },
      delayFunction:async function (){
          setTimeout(function(){
            this.showMessage = false;
            this.message = "";
            return true;
          }, 1000);
      },
      selectedItemActionFunction:function(action){
        console.log("selectedItemActionFunction", action);
        if(action == "removeUser"){
          this.confirmDeleteUserInUserGroup();
        }
      },
      confirmDeleteUserInUserGroup: function(){ 
        this.PopupTitle = "Confirm Remove User"
        this.PopupContent = "The Customer will be remove from " + this.HeaderTitle + ", It will not be able to recover the data."
        this.actionButton = "Remove anyway"
        this.popupAction = "RemoveUserInUserGroup"
        this.showPopup=true;
      },
      onclickItem: function(ID){
        console.log("onclickItem", ID);
      },
      createNewItem: function(){
        var currentItem = [];
        for(var i = 0; i<this.TableItemList.length; i++){
          currentItem.push(this.TableItemList[i].ID)
        }
        console.log(currentItem);
        this.$router.push({name: 'Add Customer To Customer Group', params:{customerGroupID: this.HeaderTitle}})
      },
      popupcancel: function(){
        console.log("popupcancel")
          this.showPopup = false;
        },
      popupaction: function (){
        console.log("popupaction")
        this.showPopup = false;
        if(this.popupAction == "RemoveUserInUserGroup"){
          console.log("RemoveUserInUserGroup")
          this.removeUserFromUserGroup();
        }else if(this.popupAction == "deleteUserGroup"){
          this.deleteUserGroup();
        }
      }, 
      deleteUserGroup: async function (){
        var object = {
          UserGroupID: this.HeaderTitle
        }
        console.log("deleteUserGroup", object)
        try{
          const ret= await this.$store.dispatch('deleteUserGroup',{router:this.router,data:object})
          if(ret.data.result){
            var message = this.HeaderTitle + " has been deleted"
              setTimeout(()=>{
                console.log("go to CustomerGroup List");
                this.$router.replace({ name: "CustomerGroup List", params: {message: message} })
              }, 2000);
          }else{
            this.message = ret.data.message
            this.showMessage = true;
          }

        }catch(err){
          console.log("deleteUserGroup error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      removeUserFromUserGroup: async function(){
        var object = {
          UserGroupID: this.HeaderTitle,
          UserList: this.selectedItem
        }
        try{
          const ret= await this.$store.dispatch('removeUserFromUserGroup',{router:this.router,data:object})
          this.message = this.selectedItem.length + " uses has been Remove"
          this.showMessage = true;
          this.selectedItem = []
          this.selectedItemCounter = this.selectedItem.length;
          
          setTimeout(this.listItem, 1000);
        }catch(err){
          console.log("removeUserFromUserGroup error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      searchItem: async function(keyword){
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;

        this.index = 0;
        this.pageNumber = 1;
        console.log("search " + keyword)
        if(keyword){
          console.log("do")
          const resultList = this.AllItemList.filter(obj => Object.values(obj).some(val => val.toString().includes(keyword)));
          this.setDisplayList(resultList)

        }else{
          this.SearchResultList = [];
          this.setDisplayList(this.AllItemList)
          console.log("reset")
        }
      },
      selectSortOption: function(option){
        console.log("charge sorting with " + option);
      },
      selectAllItem:function(action){
        this.selectAll = false;
        if(action){
          this.selectAll = true;
          for(var i = 0; i< this.TableItemList.length; i++){
            var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
            if(index < 0){
                this.selectedItem.push(this.TableItemList[i].ID)
            }
          }
        }else{
            for(var i = 0; i< this.TableItemList.length; i++){
                var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
                this.selectedItem.splice(index, 1);
            }
        }
        this.selectedItemCounter = this.selectedItem.length
      },
      selectItem:function(ID, action){
        if(action){
          this.selectedItem.push(ID)
        }else{
          this.selectAll = false;
          var index = this.selectedItem.indexOf(ID)
          this.selectedItem.splice(index, 1);
        }
        this.selectedItemCounter = this.selectedItem.length
      },
      listItem: async function(){
        var object = {
          "UserGroupID": this.HeaderTitle,
          "Size": 10000,
        }
        console.log(object);
        this.isLoading = true;
        try{
          const ret= await this.$store.dispatch('listUserInUserGroup',{router:this.router,data:object})
          
          this.getUserList(ret.data.userList)
          this.isLoading = false;
        }catch(err){
          this.isLoading = false;
          console.log("listUserInUserGroup error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      getUserList: async function(list){
        if(list.length > 0){
          var  UserList = [];
          for(var i = 0; i<list.length; i++){
            UserList.push(list[i].UserID)
          }
          var object = {
            UserList: UserList
          }
          console.log(object);
          
          try{
            const ret= await this.$store.dispatch('getUserList',{router:this.router,data:object})
            console.log(ret);
            this.AllItemList = ret.data.UserList
            this.setDisplayList(this.AllItemList)
          }catch(err){
            console.log(err.message)
          }
        }else{
          this.AllItemList = [];
          this.setDisplayList(this.AllItemList)
        }
      },
      setDisplayList(list){
        var newlist=[];
        var additionalIndex = (this.pageNumber-1) * this.displaySize
        var loopSize = this.displaySize;
        if(list.length < this.pageNumber*this.displaySize){
          loopSize = list.length - additionalIndex
        }
        for(var i = 0; i<loopSize; i++){
          var itemIndex = i+additionalIndex
          var object = {
              "ID": list[itemIndex].UserID,
              "Customer Name": list[itemIndex].GivenName+' '+list[i].FamilyName,
              "Username": list[itemIndex].Name,
              "Phone": list[itemIndex].Phone,
              "Email": list[itemIndex].Email,
            }
            newlist.push(object)
        }
        this.TableItemList = newlist;
        this.TableSelectUIControl(this.TableItemList)
      },
      TableSelectUIControl(ItemList){
        this.showNextButton = true;
        if(ItemList.length < this.displaySize){
          this.showNextButton = false;
        }

        var counter = 0;
        for(var i=0; i<ItemList.length;i++){
            var index = this.selectedItem.indexOf(ItemList[i].ID);
            if(index > -1){
                counter++
            }
        }
        if(counter ==  ItemList.length){
            this.selectAll = true;
        }else{
            this.selectAll = false;
        }
      }
    }
  };
  </script>
  