import oneSipApiCall from "../apiCall.js"

const product={
  getProduct({commit,state},{router,product}){
    return oneSipApiCall(commit,state,router,'/admin-get-product',product)
  },
  queryProduct({commit,state},{router}){
    return oneSipApiCall(commit,state,router,'/admin-query-product')
  },
  createProduct({commit,state},{router,product}){
    //return oneSipApiCall(commit,state,router,'/test-create-product',product)
    return oneSipApiCall(commit,state,router,'/admin-create-product',product)
  },

  createProductVariance({commit,state},{router,variance}){
    return oneSipApiCall(commit,state,router,'/admin-create-product-variance',variance)
  },
  deleteProductVariance({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-delete-product-variance',data)
  },
  updateProduct({commit,state},{router,product}){
    return oneSipApiCall(commit,state,router,'/admin-update-product',product)
  },
  deleteProduct({commit,state},{router,product}){
    return oneSipApiCall(commit,state,router,'/admin-delete-product',product)
  },
  searchProduct({commit,state},{router,keyword}){
    return oneSipApiCall(commit,state,router,'/admin-search-product',keyword)
  },
  deleteProductImage({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-delete-product-image',data)
  },
  getVariance({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-get-variance',data)
  },
  getProductVariance({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-get-product-variance',data)
  },
  updateProductVariance({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-update-variance',data)
  },
  deleteVarianceImage({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-delete-variance-image',data)
  },
  listAllProductID({commit,state},{router}){
    return oneSipApiCall(commit,state,router,'/admin-list-productID')
  },
  createTasteCard({commit,state},{router,tasteCard}){
    return oneSipApiCall(commit,state,router,'/admin-create-tastecard',tasteCard)
  },
  getProductTasteCard({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/admin-get-product-tastecard', data)
  },
  updateTasteCard({commit,state},{router,tasteCard}){
    return oneSipApiCall(commit,state,router,'/admin-update-tastecard', tasteCard)
  },

  
  
}

export default product;