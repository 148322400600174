<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
          ></FormHeader>
          <div class="row">
            <div style="width:60%"> 
                <md-card style="z-index: 0;">
                    <md-card-content>           
                        <NestedDrapDrop
                        :allowAddBelow=false
                        :nextLayers="HomeBanner"
                        :sending="sending"
                        v-on:removeImage="removeImage"
                        v-on:remove="confirmRemoveItem"
                        v-on:setNewLangId="setNewLangId"
                        v-on:showDelLangPopup="showDelLangPopup"
                        v-on:setAddDialogTarget="setAddDialogTarget"
                        v-on:confirmUploadFile="confirmUploadFile"
                       
                        />
                    </md-card-content>
                </md-card>
            </div>
            <div style="width:35%;padding-left: 10px;">
              <md-card>
                <md-card-content>
                  <NestedRawDisplayer
                  :value="HomeBanner"
                  title="HomeBanner"
                />
                </md-card-content>
              </md-card>

            </div>
          </div> 
          <md-button
            class="md-fab md-primary md-fab-bottom-right"
            style="position: fixed !important; bottom: 15%"
            @click="setAddDialog()"
          >
            <md-icon>add</md-icon>
          </md-button>  
        </div>
      </div>
      <Transition name="modal" v-if="showDeleteLangModal">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="delLangPopupAction"
            :PopupTitle="delLangPopupTitle"
            :PopupContent="delLangPopupContent"
            :actionButton="delLangActionButton"
          ></Popup>
      </Transition>
      <Transition name="modal" v-if="showRemoveItemPopUp">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="removeItemPopupAction"
            :PopupTitle="removeItemPopupTitle"
            :PopupContent="removeItemPopupContent"
            :actionButton="removeItemActionButton"
          ></Popup>
      </Transition>
      <Transition name="modal" v-if="showRemoveImagePopUp">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="removeImagePopupAction"
            :PopupTitle="removeImagePopupTitle"
            :PopupContent="removeImagePopupContent"
            :actionButton="removeImageActionButton"
          ></Popup>
      </Transition>
      <Transition name="modal" v-if="showNewLangModal">
          <AddDataPopup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="newLangPopupAction"
            :PopupTitle="newLangPopupTitle"
            :PopupContent="newLangContent"
            :actionButton="newLangActionButton"
          ></AddDataPopup>
      </Transition>
      <Transition name="modal" v-if="showAddDialog">
          <AddDataPopup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="addDialogAction"
            :PopupTitle="addDialogPopupTitle"
            :PopupContent="formItem"
            :actionButton="addDialogActionButton"
          ></AddDataPopup>
      </Transition>
      <Spinner v-if="isLoading"/> 
      <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
    </div>
  </template>
  
  <script>
  
  import { 
    FormHeader,
    NestedDrapDrop,
    NestedRawDisplayer,
    Popup,
    AddDataPopup,
    Spinner
   } from "@/components";

  
  export default {
    components: {
      FormHeader,
      NestedDrapDrop,
      NestedRawDisplayer,
      Popup,
      AddDataPopup,
      Spinner
    },
    created() {
      this.getInformation("Home Banner");
    },
    mounted(){
    },
    methods:{
      /***  Page Function  ***/
      getInformation: async function(InformationID){
          this.isLoading = true;
          try{
            const ret = await this.$store.dispatch('getInformation',{router:this.router,data:{id: InformationID}})
            console.log("getInformation", ret)
            this.HomeBanner = ret.data.Information.content;
            this.isLoading = false;
          }catch(err){
            this.isLoading = false;
            console.log("getInformation error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
          }
      },
      formAction: async function(){
        this.sending = true;
        var object = {
          id: "Home Banner",
          content: this.HomeBanner
        }
        console.log("formAction", object);
        this.isLoading = true;
        try{
          const ret = await this.$store.dispatch('updateInformation',{router:this.router,data:object})
          console.log("updateInformation", ret)
          for(var i = 0; i<this.deleteImageList.length; i++){
            await this.deleteImage(this.deleteImageList[i]);
          }
          this.sending = false;
          setTimeout(()=>{
            this.isLoading = false;
              this.$router.replace({name: 'Content Management', params:{message:"Home Banner has been updated"}});
          }, 2000);
        }catch(err){
          this.isLoading = false;
          this.sending = false;
          console.log("updateInformation error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      findNavInNestedMenu: function (arr, id) {
        for (const nav of arr) {
          if (nav["ID"] == id) {
            return nav;
          } else if (nav["NextLayer"] && nav["NextLayer"].length > 0) {
            const ret = this.findNavInNestedMenu(nav["NextLayer"], id);
            if (ret) {
              return ret;
            }
          }
        }
        return null;
      },
      deleteNavInNestedMenu: function (arr, id) {
        for (const [i, nav] of arr.entries()) {
          if (nav["ID"] == id) {
            arr.splice(i, 1);
            return true;
          } else if (nav["NextLayer"] && nav["NextLayer"].length > 0) {
            const ret = this.deleteNavInNestedMenu(nav["NextLayer"], id);
            if (ret) {
              return ret;
            }
          }
        }
        return false;
      },
      setDatatoForm: function(data){
        console.log("setDatatoForm", data);
        for (const [key, value] of Object.entries(this.NewInSectionTitle)) {
            for(var i = 0 ;i<value.length; i++){
                value[i]["value"] = data[value[i].key];  
            }
        }
      }, 
      resetAddDialogPopupContent: function(){
        for (const [key, value] of Object.entries(this.addDialogPopupContent)) {
          console.log(value)
          for(var i = 0 ;i<value.length; i++){
            if(value[i]["key"] == "Title"){
              value[i]["value"] = {
                "en": "",
                "zh": "",
              }
            }else if(value[i]["key"] == "TitleColour"){
              value[i]["value"] = "#313131";
            }else{
              value[i]["value"] = null;
            }
          }
        }
        this.formItem = {};
        console.log("resetAddDialogPopupContent", this.addDialogPopupContent)
      },
      uploadFile: async function(fileData){
        console.log("uploadFile", fileData);
        try{
          const ret = await this.$store.dispatch('uploadHomeBannerImage',{router:this.router})
          console.log("uploadHomeBannerImage", ret);
          const retMedia = await this.$store.dispatch('updateMediaToS3',{file:fileData.MediaURLList[0].base64, urlObject:ret.data.urlObject})
          console.log("updateMediaToS3", retMedia)
          var newImageUrl = ret.data.urlObject.url.split("?")[0];
          return newImageUrl;
        }catch(err){
          console.log("uploadHomeBannerImage error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      getFormData: async function(form){
          var FormData = {}
          for(const [key, value] of Object.entries(form)){
            for(var i = 0; i<value.length; i++){
                if(value[i].required){
                  if(value[i].value){
                    if(value[i].type == 'number'){              
                      FormData[value[i].key] = parseInt(value[i].value)
                    }else{
                      FormData[value[i].key] = value[i].value
                    }
                  }else{
                    this.formMessage = value[i].name + " is required"
                    this.showFormMessage = true;
                    return
                  }
                }else{
                  if(value[i].type == 'number'){           
                    FormData[value[i].key] = parseInt(value[i].value)
                  }else{
                    FormData[value[i].key] = value[i].value
                  }    
                }
            }
          }
          return FormData
      },
      deleteImage: async function(url){
        var object = {
          Url: url,
        }
        try{
          const ret = await this.$store.dispatch('deleteHomeBannerImage',{router:this.router, data:object})
          console.log("removeImagePopupAction", ret);
        }catch(err){
          console.log("deleteHomeBannerImage error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      imageActionSave: async function () {
        this.sending = true;
        var object = {
          id: "Home Banner",
          content: this.HomeBanner
        }
        console.log("imageActionSave", object);
        try{
          const ret = await this.$store.dispatch('updateInformation',{router:this.router,data:object})
          console.log("updateInformation", ret)
          this.sending = false;
          setTimeout(()=>{
            this.formMessage = "Home Banner has been updated"
            this.showFormMessage = true
          }, 2000);
        }catch(err){
          this.sending = false;
          console.log("updateInformation error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },

      /*** End of Page Function  ***/
     

      /***  open Popup  ***/
      confirmUploadFile: async function(fileData){
        console.log("removeImage", fileData);
        this.removeImagePopupTitle = "Cover New Image";
        this.removeImagePopupContent = "Are you sure you want to change the image from "+ fileData.id;
        this.removeImagePopupData = fileData;
        this.showRemoveImagePopUp = true;

      },
      removeImage: function(Data){
        console.log("removeImage", Data);
        this.removeImagePopupTitle = "Remove Image";
        this.removeImagePopupContent = "Are you sure you want to remove the image from "+ Data.id;
        this.removeImagePopupData = Data;
        this.showRemoveImagePopUp = true;
      },
      setAddDialog:function(){
        this.formItem = this.addDialogPopupContent;
        this.newNavTarget=this.HomeBanner;
        this.showAddDialog=true;
      },
      showDelLangPopup:function(lang, id){
        console.log("showDelLangPopup", lang, id);
        this.delLang.lang = lang;
        this.delLang.id = id;
        this.delLangPopupTitle = "Delete Language from "+ id;
        this.delLangPopupContent = "Are you sure you want to delete "+ lang +" from "+ id;
        this.showDeleteLangModal = true;
      },
      setNewLangId: function (id) {
        console.log("setNewLangId", id);
        this.newLang = id;
        this.showNewLangModal = true;
        this.newLangPopupTitle = "Add Language to "+ id;
      },
      setAddDialogTarget:function(target){
        const nav=this.findNavInNestedMenu(this.HomeBanner,target)
        this.newNavTarget=nav;
        this.formItem = this.addDialogPopupContent;
        this.showAddDialog=true;
      },
      confirmRemoveItem: function (id){
        this.removeItemID = id;
        this.removeItemPopupTitle = "Delete Item "+ id;
        this.removeItemPopupContent = "Are you sure you want to delete "+ id;
        this.showRemoveItemPopUp = true;
      },
      /***  end of open Popup  ***/

      /***  Popup Action  ***/
      removeImagePopupAction: async function(){
        this.showRemoveImagePopUp = false;

        await this.deleteImage(this.removeImagePopupData.imageUrl);
        const newFileUrl = await this.uploadFile(this.removeImagePopupData);
        var nav = this.findNavInNestedMenu(this.HomeBanner, this.removeImagePopupData.id);
        nav.BackgroundImage = newFileUrl
        this.formMessage = this.removeImagePopupData.id +" New Image Upload Success"
        this.showFormMessage = true
        this.imageActionSave();
      },
      removeItemPopupAction: function(){
        this.showRemoveItemPopUp = false;
        const nav = this.findNavInNestedMenu(this.HomeBanner, this.removeItemID);
        console.log("removeItemPopupAction", nav);
        this.deleteImageList.push(nav.BackgroundImage);
        var ret = this.deleteNavInNestedMenu(this.HomeBanner, this.removeItemID);
        this.removeItemID = "";
      },
      addDialogAction: async function(data){
        this.showAddDialog = false;
        data.NextLayer = [];
        data.ID = new Date().getTime().toString();
        console.log("addDialogAction", data.BackgroundImage);
        var object = {
            "MediaURLList": data.BackgroundImage
        }
        const newFileUrl = await this.uploadFile(object);
        data.BackgroundImage = newFileUrl;
        if(Array.isArray(this.newNavTarget)){
          this.newNavTarget.push(data);
        }
        else{
          this.newNavTarget.NextLayer.push(data);
        }
        this.formMessage = data.ID +" New Image Upload Success"
        this.showFormMessage = true
        this.imageActionSave();
        this.resetAddDialogPopupContent();
        
      },
      newLangPopupAction: function(lang){
        this.showNewLangModal = false;
        const nav = this.findNavInNestedMenu(this.HomeBanner, this.newLang);
        this.$set(nav.Title, lang.lang, "");
        this.newLang = "";
      },
      delLangPopupAction: function(){
        this.showDeleteLangModal = false;
        const nav = this.findNavInNestedMenu(this.HomeBanner, this.delLang.id);
        this.$delete(nav.Title, this.delLang.lang);
        this.delLang = {
          "lang": "",
          "id": ""
        };
      },
      popupcancel: function(){
        this.showDeleteLangModal = false;
        this.showNewLangModal = false;
        this.showAddDialog = false;
        this.showRemoveItemPopUp = false;
        this.showRemoveImagePopUp = false;
      },
      /***  end of Popup Action  ***/
    },
    data() {
      return {
        isLoading: false,
        HeaderTitle: "Home Banner",
        formActionButton: "Save",
        formMessage:  null,
        showFormMessage: false,
        confirmBack:false,
        HtmlPart: "",
        HomeBanner: [],
        allowAddBelow: true,
        sending: false,
        showDeleteLangModal: false,
        showRemoveItemPopUp: false,
        showNewLangModal: false,
        showAddDialog: false,
        newNavTarget:null,
        delLang: {
          "lang": "",
          "id": ""
        },
        newLang: "",
        delLangPopupTitle: "",
        delLangPopupContent: "",
        delLangActionButton: "Delete",
        newLangPopupTitle: "",
        addDialogPopupTitle: "Add New Home Banner",
        newLangContent:{
          "row1":[
            {
              "type": "text",
              "key": "lang",
              "name": "New Language",
              "required": true,
              "style": "width:100%",
              "value": null,
            },
          ],
        },
        formItem: {},
        addDialogPopupContent:{
          "row1":[
            {
              "type": "object",
              "key": "Title",
              "name": "Title",
              "style": "width:100%",
              "value": {
                "en": "",
                "zh": "",
              },
            },
          ],
          "row2":[
            {
              "type": "file",
              "key": "BackgroundImage",
              "name": "BackgroundImage",
              "required": true,
              "value": null,
              "style": "width:100%",
              "accept": "image/*",
              "multiple": false
            },
          ],
        },
        newLangActionButton: "Add",
        addDialogActionButton: "Add",
        removeItemID: null,
        removeItemPopupTitle: "",
        removeItemPopupContent: "",
        removeItemActionButton: "Delete",
        showRemoveImagePopUp: false,
        removeImagePopupTitle: "",
        removeImagePopupContent: "",
        removeImageActionButton: "Change",
        removeImagePopupData: null,
        deleteImageList: [],
        
      }
    },
  
  };
  </script>