<template lang="html">
  <div class="product-card" v-if="!!product" :class="{ 'dark': darkTheme, 'with-wishlist': wishlist }">
    <div class="product-img"
      :style="{'background-image':`url(${ imageSrc })`}"
      @click="toDetailPage"
    >
      <div class="product-tag" v-if="tag">{{ tag }}</div>
    </div>
    <div class="info-con">
      <div class="title-row" v-if="name">{{ name }}</div>
      <div class="location-info" v-if="location.code && location.text">
          <!-- <div class="flag-con">
              <country-flag :country="location.code" />
          </div> -->
          <div class="flag-con">
              <CountryFlag :iso="location.code" mode="rounded"/>
          </div>
          <!-- <div class="">
            <country-flag :country="location.code" rounded="true" />
          </div> -->
          <div>
            {{ location.text }}
          </div>
      </div>
      <div class="price-row" v-if="price">{{ price }}</div>
    </div>

    <md-button
        v-if="wishlist"
        class="md-icon-button md-dense wishlist-button"
        :class="{ 'active': isInWishlist }"
        @click="toggleToWishlist">
        <md-icon>favorite</md-icon>
    </md-button>
  </div>
</template>

<script>
import CountryFlag from '@dzangolab/vue-country-flag-icon'
import '@dzangolab/vue-country-flag-icon/dist/CountryFlag.css' // import stylesheet

export default {
  name: "ProductCard",
  components: {
    CountryFlag,
  },
  props: {
    product: {
      type: Object,
      // required: true,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
    wishlist: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      // -- temp --
      // imageSrc: require("@/assets/images/v2/product_1.png"),
      // tag: "semi-sweet",
      // title: "菊花牌紅酒 2015",
      // location: {
      //   text: "Hong Kong, China",
      //   code: "HK",
      // },
      // price: "HKD 500",

      id: null,
      imageSrc: null,
      tag: null,
      name: null,
      location: {
        text: null,
        code: null,
      },
      price: null,


      isInWishlist: false,
    };
  },
  created(){
      this.id = this.product.ProductID;
      this.imageSrc = process.env.VUE_APP_S3_URL+"product/"+this.product.ProductID;
      this.name = this.product.Name;
      this.price = "HKD " + this.product.UnitPrice;
      this.quantity = 1;

      if( this.wishlist ){
        this.checkIsInWishlist();
      }
  },
  methods: {
    toDetailPage(){
      console.log("Detail");
    },

    async checkIsInWishlist(){
      // ...
      this.isInWishlist = false;
    },
    async toggleToWishlist(){

        if( !this.isInWishlist ){

            try {
              const result = await this.$store.dispatch( "addWishlist", this.id );

              if( !result || !result.result ){
                throw "Unknown error";
              }

              this.isInWishlist = true;

            } catch (err) {
              console.error(err);
            }

        }else {

            try {
              const result = await this.$store.dispatch( "delWishlist", this.id );

              if( !result || !result.result ){
                throw "Unknown error";
              }

              this.isInWishlist = false;

            } catch (err) {
              console.error(err);
            }

        }

    },
  },
}
</script>

<style lang="scss" scoped>

.product-card{
  color: #313131;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;

  position: relative;
}
.product-card:hover{
  background: rgba( #F4F4F4, 0.5 );
}
.product-card:focus,
.product-card:active{
  background: rgba( #F4F4F4, 0.8 );
}


.product-card.dark{
  color: #FFFFFF;
  background: rgba( #F4F4F4, 0.1 );
}
.product-card.dark:hover{
  background: rgba( #F4F4F4, 0.5 );
}
.product-card.dark:focus,
.product-card.dark:active{
  background: rgba( #F4F4F4, 0.8 );
}

.product-card.with-wishlist{
  padding-bottom: 30px;
}



.product-img{
  aspect-ratio: 0.825;

  background-position: center;
  background-size: cover;
}

.product-tag{
  background: #FFFFFF;
  color: #FC2864;

  font-size: 12px;
  font-weight: 500;

  float: right;
  padding: 0 5px;
  margin: 5px;
}

.info-con {
  margin-top: 1em;
}

.info-con > div {
  margin: 0.8em 0;
  font-size: 14px;
}

.info-con > .title-row {
  font-weight: 500;
}

.info-con > .location-info{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-size: .8em;
  padding-left: 2px;
}


.flag-con > .flag-icon {
  transform: scale(1.25);
  margin-right: 5px;
}

.info-con > .price-row {
  margin-bottom: 0;
}


.wishlist-button{
  background-color:transparent !important;
  box-shadow: none;
  position: absolute;
  bottom: 0;
  right: 3px;

  margin: 0;
  padding: 0;

  i {
    color: rgba( #F4F4F4, 0.1 ) !important;
  }

  &.active i {
    color: #FC2864!important;
  }
}
// .flag-con {
//   border-radius: 100%;
//   overflow: hidden;
//   width: fit-content;
//   margin-right: 6px;
//   aspect-ratio: 1;
//
//   display: flex;
//   justify-content: center;
//   align-items: center;
//
//   transform: scale(1.2);
// }
//
// .flag-con > span {
//   margin: -17px;
// }



</style>
