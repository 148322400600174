<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
          ></FormHeader>
          <div class="row">
            <div style="width:30%;padding-top:10px">
              <CodeEditor
                :code="HtmlPart"
                v-on:updateCode="updateHtmlPart"
              ></CodeEditor>
            </div>
            <div style="width:65%;padding-left: 30px;z-index: 0;">
              <HtmlPreview
              :HtmlPart="HtmlPart"
              @update="updateHtmlPart"
              ></HtmlPreview>
            </div>
          </div>   
        </div>
      </div>
      <Spinner v-if="isLoading"/> 
      <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
    </div>
  </template>
  
  <script>
  
  import { 
    FormHeader,
    CodeEditor,
    HtmlPreview,
    Spinner
   } from "@/components";
  

  
  export default {
    components: {
      CodeEditor,
      FormHeader,
      HtmlPreview,
      Spinner
    },
    created() {
      this.getInformation("Contact Us");
    },
    methods:{
        getInformation: async function(InformationID){
          this.isLoading = true
            try{
            const ret = await this.$store.dispatch('getInformation',{router:this.router,data:{id: InformationID}})
            console.log("getEmailTemplate", ret)
            this.HtmlPart = ret.data.Information.content;
            this.isLoading = false
            
            }catch(err){
              this.isLoading = false
              console.log("getInformation error", err.response.data);
              this.formMessage = err.response.data.message
              this.showFormMessage = true
            }
      },
      formAction: function(){
        console.log("HtmlPart", this.HtmlPart);
            const data = {
                content: this.HtmlPart
            }
            this.isLoading = true
            try{
                const ret = this.$store.dispatch('updateContactUs',{router:this.router,data:data})
                setTimeout(()=>{
                  this.isLoading = false
                    this.$router.replace({name: 'Content Management', params:{message:"Contact Us Info has been updated"}});
                }, 2000);
            }catch(err){
              this.isLoading = false
              console.log("updateContactUs error", err.response.data);
              this.formMessage = err.response.data.message
              this.showFormMessage = true
            }
        },
      updateHtmlPart(value){
        this.confirmBack = true;
        this.HtmlPart=value;
      }
    },
    watch:{
      HtmlPart(newValue){
        console.log("HtmlPart changed: "+newValue);
      }
    },
    data() {
      return {
        isLoading: false,
        HeaderTitle: "Contact Us",
        formActionButton: "Save",
        formMessage:  null,
        showFormMessage: false,
        confirmBack:false,
        HtmlPart: "",
      }
    },
  
  };
  </script>