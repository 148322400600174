import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";




//import Icons from "@/pages/Icons.vue";
//import Maps from "@/pages/Maps.vue";
//import Notifications from "@/pages/Notifications.vue";


import Login from "@/pages/Login.vue";
import MainNavbar from "@/pages/Layout/MainNavbar.vue";
import MainFooter from "@/pages/Layout/MainFooter.vue";


//new page
import CustomerList from "@/pages/Customer/CustomerList.vue";
import CreateCustomer from "@/pages/Customer/CreateCustomer.vue";
import CustomerProfile from "@/pages/Customer/CustomerProfile.vue"
import UpdateCustomer from "@/pages/Customer/UpdateCustomer.vue";

import CustomerGroupList from "@/pages/CustomerGroupList.vue"
import CustomerGroupDetail from "@/pages/CustomerGroupDetail.vue"
import CreateCustomerGroup from "@/pages/CreateCustomerGroup.vue"
import AddCustomerToCustomerGroup from "@/pages/AddCustomerToCustomerGroup.vue"

import ProductList from "@/pages/Product/ProductList.vue";
import CreateProduct from "@/pages/Product/CreateProduct.vue";
import UpdateProduct from "@/pages/Product/UpdateProduct.vue";
import CreateProductVariance from "@/pages/Product/CreateProductVariance.vue";
import UpdateVariance from "@/pages/Product/UpdateVariance.vue";
import UpdateTasteCard from "@/pages/Product/UpdateTasteCard.vue";

import CouponList from "@/pages/CouponList.vue";
import CreateCoupon from "@/pages/CreateCoupon.vue";

import LocationList from "@/pages/LocationList.vue";
import CreateLocation from "@/pages/CreateLocation";
import UpdateLocation from "@/pages/UpdateLocation";

import EventList from "@/pages/Event/EventList.vue";
import CreateEvent from "@/pages/Event/CreateEvent.vue";
// import EventDetails from "@/pages/Event/EventDetails.vue";
import UpdateEvent from "@/pages/Event/UpdateEvent.vue";
// import UpdateEventVariance from "@/pages/Event/UpdateEventVariance.vue";
import EventSurveySummary from "@/pages/Event/EventSurveySummary.vue";
import UpdateEventSurvey from "@/pages/Event/UpdateEventSurvey.vue";
import VarianceDetail from "@/pages/Event/VarianceDetail.vue";

import SurveyTemplateList from "@/pages/Survey/SurveyTemplateList.vue";
import CreateSurveyTemplate from "@/pages/Survey/CreateSurveyTemplate.vue";
import UpdateSurveyTemplate from "@/pages/Survey/UpdateSurveyTemplate.vue";
import SurveyResult from "@/pages/Survey/SurveyResult.vue";
import SurveyResultDetail from "@/pages/Survey/SurveyResultDetail.vue";

import PaymentManagement from "@/pages/Payment/PaymentManagement.vue"
import CreateProductOrder from "@/pages/Payment/CreateProductOrder.vue"
import OrderDetail from "@/pages/Payment/OrderDetail.vue"

import RefundRequestList from "@/pages/RefundRequestList.vue"
import RefundRequest from "@/pages/RefundRequest.vue"

import EmailTemplateList from "@/pages/EmailTemplateList.vue";
import CreateEmailTemplate from "@/pages/CreateEmailTemplate.vue";
import UpdateEmailTemplate from "@/pages/UpdateEmailTemplate.vue";

import InspirationList from "@/pages/Inspiration/InspirationList.vue"
import CreateInspiration from "@/pages/Inspiration/CreateInspiration.vue"
import UpdateInspiration from "@/pages/Inspiration/UpdateInspiration.vue"

import EmailEventList from "@/pages/EmailEventList.vue";
import CreateEmailEvent from "@/pages/CreateEmailEvent.vue";

import InventoryList from "@/pages/InventoryList.vue"
import CreateInventory from "@/pages/CreateInventory.vue"
import UpdateInventory from "@/pages/UpdateInventory.vue"

import ContentManagement from "@/pages/ContentManagement.vue"
import UpdateContactUsInformation from "@/pages/UpdateContactUsInformation.vue"
import UpdatePrivacyPolicyInformation from "@/pages/UpdatePrivacyPolicyInformation.vue"
import UpdateNewInSection from "@/pages/UpdateNewInSection.vue"
import UpdateAboutWeSip from "@/pages/UpdateAboutWeSip.vue"
import UpdateBigPromotionBanner from "@/pages/UpdateBigPromotionBanner.vue"
import UpdateSEO from "@/pages/UpdateSEO.vue"
import UpdateSmallPromotionBanner from "@/pages/UpdateSmallPromotionBanner.vue"
import UpdateHomeBanner from "@/pages/UpdateHomeBanner.vue"
import UpdateTermsAndConditions from "@/pages/UpdateTermsAndConditions.vue"
import UpdateNavMenu from "@/pages/UpdateNavMenu.vue"
import UpdateFooter from "@/pages/UpdateFooter.vue"
import UpdateSurveyLandingSection from "@/pages/UpdateSurveyLandingSection.vue"
import UpdateInspirationTitle from "@/pages/UpdateInspirationTitle.vue"
import UpdateNewsletterSubscription from "@/pages/UpdateNewsletterSubscription.vue"
import UpdateFAQ from "@/pages/UpdateFAQ.vue"


import QRCodeScanner from "@/pages/QRCodeScanner.vue";

import Logout from "@/pages/Logout.vue";



const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect:"/login",
    children: [
      //new page routes
      {
        path: "customer/",
        name: "Customer List",
        component: CustomerList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "logout",
        name: "Logout",
        component: Logout,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customer/:page",
        name: "Customer List PageNumber",
        component: CustomerList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/customer",
        name: "Create Customer",
        component: CreateCustomer,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customer/profile/:customer",
        name: "Customer Profile",
        component: CustomerProfile,
        meta: {
          requiresAuth: true
        },
        props:true
      },
      {
        path: "customer/profile/update/:customer",
        name: "Update Customer",
        component: UpdateCustomer,
        meta: {
          requiresAuth: true
        },
        props:true
      },
      {
        path: "customergroup/",
        name: "CustomerGroup List",
        component: CustomerGroupList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customergroup/:page",
        name: "CustomerGroup List PageNumber",
        component: CustomerGroupList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/customergroup",
        name: "Create Customer Group",
        component: CreateCustomerGroup,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customergroup/detail/:customerGroupID",
        name: "CustomerGroup Detail",
        component: CustomerGroupDetail,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customergroup/detail/:customerGroupID/:page",
        name: "CustomerGroup Detail PageNumber",
        component: CustomerGroupDetail,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customergroup/addCustomer/:customerGroupID",
        name: "Add Customer To Customer Group",
        component: AddCustomerToCustomerGroup,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customergroup/addCustomer/:customerGroupID/:page",
        name: "Add Customer To Customer Group PageNumber",
        component: AddCustomerToCustomerGroup,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "product",
        name: "Product List",
        component: ProductList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "product/:page",
        name: "Product List PageNumber",
        component: ProductList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/product",
        name: "Create Product",
        component: CreateProduct,
        meta: {
          requiresAuth: true
        }
      },
    
      {
        path: "create/product-variance/:ProductID",
        name: "Create Product Variance",
        component: CreateProductVariance,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/product/:ProductID",
        name: "Update Product",
        component: UpdateProduct,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "update/variance/:VarianceID",
        name: "Update Variance",
        component: UpdateVariance,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "update/tastecard/:ProductID",
        name: "Update Taste Card",
        component: UpdateTasteCard,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "coupon",
        name: "Coupon List",
        component: CouponList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "coupon/:page",
        name: "Coupon List PageNumber",
        component: CouponList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/coupon",
        name: "Create Coupon",
        component: CreateCoupon,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "location",
        name: "Location List",
        component: LocationList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "location/:page",
        name: "Location List PageNumber",
        component: LocationList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/location",
        name: "Create Location",
        component: CreateLocation,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/location/:locationID",
        name: "Update Location",
        component: UpdateLocation,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "eventlist",
        name: "Event List",
        component: EventList,
        meta: {
          requiresAuth: true
        }
      },


      {
        path: "eventlist/:page",
        name: "Event List PageNumber",
        component: EventList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/event",
        name: "Create Event",
        component: CreateEvent,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/:eventID/survey",
        name: "Event Survey Summary",
        component: EventSurveySummary,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/:eventID/survey/:surveyID",
        name: "Update Event Survey",
        component: UpdateEventSurvey,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/:eventID",
        name: "Update Event",
        component: UpdateEvent,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/:eventID/variance/:varianceID",
        name: "Variance Detail",
        component: VarianceDetail,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/:eventID/variance/:varianceID/survey",
        name: "Survey Result",
        component: SurveyResult,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/:eventID/variance/:varianceID/survey/:surveyID",
        name: "Survey Result Detail",
        component: SurveyResultDetail,
        meta:{
          requiresAuth: true
        }
      },
      // {
      //   path: "event/:id/variance/:varianceID",
      //   name: "Update Event Variance",
      //   component: UpdateEventVariance,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: "event/details/:id",
      //   name: "Event Details",
      //   component: EventDetails,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      {
        path: "surveytemplate",
        name: "Survey Template List",
        component: SurveyTemplateList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "surveytemplate/:page",
        name: "Survey Template List PageNumber",
        component: SurveyTemplateList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/surveytemplate",
        name: "Create Survey Template",
        component: CreateSurveyTemplate,
        meta:{
          requiresAuth: true
        }
      },
      {
        path: "update/surveytemplate/:TemplateID",
        name: "Update Survey Template",
        component: UpdateSurveyTemplate,
        meta:{
          requiresAuth: true
        }
      },
      {
        path: "payments",
        name: "Payments",
        component: PaymentManagement,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "payments/create/productorder",
        name: "Create Product Order",
        component: CreateProductOrder,
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'payments/orderdetail/:orderID',
        name:'Order Detail',
        component: OrderDetail,
        meta: {
          requiresAuth: true
        }
      },
      {
        
        path: "refundrequest",
        name: "Refund Request",
        component: RefundRequestList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "refundrequest/:RefundRequestID",
        name: "Refund Request Detail",
        component: RefundRequest,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "EmailTemplate",
        name: "Email Template",
        component: EmailTemplateList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "EmailTemplate/:page",
        name: "Email Template PageNumber",
        component: EmailTemplateList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/EmailTemplate",
        name: "Create EmailTemplate",
        component: CreateEmailTemplate,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/EmailTemplate/:templateName",
        name: "Update EmailTemplate",
        component: UpdateEmailTemplate,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "InspirationList",
        name: "Inspiration List",
        component: InspirationList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "InspirationList/:page",
        name: "Inspiration List PageNumber",
        component: InspirationList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "Inspiration/create",
        name: "Create Inspiration",
        component: CreateInspiration,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "Inspiration/update/:inspirationID",
        name: "Update Inspiration",
        component: UpdateInspiration,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "EmailEvent",
        name: "Email Event List",
        component: EmailEventList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "EmailEvent/:page",
        name: "Email Event List PageNumber",
        component: EmailEventList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/EmailEvent",
        name: "Create Email Event",
        component: CreateEmailEvent,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "Inventory",
        name: "Inventory List",
        component: InventoryList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "Inventory/:page",
        name: "Inventory List PageNumber",
        component: InventoryList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/Inventory/",
        name: "Create Inventory",
        component: CreateInventory,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/Inventory/:InventoryID",
        name: "Update Inventory",
        component: UpdateInventory,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement",
        name: "Content Management",
        component: ContentManagement,
        meta: {
          requiresAuth: true
        }
      },  
      {
        path: "ContentManagement/Contact Us",
        name: "Contact Us",
        component: UpdateContactUsInformation,
        meta: {
          requiresAuth: true
        }
      },  
      {
        path: "ContentManagement/Privacy Policy",
        name: "Privacy Policy",
        component: UpdatePrivacyPolicyInformation,
        meta: {
          requiresAuth: true
        }
      }, 
      {
        path: "ContentManagement/NewInSection",
        name: "New In Section",
        component: UpdateNewInSection,
        meta: {
          requiresAuth: true
        }
      }, 
      {
        path: "ContentManagement/About WeSip",
        name: "About WeSip",
        component: UpdateAboutWeSip,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/BigPromotionBanner",
        name: "Big Promotion Banner",
        component: UpdateBigPromotionBanner,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/SurveyLandingSection",
        name: "Survey Landing Section",
        component: UpdateSurveyLandingSection,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/SEO",
        name: "SEO",
        component: UpdateSEO,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/smallPromotionBanner",
        name: "Small Promotion Banner",
        component: UpdateSmallPromotionBanner,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/Home Banner",
        name: "Home Banner",
        component: UpdateHomeBanner,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/Terms & Conditions",
        name: "Terms & Conditions",
        component: UpdateTermsAndConditions,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/NavMenu",
        name: "Nav Menu",
        component: UpdateNavMenu,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/Footer",
        name: "Footer",
        component: UpdateFooter,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/InspirationTitle",
        name: "Inspiration Title",
        component: UpdateInspirationTitle,
        meta:{
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/NewsletterSubscription",
        name: "Newsletter Subscription",
        component: UpdateNewsletterSubscription,
        meta:{
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/FAQ",
        name: "FAQ",
        component: UpdateFAQ,
        meta:{
          requiresAuth: true
        }
      },
      {
        path: "qr-scanner",
        name: "QR Scanner",
        component: QRCodeScanner,
        meta: {
          requiresAuth: true
        }
      },


    
      //end of new page routes


      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: "icons",
      //   name: "Icons",
      //   component: Icons,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: "maps",
      //   name: "Maps",
      //   meta: {
      //     hideFooter: true
      //   },
      //   component: Maps,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: "notifications",
      //   name: "Notifications",
      //   component: Notifications,
      //   meta: {
      //     requiresAuth: true
      //   }
      // }
    ]
  },
  
  {
    path: "/login",
    name: "login",
    components: { default: Login, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 }
    }
  },
];

export default routes;
