<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <md-card>
            <md-card-content>
              <TableComponent 
              v-on:selectItem="selectItem"
              v-on:selectAllItem="selectAllItem"
              v-on:searchItem="searchItem"
              v-on:onclickItem="onclickItem"
              v-on:selectSortOption="selectSortOption"
              v-on:selectedItemActionFunction="selectedItemActionFunction"
              v-on:goPage="goPage"
              v-on:SortPopupControl="SortPopupControl"
              :TableList="TableItemList"
              :selectedItemCounter="selectedItemCounter"
              :selectedItem="selectedItem"
              :columns="columns"
              :selectAll="selectAll"
              :selectable="selectable"
              :haveSearchFunction="haveSearchFunction"
              :sortOption="sortOption"
              :selectedItemAction="selectedItemAction"
              :pageNumber="pageNumber"
              :showNextButton="showNextButton"
              :showSortPopup="showSortPopup"
              ></TableComponent>
            </md-card-content>
          </md-card>
          <!-- :AddNewItem="AddNewItem" -->
        </div>
      </div>
      
      
    </div>
  </template>
  
  <script>
  import { 
    TableComponent,
  } from "@/components";
  
  export default {
    name: "gathering-order-list",
    components: {
      TableComponent,
    },
    data() {
      return {
        showSortPopup: false,
        index: 0,
        pageNumber: 1,
        showNextButton: true,
        displaySize: 10,
        TableItemList: [],
        AddNewButton:  "",
        columns: ["GatheringID", "UserID", "PaymentMethod", "Price", "Status", "Created Date"],
        sortOption: [
          {Key: "CreatedDatetime", Name: "Created Date", Value: true, Order: "desc"},
          {Key: "Price", Name: "Price down", Value: false, Order: "desc"},
          {Key: "Price", Name: "Price up", Value: false, Order: "asc"},
        ],
        selectedItemAction: [
          //{Key: "delete", Name: "Delete selected", Style:"color:#CC2727"},
        ],
        selectedItem: [],
        selectedItemCounter: 0,
        selectAll: false,
        selectable: true,
        haveSearchFunction: true,
        filter: [],
      }
    },
    async created() {
        this.setLoading(true)
        this.setDefaultSort()
       //await this.listItem();
        this.setLoading(false)
      },
    methods:{
      setLoading(isLoading) {
        this.$emit("setLoading", isLoading);
      },
      setDefaultSort: function(){
          var index = this.sortOption.findIndex(option => option.Value === true)
          var object = {}
          object[this.sortOption[index].Key] = {"order" : this.sortOption[index].Order}
          this.Sort = [object]
        },
      SortPopupControl: function (){
          this.showSortPopup = !this.showSortPopup
        },
      goPage:function(action){
        if(action == "next"){
          this.index = this.index + this.displaySize
          this.pageNumber = this.pageNumber + 1
        }else if(action == "prev" && this.index > 0){
          this.index = this.index - this.displaySize
          this.pageNumber = this.pageNumber - 1
        }else{
          this.index = 0
          this.pageNumber = 1
        }
        //this.$router.replace({ name: "Coupon List PageNumber", params: {page:this.pageNumber} })
        this.listItem()
      },
      selectedItemActionFunction:function(action){
        console.log("selectedItemActionFunction", action);
      },
      onclickItem: function(ID){
        //this.$router.push({name: 'Update Product', params:{productID:ID}})
      },
      selectSortOption: function(option){
        console.log("charge sorting with " + option);
        var sortKey = "";
        var sortOrder = "";
          for(var i = 0; i<this.sortOption.length; i++){
            if(this.sortOption[i].Name == option){
              sortKey = this.sortOption[i].Key
              sortOrder = this.sortOption[i].Order
              this.sortOption[i].Value = true
            }else{
              this.sortOption[i].Value = false
            }
          }
          var object = {}
          object[sortKey] = {"order" : sortOrder}
          this.Sort = [object]
          this.showSortPopup = false;
          this.listItem();
      },
      searchItem: async function(keyword){
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;
  
        this.index = 0;
        this.pageNumber = 1;
        console.log("search " + keyword)
        var object = {
          "Size": this.displaySize,
          "From": this.index,
          "Keyword": keyword,
          "Sort": this.Sort
        }
        if(this.filter.length > 0){
            object["Filter"] = this.filter
          }
        try{
          const ret= await this.$store.dispatch('searchGatheringOrder',{router:this.router,keyword:object})
          this.setDisplayList(ret.data.data.hits.hits)
        }catch(err){
          console.log(err);
        }
      },
      selectAllItem:function(action){
          this.selectAll = false;
          if(action){
            this.selectAll = true;
            for(var i = 0; i< this.TableItemList.length; i++){
              var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
              if(index < 0){
                  this.selectedItem.push(this.TableItemList[i].ID)
              }
            }
          }else{
              for(var i = 0; i< this.TableItemList.length; i++){
                  var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
                  this.selectedItem.splice(index, 1);
              }
          }
          console.log("after", this.selectedItem);
          this.selectedItemCounter = this.selectedItem.length
        },
      selectItem:function(ID, action){
        console.log(ID, action);
        if(action){
          this.selectedItem.push(ID)
        }else{
          this.selectAll = false;
          var index = this.selectedItem.indexOf(ID)
          this.selectedItem.splice(index, 1);
        }
        console.log(this.selectedItem.length);
        this.selectedItemCounter = this.selectedItem.length
  
      },
      listItem: async function(){
        var object = {
          "Size": this.displaySize,
          "From": this.index,
          "Sort": this.Sort
        }
        if(this.filter.length > 0){
            object["Filter"] = this.filter
          }
        console.log(object);
        try{
          const ret= await this.$store.dispatch('searchGatheringOrder',{router:this.router,keyword:object})
          this.setDisplayList(ret.data.data.hits.hits)
        }catch(err){
          console.log(err.message)
        }
        },
        setDisplayList(list){
          var newlist=[];
          for(var i = 0; i<list.length; i++){
            var object = {
              "ID": list[i]["_source"].GatheringID,
              "GatheringID": list[i]["_source"].GatheringID,
              "UserID": list[i]["_source"].UserID,
              "PaymentMethod": list[i]["_source"].PaymentMethod,
              "Price": list[i]["_source"].Price,
              "Status": list[i]["_source"].PaymentStatus,
              "Created Date": list[i]["_source"].CreatedDatetime,
            }
            newlist.push(object)
          }
          this.TableItemList = newlist;
          this.TableSelectUIControl(this.TableItemList)
        },
        TableSelectUIControl(ItemList){
          this.showNextButton = true;
          if(ItemList.length < this.displaySize){
            this.showNextButton = false;
          }
          var counter = 0;
          for(var i=0; i<ItemList.length;i++){
              var index = this.selectedItem.indexOf(ItemList[i].ID);
              if(index > -1){
                  counter++
              }
          }
          if(counter ==  ItemList.length){
              this.selectAll = true;
          }else{
              this.selectAll = false;
          }
        }
    }
  };
  </script>
  