<template lang="html">
  <div class="blog-slider" :style="{ 'background-image' : `url( ${ urls[ activeId ] } )` }">

      <div class="indicators-con" v-if="urls.length > 1">
          <div class="indicator" v-for="(item, i) in urls" :class="{'active': activeId == i }" :key="i">
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Blog-Slider',
  props: {
    urls: {
      type: Array,
      required: true,
    },
  },
  data(){
    return {
      activeId: 0,
    };
  },
  methods: {
    toPrevItem(){
      if( this.urls.length <= 1 ) return;

      if( --this.activeId < 0 ){
        // wrap to the last item
        this.activeId = this.urls.length - 1;
      }
    },
    toNextItem(){
      if( this.urls.length <= 1 ) return;

      if( ++this.activeId >= this.urls.length ){
        // wrap to the first item
        this.activeId = 0;
      }

    },
  },
}
</script>

<style lang="scss" scoped>
.blog-slider{
  width: 100%;
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  position: relative;

  transition: background-image .25s;
}

.indicators-con{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    position: absolute;
    bottom: 35px;
    left: 0;
    right: 0;

    padding: 10px;

    width: 100%;

    .indicator {
      transition: all .25s;
      opacity: .33;
      background: #FFFFFF;
      flex-grow: 1;

      height: 3px;
      z-index: 1;

      box-shadow: 0 1px 3px #00000080;

      &.active {
        opacity: 1;
      }

      &:not( :first-child ){
        margin-left: 5px;
      }

    }
}
</style>
