<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <FormHeader
                    v-on:formAction="formAction"
                    :HeaderTitle="HeaderTitle"
                    :confirmBack="confirmBack"
                    :formActionButton="formActionButton"
                />
                <!--
                    if tab.Selected is true, then the tab is selected
                    background color is black and text color is white
                    else, background color is white and text color is black
                -->
                <div class="row" style="width: 100% !important; padding: 20px 8px;">
                    <div v-for="(tab) in Tab" :key="tab.Name" class="tab" :style="tabWidth" >
                        <div @click="changeTab(tab.Name)">
                            <md-card class="tabClick" style="margin: unset;">
                                <md-card-content :class="tabSelected(tab.Selected)" style="padding:25px 0 !important" >
                                    <label class="tabLabel">{{tab.Name}}</label>
                                </md-card-content>
                            </md-card>
                        </div>
                    </div>
                </div> 
                <div v-if="(showTab == 'GeneralInfo')">
                    <GeneralInfo
                            v-on:checkEdited="checkEdited"
                            v-on:checkLoading="checkLoading"
                            v-on:checkMessage="checkMessage"
                            :mode="mode"
                            :Status="Status"
                            :EventTypeInput="GeneralInfo.EventTypeInput"
                            :EventLevelInput="GeneralInfo.EventLevelInput"
                            :EventLocationInput="GeneralInfo.EventLocationInput"
                            :DisplayLanguageInput="GeneralInfo.DisplayLanguageInput"
                            :LanguageInput="GeneralInfo.LanguageInput"
                            :TitleInput="GeneralInfo.TitleInput"
                            :DescriptionInput="GeneralInfo.DescriptionInput"
                            :TagsInput="GeneralInfo.TagsInput"
                            :PolicyInput="GeneralInfo.PolicyInput"
                            :StatusInput="GeneralInfo.StatusInput"
                            
                        />
                </div>
                <div v-if="(showTab == 'ProductList')">
                    <ProductList
                        v-on:getSurvey="getSurvey"
                        v-on:setProductList="setProductList"
                        v-on:checkEdited="checkEdited"
                        :ProductList="ProductList"
                        :Status="Status"
                        :mode="mode"
                    />
                </div>
                <div  v-if="(showTab == 'EventImage')">
                    <EventImage
                        :ImageList="EventImageList"
                        :Status="Status"
                        :mode="mode"
                    />
                </div>
                <div v-if="(showTab == 'EventVariance')">
                    <EventVariance
                        v-on:addEventVariance="addEventVariance"
                        v-on:deleteEventVariance="deleteEventVariance"
                        :EventVarianceList="EventVarianceList"
                        :mode="mode"
                    />
                </div>
            </div>
        </div>
        <Spinner v-if="isLoading"/> 
        <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
    </div>
</template>

<script>
import { 
  FormHeader,
  Spinner
 } from "@/components";
 import GeneralInfo from "./tabPage/GeneralInfo.vue";
 import ProductList from "./tabPage/ProductList.vue";
 import EventImage from "./tabPage/EventImage.vue";
 import EventVariance from "./tabPage/EventVariance.vue";
export default {
  components: {  
    FormHeader,
    Spinner,
    GeneralInfo,
    ProductList,
    EventImage,
    EventVariance
  },
  async created() {
    
    if(this.$route.params.eventID){
        this.isLoading = true;
        await this.getEvent(this.$route.params.eventID);
        await this.getSurvey(this.$route.params.eventID);

    }else{
        this.$router.replace({name: 'Event List'})
    }
    this.isLoading = false;
  },
  mounted(){
    // if(this.$route.query.tab){
    //     this.showTab = this.$route.query.tab
    //     for(var t in this.Tab){
    //         if(this.Tab[t].Key == this.showTab){
    //             this.Tab[t].Selected = true
    //         }else{
    //             this.Tab[t].Selected = false
    //         }
    //     }
    // }
  },
  computed: {
    /*
        style function
    */
    tabWidth(){
        return {
            width: (100 / this.Tab.length)-1 + "%"
        }
    },
    
  },
  data() {
    return {
        mode: "Edit",
        Status: "",
        isLoading: false,
        HeaderTitle: "Edit Event",
        formActionButton: "Save",
        confirmBack:false,
        showFormMessage: false,
        formMessage: "",
        showTab: "GeneralInfo",
        ProductList: [],
        currentImageList: [],
        EventImageList: [],
        EventVarianceList: [],
        SurveyList: [],
        Tab: [
            {
                Name: "General Info",
                Key: "GeneralInfo",
                Selected: true
            },
            {
                Name: "Product List",
                Key: "ProductList",
                Selected: false
            },
            {
                Name: "Image",
                Key: "EventImage",
                Selected: false
            },
            {
                Name: "Variance",
                Key: "EventVariance",
                Selected: false
            },
            {
                Name: "Survey",
                Key: "EventSurvey",
                Selected: false
            }
        ],
        GeneralInfo:{
            TitleInput: {
                "type": "text",
                "key": "Title",
                "name": "Title",
                "required": true,
                "value": "",
            },
            DescriptionInput: {
                "type": "text",
                "key": "Description",
                "name": "Description",
                "required": false,
                "value": "",
            },
            PolicyInput: {
                "type": "text",
                "key": "Policy",
                "name": "Policy",
                "required": true,
                "value": "\"注意事項:\n- 為了課堂順利進行，請注意不要遲到，遲到的時間將不會順延或補課，也無法退款。\n- 請到達後向在場工作人員出示入場二維碼門票，如報名時有邀請嘉賓請集合後出示門票。\n- 只限一次24小時前通知改期及不設退款。\n- 如因天氣或其他不可抗力因素無法如常進行課程，請先查詢安排，課程亦可能將會延期。\n- 場地需要脫鞋及席地而坐，請穿襪子及避免穿著短裙出席。\n- 未滿18歲人士禁止參與任何涉及酒精飲料的活動，工作人員如有需要可要求參加者出示其身分證以供核實。\""
            },
            LanguageInput: {
                "key": "Language",
                "name": "Language",
                "required": true,
                "value": null,
                "option": [
                    {
                    "key": "English",
                    "name": "English",
                    },
                    {
                    "key": "Cantoneses",
                    "name": "廣東話",
                    },
                    {
                    "key": "Mandarin",
                    "name": "普通話",
                    },
                ]
            },
            DisplayLanguageInput: {
                "key": "DisplayLanguage",
                "name": "Display Language",
                "required": true,
                "value": null,
                "option": []
            },
            EventTypeInput: {
                "key": "EventType",
                "name": "Event Type",
                "required": true,
                "value": null,
                "option": []
            },
            EventLevelInput: {
                "key": "EventLevel",
                "name": "Event Level",
                "required": true,
                "value": null,
                "option": []
            },
            EventLocationInput: {
                "key": "Country",
                "name": "Country / Region",
                "required": true,
                "model": "",
                "value": "",
            },
            TagsInput:{
                "type": "array",
                "key": "Tags",
                "name": "Tags",
                "required": false,
                "value": [],
            },
            StatusInput:{
                "type": "select",
                "key": "Status",
                "name": "Status",
                "required": false,
                "value": "",
                "option": [
                    {
                        "key": "Draft",
                        "name": "草稿",
                    },
                    {
                        "key": "Published",
                        "name": "公開",
                    },
                    {
                        "key": "Private",
                        "name": "私人",
                    },
                    {
                        "key": "UnListed",
                        "name": "不公開",
                    }
                ]
            }
        },  
    }
  },
  methods:{
    /*
        General Info function
    */
    getEvent: async function(EventID){
        try{
            const ret= await this.$store.dispatch('getEvent',{router:this.router, data:{EventID:EventID}})
            console.log("getEvent", ret.data.Event)
            var event = ret.data.Event
            this.Status = event.Status
            if(event.Image.length > 0){
                this.currentImageList = event.Image;
                for(var i =0; i< event.Image.length; i++){
                var object = {
                    ID: event.Image[i].ImageID,
                    current: true,
                    url: process.env.VUE_APP_S3_URL+event.Image[i].path,
                    base64: "",
                    Action: "none"
                }
                this.EventImageList.push(object)
                }
            }
            for(const [key, value] of Object.entries(this.GeneralInfo)){
                if(value.key == 'Country'){
                    value.value = event.Country.CountryID;
                    value.model = event.Country.Name.en+", "+event.Country.Name.zh+", "+event.Country.Alpha2+", "+event.Country.Alpha3
                    
                    continue
                }else if (value.key == 'EventType'){
                    value.value = event.EventType.EventTypeID;
                    value.model = event.EventType.Name.en+" / "+event.EventType.Name.zh
                    continue
                }else if (value.key == 'EventLevel'){
                    value.value = event.EventLevel.EventLevelID;
                    value.model = event.EventLevel.Name.en+" / "+event.EventLevel.Name.zh
                    continue
                }else{
                    value.value = event[value.key]
                }
               
            }

        }catch(err){
            console.log("getEvent error", err.response.data);
            this.message = err.response.data.message
            this.showMessage = true
        }
    },
    getSurvey: async function(EventID){
      
      try{
          const ret= await this.$store.dispatch('listEventSurvey',{router:this.router, data:{EventID:EventID}})
          console.log("listEventSurvey", ret.data.SurveyList)
          this.SurveyList = ret.data.SurveyList
          var productIDList = [];
            for(var s in this.SurveyList){
                if(this.SurveyList[s].ProductID){
                    var index = productIDList.findIndex(x => x == this.SurveyList[s].ProductID)
                    if(index < 0){
                        productIDList.push(this.SurveyList[s].ProductID)
                    }
                }

            }
            var productList = await this.getProductList(productIDList)
            this.ProductList = []
            for(var p in productList){
                var product = productList[p]
                console.log("product", product)
                var producObject = {
                    ID: product.ProductID,
                    ProductName: product.Name.en+" / "+product.Name.zh,
                    MinimumPrice: product.MinimumPrice,
                    Parameter: product.ProductType.Parameter,
                    Survey: [],
                    img: process.env.VUE_APP_S3_URL+product.Image[0].path
                }
                
                for(var s in this.SurveyList){
                    var survey = this.SurveyList[s]
                    var Parameter = {}
                    if(survey.ProductID == product.ProductID){
                        for(var p in survey.Parameter){
                            var key = survey.Parameter[p].Key
                            Parameter[key] = survey.Parameter[p].value
                        }
                        var surveyObject = {
                            Survey: this.SurveyList[s].SurveyID,
                            Parameter: Parameter,
                            new: false
                        }
                        producObject.Survey.push(surveyObject)
                    }
                }
                this.ProductList.push(producObject)
            }

            console.log("ProductList", this.ProductList)
            this.isLoading = false;
        }
        catch(err){
            this.isLoading = false;
            console.log("listEventSurvey error", err.response.data);
            this.message = err.response.data.message
            this.showMessage = true
        }

    },
    getProductList: async function(productList){
        try{
          const ret= await this.$store.dispatch("getProduct",{router:this.router, product:{ProductList: productList}})
          return ret.data.ProductDetailList;
          
        }
        catch(err){
          this.isLoading = false;
          console.log("getProduct error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
    },
    /*
        Event Variance function
    */
    addEventVariance: function(newVariance){
        console.log("addEventVariance", newVariance)
        this.EventVarianceList.push(newVariance)
    },
    deleteEventVariance: function(delList){
        for(var d in delList){
            var index = this.EventVarianceList.findIndex(x => x.ID == delList[d])
            if(index >= 0){
                this.EventVarianceList.splice(index, 1)
            }
        }
    },
    formAction: async function(){
        console.log("formAction", this.showTab);
        switch(this.showTab){
            case "GeneralInfo":
                await this.GeneralInfoAction()
                break;
            case "ProductList":
                await this.ProductListAction()
                break;
            case "EventImage":
                await this.EventImageAction()
                break;
        }
    },
    async ProductListAction(){
        console.log("ProductListAction", this.ProductList);
    },
    async GeneralInfoAction (){
        var GeneralInfoObject = {}
        if(this.Status == "Draft"){
            var GeneralInfoResut = this.GeneralInfoCheck();
            if(GeneralInfoResut){
                console.log("pass")
            }else{
                console.log("fail")
                return;
            }

            
            for(const [key, value] of Object.entries(this.GeneralInfo)){
                GeneralInfoObject[value.key] = value.value
            }
            // go to api
        }else{
            // only update status
            if(this.Status !== this.GeneralInfo.StatusInput.value){
                GeneralInfoObject = {
                    Status: this.GeneralInfo.StatusInput.value
                }
                // go to api
            }else{
                this.formMessage = "You are only allow edit Event in Draft status"
                this.showFormMessage = true
            }
        }
        if(Object.keys(GeneralInfoObject).length > 0){
            GeneralInfoObject.EventID = this.$route.params.eventID
            try{
                this.isLoading = true;
                const ret= await this.$store.dispatch('updateEvent',{router:this.router, data:GeneralInfoObject})
                console.log("updateEvent", ret.data.Event)
                this.isLoading = false;
                this.formMessage = "Update Event Success"
                this.showFormMessage = true
            }catch(err){
                this.isLoading = false;
                console.log("updateEvent error", err.response.data);
                this.formMessage = err.response.data.message
                this.showFormMessage = true
            }
        }
       
    },
    async EventImageAction(){
        console.log("EventImageAction", this.EventImageList)
        var ImageList = []
        var newImageList = []
        for(var i in this.EventImageList){
            var imageObject = {
                ImageID: this.EventImageList[i].ID,
                Action: this.EventImageList[i].Action
            }
            if(!this.EventImageList[i].current){
                newImageList.push(this.EventImageList[i])
                imageObject.ContentType = this.EventImageList[i].base64.split(":",2)[1].split(";",1)[0]
            }
            ImageList.push(imageObject)
        }
        console.log("ImageList", ImageList)
        console.log("newImageList", newImageList) 
        //return
        this.isLoading = true;

        try{
            const ret = await this.$store.dispatch('updateEventImage',{router:this.router, data:{EventID:this.$route.params.eventID, Image: ImageList}})
            console.log("updateEventImage", ret)
            for(var i = 0; i<newImageList.length; i++){
                const retMedia = await this.$store.dispatch('updateMediaToS3',{file: newImageList[i].base64, urlObject:ret.data.UploadImageUrlList[i]})
            }
            this.isLoading = false;
            this.formMessage = "Update Event Image Success"
            this.showFormMessage = true
            this.EventImageList = []
            this.getEvent(this.$route.params.eventID);
        }catch(err){
            this.isLoading = false;
            console.log("updateEventImage error", err.response.data);
            this.formMessage = err.response.data.message
            this.showFormMessage = true
        }

    },
    /*
        Data Check
    */
    GeneralInfoCheck(){
        for(const [key, value] of Object.entries(this.GeneralInfo)){
            if(!value.required){
                continue;
            }
            if(value.value == null || value.value == "" || value.value == []){
                this.formMessage = value.name + " is required"
                this.showFormMessage = true
                return false
            }
        }
        return true
    },
    ProductListCheck(){
        if(this.ProductList.length == 0){
            this.formMessage = "Product List is required"
            this.showFormMessage = true
            return false
        }
        for(var p in this.ProductList){
            console.log(this.ProductList[p])
            if(this.ProductList[p].Survey.length == 0){
                this.formMessage = "Survey is required"
                this.showFormMessage = true
                return false
            }
            for(var s in this.ProductList[p].Survey){
                var survey = this.ProductList[p].Survey[s]
                if(survey.Survey == null || survey.Survey == ""){
                    this.formMessage = "Survey is required"
                    this.showFormMessage = true
                    return false
                }
            }
        }
        return true
    },
    EventImageListCheck(){
        if(this.EventImageList.length == 0){
            this.formMessage = "Event Image is required"
            this.showFormMessage = true
            return false
        }
        return true
    },
    /*
        Product List function
    */
    setProductList: function(ProductList){
        console.log("setProductList", ProductList)
      //push product list to inspiration product list
      for(var i=0;i<ProductList.length;i++){
        var index = this.ProductList.findIndex(x => x.ID == ProductList[i].ID)
        if(index < 0){
          this.ProductList.push(ProductList[i])
        }
      }
    },
    /*
        check if the form is edited
    */
    checkEdited: function(check){
        this.confirmBack = check
    },
    checkLoading: function(check){
        //this.isLoading = check
    },
    checkMessage: function(message){
        this.formMessage = message
        this.showFormMessage = true
    },

    /*
        change tab function
    */
    tabSelected: function(selected){
        if(selected){
            return "tabSelected"
        }else{
            return "tabNotSelected"
        }
    },
    changeTab: function(tab){
        console.log(tab)
        this.formActionButton = null
        if(tab == "General Info" || tab == "Image"){
            this.formActionButton = "Save"
        }
        if(tab == "Survey"){
            this.$router.push({ name: "Event Survey Summary", params:{eventID:this.$route.params.eventID}});
        }else{
            for(var t in this.Tab){
                if(this.Tab[t].Name == tab){
                    this.Tab[t].Selected = true
                    this.showTab = this.Tab[t].Key
                    //this.$router.push({ path: '/event/'+this.$route.params.eventID, query: { tab: this.showTab } });
                }else{
                    this.Tab[t].Selected = false
                }
            }
            
        }
    }

  }
}
</script>
<style>
    .tab{
        text-align: center;
        padding-right: 1%;
    }
    .tabLabel{
        font-size: 25px;
    }
    .tabClick{
        cursor: pointer;
    }
    .tabSelected{
        background-color: black;
        color: white;
    }
    .tabNotSelected{
        background-color: white;
        color: black;
    }
    .TagePage{
        text-align: center;
        margin: unset;
        height: 75vh;
    }
</style>
