<template>
  <router-view></router-view>
</template>

<script>
export default {
  computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    }
};
</script>
