import axios from "axios"

import url from "../config.js"
import oneSipApiCall from "../apiCall.js"
const attendance={
    scanQRCode({commit,state},{router,data}){
      return oneSipApiCall(commit,state,router,'/admin-scan-qrcode',data)
    },
    takeAttendance({commit,state},{router,data}){
      return oneSipApiCall(commit,state,router,'/admin-take-attendance',data)
    },
}

export default attendance;