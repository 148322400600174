
<template>
    <div style="text-align:left;">
        <div class="row">
          <div style="width:50%"> <h3 class="formTitle">Inventory</h3></div>
          <div v-if="editAble" class="action" @click="addinventory()"><u>add Inventory</u> </div>
        </div>
        <div v-if="(InventoryList.length > 0)" style=" overflow-y: scroll;" :style="maxheight">
          <table class="inventoryTable">
            <tr v-for="(Inventory, index) in InventoryList" :key="index">
              <td style="padding: 0 20px">
                <label>Inventory Location</label>
                <md-field style="margin-top:0px;padding-top:0px">
                  <md-select v-model="Inventory.InventoryID">
                    <md-option v-for="(inventory,i) in inventoryList" :key="i" :value="inventory.InventoryID">{{inventory.InventoryName.en}}/{{inventory.InventoryName.zh}}</md-option>
                  </md-select>
                </md-field>
              </td>
              <td style="padding: 0 20px">
                <label>Quantity In Stock</label>

                <md-field style="margin-top:0px;padding-top:5px">
                    <md-input type="number" min="1" v-model="Inventory.Quantity" />
                </md-field>
              </td>
              <td style="text-align:center"><u class="productRemove" @click="delInventoryItem(index)">remove</u></td>
            </tr>
          </table>
   
        </div>
        <div v-else style="text-align:center">
            No Inventory <br>
            <md-button v-if="editAble" style="background-color: #575757 !important;width: 35% !important; margin-top: 20px;" @click="addinventory()">Add Inventory</md-button>
        </div>
    </div>
    
</template>

<script>


export default {
  created() {
    this.listInventory();   
  },
  methods:{
    delInventoryItem: async function(index){
      this.InventoryList.splice(index, 1); 
    },  
    addinventory: async function(){
        console.log("go to add Inventory");
        var object = {
          "InventoryID": null,
          "Quantity": 0
        }
        this.InventoryList.push(object);
    },
    listInventory: async function(){
      console.log("listInventory")
        try{       
          const ret= await this.$store.dispatch("listInventory",{router:this.router})
          console.log(ret);
          this.inventoryList=ret.data.InventoryList;     
        }
        catch(err){
          console.log(err.message);
        }
      },  
  },
  name: "inentory-selecter-component",
  props: {
    InventoryList:{
        type: Array,
        default(){
          return []
        }
      },
    editAble: {
      default(){
        return true
      }
    },
    maxheight:{
      default(){
        return "max-height: 300px"
      }
    }
  },
  data() {
    return {
      inventoryList:[]
    };
  },
  computed: {

  }
};
</script>

<style>
    .productRemove{
        font-size: 18px;
        padding-top:25px;
        color:#CC2727;
        cursor: pointer;
    }
    .productText{
        font-size: 20px;
        padding-top:25px;
        padding-left:20px;
    }
    .productImg{
        width: 100px !important;
        height: 100px !important;
    }
    .backButtonTitlePadding{
      padding-left: 20px;
    }
    .backIcon{
      padding: unset;
      margin: unset;
      color:black !important;
    }
    .backbutton{
      padding: unset;
      margin: 15px 0 0 0 !important;
      min-width: 40px !important;
      width: 40px !important;
      height: 45px !important;
    }
    .formTitle{
      padding-left: 15px;
      margin-top: 10px;
      font-weight: 500;
    }
    .action{
        text-align:right;
        width:50%;
        margin: 10px 0;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
    }
    .inventoryTable{
      width:100%;
    }
    .inventoryTable td {
      border: none;
    }
    .product-select-container{
        max-width: 90%;
        height: 900px;
        margin: 0px auto;
        position: relative;
        background-color: #fff;
        transition: all .3s ease;
        box-shadow: 0 27px 24px 0 rgba(0,0,0,.2), 0 40px 77px 0 rgba(0,0,0,.22);
        border-radius: 6px;
        border: none;
    }
</style>
