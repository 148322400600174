import oneSipApiCall from "../apiCall.js"
import axios from "axios"
import url from "../config.js"

const location={
  SearchLocations({commit,state},{router,keyword}){
    return oneSipApiCall(commit,state,router,'/admin-search-location',keyword)
  },
  listLocations({commit,state},{router}){
    return oneSipApiCall(commit,state,router,'/admin-list-locations')
  },
  createLocation({commit,state},{router,location}){
    return oneSipApiCall(commit,state,router,'/admin-create-location',location)
  },
  getLocation({commit,state},{router,location}){
    return oneSipApiCall(commit,state,router,'/admin-get-location',location)
  },
  updateLocation({commit,state},{router,location}){
    return oneSipApiCall(commit,state,router,'/admin-update-location',location)
  },
  deleteLocation({commit,state},{router,location}){
    return oneSipApiCall(commit,state,router,'/admin-delete-location',location)
  }
}

export default location;