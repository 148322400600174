
<template>
  <md-card  style="margin:10px 0">
    <div style="position:absolute;right:-60px">
        <md-button class="md-fab md-mini md-primary md-arrow" @click="moveUp(index)">
          <md-icon>arrow_upward</md-icon>
        </md-button>
        <br>
        <md-button class="md-fab md-mini md-primary md-arrow" @click="moveDown(index)">
          <md-icon>arrow_downward</md-icon>
        </md-button>
      </div>
    <md-card-content>
      <div style="text-align:left">
        <!-- <div  class="row">
          <div style="width:50%"> <h3 class="formTitle">Question {{index + 1 }}</h3></div>
          <div  class="action"><md-icon style="color:#cc2727" @click="removeQuestion(index)">delete</md-icon> </div>
        </div> -->
        <div class="row">
            <div class="col"> <h3 class="formTitle"> Question: {{ index + 1 }}</h3></div>
            <div style="text-align: right;" class="row">
                <div class="col" @click="diplicateQuestion(index)"><md-icon class="iconButton">file_copy</md-icon></div>
                <div class="col" @click="removeQuestion(index)"><md-icon  class="iconButton" style="color:#cc2727">delete</md-icon> </div>
            </div>
        </div>
        <div style="padding-top:20px;">
          <div class="column inputcolumn" style="width: 100%">
            <label>Question</label>
            <md-field style="margin-top:0px;padding-top:5px">
                <md-input type="text"  class="formInput" v-model=questionObj.Question />
            </md-field>
          </div>
          <div class="row">
            <div class="column inputcolumn">
              <label>Type</label>
              <md-field style="margin-top:0px;padding-top:0px">
                <md-select class="formInput" v-model="questionObj.Type" @md-selected="selected">
                  <md-option v-for="option in QuestionTypeOption" :key="option.key" :value=option.key >{{option.name}}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="column inputcolumn" >
              <div class="row" style="padding:10px 0 10px 0">
                <div><input type="checkbox" class="tablecheckbox"  v-model="questionObj.Optional"/></div>    
                <div style="padding-left:10px"><label>Optional</label></div>  
              </div>
            </div>
          </div>


          <div style="padding-top:10px;padding-bottom: 10px;">

            <div v-if="haveAnswer" class="column inputcolumn" style="width: 100%;">
              <label>Answer</label>
             
              <draggable
                v-model="questionObj.Answers"
                >

              <div class="row" v-for="(Item, index) in questionObj.Answers" :key="index" >
                <div class="column" style="cursor: all-scroll;">
                  <md-checkbox v-if="questionObj.Type=='Checkbox'" :disabled="true"></md-checkbox>
                  <md-radio v-if="questionObj.Type=='Radio'" :disabled="true"></md-radio>
                  <!-- <md-input type="radio" v-if="questionObj.Type=='Radio'" :disabled="true"></md-input> -->
                </div>
                <div class="column" style="width:80%">
                  <md-field style="margin-top:0px;padding-top:5px">
                    <md-input type="text" class="formInput" v-model=Item.Name />
                  </md-field>
                </div>
                <div class="column" style="cursor: pointer;" @click="removeAnswers(index)">
                    <md-icon >close</md-icon>
                </div>
              </div>

              </draggable>
              <div class="row" v-if="haveOtherOption">
                <div class="column" style="cursor: all-scroll;">
                  <md-checkbox v-if="questionObj.Type=='Checkbox'" :disabled="true"></md-checkbox>
                  <md-radio v-if="questionObj.Type=='Radio'" :disabled="true"></md-radio>
                </div>
                <div class="column" style="width:80%">
                  <md-field style="margin-top:0px;padding-top:5px">
                    <md-input type="text" class="formInput" value="Other" :disabled="true"/>
                  </md-field>
                </div>
                <div class="column" style="cursor: pointer;" @click="removeOtherOption()">
                    <md-icon >close</md-icon>
                </div>
              </div>
              <div>
                <label @click="addAnswers()" style="cursor: pointer;">Add Option</label><label v-if="(!haveOtherOption)"> or </label><label v-if="(!haveOtherOption)" style="cursor: pointer;" @click="addOtherOption()">Add Other</label>
              </div>





            </div>
            <!-- <div v-if="haveHaveModelAnswer" class="column inputcolumn">
              <div class="row" style="padding:10px 0 10px 0">
                <div><input type="checkbox" class="tablecheckbox"  v-model="haveModelAnswer"/></div>    
                <div style="padding-left:10px"><label>Have Model Answer</label></div>  
              </div>
            </div> -->
            <!-- <div v-if="haveModelAnswer" class="column inputcolumn" style="width: 100%;">
              <label>Model Answer</label>
              <md-chips v-model="questionObj.modelAnswer"  md-check-duplicated id="modelanswer" style="margin-top:0px;padding-top:0px"></md-chips>
            </div> -->
            <!-- <div v-if="haveOtherOption" class="column inputcolumn" style="width: 100%;">
              <div class="row" style="padding:10px 0 10px 0">
                <div><input type="checkbox" class="tablecheckbox"  v-model="questionObj.otherOption"/></div>    
                <div style="padding-left:10px"><label>Have Other Option</label></div>  
              </div>
            </div> -->
            <!-- <div v-if="haveHits" class="column inputcolumn" style="width: 100%;">
              <label>Hits</label>
              <md-field style="margin-top:0px;padding-top:5px">
                  <md-input type="text"  class="formInput" v-model=questionObj.Hits />
              </md-field>
            </div> -->
            <!-- <div v-if="haveLikeAction" class="column inputcolumn">
              <div class="row" style="padding:10px 0 10px 0">
                <div><input type="checkbox" class="tablecheckbox"  v-model="questionObj.LikeAction"/></div>    
                <div style="padding-left:10px"><label>Like Action</label></div>  
              </div>
            </div> -->
          </div>


        </div>


      
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name:"survey-question-form",
  components: {
    draggable,
  },
  props:{
    questionObj:{
      type:Object
    },
    index:{
      type:Number
    }
  },
  created() {
    //this.setQuestionType();

  },
  mounted(){

  },
  methods:{
    setQuestionType: function(){
      console.log("setQuestionType", this.questionObj);
      this.selected(this.questionObj.questionType)
    },
    moveUp:function(index){
      this.$emit("moveUp", index);
    },
    moveDown: function(index){
      this.$emit("moveDown", index);
    },
    removeQuestion:function(index){
      this.$emit("removeQuestion", index);
    },
    diplicateQuestion:function(index){
      this.$emit("diplicateQuestion", index);
    },
    removeAnswers:function(index){
      if(this.questionObj.Answers.length > 1){
        this.questionObj.Answers.splice(index, 1);
        this.$forceUpdate();
      }
    },
    removeOtherOption: function(){
      this.haveOtherOption = false;
    },
    addOtherOption: function(){
      this.haveOtherOption = true;
      this.questionObj.OtherOption = true;
    },
    addAnswers:function(){
      console.log("addAnswer");
      this.questionObj.Answers.push({
        Name:"Option "+(this.questionObj.Answers.length+1),
      });
      this.$forceUpdate();
    },
    selected:function(value){
      console.log(value);
      switch (value){
        case "Text":
          this.haveLikeAction = false;
          this.haveAnswer = false;
          this.haveHits = true;
          this.haveModelAnswer = false;
          this.haveHaveModelAnswer = false;
          
        break;
        case "Radio":
          this.haveLikeAction = true;
          this.haveAnswer = true;
          this.haveHits = true;
          this.haveModelAnswer = false;
          this.haveHaveModelAnswer = false;
          
        break;
        case "Checkbox":
          this.haveLikeAction = false;
          this.haveAnswer = true;
          this.haveHits = true;
          this.haveModelAnswer = false;
          this.haveHaveModelAnswer = true;
         
        break;
        case "Option":
          this.haveLikeAction = false;
          this.haveAnswer = true;
          this.haveHits = true;
          this.haveModelAnswer = false;
          this.haveHaveModelAnswer = true;
          
        break;
      }
      
    },
  },
  data(){
    //console.log(initMenu());
    //console.log(this.questionObj);
    return {
      haveLikeAction:false,
      haveAnswer:this.questionObj.Answers?true:false,
      haveHits:this.questionObj.Hits?true:false,
      haveModelAnswer:this.questionObj.modelAnswer?true:false,
      haveHaveModelAnswer:this.questionObj.modelAnswer?true:false,
      haveOtherOption:this.questionObj.otherOption?true:false,
      QuestionTypeOption: [
        {
          key: "Text",
          name: "Text"
        },
        {
          key: "Radio",
          name: "Radio"
        },
        {
          key: "Checkbox",
          name: "Checkbox"
        },
        {
          key: "TasteNote",
          name: "TasteNote"
        },
        {
          key: "FiveSenses",
          name: "五感"
        },
        {
          key: "Aroma",
          name: "香氣"
        }
        // {
        //   key: "Option",
        //   name: "Option"
        // },
      ],
      Answers: []
    }
  },
  watch:{
    // haveLikeAction:function(newValue,oldValue){
    //   if(newValue){
    //     this.questionObj["LikeAction"] = false
    //   }else{
    //     delete this.questionObj.LikeAction;
    //   }
    // },
    haveAnswer:function(newValue,oldValue){
      if(newValue){
        this.questionObj["Answers"] = []
        this.addAnswers();
      }else{
        delete this.questionObj.Answers;
      }
    },
    // haveHits:function(newValue,oldValue){
    //   if(newValue){
    //     this.questionObj["Hits"] = null
    //   }else{
    //     delete this.questionObj.Hits;
    //   }
    // },
    // haveModelAnswer:function(newValue,oldValue){
    //   if(newValue){
    //     this.questionObj["modelAnswer"] = []
    //   }else{
    //     delete this.questionObj.modelAnswer;
    //   }
    // },
  },
}
</script>

<style>
.inputcolumn{
  width:50%;
  padding:0 20px
}
.action{
    text-align:right;
    width:50%;
    margin: 10px 0;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}
.formButton{
  background-color: #575757 !important;
  width: 100px !important;
}
.formTitle{
  padding-left: 15px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 20px;
}
.formInput{
  font-weight: 500;
  font-size: 18px !important;
}
</style>