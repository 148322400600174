<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <TableHeader
        :HeaderTitle="HeaderTitle"
        :AddNewButton="AddNewButton"
        v-on:createNewItem="createNewItem"
      ></TableHeader>
      <md-card>
          <md-card-content>
            <TableComponent 
            v-on:selectItem="selectItem"
            v-on:selectAllItem="selectAllItem"
            v-on:searchItem="searchItem"
            v-on:onclickItem="onclickItem"
            v-on:selectSortOption="selectSortOption"
            v-on:selectedItemActionFunction="selectedItemActionFunction"
            v-on:goPage="goPage"
            v-on:SortPopupControl="SortPopupControl"
            :TableList="TableItemList"
            :selectedItemCounter="selectedItemCounter"
            :selectedItem="selectedItem"
            :columns="columns"
            :selectAll="selectAll"
            :selectable="selectable"
            :haveSearchFunction="haveSearchFunction"
            :sortOption="sortOption"
            :selectedItemAction="selectedItemAction"
            :pageNumber="pageNumber"
            :showNextButton="showNextButton"
            :showSortPopup="showSortPopup"
            ></TableComponent>
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
      <Transition name="modal" v-if="showPopup">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="popupaction"
            :PopupTitle="PopupTitle"
            :PopupContent="PopupContent"
            :actionButton="actionButton"
          ></Popup>
      </Transition>
    </div>
    <Spinner v-if="isLoading"/> 
    <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
    
  </div>
</template>

<script>
import { 
  TableComponent,
  TableHeader,
  Popup,
  Spinner
} from "@/components";

export default {
  components: {
    TableComponent,
    TableHeader,
    Popup,
    Spinner
  },
  data() {
    return {
      isLoading: false,
      message: "",
      showPopup: false,
      showSortPopup: false,
      PopupTitle: "Delete Email Event",
      PopupContent: "The Email Event data will no longer saved and will not be able to recover the data",
      actionButton: "Delete anyway",
      index: 0,
      pageNumber: 1,
      showMessage: false,
      showNextButton: true,
      displaySize: 10,
      HeaderTitle: "Email Event List",
      TableItemList: [],
      AddNewButton:  "Add Email Event",
      columns: ["EmailEvent Name", "EmailDateTime", "Status"],
      sortOption: [
        {Key: "EmailDataTime", Name: "Email Date", Value: true, Order: "desc"},
      ],
      selectedItemAction: [
        //{Key: "delete", Name: "Delete selected", Style:"color:#CC2727"},
      ],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      haveSearchFunction: true,
      filter: [],
    }
  },
  async created() {
      this.isLoading = true;
      console.log("params", this.$route.params)
      this.message = this.$route.params.message;
      if(this.$route.params.page){
        if(Number(this.$route.params.page) > 0){
          this.pageNumber = Number(this.$route.params.page)
        }
        this.index = this.index + (Number(this.$route.params.page) - 1 )*this.displaySize
      }
      this.checkShowMessage();
      this.setDefaultSort()
      await this.listItem();
      this.isLoading = false;
    },
  methods:{
    setDefaultSort: function(){
      if(this.sortOption.length > 0){
        var index = this.sortOption.findIndex(option => option.Value === true)
        var object = {}
        object[this.sortOption[index].Key] = {"order" : this.sortOption[index].Order}
        this.Sort = [object]
      }
    },
    SortPopupControl: function (){
        this.showSortPopup = !this.showSortPopup
      },
    goPage:function(action){
      if(action == "next"){
        this.index = this.index + this.displaySize
        this.pageNumber = this.pageNumber + 1
      }else if(action == "prev" && this.index > 0){
        this.index = this.index - this.displaySize
        this.pageNumber = this.pageNumber - 1
      }else{
        this.index = 0
        this.pageNumber = 1
      }
      this.$router.replace({ name: "Email Event List PageNumber", params: {page:this.pageNumber} })
      this.listItem()
    },
    checkShowMessage: async function(){
      if(this.message){
        try{
          await this.delayFunction();
          this.showMessage = true;
        }catch(err){
          console.log(err)
        }
      }
    },
    delayFunction:async function (){
        setTimeout(function(){
          this.showMessage = false;
          this.message = "";
          return true;
        }, 1000);
    },
    selectedItemActionFunction:function(action){
      console.log("selectedItemActionFunction", action);
      if(action == "delete"){
        this.showPopup = true
      }
    },
    onclickItem: function(ID){
      //this.$router.push({name: 'Update Location', params:{locationID:ID}})
    },
    createNewItem: function(){
      this.$router.push({name: 'Create Email Event'})
    },
    popupcancel: function(){
        this.showPopup = false;
      },
    popupaction: function (){
      this.showPopup = false;
      //this.deleteLocation();
    },  

    selectSortOption: function(option){
      console.log("charge sorting with " + option);
      var sortKey = "";
      var sortOrder = "";
        for(var i = 0; i<this.sortOption.length; i++){
          if(this.sortOption[i].Name == option){
            sortKey = this.sortOption[i].Key
            sortOrder = this.sortOption[i].Order
            this.sortOption[i].Value = true
          }else{
            this.sortOption[i].Value = false
          }
        }
        var object = {}
        object[sortKey] = {"order" : sortOrder}
        this.Sort = [object]
        this.showSortPopup = false;
        this.listItem();
    },
    searchItem: async function(keyword){
      this.selectAll = false;
      this.selectedItem = [],
      this.selectedItemCounter = 0;

      this.index = 0;
      this.pageNumber = 1;
      console.log("search " + keyword)
      var object = {
        "Size": this.displaySize,
        "From": this.index,
        "Keyword": keyword,
        "Sort": this.Sort
      }
      if(this.filter.length > 0){
          object["Filter"] = this.filter
        }
      try{
        const ret= await this.$store.dispatch('searchEmailEvent',{router:this.router,keyword:object})
        this.setDisplayList(ret.data.data.hits.hits)
      }catch(err){
        console.log("searchEmailEvent error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
      }
    },
    selectAllItem:function(action){
        this.selectAll = false;
        if(action){
          this.selectAll = true;
          for(var i = 0; i< this.TableItemList.length; i++){
            var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
            if(index < 0){
                this.selectedItem.push(this.TableItemList[i].ID)
            }
          }
        }else{
            for(var i = 0; i< this.TableItemList.length; i++){
                var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
                this.selectedItem.splice(index, 1);
            }
        }
        console.log("after", this.selectedItem);
        this.selectedItemCounter = this.selectedItem.length
      },
    selectItem:function(ID, action){
      console.log(ID, action);
      if(action){
        this.selectedItem.push(ID)
      }else{
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID)
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length

    },
    listItem: async function(){
      var object = {
        "Size": this.displaySize,
        "From": this.index,
        "Sort": this.Sort
      }
      if(this.filter.length > 0){
          object["Filter"] = this.filter
        }
      console.log(object);
      try{
        const ret= await this.$store.dispatch('searchEmailEvent',{router:this.router,keyword:object})
        this.setDisplayList(ret.data.data.hits.hits)
      }catch(err){
        console.log("searchEmailEvent error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
      }
      },
      setDisplayList(list){
        var newlist=[];
        for(var i = 0; i<list.length; i++){
          var object = {
            "ID": list[i]["_source"].EmailEventID,
            "EmailEvent Name": list[i]["_source"].EmailEventName,
            "EmailDateTime": list[i]["_source"].EmailDateTime,
            "Status": list[i]["_source"].Status,
          }
          newlist.push(object)
        }
        this.TableItemList = newlist;
        this.TableSelectUIControl(this.TableItemList)
      },
      TableSelectUIControl(ItemList){
        this.showNextButton = true;
        if(ItemList.length < this.displaySize){
          this.showNextButton = false;
        }
        var counter = 0;
        for(var i=0; i<ItemList.length;i++){
            var index = this.selectedItem.indexOf(ItemList[i].ID);
            if(index > -1){
                counter++
            }
        }
        if(counter ==  ItemList.length){
            this.selectAll = true;
        }else{
            this.selectAll = false;
        }
      }
  }
};
</script>
