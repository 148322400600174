<template>
  <div class="white-bg full-height">

  </div>
</template>
<script>

export default {
  name:"survey-area"
}
</script>

<style lang="scss" scoped>
  .white-bg{
    background-color: grey;
  }
  .full-height{
    height:100%;
  }
</style>
