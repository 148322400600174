<template>
    <div>
        <md-card class="TagePage" style="margin: unset;height: 90vh !important;">
          <md-card-content >
                <div v-if="(EventVarianceList.length>0)">
                    <div style="width: 100%;text-align: right;">
                        <button @click="openPopup()" class="newProductButton">Add Event Variance</button>
                    </div>
                    <TableComponent
                        v-on:onclickItem="onclickItem"
                        v-on:selectItem="selectItem"
                        v-on:selectAllItem="selectAllItem"
                        v-on:selectedItemActionFunction="selectedItemActionFunction"
                        v-on:goPage="goPage"
                        :TableList="TableItemList"
                        :selectedItemCounter="selectedItemCounter"
                        :selectedItem="selectedItem"
                        :columns="columns"
                        :selectAll="selectAll"
                        :selectable="true"
                        :selectedItemAction="selectedItemAction"
                        :pageNumber="pageNumber"
                        :showNextButton="showNextButton"
                        style="text-align: left;"
                    />
                </div>
                <div v-else>
                    <button @click="openPopup()" class="newProductButton centerButton">Add Event Variance</button>
                </div>
            </md-card-content>
        </md-card>
        <Transition name="modal" v-if="showAddVariance">
            <div  class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="popup">
                            <div class="modal-header">
                                <slot name="header">New Event Variance</slot>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="inputcolumn">
                                        <TextInput
                                            :Item="VarianceInput.EventDateInput"
                                            v-on:checkEdited="checkEdited"
                                        />
                                        </div>
                                    <div class="inputcolumn">
                                        <TextInput
                                            :Item="VarianceInput.EventTimeInput"
                                            v-on:checkEdited="checkEdited"
                                            />
                                        </div>
                                </div>
                                <div class="row">
                                    <div class="inputcolumn">
                                        <TextInput
                                            :Item="VarianceInput.EventDurationInput"
                                            v-on:checkEdited="checkEdited"
                                        />
                                        </div>
                                    <div class="inputcolumn">
                                        <SelectInput
                                            :Item="VarianceInput.LocationInput"
                                            v-on:checkEdited="checkEdited"
                                            style="padding-top:25px"
                                            />
                                        </div>
                                </div>
                                <div class="row">
                                    <div class="inputcolumn">
                                        <TextInput
                                            :Item="VarianceInput.MaxSeatInput"
                                            v-on:checkEdited="checkEdited"
                                        />
                                        </div>
                                    <div class="inputcolumn">
                                        <TextInput
                                            :Item="VarianceInput.PriceInput"
                                            v-on:checkEdited="checkEdited"
                                            />
                                        </div>
                                </div>
                                <div class="row">
                                    <div class="inputcolumn">
                                        <TextInput
                                            :Item="VarianceInput.MinimumParticipator"
                                            v-on:checkEdited="checkEdited"
                                        />
                                        </div>
                                    <div class="inputcolumn">
                                        <SelectInput
                                            :Item="VarianceInput.HostInput"
                                            v-on:checkEdited="checkEdited"
                                            style="padding-top:25px"
                                            />
                                        </div>
                                </div>
                                <!-- <div class="row" v-for="(input, key) in VarianceInput" :key="key">
                                    <SelectInput
                                        v-if="input.type=='option'"
                                        :Item="input"
                                        v-on:checkEdited="checkEdited"
                                    />
                                    <TextInput
                                        v-else
                                        :Item="input"
                                        v-on:checkEdited="checkEdited"
                                    />
                                </div> -->
                                <div class="row" style="text-align: center;padding-top: 20px;">
                                    <div class="column" style="width:47.5%">
                                        <label @click="showAddVariance=false">Cancel</label>
                                    </div>
                                    <div class="column" style="width:5%"></div>
                                    <div class="column" style="width:47.5%">
                                        <button class="cancelButton" @click="addEventVariance">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </Transition>
        <Spinner v-if="isLoading"/> 
    </div>
    
</template>

<script>
import { 
    TextInput,
    TableComponent,
    SelectInput,
    Spinner

 } from "@/components";


export default {
    components: {
        TextInput,
        TableComponent,
        SelectInput,
        Spinner,
    },
    async created() {
        this.isLoading = true;
        this.getHost();
        await this.listLocations();
        this.setDisplayList();  
        this.isLoading = false;
        if(this.mode == "Edit"){
          await this.listEventVariance(this.$route.params.eventID);
        }
    },

    methods:{
        goPage:function(action){
            console.log("goPage", action);
            if(action == "next"){
                this.index = this.index + this.displaySize
                this.pageNumber = this.pageNumber + 1
            }else if(action == "prev" && this.index > 0){
                this.index = this.index - this.displaySize
                this.pageNumber = this.pageNumber - 1
            }else{
                this.index = 0
                this.pageNumber = 1
            }
            console.log(this.index, this.pageNumber)
            this.setDisplayList()
        },
        selectItem:function(ID, action){
            console.log(ID, action);
            if(action){
                this.selectedItem.push(ID)
            }else{
                this.selectAll = false;
                var index = this.selectedItem.indexOf(ID)
                this.selectedItem.splice(index, 1);
            }
            console.log(this.selectedItem.length);
            this.selectedItemCounter = this.selectedItem.length
        },
        selectAllItem:function(action){
            this.selectAll = false;
            this.selectedItem = []
            if(action){
                this.selectAll = true;
                for(var i = 0; i< this.EventVarianceList.length; i++){
                    this.selectedItem.push(this.EventVarianceList[i].ID)
                }
            }
            console.log("after", this.selectedItem);
            this.selectedItemCounter = this.selectedItem.length
        },
        onclickItem:function(ID){
            if(this.mode == "Edit"){
                console.log("onclickItem", ID); 
                this.$router.push({name: 'Variance Detail', params: {varianceID: ID}})
            }
        },
        selectedItemActionFunction: async function(action){
            console.log("selectedItemActionFunction", action)
            if(action == "delete"){
                this.$emit("deleteEventVariance", this.selectedItem)
                this.selectedItem = []
                this.selectedItemCounter = 0
                this.selectAll = false;
                this.setDisplayList();
            }
        },
        openPopup:function(){
            for(var key in this.VarianceInput){
                this.VarianceInput[key].value = null
            }
            this.showAddVariance = true
        },
        addEventVariance: async function(){
           
                var newVariance = {}
                newVariance.ID = new Date().getTime()
                for(var key in this.VarianceInput){
                    if(this.VarianceInput[key].required && !this.VarianceInput[key].value){
                        alert(this.VarianceInput[key].name + " is required")
                        return
                    }
                    newVariance[this.VarianceInput[key].key] = this.VarianceInput[key].value
                    this.VarianceInput[key].value = null
                }
                
            if(this.mode == "Create"){
                this.$emit("addEventVariance", newVariance)
                this.setDisplayList();
                this.showAddVariance = false
            }else{
                await this.AddVariance(newVariance)
            }
        },
        AddVariance:async function(variance){
            console.log("AddVariance", variance)
            //variance.EventID = this.$route.params.eventID
            var object = {
                EventID: this.$route.params.eventID,
                VarianceList: [variance]
            }
            this.isLoading = true;
            try{
                const ret = await this.$store.dispatch('createEventVarianceList',{router:this.router, data: object})
                console.log(ret)
                this.isLoading = false;
                this.listEventVariance(this.$route.params.eventID);
                this.showAddVariance = false
            }catch(err){
                this.isLoading = false;
                console.log("AddVariance error", err);
            }
        },
        listEventVariance: async function(EventID){
            this.isLoading = true;
            try{
                const ret= await this.$store.dispatch('listEventVariance',{router:this.router, data:{EventID:EventID}})
                console.log(ret)
                // this.EventVarianceList = ret.data.EventVariance
                // this.setDisplayList();
                this.EventVarianceList = []
                for(var i = 0; i< ret.data.EventVariance.length; i++){
                    this.EventVarianceList.push({
                        "ID": ret.data.EventVariance[i].VarianceID,
                        "EventDate": ret.data.EventVariance[i].EventDate,
                        "EventTime": ret.data.EventVariance[i].EventStartTime,
                        "Duration": ret.data.EventVariance[i].Duration,
                        "Location": ret.data.EventVariance[i].Location.EventHostLocationID,
                        "HostID": ret.data.EventVariance[i].HostID,
                        "Price": ret.data.EventVariance[i].Price,
                        "MaxSeat": ret.data.EventVariance[i].MaxSeat,
                    })
                }
                //update EventVarianceList sort by EventTime and EventDate desc
                this.EventVarianceList.sort(function(a,b){
                    var aDate = new Date(a.EventDate + " " + a.EventTime)
                    var bDate = new Date(b.EventDate + " " + b.EventTime)
                    return bDate - aDate
                })
                

                this.setDisplayList();
                this.isLoading = false;
            }catch(err){
                this.isLoading = false;
                console.log("listEventVariance error", err);
            }
        },
        setDisplayList(){
            var newlist=[];
            var startNumber = this.index;
            var endNumber = startNumber + this.displaySize;
            if(endNumber > this.EventVarianceList.length){
                endNumber = this.EventVarianceList.length
            }
            console.log(startNumber, endNumber)
            for(var i = startNumber; i<endNumber; i++){
                var location = this.LocationList.find(x => x.EventHostLocationID == this.EventVarianceList[i].Location)
                console.log("location", location)
                var host = this.HostList.find(x => x.HostID == this.EventVarianceList[i].HostID)
                var object = {
                    "ID": this.EventVarianceList[i].ID,
                    "Event Time Slot": this.EventVarianceList[i].EventDate + " " + this.EventVarianceList[i].EventTime,
                    "Duration": this.EventVarianceList[i].Duration,
                    "Location": location.Name,
                    "Host": host.Name.zh + " ( " + host.Name.en + " )",
                    "Price": this.EventVarianceList[i].Price,
                    "Max Seats": this.EventVarianceList[i].MaxSeat,
                }
                newlist.push(object)
            }
            this.TableItemList = newlist;
            this.TableSelectUIControl(this.TableItemList)
        },
        TableSelectUIControl(ItemList){
            this.showNextButton = true;
            if(ItemList.length < this.displaySize){
            this.showNextButton = false;
            }

            var counter = 0;
            for(var i=0; i<ItemList.length;i++){
                var index = this.selectedItem.indexOf(ItemList[i].ID);
                if(index > -1){
                    counter++
                }
            }
            if(counter ==  ItemList.length){
                this.selectAll = true;
            }else{
                this.selectAll = false;
            }
        },
        getHost: async function(){
            try{
                const ret= await this.$store.dispatch('getHost',{router:this.router})
                console.log(ret)
                var hosts = ret.data.Host
                this.HostList = hosts
                for(var i= 0 ; i<hosts.length; i++){
                    var object = {
                    "key": hosts[i].HostID,
                    "name": hosts[i].Name.zh + " ( " + hosts[i].Name.en + " )"
                    }
                    this.VarianceInput.HostInput.option.push(object)
                }
            }catch(err){
                console.log("getHost error", err);
            }
        },
        listLocations: async function(){
            try{
                const ret= await this.$store.dispatch('listLocations',{router:this.router})
                console.log(ret)
                var locations = ret.data.data.Items
                this.LocationList = locations
                for(var i= 0 ; i<locations.length; i++){
                    var object = {
                    "key": locations[i].EventHostLocationID,
                    }
                    if(locations[i].SaleableArea){
                    object.name = locations[i].Name + " ( " + locations[i].SaleableArea.toString() + " ft² )"
                    }
                    else{
                    object.name = locations[i].Name
                    }
                    this.VarianceInput.LocationInput.option.push(object)
                }
            }catch(err){
                console.log("listLocations error", err);
            }
        },
        checkEdited:function(){
            this.$emit("checkEdited", true);
        },

    },
    props: {
        mode: {
            default (){
                return "Create"
            }
        },
        EventVarianceList:{
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            isLoading: false,
            showAddVariance: false,
            TableItemList: [],
            index: 0,
            pageNumber: 1,
            showNextButton: true,
            displaySize: 10,
            HostList: [],
            LocationList: [],
            columns: ["Event Time Slot", "Duration", "Location", "Host",  "Price", "Max Seats"],
            selectedItemAction: [
                {Key: "delete", Name: "Delete selected", Style:"color:#CC2727"},
            ],
            selectedItem: [],
            selectedItemCounter: 0,
            selectAll: false,
            VarianceInput: {
                EventDateInput:{
                    "type": "Date",
                    "key": "EventDate",
                    "name": "Event Date",
                    "required": true,
                    "value": null, 
                },
                EventTimeInput: {
                    "type": "time",
                    "key": "EventTime",
                    "name": "Event Time",
                    "required": true,
                    "value": null,
                },
                EventDurationInput: {
                    "type": "number",
                    "key": "Duration",
                    "name": "Duration (minutes)",
                    "required": true,
                    "value": null,
                },
                LocationInput: {
                    "type": "option",
                    "key": "Location",
                    "name": "Location",
                    "required": true,
                    "value": null,
                    "option": []
                },
                MaxSeatInput: {
                    "type": "number",
                    "key": "MaxSeat",
                    "name": "Max Seats",
                    "required": true,
                    "value": 0,
                },
                PriceInput: {
                    "type": "number",
                    "key": "Price",
                    "name": "Price per Seat",
                    "required": true,
                    "value": null,
                },
                MinimumParticipator:{
                    "type": "number",
                    "key": "MinimumParticipator",
                    "name": "Minimum Participator",
                    "required": true,
                    "value": null,
                },
                HostInput: {
                    "type": "option",
                    "key": "HostID",
                    "name": "Host",
                    "required": true,
                    "value": null,
                    "option": []
                },
            }

        }
    }
}
</script>