<template>

    <div >
        {{ Item.ID }}
        <table style="width:100%">
            <tr>
                <td style="width: 70%">
                    <video
                        v-if="Item.url!='' && Item.type=='Video'"
                        :src=Item.url
                        style="width: 400px; height: 200px; padding-top: 30px;object-fit: contain;"
                        controls    
                    />
                    <img 
                        v-else-if="Item.url!=''"
                        :src=Item.url
                        style="width: 400px; height: 200px; padding-top: 30px;object-fit: contain;"
                    />
                    <md-field v-else>
                        <md-file  :accept="acceptType" @md-change="uploadFile($event, Item.ID)" />
                    </md-field>  
                </td>
                <td style="width: 30%;text-align: center;">
                    <div class="itemRemove"  @click="RemoveItem(Item.ID)"><u>remove</u></div>
                </td>
            </tr>
        </table>
    </div>



</template>
<script>
export default {
    components: {

    },
    methods:{
        RemoveItem: function(ID){
            this.$emit("RemoveItem", ID);
        }, 
        uploadFile: async function(fileList, ID){
        if(fileList.constructor === FileList && fileList[0] !==undefined){
            var file=fileList[0];
            // var object = {
            //     "base64": await this.getBase64(file),
            //     "url": URL.createObjectURL(file),
            // }
            //this.Item = object;
            this.Item.base64 = await this.getBase64(file);
            this.Item.url = URL.createObjectURL(file);
            //this.$emit("SetItemObject", ID, object);
        }
      },
      getBase64(file) {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result)
          }
          reader.readAsDataURL(file)
        })
      },
    },
    name: "object-input-component",
    props: {
        acceptType:{
            default() {
                return [];
            },
        },
        Item:{
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            
        };
    },
    computed: {

    }
};
</script>
<style>

</style>