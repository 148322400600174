import { render, staticRenderFns } from "./QueryProductCard.vue?vue&type=template&id=98f25612&scoped=true"
import script from "./QueryProductCard.vue?vue&type=script&lang=js"
export * from "./QueryProductCard.vue?vue&type=script&lang=js"
import style0 from "./QueryProductCard.vue?vue&type=style&index=0&id=98f25612&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98f25612",
  null
  
)

export default component.exports