import axios from "axios"
import oneSipApiCall from "../apiCall.js"
import url from "../config.js"

const auth={
  login({commit},user){
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({url: url+'/admin-login', data: user, method: 'POST' })
      .then(resp => {
        
        const idToken=resp.data.AuthenticationResult.IdToken;
        const accessToken=resp.data.AuthenticationResult.AccessToken;
        const refreshToken=resp.data.AuthenticationResult.RefreshToken;
        //const user = resp.data.user
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        // Add the following line:
        axios.defaults.headers.common['Authorization'] = idToken;
        commit('auth_success', {idToken,accessToken,refreshToken});
        resolve(resp)
      })
      .catch(err => {
        commit('auth_error')
        localStorage.removeItem('idToken')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        reject(err)
      })
    })
  },
  forceChangePassword({commit},user){
    return new Promise((resolve,reject)=>{
      commit('auth_request')
      axios({url: url+'/force-change-password',data: user, method: 'POST'})
      .then(resp=>{
        const idToken=resp.data.AuthenticationResult.idToken
        const accessToken=resp.data.AuthenticationResult.accessToken
        const refreshToken=resp.data.AuthenticationResult.refreshToken

        localStorage.setItem('idToken', idToken)
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)

        axios.defaults.headers.common['Authorization']=idToken
        commit('auth_success', {idToken,accessToken,refreshToken})
        resolve(resp)
      })
      .catch(err=>{
        commit('auth_error')
        localStorage.removeItem('idToken')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        reject(err)
      })
    })
  },
  // getEvent({commit,state},{router}){
  //   return oneSipApiCall(commit,state,router,'/admin-login',data)
  // },
  // logout({commit,state},{router}){
  //   var token = localStorage.getItem('accessToken');
  //   var object = {"accessToken": token};
  //   const result = oneSipApiCall(commit,state,router,'/admin-logout',object)
  //   console.log("logout result",result);

  //   localStorage.removeItem('idToken')
  //   localStorage.removeItem('accessToken')
  //   localStorage.removeItem('refreshToken')
  //   delete axios.defaults.headers.common['Authorization']
  // }
  logout({commit,state},{router}){
    var token = localStorage.getItem('accessToken');
    var object = {"accessToken": token};
    const result = oneSipApiCall(commit,state,router,'/admin-logout',object)
    return new Promise((resolve, reject) => {
      commit('logout')
      localStorage.removeItem('idToken')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  }
}

export default auth;