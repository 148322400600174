import axios from "axios"
const Buffer = require('buffer').Buffer;

const upload={
async updateMediaToS3({commit,state}, {file, urlObject}){
    console.log("updateMediaToS3",urlObject)
    delete axios.defaults.headers.common["Authorization"];
    axios.defaults.data = "";
    var buf = Buffer.from(file.replace(/^data:+\w+\/\w+;base64,/, ""),'base64')
    // const signedUrl = "/uploadToS3" + urlObject.url.replace("https://onesip-media-test-temp.s3.ap-southeast-1.amazonaws.com", "");
    const signedUrl = urlObject.url;
    console.log(axios.defaults);
    try {
      const response = await axios.put(signedUrl, buf, {
        headers: {
          'Content-Type': urlObject.ContentType,
          'Content-Encoding': 'base64'
        }
      });
      axios.defaults.headers.common["Authorization"] = localStorage.getItem('idToken');
      console.log("response",response)
      return response;
    } catch (error) {
      console.error(`Error uploading image to S3: ${error}`);
      axios.defaults.headers.common["Authorization"] = localStorage.getItem('idToken');
      return error;
    }
  },
}
export default upload;