
<template>
    <div class="row" style="padding: 0 10px">
      <div class="column" style="width: 50%">
        <div class="row">
          <md-button class="backbutton" style="background-color:white !important;" @click="goBack">
            <md-icon class="backIcon" style="color:black !important;margin-left: -10px;">arrow_back_ios</md-icon>
          </md-button>
          <h3 class="title" style="flex: 1; padding-left: 20px;"> {{HeaderTitle}}</h3>
        </div>
      </div>

      <div class="column" style="width: 50%; text-align: right;">
        <button v-if="formActionButton" @click="formAction()" class="tablenewItemButton">{{formActionButton}}</button>
      </div>
      <Transition name="modal" v-if="showPopup">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="popupaction"
            :PopupTitle="PopupTitle"
            :PopupContent="PopupContent"
            :actionButton="actionButton"
          ></Popup>
      </Transition>

    </div>
</template>
  
  <script>
import  Popup from "./Popup.vue";

  export default {
    components: {
      Popup,
    },
    methods:{
      formAction: function(){
        this.$emit("formAction");
      },
      popupcancel: function(){
        this.showPopup = false;
      },
      popupaction: function (){
        this.showPopup = false;
        this.$router.go(-1);
      },  
      goBack: function(){
        if(this.confirmBack){
          this.showPopup = true;
          console.log("show confirm popup");
        }else{
          this.$router.go(-1)
        }
        
      },
    },

    name: "form-header-component",
    props: {
        HeaderTitle:{
          required: true,
          type: String,
          default() {
              return "";
          },
        },
        formActionButton: {
          type: String,
          default() {
              return "";
          },
        },
        confirmBack:{
          default() {
              return false;
          },
        }
    },
    data() {
      return {
        showPopup: false,
        PopupTitle: "Unsaved Data",
        PopupContent: "There are unsaved data on this page, are you sure you want to leave this page?",
        actionButton: "Leave anyway"
      };
    },
    computed: {

    }
  };
  </script>

  <style>
    .backIcon{
      padding: unset;
      margin: unset;
      color:black !important;
    }
    .backbutton{
      padding: unset;
      margin: 15px 0 0 0 !important;
      min-width: 40px !important;
      width: 40px !important;
      height: 45px !important;
    }
  </style>
  