const getAbbrNumber = function( value ){
  const abbrChar = ['', 'K', 'M', 'B', 'T'];
  var pt = 0;

  while( value >= 1000 && pt < abbrChar.length - 1 ){
    pt ++;
    value /= 1000;
  }

  if( pt == 0 ){
    return value;
  }

  if( value >= 10 ){
      return Math.floor( value ) + abbrChar[ pt ];
  } else{
      return Math.floor( value * 10 ) / 10 + abbrChar[ pt ];
  }

}

export default {
  getAbbrNumber,
};
